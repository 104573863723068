import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import { Header } from "components/layout/header";
import {
	ControlOrdersEdit,
	OrdersCreator,
	ControlOrdersInfo,
	FilterOrders,
	OrdersInfo,
} from "components/orders";
import {
	SearchForm,
	Button,
	Table,
	Tr,
	Td,
	Icon,
	Text,
	ControlMenu,
	Input,
	Modal,
	ButtonGroup,
	PageDescription,
	Screen,
	Disclosure,
	Pagination,
} from "components/ui";
import { $api } from "api";
import { getDate } from "utils/getDate";
import { useGetOrdersQuery } from "redux/reducers/orders";
import { useDeleteOrdersMutation } from "redux/reducers/orders";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "redux/asyncAction/region";
import { getCities } from "redux/asyncAction/region";

export default function Orders() {
	const { t } = useTranslation(["orders", "common"]);
	const [openedMenu, setOpenedMenu] = useState("");
	const [infoOrder, setInfoOrder] = useState({});
	const [editingOrder, setEditingOrder] = useState({});
	const [checkedOrders, setCheckedOrders] = useState([]);
	const [idDeletingOrder, setIdDeletingOrder] = useState(-1);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenFilterMobile, setIsOpenFilterMobile] = useState(false);
	const defaultFilters = { time: "all", region: null, status: "all" };
	const [filters, setFilters] = useState(defaultFilters);
	const { showMessage } = useMessage();
	const { deviceWidth } = useWindowSize();
	const isEditMode = openedMenu === "edit";
	const isInfoMode = openedMenu === "info";
	const isCreateMode = openedMenu === "create";
	const [searchText, setSearchText] = useState("");
	const [period, setPeriod] = useState("");
	const { cities } = useSelector((state) => state.regionReducer);

	//console.log("searchtext", searchText);

	const periods = {
		all: "",
		week: "FOR_WEEK",
		month: "FOR_MONTH",
		today: "FOR_DAY",
	};
	const handlePeriod = (per) => {
		console.log(per);

		setPeriod(periods[per]);
	};

	let dispatch = useDispatch();

	useEffect(() => {
		//dispatch(getGlobalCategories());
		dispatch(getCountries());
		dispatch(getCities());
	}, []);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	const { data } = useGetOrdersQuery({
		period,
		limit: itemsPerPage,
		page: currentPage,
		status: filters.status === "all" ? null : filters.status,
		// period: "FOR WEEK" - "FOR MONTH"
		cityId: filters.region === "all" ? null : filters.region,
		query: searchText,
		// sortBy: "CREATED_ASC",
	});
	const [deleteOrders] = useDeleteOrdersMutation();

	const orders = data?.orders || [];
	// console.log(data?.orders, "data");
	//delete after connect to db
	// const [orders, setOrders] = useState(data?.orders);
	// console.log(orders, "orders", data);
	const closeMenu = () => {
		setOpenedMenu("");
		isInfoMode && setInfoOrder({});
		isEditMode && setEditingOrder({});
	};

	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenu("");
		isActive ? setOpenedMenu(type) : closeMenu();
	};

	const setDeleteMode = (id) => {
		setIdDeletingOrder(id);
		setIsOpenModal(true);
	};

	const deleteItems = () => {
		setIsOpenModal(false);
		deleteOrders({ orderIds: [idDeletingOrder] });
	};

	const setInfoMode = (id) => {
		setInfoOrder(orders.find((v) => v._id === id));
		toggleOpenMenu(true, "info");
	};

	const setEditMode = (id) => {
		setEditingOrder(orders.find((v) => v._id === id));
		toggleOpenMenu(true, "edit");
	};

	const setCreateMode = () => {
		toggleOpenMenu(true, "create");
	};

	const setChecked = (checked, id) => {
		if (checked && !checkedOrders.includes(id)) {
			setCheckedOrders([...checkedOrders, id]);
		} else {
			setCheckedOrders(checkedOrders.filter((v) => v !== id));
		}
	};

	const setAllChecked = (checked) => {
		if (checked) {
			const notCheckedOrders = orders.filter(
				(v) => !checkedOrders.includes(v._id)
			);
			setCheckedOrders([
				...checkedOrders,
				...notCheckedOrders.map((v) => v._id),
			]);
		} else {
			setCheckedOrders([]);
		}
	};

	const onChangeFilter = (value, filter) => {
		setFilters({
			...filters,
			[filter]: value,
		});
	};

	const clearFilters = () => {
		setFilters(defaultFilters);
	};

	const MobileFilter = () => (
		<Screen size="xl" down>
			<Icon
				name="filters"
				size="md"
				hoverColor="gray"
				onClick={() => setIsOpenFilterMobile(true)}
			/>
		</Screen>
	);

	const afterSuccessEditing = async (data) => {
		setOrders(
			orders.map((order) =>
				order._id === data._id ? Object.assign({}, data) : order
			)
		);
		showMessage({ value: t("messages.dataUpdated", { ns: "common" }) });
	};

	const afterSuccessCreating = async (data) => {
		data._id = Math.floor(Math.random() * 1000);
		data.number = orders?.length;
		data.sum = 3333;

		setOrders([...orders, data]);
		showMessage({ value: t("messages.dataUpdated", { ns: "common" }) });
	};

	console.log(orders, infoOrder, "messages.dataUpdated");

	const getNum = (page, i) => {
		return (page - 1) * 15 + i + 1;
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<Header options={<MobileFilter />} />
			<main>
				<Screen size="xl" down>
					<SearchForm.Section sectionFirst>
						<SearchForm placeholder={t("searchPlaceholder")} />
					</SearchForm.Section>
				</Screen>

				<PageDescription.Section>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={t("title")} />
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={
									"Этот раздел предназначен для работы с заказами вашего интернет-магазина. Функционал позволит вам выполнять следующие действия с заказами: создавать новые, узнавать и редактировать информацию о уже имеющихся в истории, а также отслеживать статусы. Для комфортной работы можете воспользоваться фильтрами и поиском."
								}
								placementCustomTitle="right"
								customTitle
								style={{ marginTop: "-10px", marginLeft: "8px" }}
							/>
						</Screen>
					</div>
				</PageDescription.Section>

				<Screen size="xl">
					<SearchForm.Section>
						<SearchForm.Group>
							<SearchForm
								placeholder={t("searchPlaceholder")}
								onSearch={setSearchText}
							/>
							<SearchForm.GroupItem>
								<Button
									style={{ marginLeft: 78 }}
									onClick={() => setCreateMode()}
								>
									{t("addOrder")}
								</Button>
							</SearchForm.GroupItem>
						</SearchForm.Group>
					</SearchForm.Section>
				</Screen>
				<section className="section">
					<div className="container">
						{orders?.length ? (
							<div className="row row-20">
								<div className="col-xl-3 col-xxxl-2">
									<Screen size="xl">
										<FilterOrders
											cities={cities}
											filters={filters}
											onChange={onChangeFilter}
											clear={clearFilters}
											handlePeriod={handlePeriod}
										/>
									</Screen>
									<OrdersInfo data={data.statistics} />
									<Screen size="xl" down>
										<Button
											className="offset-top-10"
											onClick={() => setCreateMode()}
											fluid={deviceWidth === "small"}
										>
											{t("addOrder")}
										</Button>
									</Screen>
								</div>
								<div className="col-xl-9 col-xxxl-10">
									<Screen size="xl" down>
										<Text size="md" color="blue">
											{t("userOrders")}
										</Text>
									</Screen>
									<Table
										className={cn(deviceWidth !== "large" && "offset-top-10")}
									>
										<Table.Head>
											<Td width={45}>
												<Input
													type="checkbox"
													name="all"
													onChange={(e) => setAllChecked(e.target.checked)}
													checked={orders.length === checkedOrders.length}
												/>
											</Td>
											<Td width={60}>№</Td>
											<Td width={238}>{t("table.client")}</Td>
											<Td width={150}>{t("table.region")}</Td>
											<Td width={150}>{t("table.created")}</Td>
											<Td width={150}>{t("table.paid")}</Td>
											<Td width={150}>{t("table.delivered")}</Td>
											<Td width={150}>{t("table.sum")}</Td>
											{/* <Td width={130}>{t("table.deliverySum")}</Td> */}
											<Td width={130} style={{ marginLeft: "-8px" }}>
												{t("table.status")}
											</Td>
											{/*<Td width={deviceWidth === "large" ? 113 : 44}>
												<div
													style={{
														display: "flex",
														justifyContent: "flex-end",
													}}
												>
													<Icon
														width={30}
														name="delete"
														size="md"
														color="red"
														onClick={() =>
															deleteOrders({ orderIds: checkedOrders })
														}
														title={t("buttonDesc.delete", {
															value: "",
															ns: "common",
														})}
														cursorPointer
													/>
												</div>
											</Td>*/}
										</Table.Head>
										<Table.Body>
											{orders?.map((order, i) => {
												const colors = {
													delivered: "#B3DE75",
													process: "#78C2DD",
													fail: "red",
												};

												const bgs = {
													delivered: "rgba(179, 222, 117, 0.2)",
													process: "rgba(120, 194, 221, 0.2)",
													fail: "rgba(235, 35, 22, 0.2)",
												};
												return (
													<Tr key={order._id}>
														<Td width={45}>
															<Input
																type="checkbox"
																name={order?.number?.toString()}
																onChange={(e) =>
																	setChecked(e.target.checked, order._id)
																}
																checked={checkedOrders.includes(order._id)}
															/>
														</Td>
														{/* <Td width={50}>{order.number}</Td> */}
														<Td width={60}>{order?.orderNumber}</Td>
														<Td
															width={238}
															overflow
														>{`${order?.firstName} ${order?.lastName}`}</Td>
														<Td width={150} overflow>
															{order?.shippingAddress?.city?.title}
														</Td>
														<Td width={150}>{getDate(order.createdAt)}</Td>
														<Td width={150}>
															{order.paid ? "Оплачен" : "Не оплачен"}
														</Td>
														<Td width={150}>
															{order?.deliveredAt
																? getDate(order?.deliveredAt)
																: ""}
														</Td>
														<Td width={130}>{order.amount} ₽</Td>
														{/* <Td width={130}>{order.shippingPrice} ₽</Td> */}
														<Td
															width={150}
															style={{
																border: `1px solid ${
																	colors[
																		order.status === "DELIVERED"
																			? "delivered"
																			: "fail"
																	]
																}`,
																marginLeft: "-8px",
																marginRight: "8px",
																borderRadius: "4px",
																background: `${
																	bgs[
																		order.status === "DELIVERED"
																			? "delivered"
																			: "fail"
																	]
																}`,
															}}
														>
															{order.status
																? t(`statuses.${order.status}`)
																: ""}
														</Td>
														<Td
															width={deviceWidth === "large" ? 105 : 44}
															buttons={deviceWidth === "large"}
															ellipsis={deviceWidth !== "large"}
														>
															<Screen size="xl" down>
																<Disclosure>
																	<Text
																		as="p"
																		onClick={() => setInfoMode(order._id)}
																	>
																		{t("buttonDesc.info", {
																			value: t("order2"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setEditMode(order._id)}
																	>
																		{t("buttonDesc.edit", {
																			value: t("order"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setDeleteMode(order._id)}
																	>
																		{t("buttonDesc.delete", {
																			value: t("order"),
																			ns: "common",
																		})}
																	</Text>
																</Disclosure>
															</Screen>
															<Screen size="xl">
																<Icon
																	name="info-border"
																	size="md"
																	onClick={() => setInfoMode(order._id)}
																	title={t("buttonDesc.view", {
																		value: "",
																		ns: "common",
																	})}
																	style={{ marginRight: 10 }}
																	cursorPointer
																/>
																<Icon
																	name="edit"
																	size="md"
																	turnOff={editingOrder.id !== order._id}
																	onClick={() => setEditMode(order._id)}
																	title={t("buttonDesc.edit", {
																		value: "",
																		ns: "common",
																	})}
																	style={{ marginRight: 10 }}
																	cursorPointer
																/>
																{/*<Icon
																	name="delete"
																	size="md"
																	color="red"
																	onClick={() => setDeleteMode(order._id)}
																	title={t("buttonDesc.delete", {
																		value: "",
																		ns: "common",
																	})}
																	cursorPointer
																/>*/}
															</Screen>
														</Td>
													</Tr>
												);
											})}
										</Table.Body>
									</Table>
									<Pagination
										//key={currentPage}
										current={currentPage}
										pageSize={itemsPerPage}
										onChange={handlePageChange}
										total={data.total}
										mobile={deviceWidth !== "large"}
									/>
								</div>
							</div>
						) : (
							<div className="row row-20">
								<div className="col-xl-3 col-xxxl-2">
									<Screen size="xl">
										<FilterOrders
											cities={cities}
											filters={filters}
											onChange={onChangeFilter}
											clear={clearFilters}
											handlePeriod={handlePeriod}
										/>
									</Screen>
									<OrdersInfo />
									<Screen size="xl" down>
										<Button
											className="offset-top-10"
											onClick={() => setCreateMode()}
											fluid={deviceWidth === "small"}
										>
											{t("addOrder")}
										</Button>
									</Screen>
								</div>
								<div className="col-xl-9 col-xxxl-10">
									<Text size="md">{t("emptyPage")}</Text>
								</div>
							</div>
						)}
					</div>
				</section>
			</main>

			{isInfoMode && (
				<ControlOrdersInfo
					order={infoOrder}
					orderId={infoOrder._id}
					isOpen={isInfoMode}
					onClose={closeMenu}
				/>
			)}
			{isEditMode && (
				<ControlOrdersEdit
					initialOrder={editingOrder}
					orderId={editingOrder._id}
					isOpen={isEditMode}
					onClose={closeMenu}
					afterSuccessChanges={afterSuccessEditing}
					mode="edit"
				/>
			)}
			{isCreateMode && (
				<OrdersCreator
					initialOrder={{}}
					isOpen={isCreateMode}
					onClose={closeMenu}
					afterSuccessCreating={afterSuccessCreating}
					mode={openedMenu}
				/>
			)}

			<ControlMenu
				isOpen={isOpenFilterMobile}
				onClose={() => setIsOpenFilterMobile(false)}
				observerChanges={false}
				title={t("filters.mobile.title")}
			>
				<Screen size="xl" down>
					<FilterOrders
						cities={cities}
						filters={filters}
						onChange={onChangeFilter}
						clear={clearFilters}
						handlePeriod={handlePeriod}
					/>
				</Screen>
			</ControlMenu>

			<Modal
				isOpen={isOpenModal}
				onRequestClose={() => setIsOpenModal(false)}
				size="sm"
			>
				<div className="modal-body">
					<div className="modal-body__text">
						<Text as="p" size="xl" align="center">
							{checkedOrders.length > 1
								? t("alertMessage.items")
								: t("alertMessage.item")}
						</Text>
					</div>
					<div className="modal-body__confirm-buttons">
						<ButtonGroup>
							<Button fluid={true} onClick={() => deleteItems()}>
								{t("buttons.confirm", { ns: "common" })}
							</Button>
							<Button fluid={true} onClick={() => setIsOpenModal(false)}>
								{t("buttons.cancel", { ns: "common" })}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</Modal>
		</>
	);
}
