import React from 'react'
import { useTranslation } from 'react-i18next'
import ActiveLink from 'components/utils/ActiveLink'
import { Icon, Text } from 'components/ui'
import styles from './header.module.sass'

export default function MobileMenu() {
	const { t } = useTranslation(['common'])

	const logout = () => {
		localStorage.removeItem('zztoken')
		window.location.reload(false);
	}


	return (
		<ul className={styles['mobile-menu']}>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/dashboard' className={styles.link} activeClassName={styles.active}>
					<Icon name='dashboard' size='md' color='blue' />
					<Text size='xl'>{t('menu.dashboard')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/orders' className={styles.link} activeClassName={styles.active}>
					<Icon name='orders' size='md' color='blue'  />
					<Text size='xl'>{t('menu.orders')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/clients' className={styles.link} activeClassName={styles.active}>
					<Icon name='clients' size='md' color='blue'  />
					<Text size='xl'>{t('menu.clients')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/catalog/category' className={styles.link} activeClassName={styles.active} nav='catalog'>
					<Icon name='catalog' size='md' color='blue'  />
					<Text size='xl'>{t('menu.catalog')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/reviews' className={styles.link} activeClassName={styles.active}>
					<Icon name='reviews' size='md' color='blue'  />
					<Text size='xl'>{t('menu.reviews')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/hints' className={styles.link} activeClassName={styles.active}>
					<Icon name='hints' size='md' color='blue'  />
					<Text size='xl'>{t('menu.hints')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/settings' className={styles.link} activeClassName={styles.active}>
					<Icon name='settings' size='md' color='blue'  />
					<Text size='xl'>{t('menu.settings')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/seo' className={styles.link} activeClassName={styles.active}>
					<Icon name='seo' size='md' color='blue'  />
					<Text size='xl'>{t('menu.seo')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/payment' className={styles.link} activeClassName={styles.active}>
					<Icon name='payment' size='md' color='blue'  />
					<Text size='xl'>{t('menu.payment')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/delivery' className={styles.link} activeClassName={styles.active}>
					<Icon name='delivery' size='md' color='blue'  />
					<Text size='xl'>{t('menu.delivery')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
				<ActiveLink to='/rate' className={styles.link} activeClassName={styles.active}>
					<Icon name='rate' size='md' color='blue'  />
					<Text size='xl'>{t('menu.rate')}</Text>
				</ActiveLink>
			</li>
			<li className={styles['mobile-menu__item']}>
	
				<div className={styles.link} onClick={logout}>
					<Icon name='exit' size='md' color='blue' cursorPointer={true} />
					<Text size='xl'>Выйти</Text>
				</div>
		
			</li>
		</ul>
	)
}
