import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";
import omit from "lodash/omit";

const apiWithBannersTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Banner", "DefaultBanner"],
});

export const bannersApi = apiWithBannersTags.injectEndpoints({
	endpoints: (builder) => ({
		getDefaultBanners: builder.query({
			query: (params) => ({
				url: "/getDefaultBanners",
				params,
			}),
			providesTags: ["DefaultBanner"],
		}),
		// deletePromocodes: builder.mutation({
		// 	query: (data) => ({
		// 		url: `/promocodes/${data._id}`,
		// 		method: "DELETE",
		// 		data,
		// 	}),
		// 	invalidatesTags: ["Promocodes"],
		// }),
		// editPromocode: builder.mutation({
		// 	query: (data) => ({
		// 		url: `/promocodes/${data.id}`,
		// 		method: "PUT",
		// 		data,
		// 	}),
		// 	invalidatesTags: ["Promocodes"],
		// }),
		addBanner: builder.mutation({
			query: (data) => ({
				url: `/addBanner`,
				method: "POST",
				data,
			}),
			invalidatesTags: ["Banner"],
		}),
		updateBanner: builder.mutation({
			query: (data) => ({
				url: `/updateBanner`,
				method: "POST",
				data,
			}),
			invalidatesTags: ["Banner"],
		}),
		removeBanner: builder.mutation({
			query: (data) => ({
				url: `/removeBanner`,
				method: "POST",
				data,
			}),
			invalidatesTags: ["Banner"],
		}),
	}),
});

export const {
	// useDeletePromocodesMutation,
	useGetDefaultBannersQuery,
	// useEditPromocodeMutation,
	useAddBannerMutation,
	useUpdateBannerMutation,
	useRemoveBannerMutation,
} = bannersApi;
