import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import useWindowSize from "hooks/useWindowSize";
import useMessage from "hooks/useMessage";
import { Select, Text, Textarea, Button, ControlMenu } from "components/ui";
import {
	useEditReviewsMutation,
	useGetReviewQuery,
} from "redux/reducers/reviews";

const ControlReviewsEdit = ({
	initialReview,
	isOpen,
	onClose,
	afterSuccessChanges,
	reviewId,
}) => {
	const { t } = useTranslation(["reviews", "common"]);
	const [dataReview, setDataReview] = useState({});
	const [haveChanges, setHaveChanges] = useState(false);
	const {
		register,
		handleSubmit,
		trigger,
		getValues,
		reset,
		formState: { errors },
		control,
		setValue,
	} = useForm();
	const { deviceWidth } = useWindowSize();
	const { showMessage } = useMessage();
	const allowedStatuses = ["PUBLISHED", "MODERATED", "REJECTED"];

	const getReviewQuery = useGetReviewQuery({ reviewId });
	const review = getReviewQuery.data;
	console.log("reviewId", reviewId, review);

	useEffect(() => {
		// setDataReview(review?.data);
		reset(
			{ status: review?.data?.status, reply: review?.data?.reply },
			{ keepDefaultValues: true }
		);
	}, [isOpen, review]);
	useEffect(() => {
		if (!review) return;
		if (Object.keys(review).length) {
			//change checking objects when will be connect to db
			if (JSON.stringify(review) !== JSON.stringify(review)) {
				setHaveChanges(true);
			}
		}

		return () => setHaveChanges(false);
	}, [initialReview, review]);
	console.log(review, "reviewreview");

	const onChangingReview = (value, name) => {
		console.log(value, name);
		// setDataReview({
		// 	...review,
		// 	[name]: value,
		// });
	};

	const canCloseMenu = async () => {
		return await trigger(Object.keys(getValues()));
	};

	const [editReviews] = useEditReviewsMutation();

	const saveEditingReview = async (data) => {
		editReviews({
			reviewId: reviewId,
			status: data.status,
			reply: data.reply,
		});
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
		// afterSuccessChanges(review);
		onClose();
	};

	if (!review) return null;

	return (
		<ControlMenu
			isOpen={isOpen}
			onClose={onClose}
			title={t("drawer.editReview")}
			hasChanges={haveChanges}
			isCanClose={canCloseMenu}
			onSaveData={saveEditingReview}
		>
			<form onSubmit={handleSubmit(saveEditingReview)}>
				<div className="row row-10">
					<div className="col-md-6">
						<div>
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								style={{ marginRight: 5 }}
							>
								{t("table.client")}
							</Text>
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								weight="medium"
							>
								{`${review?.data?.buyer?.firstName} ${review?.data?.buyer?.lastName}`}
							</Text>
						</div>
						<div className="offset-top-10">
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								style={{ marginRight: 5 }}
							>
								{t("table.phone")}
							</Text>
							<Text size="sm" weight="medium">
								{review?.data?.buyer?.phone}
							</Text>
						</div>
						<div className="offset-top-10">
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								style={{ marginRight: 5 }}
							>
								{t("drawer.reviewInfo.feedback")}
							</Text>
							<Text size="sm" weight="medium">
								{review?.data.text}
							</Text>
						</div>
					</div>
					<div className="col-md-6">
						<div>
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								style={{ marginRight: 5 }}
							>
								{t("table.region")}
							</Text>
							<Text size="sm" weight="medium">
								{review?.data?.buyer?.city?.title}
							</Text>
						</div>
						<div className="offset-top-10">
							<Text
								size={deviceWidth === "large" ? "md" : "sm"}
								style={{ marginRight: 5 }}
							>
								{t("table.created")}
							</Text>
							<Text size="sm" weight="medium">
								{review?.data?.createdAt
									.slice(0, 10)
									.split("-")
									.reverse()
									.join(".")}
							</Text>
						</div>
						<div className="offset-top-10">
							<Controller
								control={control}
								name="status"
								render={({ field }) => (
									<Select
										// value={
										// 	dataReview?.status ? dataReview?.status : review?.data?.status
										// }
										value={field.value}
										onChange={(value) => setValue(field.name, value)}
										label={t("table.status")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										labelColor="blue"
										fluid
									>
										{allowedStatuses.map((status) => (
											<Select.Option key={status} value={status}>
												{t("statuses." + status)}
											</Select.Option>
										))}
									</Select>
								)}
							/>
						</div>
					</div>
					<div className="col-12">
						<Textarea
							type="text"
							name="reply"
							register={register}
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label={t("drawer.reviewInfo.commentManager")}
							placeholder={t("drawer.placeholderComment")}
							// value={dataReview?.reply}
							// onChange={(data) => onChangingReview(data, "reply")}
							errors={errors}
							heightSize="lg"
							labelSize={deviceWidth === "large" ? "md" : "sm"}
							fluid
						/>
					</div>
				</div>
				<Button fluid={deviceWidth === "small"} type="submit">
					{t("buttons.save", { ns: "common" })}
				</Button>
			</form>
		</ControlMenu>
	);
};

ControlReviewsEdit.propTypes = {
	initialReview: PropTypes.shape({}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	afterSuccessChanges: PropTypes.func.isRequired,
};

export default ControlReviewsEdit;
