import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Icon, Text } from "components/ui";
import styles from "./pagination.module.sass";
import { Pagination as Pag } from "antd";

const Pagination = ({
	className,
	mobile,
	current,
	pageSize = 15,
	total,
	onChange,
}) => {
	const { t } = useTranslation(["common"]);

	const paginationClassList = cn(styles.pagination, className);

	if (mobile) {
		return (
			<Text
				className={cn(styles.pagination, styles["pagination-mobile"])}
				decoration="underline"
				size="md"
			>
				{t("words.showMore")}
			</Text>
		);
	}

	return (
		<div className={paginationClassList}>
			<Pag
				className={styles.pagCont}
				onChange={(value) => onChange(value)}
				defaultCurrent={1}
				current={current}
				total={total}
				pageSize={pageSize}
			/>
		</div>
	);
};

Pagination.propTypes = {
	className: PropTypes.string,
	mobile: PropTypes.bool,
};

export default Pagination;
