import { $mediaApi } from "api";
import { $api } from "api";
import { toast } from "materialize-css";

class Auth {
	async loginUser(email, password) {
		const res = await $api.post("/auth/login", { email, password });
		localStorage.setItem("zztoken", res.data.authToken);
		return res;
	}

	async regUser(email, name, password) {
		const res = await $api.post("/auth/registration", {
			email,
			name,
			password,
		});
		localStorage.setItem("regtoken", res.data.authToken);
		return res;
	}

	async resetPassword(email) {
		try {
			return await $api.post("/auth/resetPassword", { email });
		} catch (error) {
			toast({
				html: "Указанная электронная почта не найдена",
				classes: "error",
			});
		}
	}

	async getImgStr(userLogo) {
		let formData = new FormData();
		formData.append("image", userLogo);
		const res = await $mediaApi.post("/uploadMedia", formData);
		return res;
	}

	async searchInn(query) {
		try {
			const res = await $api.get(`/dadataGetOrganizationByInn?inn=${query}`);
			return res;
		} catch (error) {
			toast({ html: "Ошибка сервера", classes: "error" });
		}
	}

	async searchBik(query) {
		try {
			const res = await $api.get(`/dadataGetOrganizationByBik?bik=${query}`);
			// console.log(res.data, query, "apiSearchBik");
			return res;
		} catch (error) {
			toast({ html: "Ошибка сервера", classes: "error" });
		}
	}

	async checkPartner(email) {
		try {
			const res = await $api.post("/auth/ifPartnerExists", { email });
			return res;
		} catch (error) {
			toast({ html: "Ошибка сервера", classes: "error" });
		}
	}

	async regClient(user, shop, requisites, numContract) {

		try {
			const res = await $api.post("/auth/registration", {
				partnerData: {
					email: user.email,
					name: user.name,
					password: user.password,
					organizationName: requisites.name,
					phoneNumber: shop.number,
				},
				shopData: {
					subDomain: shop.subdomain,
					mainInformation: {
						title: shop.name,
						countryId: shop.country,
						cityId: shop.city,
						rootCategory: shop.category,
						phoneNumber: shop.number,
						advantages: shop.advantages,
						logoUrl: shop?.logoUrl,

						schedule: {
							mondayToFriday: {
								openTime: shop.weekdaysFrom,
								closeTime: shop.weekdaysTo,
							},
							saturdayToSunday: {
								openTime: shop.weekendFrom,
								closeTime: shop.weekendTo,
							},
						},

						shortDescription: shop.description,
					},
					bankDetails: {
						organizationName: requisites.name,
						OKVED: "OOO",
						INN: requisites.inn,
						KPP: requisites.kpp,
						OGRN: requisites.ogrn,
						BIK: requisites.bik,
						taxType: requisites.tax,
						bankName: requisites.bank,
						checkingAccount: requisites.checkingAccount,
						officialAddress: requisites.legalAddress,
						directorFullName:
							requisites.leadFirstName +
							" " +
							requisites.leadMiddleName +
							" " +
							requisites.leadLastName,
						contractNumber: numContract,
					},
				},
			});
			localStorage.setItem("zztoken", res.data.authToken);
			return res;
		} catch (error) {
			toast({ html: "Ошибка сервера", classes: "error" });
		}
	}
}

export default new Auth();
