import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import { ControlDomainEdit } from "components/seo";
import { Text, Icon, Button, ControlMenu, Screen } from "components/ui";
import domainImg from "assets/seo/domain.svg";
import styles from "./seo.module.sass";
import { updateDomain } from "../../redux/asyncAction/settings";
import { useDispatch } from "react-redux";

const Domain = ({ domain }) => {
	const { t } = useTranslation(["seo", "common"]);
	const { showMessage } = useMessage();
	let dispatch = useDispatch();
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [domainInfoSettings, setDomainInfoSettings] = useState({});
	const { deviceWidth } = useWindowSize();

	const closeControlMenu = () => {
		setIsOpenMenu(false);
	};
	const saveUserDomain = () => {
		dispatch(
			updateDomain({
				domain: domainInfoSettings.domain,
				subDomain: domainInfoSettings.subDomain,
			})
		);
		closeControlMenu();
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
	};
	console.log(domainInfoSettings, "domainInfoSSSSSS", domain);
	return (
		<>
			<div className={styles["domain-settings"]}>
				<Text size="md" color="blue">
					{t("domain.title")}
				</Text>
				<Screen size="xl" down>
					<Text as="p" size="sm" className="offset-top-20">
						{t("domain.connectDomain")}
					</Text>
				</Screen>
				<div className={cn(styles["domain-settings-wrapper"], "offset-top-10")}>
					<Screen size="xl">
						<div style={styles["domain-settings__icon"]}>
							<img src={domainImg} alt="" />
						</div>
					</Screen>
					<div className={styles["domain-settings__content"]}>
						<Text as="p" size="md" color="gray" style={{ fontSize: 12 }}>
							{t("domain.connectYourDomain")}
						</Text>
						<div className="offset-top-10">
							<Text as="p" size="md" color="gray">
								{t("domain.currentAddress")}
								<a
									href={
										domainInfoSettings.domain
											? `https://${domainInfoSettings.domain}.zumzak.ru`
											: domainInfoSettings.subDomain
											? `https://${domainInfoSettings.subDomain}.zumzak.ru`
											: `https://${domain}.zumzak.ru`
									}
									target="_blank"
								>
									<Text size="md" color="blue" style={{ marginLeft: 10 }}>
										{domainInfoSettings.domain
											? `https://${domainInfoSettings.domain}.zumzak.ru`
											: domainInfoSettings.subDomain
											? `https://${domainInfoSettings.subDomain}.zumzak.ru`
											: `https://${domain}.zumzak.ru`}
									</Text>
								</a>
							</Text>
						</div>
						<Button
							className="offset-top-15"
							onClick={() => setIsOpenMenu(true)}
							fluid={deviceWidth === "small"}
						>
							{t("domain.changeAddress")}
						</Button>
					</div>
					<Screen size="xl">
						<div className={styles["domain-settings__faq"]}>
							<Icon
								name="question-border"
								size="md"
								title={t("domain.description")}
								customTitle
							/>
						</div>
					</Screen>
				</div>
			</div>

			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeControlMenu}
				title={t("domain.drawer.title")}
				observerChanges={false}
			>
				<ControlDomainEdit
					onSubmit={saveUserDomain}
					domainInfo={domainInfoSettings}
					setDomainInfo={setDomainInfoSettings}
				/>
			</ControlMenu>
		</>
	);
};

Domain.propTypes = {
	domain: PropTypes.string.isRequired,
};

export default Domain;
