import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'components/layout/header'
import { PageDescription } from 'components/ui'

export default function Delivery() {
	const { t } = useTranslation(['delivery'])

	return (
		<>
			<Header />
			<main>
				<PageDescription.Section>
					<PageDescription
						title={t('title')}
						description={t('description')}
					/>
				</PageDescription.Section>

				<section className='section'>
					<div className='container'>
					</div>
				</section>
			</main>
		</>
	)
}
