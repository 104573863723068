import React from "react";
import "./rc-tree-select.css";
import clsx from "clsx";
import RcTreeSelect from "rc-tree-select";
import "./tree-select.scss";
import formStyles from "../form/form.module.sass";
import cn from "clsx";
import get from "lodash/get";
import { FormError, FormLabel } from "../form";

export const TreeSelect = ({
	className,
	dropdownClassName,
	labelClassName,
	errors,
	errorPos,
	fluid,
	label,
	labelSize,
	labelColor,
	name,
	...props
}) => {
	const error = get(errors || {}, name);

	const formInputWrapperClassList = cn(
		formStyles["form-input-wrapper"],
		formStyles.select,
		{
			[formStyles.error]: Boolean(error),
			[formStyles.isFluid]: fluid,
			errorPos: errorPos,
		}
	);

	const labelClassList = clsx(labelClassName, {
		[formStyles["color-" + labelColor]]: labelColor,
	});

	return (
		<label className={formInputWrapperClassList}>
			<FormLabel
				label={label}
				className={labelClassList}
				labelSize={labelSize}
			/>
			<RcTreeSelect
				treeIcon={() => null}
				switcherIcon={(obj) =>
					obj.isLeaf ? null : (
						<div
							className={clsx(
								"rc-tree-select-dropdown-switcher-icon",
								obj.expanded &&
									"rc-tree-select-dropdown-switcher-icon--expanded"
							)}
						/>
					)
				}
				className={clsx(className, fluid && "rc-tree-select--fluid")}
				dropdownClassName={clsx(dropdownClassName)}
				{...props}
			/>
			<FormError message={error?.message} errorPos={errorPos} />
		</label>
	);
};
