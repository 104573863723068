import region from "api/service/region";
import { getCitiesAction } from "redux/reducers/region";
import { getCountriesAction } from "redux/reducers/region";
import { getCitiesAvailableForSearchAction } from "redux/reducers/region";


export const getCountries = () => {
  return async function(dispatch){
    const fetchedCountries = await region.getCountries();
		dispatch(getCountriesAction(fetchedCountries.data))
  }
}

export const getCities = () => {
  return async function(dispatch){
    const fetchedCities = await region.getCities();
		dispatch(getCitiesAction(fetchedCities.data))
  }
}


export const getCitiesAvailableForSearch = () => {
  return async function(dispatch){
    const fetchedCitiesAvailableForSearch = await region.getCitiesAvailableForSearch();
    dispatch(getCitiesAvailableForSearchAction(fetchedCitiesAvailableForSearch.data))
  }
}
