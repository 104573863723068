import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "context/auth/AuthContext";
import { Loading } from "components/ui/loading";
import Layout from "components/layout";
import LayoutCatalog from "components/catalog/LayoutCatalog";
import Login from "pages/auth/Login";
import ResetPassword from "pages/auth/ResetPassword";
import Register from "pages/auth/Register";
import Dashboard from "pages/Dashboard";
import Orders from "pages/Orders";
import Clients from "pages/Clients";
import CategoriesPage from "pages/catalog/Categories";
import CategoryProducts from "pages/catalog/CategoryProducts";
import Goods from "pages/catalog/Goods";
import New from "pages/catalog/New";
import Discount from "pages/catalog/Discount";
import Recommend from "pages/catalog/Recommend";
import Popular from "pages/catalog/Popular";
import Reviews from "pages/Reviews";
import Hints from "pages/Hints";
import Settings from "pages/Settings";
import Seo from "pages/Seo";
import Payment from "pages/Payment";
import Delivery from "pages/Delivery";
import Rate from "pages/Rate";
import Preview from "pages/previews/Preview";
import { useSelector } from "react-redux";
import Promocodes from "pages/catalog/Promocodes";
import Library from "pages/banners/Library";
import YourBanners from "pages/banners/YourBanners";
import LayoutBanners from "components/banners/LayoutBanner";
import PrivacyPolicy from './components/privecy/PrivacyPolicy'
import TermsOfUse from "./components/privecy/TermsOfUse";
import NoticeDisclaimer from "./components/privecy/NoticeDisclaimer";

export default function Router() {
	const { isAuthenticated, isReady } = useAuthContext();
	const { isLogin, token } = useSelector((state) => state.authReducer);

	if (!isReady) return <Loading />;

	let zztoken = localStorage.getItem("zztoken");

	if (!zztoken) {
		return (
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="resetpassword" element={<ResetPassword />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		);
	}

	return (
		<Routes>
			<Route path="privacy-policy" element={<PrivacyPolicy />} />
			<Route path="terms-of-use" element={<TermsOfUse />} />
			<Route path="notice-disclaimere" element={<NoticeDisclaimer />} />
			<Route element={<Layout />}>
				<Route path="/" element={<Navigate to="dashboard" replace />} />
				<Route path="dashboard" element={<Dashboard />} />
				<Route path="orders" element={<Orders />} />
				<Route path="clients" element={<Clients />} />
				<Route path="catalog" element={<LayoutCatalog />}>
					<Route path="category" element={<CategoriesPage />} />
					<Route path="category/:subcategoryId" element={<CategoriesPage />} />
					<Route
						path="category/:subcategoryId/:subcategoryProductsId/*"
						element={<CategoryProducts />}
					/>
					<Route path="goods/*" element={<Goods />} />
					<Route path="new/*" element={<New />} />
					<Route path="discount/*" element={<Discount />} />
					<Route path="recommend/*" element={<Recommend />} />
					<Route path="popular/*" element={<Popular />} />
					<Route path="promocodes/*" element={<Promocodes />} />
				</Route>
				<Route path="reviews" element={<Reviews />} />
				<Route path="hints" element={<Hints />} />
				<Route path="settings" element={<Settings />} />
				<Route path="banners" element={<LayoutBanners />}>
					<Route path="library/*" element={<Library />} />
					<Route path="yourbanners/*" element={<YourBanners />} />
				</Route>
				<Route path="seo" element={<Seo />} />
				<Route path="payment" element={<Payment />} />
				<Route path="delivery" element={<Delivery />} />
				<Route path="rate" element={<Rate />} />
				<Route path="preview" element={<Preview />} />
				<Route path="*" element={<p>404</p>} />
			</Route>
		</Routes>
	);
}
