import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { ControlMenu, Button, Icon, Text, Screen } from "components/ui";
import {
	useGetPaymentsQuery,
	useEditPaymentsMutation,
} from "redux/reducers/payments";
import styles from "./payments.module.scss";
import youmomeyLogo from "assets/payments/youmoney.png";
import { YoumoneyModal } from "./YoumoneyModal";
import useMessage from "../../hooks/useMessage";

const modals = {
	youmoney: YoumoneyModal,
};

export function Payments() {
	const { t } = useTranslation(["settings", "common", "payment"]);
	const [selectedPayment, setSelectedPayment] = useState(null);
	const { deviceWidth } = useWindowSize();
	const { showMessage } = useMessage();

	const getPaymentsQuery = useGetPaymentsQuery();
	const payments = getPaymentsQuery.data?.payments || {};

	const closeModal = () => {
		setSelectedPayment(null);
	};

	const openModal = (event) => {
		const payment = event.currentTarget.dataset.payment;

		setSelectedPayment(payment || null);
	};

	const [editPayments] = useEditPaymentsMutation();

	const handleSubmit = useCallback(
		async (data) => {
			const response = await editPayments({
				system: selectedPayment,
				data,
			});

			if (!response.error) {
				showMessage({ value: t("messages.dataIsSaved", { ns: "payment" }) });
			}
		},
		[editPayments, selectedPayment]
	);

	const Modal = modals[selectedPayment];

	return (
		<>
			<div className={cn(styles["setting-store"], styles["faq"])}>
				<div className={styles["setting-store__header"]}>
					<div className={styles["title"]}>
						<Icon size="md" name="payment" color="blue" />
						<Text size="md" color="blue">
							{t("filters.payment")}
						</Text>
					</div>
					<Screen size="xl">
						<Icon name="question-border" size="md" />
					</Screen>
				</div>

				<div className={styles["setting-store__content"]}>
					{!getPaymentsQuery.loading && (
						<div className={styles.paymentCard}>
							<img className={styles.paymentCard__logo} src={youmomeyLogo} />
							<Button
								data-payment={"youmoney"}
								onClick={openModal}
								fluid={deviceWidth === "small"}
							>
								{t(payments?.youmoney ? "buttons.edit" : "buttons.add", {
									ns: "common",
								})}
							</Button>
						</div>
					)}
				</div>
			</div>

			{Modal && (
				<ControlMenu
					isOpen={selectedPayment}
					onClose={closeModal}
					title={t("modalTitle", { ns: "payment" })}
					//hasChanges={hasFaqChanges}
					//onSaveData={onAlertSaveData}
					//isCanClose={canCloseMenu}
				>
					{<Modal data={payments[selectedPayment]} onSubmit={handleSubmit} />}
				</ControlMenu>
			)}
		</>
	);
}
