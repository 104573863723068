import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";
import omit from "lodash/omit";

const apiWithPromocodeTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Promocodes"],
});

export const promocodesApi = apiWithPromocodeTags.injectEndpoints({
	endpoints: (builder) => ({
		getPromocodes: builder.query({
			query: (params) => ({
				url: "/promocodes",
				params,
			}),
			providesTags: ["Promocodes"],
		}),
		deletePromocodes: builder.mutation({
			query: (data) => ({
				url: `/promocodes/${data._id}`,
				method: "DELETE",
				data,
			}),
			invalidatesTags: ["Promocodes"],
		}),
		editPromocode: builder.mutation({
			query: (data) => ({
				url: `/promocodes/${data.id}`,
				method: "PUT",
				data,
			}),
			invalidatesTags: ["Promocodes"],
		}),
		createPromocode: builder.mutation({
			query: (data) => ({
				url: `/createPromoCodes`,
				method: "POST",
				data,
			}),
			invalidatesTags: ["Promocodes"],
		}),
	}),
});

export const {
	useDeletePromocodesMutation,
    useGetPromocodesQuery,
	useEditPromocodeMutation,
	useCreatePromocodeMutation,
} = promocodesApi;
