import styles from "./bannerEditor.module.scss";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { ParentSize } from "@visx/responsive";
import { useEffect } from "react";
import { fabric } from "fabric";

export const BannerEditor = ({ banner, editorRef }) => {
	const { editor, onReady } = useFabricJSEditor();
	editorRef.current = editor;

	useEffect(() => {
		fabric.Image.fromURL(
			`https://back-media.zumzak.ru/static/${banner.imageUrl}`,
			(image) => {
				const editor = editorRef.current;

				editor.canvas.width = banner.styles.width;
				editor.canvas.height = banner.styles.height;

				editor.canvas.setBackgroundImage(
					image,
					editor.canvas.renderAll.bind(editor.canvas)
				);

				for (let i = 0; i < banner.textObjects.length; i++) {
					const textObject = banner.textObjects[i];
					const text = addText(editor, textObject);

					if (i === 0) editor.canvas.setActiveObject(text);
				}
			},
			{ crossOrigin: "Anonymous" }
		);
	}, [editorRef, banner]);

	return (
		<ParentSize>
			{(parent) => (
				<div
					style={{
						aspectRatio: `${banner.styles.width}/${banner.styles.height}`,
						width: "100%",
						overflow: "hidden",
					}}
				>
					<div
						style={{
							width: `${banner.styles.width}px`,
							//height: `${banner.styles.banner.height}px`,
							transform: `scale(${
								parent.width / (banner.styles.width / 100) / 100
							})`,
							transformOrigin: `0 0`,
							aspectRatio: `${banner.styles.width}/${banner.styles.height}`,
						}}
					>
						<FabricJSCanvas className={styles.canvas} onReady={onReady} />
					</div>
				</div>
			)}
		</ParentSize>
	);
};

function addText(editor, options) {
	console.log("addText", editor, options);
	const { text, lineHeight, ...restOptions } = options;

	const obj = new fabric.Textbox(options.text, restOptions);

	editor.canvas.add(obj);

	return obj;
}
