import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "context/auth/AuthContext";
import { Text, Button, Input, LocaleSwitcher } from "components/ui";
import logoSrc from "assets/logo.svg";
import styles from "./auth.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { regClient } from "redux/asyncAction/auth";
import InputAuth from "components/ui/form/InputAuth";
import { SelectAuth } from "components/ui/select";
import { Select } from "antd";
import { FormError } from "components/ui";
import FormErrorAuth from "components/ui/form/FormErrorAuth";
import { toast } from "materialize-css";
import selectIcon from "../../assets/dropdownauth.svg";
import useDebounce from "hooks/useDebounce";
import { searchInn, searchBik } from "redux/asyncAction/auth";
import axios from "axios";
import { $api } from "api";

const SelectIcon = () => {
	return <img src={selectIcon} alt="" />;
};

const RequisitesRegister = ({ setRegisterInfo }) => {
	const [innValue, setInnValue] = useState("");

	const handleInnChange = (e) => {
		const newValue = e?.target?.value.slice(0, 12); // Ограничиваем количество символов до 12
		setInnValue(newValue);
	};
	const { t } = useTranslation(["auth"]);
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
		setValue,
		getValues,
	} = useForm({ mode: "onTouched", reValidateMode: "onChange" });
	const { setIsAuthenticated } = useAuthContext();
	const navigate = useNavigate();
	let dispatch = useDispatch();

	const { shopDetails, userDetails } = useSelector(
		(state) => state.authReducer
	);
	const { searchedInn, searchedInnLoading } = useSelector(
		(state) => state.authReducer
	);
	const { searchedBik, searchedBikLoading } = useSelector(
		(state) => state.authReducer
	);
	//const [value, setValue] = useState("");

	// console.log(searchedBik)
	const [searchTerm, setSearchTerm] = useState("");
	const [searchBikValue, setSearchBikValue] = useState("");
	// API search results
	const [results, setResults] = useState([]);
	// Searching status (whether there is pending API request)
	const [isSearching, setIsSearching] = useState(false);
	// Debounce search term so that it only gives us latest value ...
	// ... if searchTerm has not been updated within last 500ms.
	// The goal is to only have the API call fire when user stops typing ...
	// ... so that we aren't hitting our API rapidly.
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const [innInfo, setInnInfo] = useState(null);
	const [numContract, setNumContract] = useState(null);

	console.log(searchBikValue, "bik");
	// Effect for API call
	useEffect(
		() => {
			setIsSearching(true);
			dispatch(searchInn(debouncedSearchTerm));
			if (searchBikValue.length === 9) {
				setIsSearching(true);
				dispatch(searchBik(searchBikValue));
			}
		},
		[debouncedSearchTerm, searchBikValue] // Only call effect if debounced search term changes
	);

	useEffect(() => {
		if (searchedBik?.length) {
			setValue("bank", searchedBik[0]?.value, { shouldValidate: true });
		}
	}, [searchedBik]);

	useEffect(() => {
		if (!searchTerm) {
			setInnInfo(null);
		}
	}, [searchTerm]);
	useEffect(() => {
		
		$api.get("/createContract", {
				headers: {
					"Cache-Control": "no-cache",
				},
			})
			.then((response) => {
				setNumContract(response.data.data);
				localStorage.setItem("numContract", response.data.data.title);
			})
			.catch((error) => {
				console.log(error);
			});
		console.log(numContract, "success");
	}, []);

	console.log(numContract, "numContract");

	//delete after connect to db
	const allowedCategories = ["Продукты", "Одежда", "Дом"];
	const allowedTaxation = ["simplified", "general"];

	const submitForm = async (requisites) => {
		// setRegisterInfo(requisites)
		//delete after connect to db
		// setIsAuthenticated(true)
		// navigate('../dashboard', { replace: true })
		try {
			await dispatch(
				regClient(userDetails, shopDetails, requisites, numContract._id)
			);
			navigate("/dashboard");
		} catch (error) {
			toast({ html: "Ошибка регистрации", classes: "error" });
		}
	};

	const handleChange = (event) => {
		const newValue = event.slice(0, 9); // Ограничиваем количество символов до 5
		setValue(newValue);
	};

	const refForm = useRef();

	console.log(searchedInn);
	// console.log(searchedInn[0].data.inn, innInfo.data.inn,  'searchedInn innInfo')
	return (
		<form onSubmit={handleSubmit(submitForm)} ref={refForm}>
			<div className={cn(styles["register"], styles["requisites"])}>
				<div className="row row-10">
					<div className="col-12">
						<div className={styles.left}>
							<Text size="md" color="white">
								{t("requisites.number")}
								<Text size="md" color="white" style={{ marginLeft: 10 }}>
									{numContract?.title}
								</Text>
							</Text>
						</div>
					</div>
					<div className="col-xl-6">
						<Text size="labelinp">{t("requisites.name")}</Text>
						<div>
							<InputAuth
								//value={innInfo?.data?.name.full}
								id="name"
								name="name"
								register={register}
								// maxlength={2
								// value={value}
								rules={{
									required: {
										value: true,
										message: t("messageError.nameOfOrganization"),
									},
									validate: (value) => {
										if (value.length < 3) {
											return "Количество символов должно быть 12";
										} else {
											return true;
										}
									},
								}}
								errors={errors}
								fluid
								errorPos="organizationLeft"
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.inn")}</Text>
							<Controller
								rules={{
									required: {
										value: true,
										message: t("messageError.inn"),
									},
									///maxLength: 3,
									// validate: (value) => {
									// 	if (
									// 		String(value).length < 12 ||
									// 		String(value).length > 12
									// 	) {
									// 		return "Количество символов должно быть 12";
									// 	} else {
									// 		return true;
									// 	}
									// },
								}}
								control={control}
								name="inn"
								defaultValue={""}
								render={({ field: { onChange, ...props } }) => (
									<>
										{console.log("inn changed", props.value)}
										<InputAuth
											type="number"
											id="inn"
											name="inn"
											// maxlength={3}
											errorPos="organizationLeft"
											//register={register}
											// value={searchTerm}

											errors={errors}
											fluid
											{...props}
											onChange={(e) => {
												if (e.currentTarget.value?.toString().length > 12) {
													onChange(
														e.currentTarget.value?.toString().slice(0, 12)
													);
												} else {
													onChange(e.currentTarget.value);
												}
												// 	return value?.toString().slice(12);
												handleChange(e.currentTarget.value);
												setSearchTerm(e.currentTarget.value);
												handleInnChange(e.target.value);
											}}
										/>
									</>
								)}
							/>
							{searchedInn[0]?.data?.inn !== innInfo?.data?.inn && (
								<div
									style={{
										maxHeight: "150px",
										overflowY: "auto",
										background: "white",
										borderRadius: "10px",
										marginTop: "2px",
										padding: "10px 10px",
									}}
								>
									{searchedInn?.map((result) => (
										<div className={styles.selectInn} key={result.data.inn}>
											<span
												onClick={() => {
													setInnInfo(result);
													console.log(result.data, ".data.adress");
													// reset(
													// 	{
													// 		inn: result.data.inn,
													// 		name: result.data.name.full,
													// 		kpp: result.data.kpp,
													// 		ogrn: result.data.ogrn,
													// 		legalAddress: result.data.address.value,

													// 	},
													// 	{
													// 		keepDirty: true,
													// 		keepTouched: true,
													// 		keepIsSubmitted: true,
													// 	}
													// );
													setValue("inn", result.data.inn, {
														shouldValidate: true,
													});
													setValue("name", result.data.name.full, {
														shouldValidate: true,
													});
													setValue("kpp", result.data.kpp, {
														shouldValidate: true,
													});
													setValue("ogrn", result.data.ogrn, {
														shouldValidate: true,
													});
													setValue("legalAddress", result.data.address.value, {
														shouldValidate: true,
													});
													setValue(
														"leadLastName",
														result?.data?.management?.name.split(" ")[0],
														{ shouldValidate: true }
													);
													setValue(
														"leadFirstName",
														result?.data?.management?.name.split(" ")[1],
														{ shouldValidate: true }
													);
													setValue(
														"leadMiddleName",
														result?.data?.management?.name.split(" ")[2] || "",
														{ shouldValidate: true }
													);

													setSearchTerm(result.data.inn);
												}}
											>
												{result.data.inn}
												<span> {result?.data?.name?.full}</span>
											</span>
										</div>
									))}
								</div>
							)}
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.kpp")}</Text>
							<InputAuth
								// value={innInfo?.data?.kpp}
								type="number"
								// maxlength='3'
								errorPos="organizationLeft"
								id="kpp"
								name="kpp"
								register={register}
								rules={{
									required: { value: true, message: t("messageError.kpp") },
								}}
								errors={errors}
								fluid
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.ogrn")}</Text>
							<InputAuth
								// value={innInfo?.data?.ogrn}
								type="number"
								id="ogrn"
								name="ogrn"
								register={register}
								rules={{
									required: { value: true, message: t("messageError.ogrn") },
								}}
								errors={errors}
								fluid
								errorPos="organizationLeft"
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.bik")}</Text>
							<InputAuth
								type="number"
								id="bik"
								name="bik"
								onChange={(e) => {
									setSearchBikValue(e);
									setValue("bik", e, { shouldValidate: true });

									reset(
										{
											bik: e,
											bank: searchedBik[0]?.value,
										},
										{ keepValues: true }
									);
								}}
								register={register}
								rules={{
									required: { value: true, message: t("messageError.bik") },
									validate: (value) => {
										if (String(value).length < 9 || String(value).length > 9) {
											return "Количество символов должно быть 9";
										} else {
											return true;
										}
									},
								}}
								errors={errors}
								fluid
								errorPos="organizationLeft"
							/>
						</div>
					</div>
					<div className="col-xl-6">
						<Text size="labelinp">{t("requisites.tax")}</Text>
						<div>
							<Controller
								name="tax"
								control={control}
								errorPos="phoneRight"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.taxationSystem"),
									},
								}}
								render={({ field: { onChange, value } }) => (
									<Select
										name="tax"
										value={value || t("requisites.placeholder.tax")}
										onChange={onChange}
										errors={errors}
										fluid
										errorPos="phoneRight"
										className={styles.selectAuth}
									>
										{allowedTaxation.map((taxation) => (
											<Select.Option key={taxation} value={taxation}>
												{t("requisites.allowedTaxation." + taxation)}
											</Select.Option>
										))}
									</Select>
								)}
							/>
						</div>
						{errors?.tax?.message && (
							<div
								className={`${styles.errorMessage1} ${styles.errorMessage2}`}
							>
								<FormErrorAuth
									message={errors?.tax?.message}
									errorPos="phoneRight"
								/>
							</div>
						)}
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.bank")}</Text>
							<InputAuth
								// value={searchedBik ? searchedBik[0]?.value : ''}
								id="bank"
								name="bank"
								errorPos="phoneRight"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.nameBank"),
									},
								}}
								errors={errors}
								fluid
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.checkingAccount")}</Text>
							<InputAuth
								type="number"
								id="checkingAccount"
								name="checkingAccount"
								errorPos="phoneRight"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.currentAccount"),
									},
									validate: (value) => {
										if (
											String(value).length < 20 ||
											String(value).length > 20
										) {
											return "Количество символов должно быть 20";
										} else {
											return true;
										}
									},
								}}
								errors={errors}
								fluid
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp">{t("requisites.legalAddress")}</Text>
							<InputAuth
								id="legalAddress"
								name="legalAddress"
								errorPos="phoneRight"
								// value={innInfo?.data?.address?.value}
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.legalAddress"),
									},
								}}
								errors={errors}
								fluid
								style={{ paddingRight: 9 }}
							/>
						</div>
						<div className="offset-top-10">
							<Text size="labelinp" color="white">
								{t("requisites.lead")}
							</Text>
							<InputAuth
								// value={innInfo?.data?.management?.name.split(" ")[0]}
								id="leadLastName"
								name="leadLastName"
								errorPos="fullName"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.managersLastName"),
									},
								}}
								errors={errors}
								placeholder={t("requisites.placeholder.leadLastName")}
								className="offset-top-10"
								fluid
							/>
							<InputAuth
								id="leadFirstName"
								name="leadFirstName"
								errorPos="fullName"
								// value={innInfo?.data?.management?.name.split(" ")[1]}
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.managersFirstName"),
									},
								}}
								errors={errors}
								placeholder={t("requisites.placeholder.leadFirstName")}
								className="offset-top-10"
								fluid
							/>
							<InputAuth
								id="leadMiddleName"
								name="leadMiddleName"
								// value={innInfo?.data?.management?.name.split(" ")[2]}
								register={register}
								errors={errors}
								placeholder={t("requisites.placeholder.leadMiddleName")}
								className="offset-top-10"
								fluid
							/>
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<button
					type="submit"
					view="secondary"
					className={styles.btnNewAuthNext}
				>
					{t("button.next")}
				</button>
			</div>
		</form>
	);
};

RequisitesRegister.propTypes = {
	setRegisterInfo: PropTypes.func.isRequired,
};

export default RequisitesRegister;
