import auth from "api/service/auth";
import { addUserAction } from "redux/reducers/auth";
import { checkPartnerAction } from "redux/reducers/auth";
import { searchInnAction } from "redux/reducers/auth";
import { searchBikAction } from "redux/reducers/auth";
import { regClientAction } from "redux/reducers/auth";
import { addShopAction } from "redux/reducers/auth";
import { resetPasswordAction } from "redux/reducers/auth";
import { registerAction } from "redux/reducers/auth";
import { loginAction } from "redux/reducers/auth";

export const login = (email, password) => {
	return async function (dispatch) {
		const res = await auth.loginUser(email, password);
		dispatch(loginAction(res));
	};
};

export const registerUser = (email, name, password) => {
	return async function (dispatch) {
		const res = await auth.regUser(email, name, password);
		dispatch(registerAction(res));
	};
};

export const resetPassword = (email) => {
	return async function (dispatch) {
		await auth.resetPassword(email);
		dispatch(resetPasswordAction());
	};
};

export const checkPartner = (email) => {
	return async function (dispatch) {
		const res = await auth.checkPartner(email);
		dispatch(checkPartnerAction(res.data?.exists));
	};
};

export const addUser = (data) => {
	return function (dispatch) {
		dispatch(addUserAction(data));
	};
};

export const addShop = (data) => {
	return function (dispatch) {
		dispatch(addShopAction(data));
	};
};

export const regClient = (user, shop, req, numContract) => {
	return async function (dispatch) {
		const res = await auth.regClient(user, shop, req, numContract);
		dispatch(regClientAction(res));
	};
};

export const searchInn = (query) => {
	return async function (dispatch) {
		const res = await auth.searchInn(query);
		dispatch(searchInnAction(res.data.data.suggestions));
	};
};

export const searchBik = (query) => {
	return async function (dispatch) {
		const res = await auth.searchBik(query);
		console.log(res.data.data.suggestions);
		dispatch(searchBikAction(res.data.data.suggestions));
	};
};
