// import thunk from "redux-thunk";
// import {
// 	legacy_createStore as createStore,
// 	applyMiddleware,
// 	combineReducers,
// } from "redux";
import { configureStore } from "@reduxjs/toolkit";
//import { composeWithDevTools } from "redux-devtools-extension";
import { dashboardReducer } from "./reducers/dashboard";
import { authReducer } from "./reducers/auth";
import { categoryReducer, categoryApi } from "./reducers/category";
// import { productApi } from "./reducers/product";
// import { dashboardApi } from "./reducers/dashboard2";
import { regionReducer } from "./reducers/region";
import { catalogReducer } from "./reducers/catalog";
import { settingsReducer } from "./reducers/settings";
import { clientReducer } from "./reducers/client";
import { baseApi } from "api";
// import { promoCodesReducer } from "./reducers/promocode";
import { updateSideBarReducer } from "./reducers/global";
import { productsReducer } from "./reducers/product";
//import { reviewsApi } from "./reducers/reviews";

/*const rootReducer = combineReducers({
	dashboardReducer,
	authReducer,
	categoryReducer,
	regionReducer,
	catalogReducer,
	settingsReducer,
	clientReducer,
	promoCodesReducer,
	updateSideBarReducer,
	productsReducer,
});*/

export const store = configureStore({
	reducer: {
		dashboardReducer,
		authReducer,
		categoryReducer,
		regionReducer,
		catalogReducer,
		settingsReducer,
		clientReducer,
		updateSideBarReducer,
		productsReducer,
		[baseApi.reducerPath]: categoryApi.reducer,
		// [categoryApi.reducerPath]: categoryApi.reducer,
		// [productApi.reducerPath]: productApi.reducer,
		// [reviewsApi.reducerPath]: reviewsApi.reducer,
		// [dashboardApi.reducerPath]: dashboardApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			baseApi.middleware
			// categoryApi.middleware,
			// productApi.middleware,
			// reviewsApi.middleware,
			// dashboardApi.middleware
		),
});

/*export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk))
);*/
