const defaultState = {
	shopData: [],
	shopDataLoading: true,
	isReqEdit: false,
	isReqEditLoading: true,
	isMainEdit: false,
	isMainEditLoading: true,
	isContactsEdit: false,
	isContactsEditLoading: true,
	isQaAdded: false,
	isQaDeleted: false,
	isQaHided: false,
	isQaEdited: false,
	isSeoEdited: false,
	isDomainEdited: false,
};

const GET_SHOPDATA = "GET_SHOPDATA";
const EDIT_MAIN = "EDIT_MAIN";
const EDIT_REQ = "EDIT_REQ";
const EDIT_CONTACTS = "EDIT_CONTACTS";

const ADD_QA = "ADD_QA";
const DELETE_QA = "DELETE_QA";
const HIDE_QA = "HIDE_QA";
const EDIT_QA = "EDIT_QA";

const EDIT_SEO = "EDIT_SEO";
const UPDATE_DOMAIN = "UPDATE_DOMAIN";
const UPDATE_BANNERS = "UPDATE_BANNERS";

export const settingsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_SHOPDATA:
			return { ...state, shopData: action.payload, shopDataLoading: false };
		case UPDATE_BANNERS:
			return {
				...state,
				shopData: {
					...state.shopData,
					banners: action.payload,
				},
			};

		case EDIT_MAIN:
			return {
				...state,
				shopData: action.payload,
				isMainEdit: true,
				isMainEditLoading: false,
			};

		case EDIT_REQ:
			return {
				...state,
				shopData: action.payload,
				isReqEdit: true,
				isReqEditLoading: false,
			};

		case EDIT_CONTACTS:
			return {
				...state,
				shopData: action.payload,
				isContactsEdit: true,
				isContactsEditLoading: false,
			};

		case ADD_QA:
			return {
				...state,
				shopData: { ...state.shopData, QA: action.payload.QA },
			};

		case DELETE_QA:
			return {
				...state,
				shopData: { ...state.shopData, QA: action.payload.QA },
			};

		case HIDE_QA:
			return {
				...state,
				shopData: { ...state.shopData, QA: action.payload.QA },
			};

		case EDIT_QA:
			return {
				...state,
				shopData: { ...state.shopData, QA: action.payload.QA },
			};

		case EDIT_SEO:
			return { ...state, shopData: action.payload, isSeoEdited: true };

		case UPDATE_DOMAIN:
			return { ...state, shopData: action.payload, isDomainEdited: true };

		default:
			return state;
	}
};

export const getShopDataAction = (payload) => ({ type: GET_SHOPDATA, payload });
export const editMainAction = (payload) => ({ type: EDIT_MAIN, payload });
export const editReqAction = (payload) => ({ type: EDIT_REQ, payload });
export const editContactsAction = (payload) => ({
	type: EDIT_CONTACTS,
	payload,
});
export const addQaAction = (payload) => ({ type: ADD_QA, payload });
export const deleteQaAction = (payload) => ({ type: DELETE_QA, payload });
export const hideQaAction = (payload) => ({ type: HIDE_QA, payload });
export const editQaAction = (payload) => ({ type: EDIT_QA, payload });
export const editSeoAction = (payload) => ({ type: EDIT_SEO, payload });
export const updateDomainAction = (payload) => ({
	type: UPDATE_DOMAIN,
	payload,
});
