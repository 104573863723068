import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import {Header} from 'components/layout/header'
import {Collapse, PageDescription, Screen, SearchForm, Text} from 'components/ui'
import {Button} from 'components/ui'
import AskQuestion from 'components/hints/controls/AskQuestion'
import {ControlMenu} from 'components/ui'
import {Icon} from 'components/ui'
import {$api} from "../api";

export default function Hints() {

	const {t} = useTranslation(['hints'])

	const {deviceWidth} = useWindowSize()


	const [hints, setHints] = useState()


	useEffect(() => {

		$api.get("/getAllHints")
			.then(({ data }) => {
				const { hints } = data
				setHints(hints)
			})
			.catch((error) =>console.error('Ошибка:', error))


	}, []);

	//delete after connect to db

	const [isOpenMenu, setIsOpenMenu] = useState(false)


	const closeControlMenu = () => {
		setIsOpenMenu(!isOpenMenu)
		//complete after connect to db

		// if (isInfoMode) {
		// 	setInfoOrder({})
		// }
	}


	const openControlMenu = () => {
		setIsOpenMenu(true)
	}


	return (<>
		<Header/>
		<main>
			<Screen size='xl' down>
				<SearchForm.Section sectionFirst>
					<SearchForm placeholder={t('searchPlaceholder')}/>
				</SearchForm.Section>
			</Screen>


			<PageDescription.Section>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<PageDescription title={'Чёткие ответы на самые частые вопросы.'}/>
					<Screen size='xl'>
						<Icon
							name='question-border'
							size='md'
							title={'Данный раздел будет отображаться в основном верхнем меню вашего интернет-магазина и содержать ответы на самые распространённые вопросы покупателей. Для вашего удобства создан шаблон, который вы можете дополнить новыми блоками, отредактировать имеющуюся информацию, либо же удалить неактуальные для вашего интернет-магазина вопросы и ответы.'}
							placementCustomTitle='right'
							customTitle
							style={{marginTop: '-10px', marginLeft: '8px'}}
						/>
					</Screen>
				</div>
			</PageDescription.Section>

			<Screen size='xl'>
				<SearchForm.Section>
					<SearchForm placeholder={'Поиск...'}/>
				</SearchForm.Section>
			</Screen>

			<section className='section'>
				<div className='container'>
					<Collapse>
						{hints?.map((item, index) => {
							return (
								<Collapse.Panel
									key={index}
									index={index}
									label={item.question}
									isArrowMoving={false}>
									<Text>{item.textArea}</Text>
								</Collapse.Panel>
							)
						})}
						{/*{data?.map((hintItem, i) => (*/}
						{/*	console.log(hintItem)*/}
						{/*	// <Collapse.Panel*/}
						{/*	// 	key={i} label={hintItem.question} index={i}*/}
						{/*	// 	isArrowMoving={false}>*/}
						{/*	// 	<Text>{data.textArea}</Text>*/}
						{/*	// </Collapse.Panel>*/}
						{/*))}*/}
					</Collapse>
					{/*{hints.question}*/}
					{/*<br/>*/}
					{/*{hints.textArea}*/}
					<div style={{marginTop: '12px'}}>
						<Button view="primary" onClick={openControlMenu}>Задать вопрос</Button>
					</div>

				</div>
			</section>
		</main>
		<ControlMenu
			isOpen={isOpenMenu}
			onClose={closeControlMenu}
			title={'Задать вопрос'}>
			<AskQuestion hints={hints} setHints={setHints} onClose={closeControlMenu}/>
		</ControlMenu>
	</>)
}
