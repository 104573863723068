import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useWindowSize from "hooks/useWindowSize";
import { Icon, Table, Td, Tr, Input, Button } from "components/ui";
import { Controller, useForm, useFieldArray } from "react-hook-form";

const OrdersItemsTable = (props) => {
	const {
		orderItems,
		deleteItem,
		addNewOrderItem,
		mode,
		orderQuantity,
		products,
		handleAddProduct,
		handleDeleteProduct,
		register,
		handleRender,
	} = props;
	console.log(products, "PRODUCTS");
	const { t } = useTranslation(["orders"]);
	const initNewItem = { name: "", article: "", quantity: "", cost: "" };
	const [newOrderItem, setNewOrderItem] = useState(initNewItem);
	const { deviceWidth } = useWindowSize();

	const onChangeNewOrderItem = (value, name) => {
		setNewOrderItem({
			...newOrderItem,
			[name]: value,
		});
	};

	//delete after connect to db
	const checkValid = () => {
		if (newOrderItem.name.length > 2) {
			if (newOrderItem.article.length) {
				if (Number(newOrderItem.quantity) > 1) {
					return true;
				}
			}
		}
		return false;
	};

	//change after connect to db
	const onAddNewOrderItem = () => {
		if (checkValid()) {
			newOrderItem.number = orderItems?.length + 1 || 1;
			newOrderItem.id = Math.floor(Math.random() * 1000);
			addNewOrderItem(newOrderItem);
			setNewOrderItem(initNewItem);
		}
	};

	// const checkQuantity = (id) => {
	// 	let newArray = orderQuantity.find((res) => res._id === id);
	// 	return newArray.quantity;
	// };

	return (
		<>
			<Table className="offset-top-10">
				<Table.Head>
					<Td width={35}>№</Td>
					<Td width={mode ? 422 : 462}>{t("drawer.orderInfo.itemName")}</Td>
					<Td width={120}>{t("drawer.orderInfo.article")}</Td>
					<Td width={120}>{t("drawer.orderInfo.quantity")}</Td>
					<Td width={120}>{t("drawer.orderInfo.cost")}</Td>
					{mode && <Td width={40} />}
				</Table.Head>

				<Table.Body>
					{products?.map((product, index) => (
						<Tr weight="medium" key={product._id}>
							<Td width={35}>{index + 1}</Td>
							<Td width={mode ? 422 : 462} overflow>
								{product?.data?.title}
							</Td>
							<Td width={120}>{product?.data?.vendorCode}</Td>
							{/* <Td width={120}><Input value={product?.quantity}/></Td> */}
							<Td width={120} view="input">
								<Input
									//name={`products.[${index}].quantity`}
									// value={newOrderItem?.quantity}
									// onChange={(e) =>
									// 	onChangeNewOrderItem(e.target.value, "quantity")
									// }
									{...register(`products.[${index}].quantity`)}
									placeholder="0"
									type="number"
									fluid
									disabled={mode === "edit"}
									onChange={(e) => {
										handleRender();
										product.quantity = e.target.value;
									}}
								/>
							</Td>
							<Td width={120} view="input">
								<Input
									//name={`products.[${index}].quantity`}
									// value={newOrderItem?.quantity}
									// onChange={(e) =>
									// 	onChangeNewOrderItem(e.target.value, "quantity")
									// }
									{...register(`products.[${index}].price`)}
									placeholder="0"
									type="number"
									fluid
									disabled={mode === "edit"}
								/>
							</Td>
							{/*{mode && (
								<Td width={40} ellipsis={deviceWidth !== "large"}>
									<Icon
										name="delete"
										size="md"
										color="red"
										//onClick={() => deleteItem(product._id)}
										data-index={index}
										onClick={mode === "edit" ? null : deleteItem}
										title={t("buttonDesc.delete", { value: "", ns: "common" })}
										cursorPointer
										disabled={mode === "edit"}
									/>
								</Td>
							)}*/}
						</Tr>
					))}
					{/* {mode && (
						<Tr weight="medium">
							<Td width={35}>{orderItems?.length + 1 || 1}</Td>
							<Td width={422} view="input">
								<Input
									name="name"
									value={newOrderItem.name}
									onChange={(e) => onChangeNewOrderItem(e.target.value, "name")}
									placeholder={t("drawer.placeholder.itemName")}
									fluid
								/>
							</Td>
							<Td width={120} view="input">
								<Input
									name="article"
									value={newOrderItem.article}
									onChange={(e) =>
										onChangeNewOrderItem(e.target.value, "article")
									}
									placeholder="0001"
									fluid
								/>
							</Td>
							<Td width={120} view="input">
								<Input
									name="quantity"
									value={newOrderItem?.quantity}
									onChange={(e) =>
										onChangeNewOrderItem(e.target.value, "quantity")
									}
									placeholder="0"
									type="number"
									fluid
								/>
							</Td>
							<Td width={120}>{orderItems?.cost || 0} ₽</Td>
							<Td width={40} ellipsis={deviceWidth !== "large"}>
								<Icon
									name="delete"
									size="md"
									color="red"
									title={t("buttonDesc.delete", { value: "", ns: "common" })}
									cursorPointer
								/>
							</Td>
						</Tr>
					)} */}
				</Table.Body>
			</Table>

			{/* {mode && (
				<Button
					className="offset-top-10"
					onClick={() => onAddNewOrderItem()}
					fluid={deviceWidth === "small"}
				>
					{t("buttons.add", { ns: "common" })}
				</Button>
			)} */}
		</>
	);
};

OrdersItemsTable.propTypes = {
	orderItems: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			article: PropTypes.string.isRequired,
			quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
				.isRequired,
		})
	),
	deleteItem: PropTypes.func,
	addNewOrderItem: PropTypes.func,
	mode: PropTypes.oneOf(["create", "edit"]),
};

export default OrdersItemsTable;
