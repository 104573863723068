import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";
import omit from "lodash/omit";

const apiWithReviewsTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Reviews"],
});

export const reviewsApi = apiWithReviewsTags.injectEndpoints({
	endpoints: (builder) => ({
		getReviews: builder.query({
			query: (params) => ({
				url: "/getShopReviews",
				params,
			}),
			providesTags: ["Reviews"],
		}),
		getReview: builder.query({
			query: (params) => ({
				url: "/getShopReview",
				params,
			}),
			providesTags: ["Reviews"],
		}),
		deleteReviews: builder.mutation({
			query: (data) => ({
				url: "/deleteReviews",
				method: "POST",
				data,
			}),
			invalidatesTags: ["Reviews"],
		}),
		editReviews: builder.mutation({
			query: (data) => ({
				url: "/updateReview",
				method: "POST",
				data,
			}),
			invalidatesTags: ["Reviews"],
		}),
	}),
});

export const {
	useGetReviewsQuery,
	useDeleteReviewsMutation,
	useEditReviewsMutation,
    useGetReviewQuery,
} = reviewsApi;
