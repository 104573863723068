import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout/header";
import {
	Statistics,
	RecentlySold,
	SoldItemsGraphic,
	GeneralInformation,
} from "components/dashboard";
import { PageDescription, Screen } from "components/ui";
/*import { useDispatch, useSelector } from "react-redux";
import {
	getDataForDashboard,
	getRecentlySoldProducts,
	getCurrentMonthStats,
	getGlobalInfo,
} from "../redux/asyncAction/dashboard";*/
import {
	useGetAnalyticalDataQuery,
	useGetGlobalInformationQuery,
	useGetSalesChartDataQuery,
	useGetRecentlySoldItemsQuery,
} from "../redux/reducers/dashboard2";
import { Icon } from "components/ui";

export default function Dashboard() {
	const { t } = useTranslation(["dashboard"]);
	//const { dashboardsData } = useSelector((state) => state.dashboardReducer);
	// const { dashboardRecently } = useSelector((state) => state.dashboardReducer);
	// const { dashboardCurrent } = useSelector((state) => state.dashboardReducer);
	// const { dashboardGlobalInfo } = useSelector(
	// 	(state) => state.dashboardReducer
	// );

	const [globalInfoPeriod, setGlobalInfoPeriod] = useState(null);

	const getAnalyticalDataQuery = useGetAnalyticalDataQuery();
	const getSalesChartDataQuery = useGetSalesChartDataQuery();
	const getGlobalInformationQuery = useGetGlobalInformationQuery({
		period: globalInfoPeriod,
	});
	const getRecentlySoldItemsQuery = useGetRecentlySoldItemsQuery({
		page: 1,
		limit: 12,
	});

	/*const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getDataForDashboard());
		dispatch(getRecentlySoldProducts());
		dispatch(getCurrentMonthStats());
		dispatch(getGlobalInfo());
	}, []);*/

	//fake data
	// const data = {
	// 	statistics: {
	// 		salesToday: '70000',
	// 		visitors: '300',
	// 		newClients: '+52',
	// 		salesMonth: '1173000'
	// 	},
	// 	newClients: {
	// 		lastMonth: '+23',
	// 		thisMonth: '+23',
	// 		usersCount: '2557'
	// 	}
	// }

	// const recentlySold = [
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри ', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри ', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри ', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри ', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри', cost: 750 },
	// 	{ title: 'Ламинат Kronostep АС4 Сосна Кантри ', cost: 750 }
	// ]

	// const generalInfo = {
	// 	income: '1 067 045',
	// 	totalTurnover: '19 000 000',
	// 	ordersCounter: '7 786',
	// 	deliveryCounter: '7 786',
	// 	purchaseAmount: '7 786',
	// 	profit: '7 786'
	// }

	console.log("getSalesChartDataQuery.data", getSalesChartDataQuery.data);

	return (
		<>
			<Header />
			<main>
				<PageDescription.Section>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={t("title")} />
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={
									"В данном разделе представлена информация, которая поможет анализировать продажи в вашем интернет-магазине. Для удобства статистика представлена в визуальных блоках интерактивной панели, что позволит максимально быстро и просто провести аналитику и получить данные для оперативных действий по увеличению оборота компании. "
								}
								placementCustomTitle="right"
								customTitle
								style={{ marginTop: "-10px", marginLeft: "8px" }}
							/>
						</Screen>
					</div>
				</PageDescription.Section>
				<section className="section">
					<div className="container">
						<Statistics statistics={getAnalyticalDataQuery.data} />
						<div className="row row-10">
							<Screen size="xl">
								<div className="col-xl-4">
									{/* <NewClients clients={dashboardsData}/> */}
									<RecentlySold items={getRecentlySoldItemsQuery.data} />
								</div>
							</Screen>
							<div className="col-xl-8">
								<SoldItemsGraphic
									todaySales={getSalesChartDataQuery.data?.todaySales}
									result={getSalesChartDataQuery.data?.result}
								/>
								<Screen size="xl" down>
									<RecentlySold items={getRecentlySoldItemsQuery.data} />
								</Screen>
								<GeneralInformation
									info={getGlobalInformationQuery.data}
									period={globalInfoPeriod}
									onChangePeriod={setGlobalInfoPeriod}
								/>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}
