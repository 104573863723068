import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import { Screen, Text } from 'components/ui'
import styles from './rate.module.sass'

const RatesList = ({
	activeRate,
	setRate,
	rates
}) => {
	const { t } = useTranslation(['rate'], { keyPrefix: 'rates' })
	const { deviceWidth } = useWindowSize()

	return (
		<section className='section'>
			<div className='container'>
				<Screen size='xl' down>
					<Text size='sm' color='blue'>{t('title')}</Text>
				</Screen>
				<div className={cn(
					'row row-20',
					{ ['justify-content-center offset-top-10']: deviceWidth !== 'large' }
				)}>
					{rates.map((rate, i) => (
						<div key={i} className={styles['custom-col']}>
							<div className={cn(
								styles.rate,
								{ [styles.active]: activeRate === rate.title }
							)}>
								<div>
									<Text as='p' className={styles.rate__title}>{t(rate.title + '.title')}</Text>
									<Text as='p' className={styles.rate__number}>{t(rate.title + '.numberGoods')}</Text>
									<Text as='p' className={styles.rate__description}>{t(rate.title + '.description')}</Text>
								</div>
								<div>
									<div className={styles.rate__cost}>
										<Text as='p'>
											{rate.cost}
											<Text>₽ / {t('month')}</Text>
										</Text>
									</div>
									<div
										className={styles.rate__button}
										onClick={() => setRate(rate.title)}
									>
										<Text>{t('choosePlan')}</Text>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

RatesList.propTypes = {
	activeRate: PropTypes.string.isRequired,
	setRate: PropTypes.func.isRequired,
	rates: PropTypes.array.isRequired
}

export default RatesList
