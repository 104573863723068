import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Editor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'

const RichText = ({
	id,
	onChange,
	value,
	height,
	placeholderText,
	disabled,
	initialValue
}) => {
	const editorRef = useRef(null)
	const { i18n } = useTranslation()

	return (
		<Editor
			ref={editorRef}
			apiKey={'aft7io1yopnlufmcl192xtgk0swezvx0so51molgzim0wlv7'}
			id={id.toString()}
			scriptLoading={{ delay: 0 }}
			value={value}
			onEditorChange={onChange}
			disabled={disabled}
			initialValue={initialValue}
			init={{
				height: height,
				menubar: false,
				statusbar: false,
				plugins: [
					'advlist autolink lists link image',
					'charmap print preview anchor help',
					'searchreplace visualblocks code',
					'insertdatetime media table paste wordcount'
				],
				toolbar:
					'undo redo | ' +
					'bold italic underline | ' +
					'alignleft aligncenter alignright | ' +
					'bullist numlist | ',
				language: i18n.language,
				placeholder: placeholderText,
				content_style: `
					@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
					body {
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						margin: 10px;
						cursor: ${disabled ? 'default' : 'text'}
					}
					body:before {
						color: #0000004d!important;
						font-weight: 700;
					}
				`
			}}
		/>
	)
}

RichText.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	height: PropTypes.number,
	placeholderText: PropTypes.string,
	disabled: PropTypes.bool,
	initialValue: PropTypes.string
}

RichText.defaultProps = {
	value: '',
	height: 230,
	placeholderText: '',
	disabled: false
}

export default React.memo(RichText)
