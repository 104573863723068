const defaultState = {
	dashboardsData: [],
	dashboardRecently: [],
	dashboardCurrent: [],
	dashboardGlobalInfo: [],
};

const GET_DATA_FOR_DASHBOARD = "GET_DATA_FOR_DASHBOARD";
const GET_RECENTLY_SOLD_PRODUCTS = "GET_RECENTLY_SOLD_PRODUCTS";
const GET_CURRENT_MONTH_STATS = "GET_CURRENT_MONTH_STATS";
const GET_GLOBAL_INFO = "GET_GLOBAL_INFO";

export const dashboardReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_DATA_FOR_DASHBOARD:
			return { ...state, dashboardsData: action.payload };

		case GET_RECENTLY_SOLD_PRODUCTS:
			return { ...state, dashboardRecently: action.payload };

		case GET_CURRENT_MONTH_STATS:
			return { ...state, dashboardCurrent: action.payload };

		case GET_GLOBAL_INFO:
			return { ...state, dashboardGlobalInfo: action.payload };
		default:
			return state;
	}
};

export const getDataForDashboardAction = (payload) => ({
	type: GET_DATA_FOR_DASHBOARD,
	payload,
});
export const getRecentlySoldProductsAction = (payload) => ({
	type: GET_RECENTLY_SOLD_PRODUCTS,
	payload,
});
export const getCurrentMonthStatsAction = (payload) => ({
	type: GET_CURRENT_MONTH_STATS,
	payload,
});

export const getGlobalInfoAction = (payload) => ({
	type: GET_GLOBAL_INFO,
	payload,
});
