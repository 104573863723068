import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useMessage from "hooks/useMessage";
import { LayoutLogin } from "components/auth";
import { Text, Input, Button } from "components/ui";
import logoSrc from "assets/logo.svg";
import logoSrcEn from "assets/logoEn.svg";
import styles from "components/auth/auth.module.sass";
import { useDispatch } from "react-redux";
import { resetPassword } from "redux/asyncAction/auth";
import { toast } from "materialize-css";
import InputAuth from "components/ui/form/InputAuth";
import s from "./authnew.module.sass";
import { LocaleSwitcher } from "components/ui";

const ResetPassword = () => {
	const { t } = useTranslation(["auth"]);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const [isMessageSend, setIsMessageSend] = useState(false);
	const { showMessage } = useMessage();

	let dispatch = useDispatch();

	const checkCode = (code) => {
		//add after connect db
		if (code) {
		}
	};

	const submitEmail = (data) => {
		dispatch(resetPassword(data.email));
		setIsMessageSend(true);
	};

	return (
		<LayoutLogin>
			{/* <div style={{display: 'flex', width: '100%', padding:  '0px 50px', justifyContent: 'space-between', position: 'absolute',top: '50px'}}>
				<Link to={'/'}>
					<button
								
								className={styles.btnBack}
							>
								{t('button.back')}
							</button>
				</Link>

							<LocaleSwitcher style/>
							</div> */}
			<Link to="https://partners.zumzak.ru/">
				<img
					style={{ with: "140px", height: "41px" }}
					src={t("logo") === "assets/logoEn.svg" ? logoSrcEn : logoSrc}
					alt=""
				/>
			</Link>
			<Text size="super" color="white">
				Воccтановления пароля
			</Text>
			<div className={styles["login"]}>
				{!isMessageSend ? (
					<>
						<form
							onSubmit={handleSubmit(submitEmail)}
							className="offset-top-10"
						>
							<div className={s.labelInp}>Введите вашу почту</div>
							<InputAuth
								id="email"
								name="email"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								errors={errors}
								fluid={true}
							/>
							<button type="submit" className={s.btnNewAuth2}>
								{t("button.send")}
							</button>
						</form>

						<Link to="/" className="offset-top-10">
							<Text size="xs" color="white">
								{t("comeBack")}
							</Text>
						</Link>
					</>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Text color="white" size="md" align="center">
							Новый пароль отправлен на почту!
						</Text>
						<Link to="/" className="offset-top-10">
							<button className={s.btnNewAuth} style={{ marginTop: "10px" }}>
								На главную
							</button>
						</Link>
					</div>
				)}
			</div>
		</LayoutLogin>
	);
};

export default ResetPassword;
