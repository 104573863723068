import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Text, Input } from "components/ui";
import styles from "../catalog.module.sass";

const FoundProductMobile = ({
	product,
	checkedProducts,
	handlerCheckedProducts,
}) => {
	return (
		<div
			className={cn(styles["found-product"], styles["found-product-mobile"])}
		>
			<div className={styles["found-product-mobile-top"]}>
				<div className={styles["found-product__check"]}>
					<Input
						type="checkbox"
						name="all"
						checked={
							checkedProducts.find((v) => v._id === product._id) || false
						}
						onChange={(e) => handlerCheckedProducts(e.target.checked, product)}
					/>
				</div>
				<div className={styles["found-product__info"]}>
					<Text as="p" size="sm">
						{product.title}
					</Text>
				</div>
			</div>
			<div className={styles["found-product-mobile-bottom"]}>
				<div className={styles["found-product__photo"]}>
					<img src={product?.imageUrls[0]} alt="" />
				</div>
			</div>
		</div>
	);
};

FoundProductMobile.propTypes = {
	product: PropTypes.shape({}).isRequired,
	checkedProducts: PropTypes.array.isRequired,
	handlerCheckedProducts: PropTypes.func.isRequired,
};

export default FoundProductMobile;
