import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import { Icon, Input, Screen, Select, Text, Textarea } from 'components/ui'
import titleImg from 'assets/seo/title.png'
import styles from './seo.module.sass'
import { $api } from "../../api";

const SeoSettings = ({ seo, setSeo }) => {
	const { t } = useTranslation(['seo'])
	const [activePage, setActivePage] = useState('index')
	const pages = ['index', 'cart', 'favorite', 'support', 'contacts', 'about']
	const { deviceWidth } = useWindowSize()
	const getSeoInfo = async () => {
		let info = await $api.post("/getSeoShop");
		const { index, cart, favorite, support, contacts, about } = info.data.chapter
		setSeo({ index, cart, favorite, support, contacts, about });
	};

	useEffect(async () => {
		await getSeoInfo();
	}, []);

	const onChangeSeo = (value, key) => {
		seo[activePage][key] = value
		setSeo({ ...seo })
	}

	return (
		<div className={styles['seo-settings']}>
			<div className='row row-20'>
				<div className='col-xl-3'>
					<Select
						value={activePage}
						onChange={value => setActivePage(value)}
						label={t('filters.chooseSection')}
						labelSize={deviceWidth === 'large' ? 'md' : 'sm'}
						fluid
					>
						{pages.map(page => (
							<Select.Option key={page} value={page}>{t('filters.' + page)}</Select.Option>
						))}
					</Select>
				</div>
				<div className='col-12'>
					<div className={styles['title-with-faq']}>
						<Text size={deviceWidth === 'large' ? 'md' : 'sm'}>{t('seo.title')}</Text>
						<Screen size='xl'>
							<Icon
								name='question-border'
								size='md'
								title={t('seo.titleIs')}
								placementCustomTitle='right'
								customTitle
							/>
						</Screen>
					</div>
					<div className='offset-top-10'>
						<div className={styles['img-wrapper']}>
							<img src={titleImg} alt=''/>
						</div>
						<Input
							name='title'
							value={seo[activePage]?.title || ''}
							placeholder={t('seo.placeholder.title')}
							onChange={e => onChangeSeo(e.target.value, 'title')}
							fluid={true}
							className='offset-top-10'
						/>
					</div>
				</div>
				<div className='col-12'>
					<div className={styles['title-with-faq']}>
						<Text size={deviceWidth === 'large' ? 'md' : 'sm'}>{t('seo.keywords')}</Text>
						<Screen size='xl'>
							<Icon
								name='question-border'
								size='md'
								title={t('seo.keywordsIs')}
								placementCustomTitle='right'
								customTitle
							/>
						</Screen>
					</div>
					<Textarea
						className='offset-top-10'
						name='keywords'
						placeholder={t('seo.placeholder.keywords')}
						value={seo[activePage]?.keywords || ''}
						onChange={e => onChangeSeo(e.target.value, 'keywords')}
						heightSize='md'
						fluid={true}
					/>
				</div>
				<div className='col-12'>
					<div className={styles['title-with-faq']}>
						<Text size={deviceWidth === 'large' ? 'md' : 'sm'}>{t('seo.description')}</Text>
						<Screen size='xl'>
							<Icon
								name='question-border'
								size='md'
								title={t('seo.descriptionIs')}
								placementCustomTitle='right'
								customTitle
							/>
						</Screen>
					</div>
					<Textarea
						className='offset-top-10'
						name='description'
						placeholder={t('seo.placeholder.description')}
						value={seo[activePage]?.description || ''}
						onChange={e => onChangeSeo(e.target.value, 'description')}
						heightSize='xl'
						fluid={true}
					/>
				</div>
			</div>
		</div>
	)
}

export default SeoSettings
