import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import {
	Button,
	Icon,
	Input,
	Screen,
	SearchForm,
	ResultItem,
	Select,
	Text,
	Textarea,
	TreeSelect,
} from "components/ui";
import photoDefault from "assets/catalog/photo-default.png";
import styles from "../catalog.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoriesUnNested } from "../../../redux/asyncAction/category";
import { ProductSearch } from "./ProductSearch";
import { $mediaApi } from "../../../api";
import useMessage from "../../../hooks/useMessage";
import {
	useEditProductMutation,
	useCreateProductMutation,
	useGetProductSpecificationsQuery,
} from "../../../redux/reducers/product";
import { useGetCategoriesTreeQuery } from "../../../redux/reducers/category";

const ProductCreator = ({ product, closeControlMenu, categoryId }) => {
	const { t } = useTranslation(["catalog", "common"]);

	const defaultValues = useMemo(
		() => ({
			title: product?.title || null,
			price: product?.price || null,
			wholesalePrice: product?.wholesalePrice || null,
			discountPrice: product?.discountPrice || null,
			unitOfMeasurement: product?.unitOfMeasurement || null,
			amount: product?.amount || null,
			categoryId: product?.categoryId || categoryId || null,
			availability: product?.availability || null,
			description: product?.description || null,
			quantityInStock: product?.quantityInStock || null,
			quantityInPackage: product?.quantityInPackage || null,
			specifications: product?.specifications || [],
			imageUrls: product?.imageUrls?.map((value) => ({ value })) || [],
			seo: {
				title: product?.seo?.title || null,
				keywords: product?.seo?.keywords || null,
				description: product?.seo?.description || null,
			},
			oftenSearchWith: product?.oftenSearchWith || [],
			recommendedProducts: product?.recommendedProducts || [],
			buysWith: product?.buysWith || [],
		}),
		[product]
	);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setError,
		clearErrors,
		setValue,
		unregister,
		getValues,
	} = useForm({
		defaultValues,
	});

	const getCategoriesTreeQuery = useGetCategoriesTreeQuery({ title: 1 });
	const categoriesData = getCategoriesTreeQuery.data?.categoriesTree || [];

	const productSpecificationsQuery = useGetProductSpecificationsQuery();
	const productSpecifications =
		productSpecificationsQuery?.data?.specifications || [];

	const specificationsMap = useMemo(
		() =>
			new Map(
				productSpecifications.map((specification) => [
					specification._id,
					specification.name,
				])
			),
		[productSpecifications]
	);

	const specifications = useFieldArray({
		control,
		name: "specifications",
	});

	const specificationsSet = useMemo(
		() => new Set(specifications.fields.map(({ _id }) => _id)),
		[specifications.fields]
	);

	const availableSpecifications = useMemo(
		() =>
			productSpecifications.filter(({ _id }) => !specificationsSet.has(_id)),
		[productSpecifications, specificationsSet]
	);

	const recommendedProducts = useFieldArray({
		control,
		name: "recommendedProducts",
	});

	const recommendedProductsSet = useMemo(
		() => new Set(recommendedProducts.fields.map(({ _id }) => _id)),
		[recommendedProducts]
	);

	const handleAddRecommendedProducts = (product) => {
		if (!recommendedProductsSet.has(product._id))
			recommendedProducts.append(product);
	};

	const handleDeleteRecommendedProducts = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		recommendedProducts.remove(index);
	};

	const oftenSearchWith = useFieldArray({
		control,
		name: "oftenSearchWith",
	});

	const oftenSearchWithSet = useMemo(
		() => new Set(oftenSearchWith.fields.map(({ _id }) => _id)),
		[oftenSearchWith]
	);

	const handleAddOftenSearch = (product) => {
		if (!oftenSearchWithSet.has(product._id)) oftenSearchWith.append(product);
	};

	const handleDeleteOftenSearch = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		oftenSearchWith.remove(index);
	};

	const buysWith = useFieldArray({
		control,
		name: "buysWith",
	});

	const buysWithSet = useMemo(
		() => new Set(buysWith.fields.map(({ _id }) => _id)),
		[buysWith]
	);

	const handleAddBuysWith = (product) => {
		if (!buysWithSet.has(product._id)) buysWith.append(product);
	};

	const handleDeleteBuysWith = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		buysWith.remove(index);
	};

	const imageUrls = useFieldArray({
		control,
		name: "imageUrls",
	});

	const { deviceWidth } = useWindowSize();
	const tControl = "drawer.editProduct.";
	//const { allCategories } = useSelector((state) => state.categoryReducer);
	//const dispatch = useDispatch();
	/*const [characteristics, setCharacteristics] = useState({
		name: "",
		value: "",
	});*/
	//const [specifications, setSpecifications] = useState([]);
	//const [charProps, setCharProps] = useState({ register });
	//const [productPhotoUrls, setProductPhotoUrls] = useState([]);
	//const [productPhotos, setProductPhotos] = useState([]);
	const { showMessage } = useMessage();
	//const { productsUpdated } = useSelector((state) => state.productsReducer);

	/*const getFilesData = async (urls) => {
		const filesResponses = await Promise.all(urls.map((url) => fetch(url)));
		const filesBlobs = await Promise.all(
			filesResponses.map((res) => res.blob())
		);
		setProductPhotos(filesBlobs);
	};*/

	/*useEffect(() => {
		if (product) {
			/!*setValue("title", product?.title);
			setValue("price", product?.price);
			setValue("wholesalePrice", product?.wholesalePrice);
			setValue("discountPrice", product?.discountPrice);
			setValue("unitOfMeasurement", product?.unitOfMeasurement || "");
			setValue("amount", product?.amount || "");
			setValue("categoryId", product?.category || "");
			setValue("availability", product?.availability || "");
			setValue("description", product?.description || "");
			setValue("quantityInStock", product?.quantityInStock || "");
			setValue("quantityInPackage", product?.quantityInPackage || "");
			setValue("categoryId", product?.categoryId || "");*!/

			/!*setSpecifications(
				product.specifications.map((product) => ({
					name: product.name,
					value: product.value,
				}))
			);*!/

			setProductPhotoUrls(product.imageUrls);

			/!*setValue("seoTitle", product?.seo?.title || "");
			setValue("seoKeywords", product?.seo?.keywords || "");
			setValue("seoDescription", product?.seo?.description || "");*!/

			if (product.specifications.length > 0) {
				//setCharProps({});
			}
		}

		getFilesData(product.imageUrls);
	}, [product]);*/

	const uploadImages = async () => {
		return Promise.all(
			getValues().imageUrls.map(async ({ value }, index) => {
				if (value instanceof File) {
					const formData = new FormData();
					formData.append(`image`, value);

					const response = await $mediaApi.post("/uploadMedia", formData);

					setValue(`imageUrls.${index}.value`, response.data.url);
				}
			})
		);

		/*const productImagesData = [];
		for (let i = 0; i < productPhotos.length; i++) {
			const formData = new FormData();
			formData.append(`image`, productPhotos[i]);
			productImagesData.push(formData);
		}

		const response = await Promise.all(
			productImagesData.map((imageData) =>
				$mediaApi.post("/uploadMedia", imageData)
			)
		);

		return response.map((res) => res.data.url);*/
	};

	const [createProduct] = useCreateProductMutation();
	const [editProduct] = useEditProductMutation();

	const submitForm = async () => {
		await uploadImages();

		const data = getValues();

		const productData = {
			...data,
			//specifications,
			imageUrls: data.imageUrls.map(({ value }) => value),
			mainImageUrl: imageUrls[0]?.value,
			oftenSearchWith: data.oftenSearchWith.map(({ _id }) => _id),
			recommendedProducts: data.recommendedProducts.map(({ _id }) => _id),
			buysWith: data.buysWith.map(({ _id }) => _id),
		};

		if (product) {
			/*dispatch(
				editProduct({ productData: { ...productData, _id: product._id } })
			);*/
			productData._id = product._id;
			const response = await editProduct({ productData });

			if (!response.error) showMessage({ value: t("message.productsEdit") });
		} else {
			//dispatch(createNewProduct({ productData }));
			const response = createProduct({ productData });

			if (!response.error) showMessage({ value: t("message.productsAdd") });
		}

		//dispatch(refreshProductsAction(!productsUpdated));

		closeControlMenu();
	};

	/*const handleCharacteristicChange = (name, value) => {
		setCharacteristics({ ...characteristics, [name]: value });
		//setCharProps({ register });
	};*/

	const removeCharacteristic = (event) => {
		const index = Number(event.currentTarget.dataset.index);

		specifications.remove(index);
		/*const filteredSpecifications = specifications.filter(
			(specification, index) => index !== i
		);
		setSpecifications(filteredSpecifications);*/
	};

	/*const addSpecification = () => {
		if (!characteristics.name) {
			setError("name", {
				message: t("validations.required", { ns: "common" }),
			});
		} else if (!characteristics.value) {
			setError("value", {
				message: t("validations.required", { ns: "common" }),
			});
		} else {
			setSpecifications([...specifications, characteristics]);
			setValue("name", "");
			setValue("value", "");
			setCharacteristics({ name: "", value: "" });
			//setCharProps({});
		}
	};*/

	/*const debouncedFunction = useMemo(() => {
		return debounce((categorySearchText) => {
			console.log("search");
			if (categorySearchText.length >= 3) {
				dispatch(getAllCategoriesUnNested(categorySearchText));
			}
		}, 300);
	}, [dispatch, getAllCategoriesUnNested]);*/

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		imageUrls.append({ value: file });
	};

	const deleteProductImage = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		imageUrls.remove(index);
	};

	const specificationsForm = useForm({
		defaultValues: {
			_id: null,
			value: null,
		},
	});

	const onSpecificationsSubmit = (data) => {
		specifications.append({ ...data, name: specificationsMap.get(data._id) });
		specificationsForm.reset(null, { keepDefaultValues: true });
	};

	return (
		<div className={styles["product-creator"]}>
			<form
				id={"specificationForm"}
				onSubmit={specificationsForm.handleSubmit(onSpecificationsSubmit)}
			/>
			<form id={"mainForm"} onSubmit={handleSubmit(submitForm)} />
			<div className="offset-top-10">
				<Text as="p" size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
					{t(tControl + "mainInfo")}
				</Text>
				<div className="row row-10 offset-top-10">
					<div className="col-xl-6">
						<Input
							id="title"
							name="title"
							form={"mainForm"}
							register={register}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
								minLength: {
									value: 5,
									message: t("validations.minLength", {
										value: 5,
										ns: "common",
									}),
								},
							}}
							label={t(tControl + "productName")}
							placeholder={t(tControl + "enterProductName")}
							errors={errors}
							fluid={true}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Input
							id="price"
							name="price"
							form={"mainForm"}
							register={register}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
							}}
							label={t(tControl + "price")}
							placeholder="0.00 ₽"
							errors={errors}
							fluid={true}
							type="number"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Input
							id="wholesalePrice"
							name="wholesalePrice"
							form={"mainForm"}
							register={register}
							label={t(tControl + "purchasePrice")}
							value={product?.purchasePrice}
							placeholder="0.00 ₽"
							errors={errors}
							fluid={true}
							type="number"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Input
							id="discountPrice"
							name="discountPrice"
							form={"mainForm"}
							register={register}
							label={t(tControl + "discountPrice")}
							value={product?.discount}
							placeholder="0.00 ₽"
							errors={errors}
							fluid={true}
							type="number"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Input
							id="unitOfMeasurement"
							name="unitOfMeasurement"
							form={"mainForm"}
							register={register}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
							}}
							label={t(tControl + "measurement")}
							placeholder={t(tControl + "enterMeasurement")}
							errors={errors}
							fluid={true}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Input
							type="number"
							id="quantityInPackage"
							name="quantityInPackage"
							register={register}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
							}}
							label={t(tControl + "amount")}
							placeholder={t(tControl + "enterAmount")}
							errors={errors}
							fluid={true}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-xl-6">
						<Controller
							name="categoryId"
							control={control}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
							}}
							render={({ field: { value, name } }) => (
								<TreeSelect
									//key={JSON.stringify(categoriesData)}
									//onSearch={debouncedFunction}
									//showSearch={true}
									treeData={categoriesData}
									/*treeData={[
										{
											title: "test1",
											value: "test1",
											children: [
												{ title: "test1.1", value: "test1.2" },
											],
											disabled: true,
										},
									]}*/
									form={"mainForm"}
									placeholder={t("drawer.addCategory.selectCategory")}
									//showSearch={false}
									treeDefaultExpandAll={true}
									value={value}
									name={name}
									label={t(tControl + "addCategory")}
									labelSize={deviceWidth === "large" ? "lg" : "sm"}
									errors={errors}
									fluid
									notFoundContent={null}
									onChange={(value) => {
										setValue(name, value, {
											shouldDirty: true,
											shouldValidate: true,
										});
									}}
									/*
									//value={value}
									//notFoundContent={null}
									//filterOption={false}
									//fluid
									onChange={(value) =>
										setValue(name, value, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}*/
								/>
							)}
							/*render={({ field: { onChange, value } }) => (
								<Select
									//onSearch={debouncedFunction}
									//filterOption={false}
									//notFoundContent={null}
									//showSearch={true}
									name="categoryId"
									form={"mainForm"}
									placeholder={t("drawer.addCategory.selectCategory")}
									onChange={onChange}
									label={t(tControl + "addCategory")}
									labelSize={deviceWidth === "large" ? "lg" : "sm"}
									errors={errors}
									fluid
									value={value}
								>
									{categoriesData.map((category, index) => (
										<Select.Option key={category._id} value={category._id}>
											{category.title}
										</Select.Option>
									))}
								</Select>
							)}*/
						/>
					</div>
					<div className="col-xl-6">
						<Input
							type="number"
							id="quantityInStock"
							form={"mainForm"}
							name="quantityInStock"
							register={register}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
							}}
							label={t(tControl + "availability")}
							placeholder={t(tControl + "enterAvailability")}
							errors={errors}
							fluid={true}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-12">
						<Textarea
							id="description"
							form={"mainForm"}
							name="description"
							register={register}
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label={t(tControl + "description")}
							errors={errors}
							fluid={true}
							placeholder={t(tControl + "enterDescription")}
							heightSize="lg"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div className={styles["title-with-faq"]}>
							<Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
								{t(tControl + "characteristics")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<div className="offset-top-10">
							{specifications.fields && (
								<div className="row row-10">
									{specifications.fields.map((specification, index) => (
										<div key={specification.id} className="col-12">
											<div className={styles["modifications-list"]}>
												<div className="row row-10">
													<div className="col-md-6">
														<div className={styles["result-item"]}>
															<Text>{specification.name}</Text>
															<Icon
																data-index={index}
																onClick={removeCharacteristic}
																name="delete"
																color="red"
																size="md"
																cursorPointer
															/>
														</div>
													</div>
													<div className="col-md-6">
														{/*<div className={styles["result-item"]}>
															<Text>{specification.value}</Text>
														</div>*/}
														<Input
															register={register}
															form={"mainForm"}
															name={`specifications.${index}.value`}
															placeholder={t(
																tControl + "enterСharacteristicsProperties"
															)}
															fluid={true}
															required={true}
															// onChange={(value) =>
															// 	handleCharacteristicChange("value", value)
															// }
															errors={errors}
															rules={{
																required: {
																	value: true,
																	message: t("validations.required", {
																		ns: "common",
																	}),
																},
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
							<div className="row row-10">
								<div className="col-md-6">
									{/*<Input
										form={"specificationForm"}
										register={specificationsForm.register}
										name="name"
										placeholder={t(tControl + "enterСharacteristics")}
										fluid={true}
										errors={specificationsForm.errors}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
										}}
									/>*/}
									<Controller
										name="_id"
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
										}}
										control={specificationsForm.control}
										render={({ field: { onChange, value, name } }) => (
											<Select
												//onSearch={debouncedFunction}
												//filterOption={false}
												//notFoundContent={null}
												//showSearch={true}
												name={name}
												form={"specificationForm"}
												placeholder={t(tControl + "enterСharacteristics")}
												onChange={onChange}
												errors={specificationsForm.errors}
												fluid
												value={value}
											>
												{availableSpecifications.map((specification) => (
													<Select.Option
														key={specification._id}
														value={specification._id}
													>
														{specification.name}
													</Select.Option>
												))}
											</Select>
										)}
									/>
								</div>
								<div className="col-md-6">
									<Input
										register={specificationsForm.register}
										form={"specificationForm"}
										name="value"
										placeholder={t(tControl + "enterСharacteristicsProperties")}
										fluid={true}
										required={true}
										errors={specificationsForm.errors}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
										}}
									/>
								</div>
							</div>
						</div>
						<Button
							form={"specificationForm"}
							type={"submit"}
							//disabled={!specificationsForm.formState.isValid}
							//onClick={addSpecification}
							fluid={deviceWidth === "small"}
						>
							{t("button.addCharacteristic")}
						</Button>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
							{t(tControl + "photos")}
						</Text>
						<div className="row row-sm">
							<div className="col-md-4 col-xl-3">
								<div
									className={cn(
										styles["photo-wrapper"],
										styles["product-photo"]
									)}
								>
									<label htmlFor="upload-photo">
										<img src={photoDefault} alt="" />
										<Text align="center" color="gray">
											{deviceWidth === "large"
												? t("drawer.editProduct.addPhotos")
												: t("drawer.clickToChoose")}
										</Text>
									</label>
									<input
										disabled={imageUrls.fields.length >= 7}
										id="upload-photo"
										name="upload-photo"
										type="file"
										accept="image/png, image/jpeg, image/jpg, image/svg"
										onChange={handleFileChange}
										style={{ display: "none" }}
									/>
								</div>
							</div>
							{imageUrls.fields.map((image, index) => (
								<Controller
									key={image.id}
									control={control}
									name={`imageUrls.${index}.value`}
									render={({ field }) => (
										<div className="col-6 col-sm-4 col-xl-3">
											<div
												className={cn(
													styles["photo-wrapper"],
													styles["product-photo"]
												)}
											>
												<Screen size="xs" down>
													<img
														height={"80%"}
														src={
															field.value instanceof File
																? URL.createObjectURL(field.value)
																: field.value
														}
														className={styles["uploaded-photo"]}
														alt=""
													/>
												</Screen>
												<Screen size="xs">
													<img
														height={"80%"}
														src={
															field.value instanceof File
																? URL.createObjectURL(field.value)
																: field.value
														}
														className={styles["uploaded-photo"]}
														alt=""
													/>
												</Screen>
												<div
													className={styles["close-control"]}
													style={{
														position: "absolute",
														top: "2px",
														right: "2px",
													}}
												>
													<Icon
														data-index={index}
														onClick={deleteProductImage}
														name="close"
														size="sm"
														color="red"
													/>
												</div>
											</div>
										</div>
									)}
								/>
							))}
						</div>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div className={styles["title-with-faq"]}>
							<Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
								{t(tControl + "searching")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<ProductSearch
							className="offset-top-10"
							placeholder={t(tControl + "enterArticle")}
							inputName="searching"
							searchButton={false}
							searchPrefix={false}
							onSelect={handleAddOftenSearch}
							//overlay={false}
						/>
						{/*<Select
							className="offset-top-10"
							onSearch={debouncedFunction}
							filterOption={false}
							notFoundContent={null}
							showSearch={true}
							name="categoryId"
							placeholder={t(tControl + "enterArticle")}
							//onChange={onChange}
							errors={errors}
							fluid
							value={null}
						>
							{availableSpecifications.map((category) => (
								<Select.Option key={category.name} value={category.name}>
									{category.title}
								</Select.Option>
							))}
						</Select>*/}
						{Boolean(oftenSearchWith.fields.length) && (
							<div
								className={cn(
									styles["decrease-margin-bottom"],
									"offset-top-10"
								)}
							>
								<div className="row row-10">
									{oftenSearchWith.fields.map((product, index) => (
										<div key={product._id} className="col-md-6">
											<div className={styles["result-item"]}>
												<Text>{product.title}</Text>
												<Icon
													data-index={index}
													onClick={handleDeleteOftenSearch}
													name="delete"
													color="red"
													size="md"
													cursorPointer
												/>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
							{t(tControl + "recommended")}
						</Text>
						<ProductSearch
							className="offset-top-10"
							placeholder={t(tControl + "enterArticle")}
							inputName="recommended"
							searchButton={false}
							searchPrefix={false}
							onSelect={handleAddRecommendedProducts}
							//overlay={false}
						/>
						{Boolean(recommendedProducts.fields.length) && (
							<div
								className={cn(
									styles["decrease-margin-bottom"],
									"offset-top-10"
								)}
							>
								<div className="row row-10">
									{recommendedProducts.fields.map((product, index) => (
										<div key={product._id} className="col-md-6">
											<div className={styles["result-item"]}>
												<Text>{product.title}</Text>
												<Icon
													data-index={index}
													onClick={handleDeleteRecommendedProducts}
													name="delete"
													color="red"
													size="md"
													cursorPointer
												/>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
							{t(tControl + "buyWith")}
						</Text>
						<ProductSearch
							className="offset-top-10"
							placeholder={t(tControl + "enterArticle")}
							inputName="buysWith"
							searchButton={false}
							searchPrefix={false}
							onSelect={handleAddBuysWith}
							//overlay={false}
						/>
						{Boolean(buysWith.fields.length) && (
							<div
								className={cn(
									styles["decrease-margin-bottom"],
									"offset-top-10"
								)}
							>
								<div className="row row-10">
									{buysWith.fields.map((product, index) => (
										<div key={product._id} className="col-md-6">
											<div className={styles["result-item"]}>
												<Text>{product.title}</Text>
												<Icon
													data-index={index}
													onClick={handleDeleteBuysWith}
													name="delete"
													color="red"
													size="md"
													cursorPointer
												/>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div className={styles["title-with-faq"]}>
							<Text
								as="p"
								size={deviceWidth === "large" ? "lg" : "sm"}
								color="blue"
							>
								{t(tControl + "seo")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<Input
							id="seoTitle"
							form={"mainForm"}
							name="seo.title"
							register={register}
							rules={{
								/*required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},*/
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label="Title"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + "enterTitle")}
							labelClassName="offset-top-10"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
						<Input
							id="seoKeywords"
							form={"mainForm"}
							name="seo.keywords"
							register={register}
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label="Keywords"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + "enterKeywords")}
							labelClassName="offset-top-10"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
						<Textarea
							id="seoDescription"
							form={"mainForm"}
							name="seo.description"
							register={register}
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label="Description"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + "enterDescription")}
							labelClassName="offset-top-10"
							heightSize="lg"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
				</div>
				<Button
					/*disabled={
						specifications.length < 1 || (productPhotos.length < 1 && !product)
					}*/
					form={"mainForm"}
					type="submit"
					className={
						deviceWidth === "large" ? "offset-top-10" : "offset-top-20"
					}
					fluid={deviceWidth === "small"}
				>
					{t(product ? "buttons.save" : "buttons.create", { ns: "common" })}
				</Button>
			</div>
		</div>
	);
};

ProductCreator.propTypes = {
	product: PropTypes.shape({}),
	lang: PropTypes.string.isRequired,
	setLang: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default ProductCreator;
