import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { Text, Button, Textarea, Input, Icon, Screen } from "components/ui";
import styles from "../catalog.module.sass";
import photoDefault from "assets/catalog/photo-default.png";
import {
	useEditCategoryMutation,
	useCreateCategoryMutation,
} from "../../../redux/reducers/category";
import useMessage from "../../../hooks/useMessage";
import { $mediaApi } from "../../../api";

const CategoryCreator = ({
	category,
	lang,
	setLang,
	parentCategoryId,
	setIsOpenMenu,
}) => {
	const { t } = useTranslation(["catalog", "common"]);

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			title: category?.title || null,
			mainImageUrl: category?.mainImageUrl || null,
			iconImageUrl: category?.iconImageUrl || null,
			["seo-description"]: category?.seo?.description || null,
			["seo-title"]: category?.seo?.title || null,
			["seo-keywords"]: category?.seo?.keywords || null,
		},
	});

	const { deviceWidth } = useWindowSize();
	const tControl = "drawer.editCategory";
	//const dispatch = useDispatch();
	const categoryPhotoRef = useRef(null);
	const categoryIconRef = useRef(null);
	const [categoryPhotoUrl, setCategoryPhotoUrl] = useState("");
	const [categoryIconUrl, setCategoryIconUrl] = useState("");
	const [categoryPhoto, setCategoryPhoto] = useState(null);
	const [categoryIcon, setCategoryIcon] = useState(null);

	//const [category, setEditedCategory] = useState(category);

	/*const { loading, refreshCategories } = useSelector(
		(state) => state.categoryReducer
	);*/

	const { showMessage } = useMessage();

	/*useLayoutEffect(() => {
		const file = new File([photoDefault], "photo-default.png", {
			type: "image/png",
		});

		if (!category?._id) {
			setCategoryPhoto(file);
			setCategoryIcon(file);
			return;
		}

		(async function () {
			const photoRes = await fetch(category.mainImageUrl);
			const categoryPhotoBlob = await photoRes.blob();
			setCategoryPhoto(categoryPhotoBlob);

			const iconRes = await fetch(category.iconImageUrl);
			const categoryIconBlob = await iconRes.blob();
			setCategoryIcon(categoryIconBlob);
		})();
		//setValue("title", category.title);
	}, []);*/

	const [editCategory] = useEditCategoryMutation();
	const [createCategory] = useCreateCategoryMutation();

	const submitForm = async (data) => {
		//console.log("data", data);
		const waiting = [];

		if (data.mainImageUrl instanceof File) {
			const formData = new FormData();
			formData.append("image", data.mainImageUrl);

			waiting.push(
				$mediaApi
					.post("/uploadMedia", formData)
					.then((res) => setValue("mainImageUrl", res.data.url))
			);
		}

		if (data.iconImageUrl instanceof File) {
			const formData = new FormData();
			formData.append("image", data.iconImageUrl);

			waiting.push(
				$mediaApi
					.post("/uploadMedia", formData)
					.then((res) => setValue("iconImageUrl", res.data.url))
			);
		}

		await Promise.all(waiting);

		data = getValues();

		/*const photoFormData = new FormData();
		photoFormData.append("image", categoryPhoto);

		const iconFormData = new FormData();
		iconFormData.append("image", categoryIcon);

		const [categoryIconRes, categoryPhotoRes] = await Promise.all([
			$mediaApi.post("/uploadMedia", iconFormData),
			$mediaApi.post("/uploadMedia", photoFormData),
		]);*/

		const newData = {
			...data,
			seo: {
				description: data["seo-description"],
				title: data["seo-title"],
				keywords: data["seo-keywords"],
			},
		};

		if (category) {
			const response = await editCategory({
				_id: category._id,
				...newData,
			});
			/*dispatch(
				editCategory({
					categoryData: {
						...category,
						mainImageUrl: categoryPhotoRes.data.url,
						iconImageUrl: categoryIconRes.data.url,
					},
				})
			);*/
			//dispatch(refreshCategoriesAction(!refreshCategories));
			if (!response.error) showMessage({ value: t("message.editCategory") });
		} else {
			const response = await createCategory({ ...newData, parentCategoryId });
			/*dispatch(
				createNewCategory({
					...newData,
				})
			);

			dispatch(refreshCategoriesAction(!refreshCategories));*/
			if (!response.error) showMessage({ value: t("message.createCategory") });
		}

		setIsOpenMenu(false);
	};

	// const handleChange = (event) => {
	// 	const value = event.target.value;
	// 	const name = event.target.name;
	//
	// 	if (["seo-title", "seo-keywords", "seo-description"].includes(name)) {
	// 		setNewCategory({
	// 			...newCategory,
	// 			seo: { ...newCategory.seo, [name.split("-")[1]]: value },
	// 		});
	// 	} else {
	// 		setNewCategory({ ...newCategory, [event.target.name]: value });
	// 	}
	// };

	// const handleEnter = (value) => {
	// 	setNewCategory({
	// 		...newCategory,
	// 		searchHashtags: [...newCategory.searchHashtags, value],
	// 	});
	// };

	// const resetSearchInput = (resetFunction) => {
	// 	resetFunction("");
	// };

	// const handleTagDelete = (deleteIndex) => {
	// 	setNewCategory({
	// 		...newCategory,
	// 		searchHashtags: [
	// 			...newCategory.searchHashtags.filter(
	// 				(tag, index) => index !== deleteIndex
	// 			),
	// 		],
	// 	});
	// };

	const handleClick = (event) => {
		const name = event.target.name;
		const id = event.target.id;

		if (name === "upload-category-photo" || id === "close-photo") {
			categoryPhotoRef.current.click();
		}

		if (name === "upload-category-icon" || id === "close-icon") {
			categoryIconRef.current.click();
		}
	};

	const handleRemoveImage = (event) => {
		const name = event.currentTarget.dataset.name;

		setValue(name, null);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0] || null;
		//const url = URL.createObjectURL(file);
		const name = event.target.name;

		setValue(name, file);

		/*if (name === "category-photo") {
			setCategoryPhotoUrl(url);
			setCategoryPhoto(file);
		}

		if (name === "category-icon") {
			setCategoryIconUrl(url);
			setCategoryIcon(file);
		}*/
	};

	/*const handleChange = (value, name) => {
		if (["seo-title", "seo-keywords", "seo-description"].includes(name)) {
			setEditedCategory({
				...category,
				seo: { ...category.seo, [name.split("-")[1]]: value },
			});
		} else {
			setEditedCategory({
				...category,
				[name]: value,
			});
		}
	};
	console.log("--test--");*/

	return (
		<div className={styles["category-creator"]}>
			<form onSubmit={handleSubmit(submitForm)} className="offset-top-10">
				<Text as="p" size={deviceWidth === "large" ? "lg" : "md"} color="blue">
					{t(tControl + ".mainInfo")}
				</Text>
				<div className="row row-10">
					<div className="col-12">
						<Input
							register={register}
							id="name"
							name="title"
							//onChange={(value) => handleChange(value, "title")}
							rules={{
								required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label={t(tControl + ".categoryName")}
							// onChange={value => isAddMode
							// 	? onAddingFaq(value, 'question')
							// 	: onChangingFaq(value, 'question')
							placeholder={"Введите Название категории"}
							// }
							errors={errors}
							fluid={true}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
							labelClassName="offset-top-10"
						/>
					</div>
					<div className="col-xl-6">
						<div className={styles["title-with-faq"]}>
							<Text size={deviceWidth === "large" ? "lg" : "sm"}>
								{t(tControl + ".photoCategory")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<div className={cn(styles["photo-wrapper"], styles.photo)}>
							<Controller
								control={control}
								name={"mainImageUrl"}
								rules={{
									required: true,
								}}
								render={({ field: { value, name } }) => (
									<>
										{value ? (
											<>
												<img
													src={
														value instanceof File
															? URL.createObjectURL(value)
															: value
													}
													className={styles["uploaded-photo"]}
													alt=""
												/>
												<Icon
													id="close-photo"
													data-name={name}
													onClick={handleRemoveImage}
													style={{
														position: "absolute",
														top: "0px",
														right: "0px",
														zIndex: "2000",
														cursor: "pointer",
													}}
													name="close"
													size="md"
													color="red"
												/>
												<input
													onChange={handleFileChange}
													accept="image/*"
													ref={categoryPhotoRef}
													name={name}
													id="category-photo"
													className={styles["uploaded-photo-input"]}
													type="file"
													alt="Фотография категории"
												/>
											</>
										) : (
											<>
												<img
													className={styles["uploaded-photo"]}
													src={photoDefault}
													alt=""
												/>
												<input
													onChange={handleFileChange}
													accept="image/*"
													ref={categoryPhotoRef}
													name={name}
													id="category-photo"
													className={styles["uploaded-photo-input"]}
													type="file"
													alt="Фотография категории"
												/>
												{!value && (
													<Text align="center" color="gray">
														{deviceWidth === "large"
															? t(tControl + ".bannerFaq")
															: t("drawer.clickToChoose")}
													</Text>
												)}
											</>
										)}
									</>
								)}
							/>
						</div>
						<Screen size="xl">
							<Button
								name="upload-category-photo"
								onClick={handleClick}
								className="offset-top-10"
							>
								{t("buttons.add", { ns: "common" })}
							</Button>
						</Screen>
					</div>

					<div className="col-xl-6">
						<div className={styles["title-with-faq"]}>
							<Text size={deviceWidth === "large" ? "lg" : "sm"}>
								{t(tControl + ".iconCategory")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<div className={cn(styles["photo-wrapper"], styles.icon)}>
							<Controller
								rules={{
									required: true,
								}}
								control={control}
								name={"iconImageUrl"}
								render={({ field: { value, name } }) => (
									<>
										{value ? (
											<>
												<input
													onChange={handleFileChange}
													accept="image/*"
													ref={categoryIconRef}
													name={name}
													id="category-icon"
													className={styles["uploaded-icon-input"]}
													type="file"
													alt="Иконка категории:"
												/>
												<img
													src={
														value instanceof File
															? URL.createObjectURL(value)
															: value
													}
													className={styles["uploaded-photo-edit"]}
													alt=""
												/>

												<Icon
													id="close-icon"
													onClick={handleRemoveImage}
													style={{
														position: "absolute",
														top: "0px",
														right: "0px",
														zIndex: "2000",
														cursor: "pointer",
													}}
													data-name={name}
													name="close"
													size="md"
													color="red"
												/>
											</>
										) : (
											<>
												<input
													onChange={handleFileChange}
													accept="image/*"
													ref={categoryIconRef}
													name={name}
													id="category-icon"
													className={styles["uploaded-icon-input"]}
													type="file"
													alt="Иконка категории:"
												/>
												<img
													className={styles["uploaded-photo"]}
													src={photoDefault}
													alt=""
												/>
											</>
										)}
									</>
								)}
							/>
						</div>
						<Screen size="xl">
							<Button
								onClick={handleClick}
								name="upload-category-icon"
								className="offset-top-10"
							>
								{t("buttons.add", { ns: "common" })}
							</Button>
						</Screen>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div className={styles["title-with-faq"]}>
							<Text
								as="p"
								size={deviceWidth === "large" ? "lg" : "md"}
								color="blue"
							>
								{t(tControl + ".seoSettings")}
							</Text>
							<Screen size="xl">
								<Icon size="md" name="question-border" />
							</Screen>
						</div>
						<Input
							//onChange={(value) => handleChange(value, "seo-title")}
							register={register}
							id="seo-title"
							name="seo-title"
							rules={{
								/*required: {
									value: true,
									message: t("validations.required", { ns: "common" }),
								},*/
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							//value={category?.seo?.title || category?.seo?.title}
							label="Title"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + ".enterTitle")}
							labelClassName="offset-top-10"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
						<Input
							//onChange={(value) => handleChange(value, "seo-keywords")}
							register={register}
							id="keywords"
							name="seo-keywords"
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label="seo-Keywords"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + ".enterKeywords")}
							labelClassName="offset-top-10"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
						<Textarea
							//onChange={(value) => handleChange(value, "seo-description")}
							register={register}
							id="description"
							name="seo-description"
							rules={{
								minLength: {
									value: 2,
									message: t("validations.minLength", {
										value: 2,
										ns: "common",
									}),
								},
							}}
							label="Description"
							errors={errors}
							fluid={true}
							placeholder={t(tControl + ".enterDescription")}
							labelClassName="offset-top-10"
							heightSize="lg"
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
				</div>
				<Button
					type="submit"
					className={cn(deviceWidth !== "large" && "offset-top-10")}
					fluid={deviceWidth !== "large"}
				>
					{t("buttons.save", { ns: "common" })}
				</Button>
			</form>
		</div>
	);
};

/*CategoryCreator.propTypes = {
	category: PropTypes.shape({}).isRequired,
	lang: PropTypes.string.isRequired,
	setLang: PropTypes.func.isRequired,
};*/

export default CategoryCreator;
