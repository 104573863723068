import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import useMessage from "hooks/useMessage";
import styles from "../../auth/auth.module.sass";
import moment from "moment";

import OrdersItemsTable from "./OrdersItemsTable";
import {
	Select,
	Text,
	Textarea,
	Input,
	Button,
	ButtonGroup,
	ControlMenu,
	FormError,
} from "components/ui";
import { useGetOrderQuery } from "redux/reducers/orders";
import { useEditOrderMutation } from "redux/reducers/orders";
import { useCreateOrderMutation } from "redux/reducers/orders";
import { useGetProductsQuery } from "redux/reducers/product";
import { SearchForm } from "components/ui";
import { ProductSearch } from "components/catalog/controls/ProductSearch";
import selectIcon from "../../../assets/dropdownauth.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "redux/asyncAction/region";
import { getCities } from "redux/asyncAction/region";

const SelectIcon = () => {
	return <img src={selectIcon} alt="" />;
};

const OrdersCreator = ({
	initialOrder,
	isOpen,
	onClose,
	afterSuccessChanges,
	afterSuccessCreating,
	mode,
	orderId,
	orderData,
}) => {
	const { t } = useTranslation(["orders", "common", "catalog"]);

	const [order, setOrder] = useState({});
	const [haveChanges, setHaveChanges] = useState(false);
	const [searchText, setSearchText] = useState("");
	const {
		register,
		handleSubmit,
		trigger,
		control,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			products: orderData?.order?.products,
			lastName: orderData?.order?.lastName,
			firstName: orderData?.order?.firstName,
			phone: orderData?.order?.phone,
			createdAt: moment(orderData?.order?.createdAt).format("YYYY-MM-DD"),
			//phone: orderData?.order?.phone,
			region: orderData?.order?.shippingAddress?.city[0]?.title,
			paymentMethod: orderData?.order?.paymentMethod,
			delivery: orderData?.order?.paymentMethod,
			street: orderData?.order?.shippingAddress?.street,
			building: orderData?.order?.shippingAddress?.building,
			apartment: orderData?.order?.shippingAddress?.apartment,
			floor: orderData?.order?.shippingAddress?.floor,
			comment: orderData?.order?.comment,
			email: orderData?.order?.email,
			cityId: orderData?.order?.shippingAddress?.cityId,
			shippingType: orderData?.order?.shippingType,
			status: orderData?.order?.status,
			deliveredAt: moment(orderData?.order?.deliveredAt).format("YYYY-MM-DD"),
			paidAt: moment(orderData?.order?.paidAt).format("YYYY-MM-DD"),
		},
	});
	const { deviceWidth } = useWindowSize();
	const { showMessage } = useMessage();
	const allowedStatuses = ["IN_PROGRESS", "DELIVERED", "CANCELED"];
	const allowedPaymentTypes = ["CASH", "ONLINE"];
	const allowedDeliveryTypes = ["PICKUP", "COURIER"];
	const isCreateMode = mode === "create";
	const tControl = "drawer.editProduct.";

	const { countries, cities } = useSelector((state) => state.regionReducer);
	let dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCountries());
		dispatch(getCities());
	}, []);

	const [editOrder] = useEditOrderMutation();
	const [createOrder] = useCreateOrderMutation();
	const getProducts = useGetProductsQuery({ searchText });
	const [renderBig, setRender] = useState(false);
	const handleRender = () => {
		setRender(!renderBig);
	};

	useEffect(() => {
		// console.log("useEffect orderData", JSON.stringify(orderData));
		setInitialMaskValue();
	}, [orderData]);

	const products = useFieldArray({
		control,
		name: "products",
	});

	const productsSet = useMemo(
		() => new Set(products.fields.map(({ _id }) => _id)),
		[products]
	);

	const handleAddProduct = (product) => {
		if (!productsSet.has(product._id))
			products.append({
				_id: product._id,
				quantity: 1,
				price: product.discountPrice,
				data: product,
			});
	};

	const handleDeleteProduct = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		products.remove(index);
	};

	const setInitialMaskValue = () => {
		// console.log("setInitialMaskValue orderData", JSON.stringify(orderData));
		// if (mode === "edit" && isOpen && orderData) {
		// console.log(orderData);
		// setValue("lastName", orderData?.order?.lastName);
		// setValue("firstName", orderData?.order?.firstName);
		// setValue("phone", orderData?.order?.phone);
		// setValue("name", orderData?.order?.firstName);
		// setValue(
		// 	"createdAt",
		// 	orderData?.order?.createdAt.slice(0, 10).split("-").reverse().join(".")
		// );
		// setValue("region", orderData?.order?.shippingAddress?.city[0]?.title);
		// setValue("paymentMethod", orderData?.order?.paymentMethod);
		// setValue("delivery", orderData?.order?.paymentMethod);
		// setValue("street", orderData?.order?.shippingAddress?.street);
		// setValue("building", orderData?.order?.shippingAddress?.building);
		// setValue("apartment", orderData?.order?.shippingAddress?.apartment);
		// setValue("floor", orderData?.order?.shippingAddress?.floor);
		// setValue("comment", orderData?.order?.comment);
		// setValue("email", orderData?.order?.email);
		//setValue("products", orderData?.order?.products);
		// setValue("cityId", orderData?.order?.shippingAddress?.cityId);
		// setValue("shippingType", orderData?.order?.shippingType);
		// setValue("status", orderData?.order?.status);
		// setValue("delivered", orderData.delivered);
		// }
		// };
		// const handleEnter = (searchValue) => {
		// 	setSearchText(searchValue);
		// };
		// const handleSearch = (searchValue) => {
		// 	setSearchText(searchValue);
		// };
		// const resetInput = () => {
		// 	setSearchText("");
	};

	const onChangingOrder = (value, name) => {
		setOrder({
			...order,
			[name]: value,
		});
	};

	const canCloseMenu = async () => {
		return await trigger(Object.keys(getValues()));
	};

	const cancelEditing = () => {
		setOrder(initialOrder);
		setInitialMaskValue();
		onClose();
	};

	const onChangingOrderItems = (id) => {
		//change after connect to db
		setOrder({
			...order,
			orderItems: order.orderItems.filter((v) => v._id !== id),
		});
	};

	const addNewOrderItem = (newOrder) => {
		if (!order.orderItems) {
			setOrder({
				...order,
				orderItems: [newOrder],
			});
		} else {
			setOrder({
				...order,
				orderItems: [...order.orderItems, newOrder],
			});
		}
	};

	const saveEditingOrder = async (data) => {
		const reqData = {
			orderData: {
				_id: orderData.order._id,
				paymentMethod: data.paymentMethod,
				firstName: data.firstName,
				lastName: data.lastName,
				phone: data.phone,
				email: data.email,
				status: data.status,
				comment: data.comment,
				shippingType: data.shippingType,
				shippingAddress: {
					cityId: data.cityId,
					street: data.street,
					building: data.building,
					apartment: data.apartment,
					floor: data.floor,
				},
				deliveredAt: data.deliveredAt,
				paidAt: Date.now(),
			},
		};
		editOrder(reqData);
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
		//afterSuccessChanges(order);
		onClose();

		// //change after connect to db
		// showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
		// afterSuccessChanges(order);
		// onClose();
	};

	const saveCreatingOrder = async (data) => {
		const amount = data.products.reduce(
			(amount, product) => amount + product.price * product.quantity,
			0
		);
		const wholesalePrice = data.products.reduce(
			(amount, product) =>
				amount + product.data.wholesalePrice * product.quantity,
			0
		);
		const profit = amount - wholesalePrice;

		const newOrder = {
			orderData: {
				paymentMethod: data.paymentMethod,
				products: data.products,
				firstName: data.firstName,
				lastName: data.lastName,
				phone: data.phone,
				status: data.status,

				email: data.email,
				comment: data.comment,
				shippingType: data.shippingType,
				shippingAddress: {
					cityId: data.cityId,
					street: data.street,
					building: data.building,
					apartment: data.apartment,
					floor: data.floor,
				},
				deliveredAt: moment(data.deliveredAt, "DD.MM.YYYY").toDate(),
				paidAt: Date.now(),
				amount,
				profit,
			},
		};

		createOrder(newOrder);

		// //console.log("data", data);
		// //change after connect to db
		showMessage({ value: t("messages.dataSaved", { ns: "common" }) });
		// afterSuccessCreating(order);
		onClose();
	};

	return (
		<ControlMenu
			isOpen={isOpen}
			onClose={onClose}
			title={isCreateMode ? t("drawer.createOrder") : t("drawer.editOrder")}
			hasChanges={haveChanges}
			isCanClose={canCloseMenu}
			onSaveData={isCreateMode ? saveCreatingOrder : saveEditingOrder}
		>
			<form
				onSubmit={handleSubmit(
					isCreateMode ? saveCreatingOrder : saveEditingOrder
				)}
			>
				{!isCreateMode && (
					<Text size={deviceWidth === "large" ? "lg" : "sm"}>
						{t("drawer.orderInfo.number")}
						<Text style={{ marginLeft: 5 }} size="sm">
							{orderData?.order?.orderNumber}
						</Text>
					</Text>
				)}
				<div className="row row-10 offset-top-10">
					<div className="col-xl-6">
						<div>
							{/* <Controller
								control={control}
								name="lastName"
								// defaultValue={orderData?.order?.lastName}
								render={({ field }) => ( */}
							<Input
								type="text"
								id="lastName"
								name="lastName"
								disabled={mode === "edit"}
								register={register}
								// {...field}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
									minLength: {
										value: 2,
										message: t("validations.minLength", {
											value: 2,
											ns: "common",
										}),
									},
								}}
								placeholder={t("drawer.placeholder.lastName")}
								// value={field}
								// onChange={(value) => setValue(field.lastName, value)}
								errors={errors}
								label={t("drawer.orderInfo.lastName")}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
							/>
							{/* )} */}
							{/* /> */}
						</div>
						<div className="offset-top-10">
							<Input
								type="text"
								id="firstName"
								name="firstName"
								register={register}
								disabled={mode === "edit"}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
									minLength: {
										value: 2,
										message: t("validations.minLength", {
											value: 2,
											ns: "common",
										}),
									},
								}}
								placeholder={t("drawer.placeholder.name")}
								// value={order?.firstName || ""}
								//onChange={value => onChangingOrder(value, 'name')}
								errors={errors}
								label={t("drawer.orderInfo.name")}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
							/>
						</div>
						<div className="offset-top-10">
							<Input
								type="email"
								id="email"
								name="email"
								disabled={mode === "edit"}
								register={register}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/,
										message: t("validations.email", { ns: "common" }),
									},
								}}
								placeholder={t("drawer.placeholder.email")}
								// value={orderData?.email || ""}
								// onChange={(value) => onChangingOrder(value, "email")}
								errors={errors}
								label={t("drawer.orderInfo.email")}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
							/>
						</div>
						<div className="offset-top-10">
							<Controller
								name="phone"
								register={register}
								control={control}
								disabled={mode === "edit"}
								// defaultValue={order?.phone || ""}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
									pattern: {
										value: /\+\d{1}[\- ]?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/,
										message: t("validations.number", { ns: "common" }),
									},
								}}
								render={({ field: { onChange, value } }) => (
									<InputMask
										mask="+7 (999) 999-99-99"
										value={value}
										maskChar={null}
										disabled={mode === "edit"}
										onChange={(value) => {
											onChange(value);
											onChangingOrder(value.target.value, "phone");
										}}
									>
										{(inputProps) => (
											<Input
												id="phone"
												name="phone"
												disabled={mode === "edit"}
												placeholder={t("drawer.placeholder.phone")}
												errors={errors}
												label={t("drawer.orderInfo.phone")}
												labelSize={deviceWidth === "large" ? "lg" : "sm"}
												fluid
												{...inputProps}
											/>
										)}
									</InputMask>
								)}
							/>
						</div>
						<div className="offset-top-10">
							<Input
								id="createdAt"
								name="createdAt"
								register={register}
								disabled={mode === "edit"}
								placeholder={t("drawer.placeholder.created")}
								errors={errors}
								label={t("table.created")}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
								type="date"
							/>
						</div>
						<div className="offset-top-10">
							<Controller
								name="cityId"
								control={control}
								errorPos="categotyStoreLeft"
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								render={({ field: { onChange, value } }) => (
									<Select
										className={styles.selectProduct}
										label={t("drawer.orderInfo.region")}
										name="cityId"
										showSearch
										errorPos="categotyStoreLeft"
										value={value || null}
										placeholder={t("drawer.placeholder.region")}
										onChange={onChange}
										errors={errors}
										disabled={mode === "edit"}
										fluid
										filterOption={(input, option) =>
											(option?.label ?? "")
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										options={cities?.map((el) => {
											return {
												id: el._id,
												value: el._id,
												label: el.title,
											};
										})}
										suffixIcon={<SelectIcon />}
									></Select>
								)}
							/>
							{/* <Controller
								name="country"
								control={control}
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.countryYourStoreIsLocated"),
									},
								}}
								errorPos="categotyStoreLeft"
								errors={errors}
								render={({ field: { onChange, value } }) => (
									<Select
										name="country"
										// className={styles.selectAuth}
										value={value || t("store.placeholder.country")}
										onChange={onChange}
										errors={errors}
										suffixIcon={<SelectIcon />}
										fluid
										errorPos="categotyStoreLeft"
									>
										{countries?.map((country) => (
											<Select.Option
												style={{ zIndex: "1000" }}
												key={country?._id}
												value={country?._id}
											>
												{country?.title}
											</Select.Option>
										))}
									</Select>
								)}
							/> */}
							{/* <Input
								type="text"
								id="region"
								name="region"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								placeholder={t("drawer.placeholder.region")}
								// value={order?.region || ""}
								// onChange={(value) => onChangingOrder(value, "region")}
								errors={errors}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
							/> */}
						</div>
					</div>
					<div className="col-xl-6">
						<div>
							<Controller
								control={control}
								name="paymentMethod"
								errorPos="categotyStoreLeft"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								render={({ field }) => {
									const errorMessage = errors?.paymentMethod?.message;
									return (
										<>
											<Text
												size={deviceWidth === "large" ? "lg" : "sm"}
												color={errorMessage ? "danger" : "blue"}
											>
												{t("drawer.orderInfo.paymentType")}
											</Text>
											<Select
												disabled={mode === "edit"}
												value={t(
													`paymentTypes.${
														field.value ? field.value : "default"
													}`
												)}
												onChange={(value) => setValue(field.name, value)}
												// label={t("drawer.orderInfo.paymentType")}
												labelColor="blue"
												labelSize={deviceWidth === "large" ? "lg" : "sm"}
												className={cn("offset-top-10", { error: errorMessage })}
												fluid
											>
												{allowedPaymentTypes.map((type) => (
													<Select.Option key={type} value={type}>
														{t("paymentTypes." + type)}
													</Select.Option>
												))}
											</Select>
											<FormError message={errorMessage} />
										</>
									);
								}}
							/>
						</div>
						<div className="offset-top-10">
							{/* <Text size={deviceWidth === "large" ? "lg" : "sm"} color="blue">
								{t("drawer.orderInfo.deliveryType")}
							</Text> */}
							<Controller
								control={control}
								name="shippingType"
								errorPos="categotyStoreLeft"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								render={({ field }) => {
									const errorMessage = errors?.shippingType?.message;
									return (
										<>
											<Text
												size={deviceWidth === "large" ? "lg" : "sm"}
												color={errorMessage ? "danger" : "blue"}
											>
												{t("drawer.orderInfo.deliveryType")}
											</Text>
											<Select
												disabled={mode === "edit"}
												value={t(
													field.value
														? `deliveryTypes.${field.value}`
														: "drawer.placeholder.deliveryType"
												)}
												onChange={(value) => setValue(field.name, value)}
												className={cn("offset-top-10", { error: errorMessage })}
												fluid
											>
												{allowedDeliveryTypes.map((type) => (
													<Select.Option key={type} value={type}>
														{t("deliveryTypes." + type)}
													</Select.Option>
												))}
											</Select>
											<FormError message={errorMessage} />
										</>
									);
								}}
							/>
						</div>
						<div className="offset-top-10">
							<Controller
								name="status"
								register={register}
								control={control}
								rules={{
									required: {
										value: true,
										message: t("validations.required", { ns: "common" }),
									},
								}}
								render={({ field }) => {
									const errorMessage = errors?.status?.message;
									console.log(errorMessage, field.value, "errorMessage");
									return (
										<>
											<Text
												size={deviceWidth === "large" ? "lg" : "sm"}
												color={errorMessage ? "danger" : "blue"}
											>
												{t("table.status")}
											</Text>
											<Select
												value={t(
													field.value
														? `statuses.${field.value}`
														: "drawer.placeholder.statusType"
												)}
												onChange={(value) => {
													setValue(field.name, value);
													// onChangingOrder(value, "status");
												}}
												className={cn("offset-top-10", { error: errorMessage })}
												fluid
											>
												{allowedStatuses.map((type) => (
													<Select.Option key={type} value={type}>
														{t("statuses." + type)}
													</Select.Option>
												))}
											</Select>
											<FormError message={errorMessage} />
										</>
									);
								}}
							/>
						</div>
						<div className="offset-top-10">
							{/* <Controller
								name="delivered"
								control={control}
								defaultValue={order?.delivered || ""}
								rules={{
									pattern: {
										value: /^\d{2}.\d{2}.\d{2,4}$/,
									},
								}}
								render={({ field: { onChange, value } }) => (
									<InputMask
										mask="99.99.9999"
										value={value}
										maskChar={null}
										onChange={(value) => {
											onChange(value);
											onChangingOrder(value.target.value, "delivered");
										}}
									>
										{(inputProps) => ( */}
							<Input
								id="deliveredAt"
								name="deliveredAt"
								register={register}
								placeholder={t("drawer.placeholder.delivered")}
								errors={errors}
								label={t("drawer.orderInfo.deliveryData")}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
								type="date"
								// {...inputProps}
							/>
							{/* )} */}
							{/* </InputMask> */}
							{/* )} */}
							{/* /> */}
						</div>
						<div className="offset-top-10">
							{/* <Controller
								name="paid"
								control={control}
								defaultValue={order?.paid || ""}
								rules={{
									pattern: {
										value: /^\d{2}.\d{2}.\d{2,4}$/,
									},
								}} */}
							{/* render={({ field: { onChange, value } }) => (
									<InputMask
										mask="99.99.9999"
										value={value}
										maskChar={null}
										onChange={(value) => {
											onChange(value);
											onChangingOrder(value.target.value, "paid");
										}}
									>
										{(inputProps) => ( */}
							<Input
								id="paid"
								name="paidAt"
								disabled={mode === "edit"}
								placeholder={t("drawer.placeholder.paid")}
								errors={errors}
								label={t("table.paid")}
								register={register}
								labelSize={deviceWidth === "large" ? "lg" : "sm"}
								fluid
								type="date"
								// {...inputProps}
							/>
							{/* )} */}
							{/* </InputMask> */}
							{/* )} */}
							{/* /> */}
						</div>
					</div>
					<div className="col-12">
						<Text size={deviceWidth === "large" ? "lg" : "sm"}>
							{t("drawer.orderInfo.address")}
						</Text>
						<div className="row row-top-10">
							<div className="col-xl-6">
								<Input
									id="street"
									name="street"
									register={register}
									disabled={mode === "edit"}
									placeholder={t("drawer.placeholder.street")}
									// onChange={(value) => onChangingOrder(value, "street")}
									errors={errors}
									fluid
									rules={{
										required: {
											value: true,
											message: t("validations.required", { ns: "common" }),
										},
										minLength: {
											value: 2,
											message: t("validations.minLength", {
												value: 2,
												ns: "common",
											}),
										},
									}}
								/>
							</div>
							<div className="col-xl-6">
								<Input
									id="flat"
									name="apartment"
									register={register}
									disabled={mode === "edit"}
									placeholder={t("drawer.placeholder.flat")}
									onChange={(value) => onChangingOrder(value, "flat")}
									errors={errors}
									fluid
									rules={{
										/*required: {
											value: true,
											message: t("validations.required", { ns: "common" }),
										},*/
										minLength: {
											value: 1,
											message: t("validations.minLength", {
												value: 1,
												ns: "common",
											}),
										},
									}}
								/>
							</div>
							<div className="col-xl-6">
								<Input
									id="house"
									name="building"
									register={register}
									disabled={mode === "edit"}
									placeholder={t("drawer.placeholder.house")}
									// onChange={(value) => onChangingOrder(value, "house")}
									errors={errors}
									fluid
									rules={{
										/*required: {
											value: true,
											message: t("validations.required", { ns: "common" }),
										},*/
										minLength: {
											value: 1,
											message: t("validations.minLength", {
												value: 1,
												ns: "common",
											}),
										},
									}}
								/>
							</div>
							<div className="col-xl-6">
								<Input
									id="floor"
									name="floor"
									register={register}
									disabled={mode === "edit"}
									placeholder={t("drawer.placeholder.floor")}
									// onChange={(value) => onChangingOrder(value, "floor")}
									errors={errors}
									fluid
									rules={{
										/*required: {
											value: true,
											message: t("validations.required", { ns: "common" }),
										},*/
										minLength: {
											value: 1,
											message: t("validations.minLength", {
												value: 1,
												ns: "common",
											}),
										},
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<Textarea
							type="text"
							id="comment"
							name="comment"
							disabled={mode === "edit"}
							register={register}
							placeholder={t("drawer.placeholder.comment")}
							// value={order?.commentManager || ""}
							// onChange={(value) => onChangingOrder(value, "comment")}
							errors={errors}
							heightSize="lg"
							label={t("drawer.orderInfo.comment")}
							labelSize={deviceWidth === "large" ? "lg" : "sm"}
							fluid
						/>
					</div>
					<div>
						<div className="col-12">
							<Text size={deviceWidth === "large" ? "lg" : "sm"}>
								{t("drawer.orderInfo.orderItems")}
							</Text>
							{/* <SearchForm
						resetInput={resetInput}
						onSearch={handleSearch}
						handleEnter={handleEnter}
						className="offset-top-10"
						placeholder={t("drawer.addProduct.enterName", {ns: "catalog"})}
						inputName="product"
						overlay={false}
					/> */}
							{mode !== "edit" && (
								<ProductSearch
									className="offset-top-10"
									// placeholder={t(tControl + "enterArticle")}
									placeholder={t("drawer.placeholder.itemName")}
									inputName="searching"
									searchButton={false}
									searchPrefix={false}
									onSelect={handleAddProduct}
									// overlay={false}
								/>
							)}

							<OrdersItemsTable
								register={register}
								products={products.fields}
								deleteItem={handleDeleteProduct}
								addNewOrderItem={addNewOrderItem}
								mode={mode}
								handleRender={handleRender}
							/>
						</div>
						<div className="col-12">
							<Text size="lg" style={{ fontSize: 18 }}>
								{t("drawer.total")}
								<Text color="blue" style={{ marginLeft: 7 }}>
									{orderData?.order?.amount ||
										products.fields.reduce(
											(acc, item) => acc + item.price * +item.quantity,
											0
										)}{" "}
									₽
								</Text>
							</Text>
						</div>
					</div>
				</div>
				<ButtonGroup className={cn(deviceWidth !== "large" && "offset-top-10")}>
					<Button type="submit">
						{t("buttons.confirm", { ns: "common" })}
					</Button>
					<Button color="gray" onClick={cancelEditing}>
						{t("buttons.cancel2", { ns: "common" })}
					</Button>
				</ButtonGroup>
			</form>
		</ControlMenu>
	);
};

OrdersCreator.propTypes = {
	initialOrder: PropTypes.shape({}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	afterSuccessChanges: PropTypes.func,
	afterSuccessCreating: PropTypes.func,
	mode: PropTypes.string,
};

export default OrdersCreator;
