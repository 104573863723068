import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Text } from "components/ui";
import styles from "../clients/clients.module.sass";
import { $api } from "api";

const OrdersInfo = (data) => {
	const [orders, setOrders] = useState("");
	const { t } = useTranslation(["orders"]);
console.log(data, 'ordersInfoooo')
	// const getInfo = async () => {
	// 	let info = await $api.get("/getOrdersStatistics");
	// 	setOrders(info.data);
	// };

	// useEffect(() => {
	// 	getInfo();
	// }, []);

	return (
		// <div className={cn(styles['order-info'], 'offset-xl-top-20')}>
		// 	<ul>
		// 		<li className={styles['order-info__title']}>
		// 			<Text size='md'>{t('info.info')}</Text>
		// 		</li>
		// 		<li>
		// 			<div className={styles['order-info__item']}>
		// 				<Text size='md'>{t('info.orders')}</Text>
		// 				<Text size='md' color='blue' style={{ fontSize: 15 }}>10 000 000 ₽</Text>
		// 			</div>
		// 		</li>
		// 		<li>
		// 			<div className={styles['order-info__item']}>
		// 				<Text size='md'>{t('info.delivery')}</Text>
		// 				<Text size='md' color='blue' style={{ fontSize: 15 }}>5 000 000 ₽</Text>
		// 			</div>
		// 		</li>
		// 		<li>
		// 			<div className={styles['order-info__item']}>
		// 				<Text size='md'>{t('info.profit')}</Text>
		// 				<Text size='md' color='blue' style={{ fontSize: 15 }}>3 000 000 ₽</Text>
		// 			</div>
		// 		</li>
		// 	</ul>
		// </div>
		<div>
			<div className={styles.clInfo}>
				<div className={styles.clInfoNum}>{data?.data?.amount} ₽</div>
				<div className={styles.clInfoTitle}>Сумма заказов</div>
			</div>
			<div className={styles.clInfo}>
				<div className={styles.clInfoNum}>{data?.data?.ordersCount}</div>
				<div className={styles.clInfoTitle}>Кол-во заказов</div>
			</div>
			<div className={styles.clInfo}>
				<div className={styles.clInfoNum}>{data?.data?.profit} ₽</div>
				<div className={styles.clInfoTitle}>Прибыль</div>
			</div>
		</div>
	);
};

export default React.memo(OrdersInfo);
