import { Button } from "components/ui";
import Input from "components/ui/form/Input";
//import Textarea from "components/ui/form/Textarea";
//import Select from "components/ui/select/Select";
import useMessage from "hooks/useMessage";
//import { t } from "i18next";
import { useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import styles from "./banners.module.sass";
//import bannerimg from "../../assets/bannervar.svg";
import { useGetDefaultBannersQuery } from "redux/reducers/banners";
import { fabric } from "fabric";
import { FabricJSEditor } from "fabricjs-react";
import { useAddBannerMutation } from "redux/reducers/banners";
import { BannerEditor } from "components/banners/BannerEditor";
//import s from "./banners.module.sass"
import { useOutletContext } from "react-router-dom";
import { $mediaApi } from "../../api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getBannerTextObjects } from "utils/getBannerTextobjects";

const Library = () => {
	const { t } = useTranslation(["common"]);
	const outletContext = useOutletContext();

	const dispatch = useDispatch();

	const {
		register,
		setValue,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			banner: null,
			link: "",
		},
	});

	/** @type React.MutableRefObject<FabricJSEditor>  **/
	const editorRef = useRef(null);
	//const { editor, onReady } = useFabricJSEditor();
	//const [selectedBanner, setSelectedBanner] = useState(null);
	const selectedBanner = useWatch({
		name: "banner",
		control,
	});

	//const [bannerid, setBannerId] = useState(1);
	//const [location, setLocation] = useState("position1");

	const getDefaultBannersQuery = useGetDefaultBannersQuery({
		optionNumber: outletContext.variant,
	});
	const defaultBanners = getDefaultBannersQuery?.data?.data || [];

	const { showMessage } = useMessage();

	const [addBanner] = useAddBannerMutation();

	const onSubmit = async (inputs) => {
		const textObjects = getBannerTextObjects(editorRef.current.canvas);

		const blob = await (
			await fetch(editorRef.current.canvas.toDataURL({ format: "png" }))
		).blob();

		const formData = new FormData();
		formData.append(`image`, blob);

		const response = await $mediaApi.post("/uploadMedia", formData);
		const url = response.data.url;

		addBanner({
			url,
			link: inputs.link,
			textObjects,
			defaultBannerId: inputs.banner._id,
		}).then(({ error, data }) => {
			if (!error) {
				dispatch({ type: "UPDATE_BANNERS", payload: data.data.banners });

				showMessage({ value: t("messages.dataSaved", { ns: "common" }) });
			}
		});
	};

	//console.log(defaultBanners?.data?.data, "defaultBanners");

	//const [values, setValues] = useState(null);

	// const variants = [
	// 	{
	// 		id: 1,
	// 		title: "Вариант 1",
	// 		value: "position1",
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "Вариант 2",
	// 		value: "position2",
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "Вариант 3",
	// 		value: "position3",
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "Вариант 4",
	// 		value: "position4",
	// 	},
	// ];

	// const onChangeBanner = (value) => {
	// 	setValues({
	// 		...values,
	// 		value,
	// 		// [name]: value,
	// 	});
	//
	// 	fabric.Image.fromURL(
	// 		`https://back-media.zumzak.ru/static/${value.imageUrl}`,
	// 		(image) => {
	// 			editor.canvas.setBackgroundImage(
	// 				image,
	// 				editor.canvas.renderAll.bind(editor.canvas)
	// 			);
	// 		}
	// 	);
	// };
	// console.log(editor, "iweuifweiufh");
	// const onCreateBanner = () => {
	// 	try {
	// 		// createBanner(values);
	// 		showMessage({ value: t("messages.dataSaved", { ns: "common" }) });
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };
	// console.log(values, "onChangeBanner");

	// const Abc = () => {
	// 	if (location === "Вариант 1") {
	// 		return (
	// 			<Screen size="sm">
	// 				<img
	// 					style={{
	// 						position: "absolute",
	// 						marginLeft: "1250px",
	// 						marginTop: "-80px",
	// 					}}
	// 					src={preBanner1}
	// 				/>
	// 			</Screen>
	// 		);
	// 	} else if (location === "Вариант 2") {
	// 		return (
	// 			<Screen size="sm">
	// 				<img
	// 					style={{
	// 						position: "absolute",
	// 						marginLeft: "1250px",
	// 						marginTop: "-80px",
	// 					}}
	// 					src={preBanner2}
	// 				/>
	// 			</Screen>
	// 		);
	// 	} else if (location === "Вариант 3") {
	// 		return (
	// 			<Screen size="sm">
	// 				<img
	// 					style={{
	// 						position: "absolute",
	// 						marginLeft: "1250px",
	// 						marginTop: "-80px",
	// 					}}
	// 					src={preBanner3}
	// 				/>
	// 			</Screen>
	// 		);
	// 	} else if (location === "Вариант 4") {
	// 		return (
	// 			<Screen size="sm">
	// 				<img
	// 					style={{
	// 						position: "absolute",
	// 						marginLeft: "1250px",
	// 						marginTop: "-80px",
	// 					}}
	// 					src={preBanner4}
	// 				/>
	// 			</Screen>
	// 		);
	// 	}
	// };

	// const handleChangeBanner = (e) => {
	// 	setLocation(e);
	// };
	const createBanner = async () => {
		// const reqData = {
		// 	bannerData: {
		// 		defaultBannerId: values.value.id,
		// 		title: "Text",
		// 		description: {
		// 			text: editor?.canvas?._iTextInstances[0]?.text,
		// 			x: editor?.canvas?._iTextInstances[0]?.aCoords.tl.x,
		// 			y: editor?.canvas?._iTextInstances[0]?.aCoords.tl.y,
		// 			width: editor?.canvas?._iTextInstances[0]?.width,
		// 		},
		// 	},
		// };
		// addBanner(reqData);
	};
	// const addText = () => {
	// 	editor.addText("inset text");
	// };
	// console.log("styles", styles);
	// console.log(editor?.canvas?._iTextInstances[0]?.text, "editor?.canvas?._iTextInstances[0]?.text")
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="col-xl-6" style={{ marginTop: "10px" }}>
				<p
					style={{
						fontFamily: "Montserrat",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "16px",
						lineHeight: "20px",

						marginTop: "20px",
					}}
				>
					Баннеры:
				</p>
				<Controller
					name={"banner"}
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<>
							{defaultBanners?.map((banner) => (
								<span
									key={banner?._id}
									//onClick={() => setValue(field.name, banner)}
									style={
										selectedBanner?._id === banner?._id
											? {
													marginTop: "5px",
													filter: "drop-shadow(0px 0px 10px #78C2DD)",
											  }
											: { marginTop: "5px" }
									}
								>
									{/* <button onClick={addText}> */}
									<img
										onClick={() => {
											// onChangeBanner({
											// 	id: banner?._id,
											// 	imageUrl: banner?.imageUrl,
											// });
											// onChangeBanner(banner?.imageUrl, "imageUrl");
											setValue(field.name, banner);
											//addText();
										}}
										style={{ marginTop: "5px", width: "350px" }}
										className={styles["bannerLib"]}
										key={banner?._id}
										src={`https://back-media.zumzak.ru/static/${banner?.preview}`}
									/>
									{/* </button> */}
								</span>
							))}
						</>
					)}
				/>
			</div>
			{selectedBanner && (
				<div className={"col-xl-6"}>
					<BannerEditor
						key={selectedBanner._id}
						editorRef={editorRef}
						banner={selectedBanner}
					/>
				</div>
			)}
			{/*<FabricJSCanvas className={styles.simpleCanvas} onReady={onReady} />*/}
			{/* <div className="col-xl-6" style={{ marginTop: "15px" }}>
				<Input
					id="title"
					name="title"
					label="Заголовок"
					labelSize="lg"
					placeholder="Заголовок"
					onChange={(e) => onChangeBanner(e.target.value, "title")}
					fluid={true}
				/>
			</div> */}
			{/* <div className="col-xl-6" style={{ marginTop: "10px" }}>
				<Textarea
					id="text"
					name="text"
					label="Описание баннера"
					placeholder="Введите текст баннера..."
					heightSize="xl"
					onChange={(e) => onChangeBanner(e.target.value, "text")}
					fluid={true}
				/>
			</div> */}
			<div className="col-xl-6" style={{ marginTop: "15px" }}>
				<Input
					id="link"
					name="link"
					label="Ссылка"
					labelSize="lg"
					placeholder="Вставьте ссылку для перехода..."
					register={register}
					rules={{ required: true }}
					// onChange={(e) =>
					// 	onChangeBanner({
					// 		...values,
					// 		value: { ...value, link: e.target.value },
					// 	})
					// }
					fluid={true}
				/>
			</div>
			<div className="col-xl-6" style={{ marginTop: "20px" }}>
				<Button type={"submit"}>Добавить</Button>
			</div>
		</form>
	);
};

export default Library;
