import "../../components/catalog/Promocode.scss";
import { Icon, Button, ControlMenu } from "components/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Promocode from "components/catalog/Promocode";
import AddPromocode from "components/catalog/controls/promocodes/AddPromocode";
import InfoPromocode from "components/catalog/controls/promocodes/InfoPromocode";
import EditPromocode from "components/catalog/controls/promocodes/EditPromocode";
// import {
// 	getAllPromoCodes,
// 	deletePromoCode,
// } from "../../redux/asyncAction/promocode";
import { useDispatch, useSelector } from "react-redux";
import useMessage from "../../hooks/useMessage";
import { useGetPromocodesQuery } from "redux/reducers/promocode";
import { useDeletePromocodesMutation } from "redux/reducers/promocode";
import { useSearchParams } from "react-router-dom";

const Promocodes = () => {
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [openedMenu, setOpenedMenu] = useState({});
	const [mode, setMode] = useState("add");
	const [selectedPromoCode, setSelectedPromoCode] = useState({});
	const [promo, setPromo] = useState(null);
	const dispatch = useDispatch();
	// const { promoCodes } = useSelector((state) => state.promoCodesReducer);
	const [refreshPromoCodes, setRefreshPromoCodes] = useState(false);
	const { showMessage } = useMessage();
	const { t } = useTranslation(["catalog", "common"]);
	const [search, setSearch] = useState("");

	const [searchParams] = useSearchParams();
	const params = Object.fromEntries(searchParams.entries());
	const searchText = params.searchText;

	const getPromocodes = useGetPromocodesQuery({
		searchText,
	});

	const [deletePromocodes] = useDeletePromocodesMutation();

	useEffect(() => {
		// dispatch(getAllPromoCodes());
	}, [refreshPromoCodes]);

	const menuTypes = [
		{
			type: "addPromocode",
			value: "Добавить промокод",
		},
		{
			type: "editPromocode",
			value: "Редактировать промокод",
		},
		{
			type: "infoPromocode",
			value: "Информация о промокоде",
		},
	];

	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenu({});

		if (isActive) {
			setIsOpenMenu(true);
			setOpenedMenu(menuTypes.find((v) => v.type === type));
		} else {
			closeMenu();
		}
	};

	const closeMenu = () => {
		setIsOpenMenu(false);
		setOpenedMenu({});
	};

	const createPromocode = () => {
		setMode("add");
		toggleOpenMenu(true, "addPromocode");
	};

	const infoPromocode = (value) => {
		setMode("info");
		toggleOpenMenu(true, "infoPromocode");
		setSelectedPromoCode(value);
	};

	const editPromocode = (value) => {
		setMode("edit");
		toggleOpenMenu(true, "editPromocode");
		setSelectedPromoCode(value);
	};

	const deletePromocode = (data) => {
		console.log(data);
		deletePromocodes(data);
		// dispatch(deletePromoCode(value));
		showMessage({ value: t("message.deletePromoCode") });
		setRefreshPromoCodes(!refreshPromoCodes);
	};

	return (
		<>
			<section className="section">
				<div className="container">
					<div>
						<div>Промоакции:</div>
						<div className="promocode-list">
							{getPromocodes?.data?.promoCodes?.length ? (
								<>
									{getPromocodes?.data?.promoCodes?.map((item) => (
										<div className="promocode-item" key={item?.id}>
											<Promocode
												title={item?.title}
												id={item?._id}
												deletePromocode={() => deletePromocode(item)}
												infoPromocode={() => infoPromocode(item)}
												editPromocode={() => editPromocode(item)}
											/>
										</div>
									))}
								</>
							) : (
								<div>У вас нет промокодов</div>
							)}
						</div>

						<Button onClick={createPromocode}>Добавить промокод</Button>
					</div>
				</div>
			</section>
			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeMenu}
				title={openedMenu.value}
			>
				{openedMenu.type === "addPromocode" && (
					<AddPromocode
						closeMenu={closeMenu}
						setRefreshPromoCodes={setRefreshPromoCodes}
						refreshPromoCodes={refreshPromoCodes}
					/>
				)}
				{openedMenu.type === "infoPromocode" && (
					<InfoPromocode
						closeMenu={closeMenu}
						selectedPromoCode={selectedPromoCode}
					/>
				)}
				{openedMenu.type === "editPromocode" && (
					<EditPromocode
						selectedPromoCode={selectedPromoCode}
						closeMenu={closeMenu}
						setRefreshPromoCodes={setRefreshPromoCodes}
						refreshPromoCodes={refreshPromoCodes}
					/>
				)}
			</ControlMenu>
		</>
	);
};
export default Promocodes;
