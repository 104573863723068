import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Filter } from "components/ui";
import { Select } from "components/ui";
import styles from "./clients.module.sass";

import selectIcon from "../../assets/selectArrNew.svg";
import { useSelector } from "react-redux";
const FilterClients = ({
	filters: { region },
	onChange,
	clear,
	mobile,
	cities,
}) => {
	const { t } = useTranslation(["clients"]);

	const [open, setOpen] = useState(false);
	const [a, setA] = useState("");

	const onOpenClick = () => {
		setOpen((prev) => !prev);
	};
	// const { countries, cities } = useSelector((state) => state.regionReducer);
	console.log(cities);

	return (
		<>
			<div className={styles.clOpen} onClick={onOpenClick}>
				<div className={styles.clOpenTitle}>Cортировка</div>

				<img
					style={{ transform: open ? "rotate(180deg)" : "" }}
					src={selectIcon}
					alt=""
				/>
			</div>

			{open && (
				<div className={styles.clSel}>
					<Select
						defaultValue="Все регионы"
						onChange={(value) => {
							console.log(value);
							onChange(value, "region");
						}}
					>
						{cities?.map((city) => (
							<Select.Option key={city._id} value={city._id}>
								{city?.title}
							</Select.Option>
						))}
					</Select>
				</div>
			)}
		</>
	);
};

FilterClients.propTypes = {
	filters: PropTypes.shape({
		region: PropTypes.string.isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	clear: PropTypes.func.isRequired,
	mobile: PropTypes.bool,
};

export default FilterClients;
