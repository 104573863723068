import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LayoutLogin } from "./index";
import { Text, Button, Input } from "components/ui";
import logoSrc from "assets/logo.svg";
import logoSrcEn from "assets/logoEn.svg";
import styles from "./auth.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "materialize-css";
import { registerUser } from "redux/asyncAction/auth";
import { addUser } from "redux/asyncAction/auth";
import { checkPartner } from "redux/asyncAction/auth";
import InputAuth from "components/ui/form/InputAuth";
import { LocaleSwitcher } from "components/ui";
import { height } from "@mui/system";
// import { Link } from 'react-router-dom';

const UserRegister = ({ setRegisterInfo, setRegStep }) => {
	const { t } = useTranslation(["auth"]);
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isSubmitted },
	} = useForm();
	const { exist } = useSelector((state) => state.authReducer);
	const [agree, setAgree] = useState(true);
	const [terms, setTerms] = useState(true);
	const [logo, setLogo] = useState();

	console.log("register");

	useEffect(() => {
		const data = getValues();
		if (!isSubmitted) {
			return;
		}
		if (exist) {
			toast({ html: "Данный email уже зарегистрирован!", classes: "error" });
		} else {
			if (data.password !== data.repeatPassword) {
				toast({ html: "Пароли не совпадают", classes: "error" });
			} else {
				setRegStep("store");
				dispatch(addUser(data));
			}
		}
	}, [exist]);

	const dispatch = useDispatch();

	const submitForm = (data) => {
		console.log("data", data);
		dispatch(checkPartner(data.email));
	};

	return (
		<LayoutLogin>
			<Link to="https://partners.zumzak.ru/">
				<img
					className={styles.logo}
					style={{ with: "140px", height: "41px" }}
					src={t("logo") === "assets/logoEn.svg" ? logoSrcEn : logoSrc}
					alt=""
				/>
			</Link>
			<Text style={{ textAlign: "center" }} size="super2" color="white">
				{t("registrationNewPartners")}
			</Text>
			<div className={cn(styles["register"], styles["user"])}>
				<form onSubmit={handleSubmit(submitForm)} className="offset-top-10">
					<div className="row row-10">
						<div className="col-12">
							<div className={styles.labelInp}>
								{t("drawer.infoClient.name", { ns: "clients" })}{" "}
								<span style={{ color: "red" }}>*</span>
							</div>
							<InputAuth
								id="name"
								name="name"
								register={register}
								errorPos="right"
								rules={{
									required: { value: true, message: t("messageError.name") },
									// minLength: { value: 2, message: t('validations.minLength', { value: 2, ns: 'common' }) }
								}}
								errors={errors}
								fluid
							/>
						</div>
						<div className="col-12">
							<div className={styles.labelInp}>
								Email <span style={{ color: "red" }}>*</span>
							</div>
							<InputAuth
								type="email"
								name="email"
								errorPos="right"
								register={register}
								rules={{
									required: { value: true, message: t("messageError.email") },
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/,
										message: t("validations.email", { ns: "common" }),
									},
								}}
								errors={errors}
								fluid
							/>
						</div>
						<div className="col-12">
							<div className={styles.labelInp}>
								{t("placeholder.passwordRegNew")}{" "}
								<span style={{ color: "red" }}>*</span>
							</div>
							<InputAuth
								id="password"
								name="password"
								errorPos="right"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.password"),
									},
									validate: (value) => {
										if (value.length < 5) {
											return "Длина пароля должна быть не менее 5 символов";
										}

										const re = /^[a-zA-Z0-9#$%&@^`~]*$/;

										if (!re.test(value)) {
											//return "Пароль должен состоять из символов [A-Za-z0-9#$%&@^`~]";
											return "Используйте буквы (a-z, A-Z) и цифры";
										}

										return true;
									},
									// minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
								}}
								errors={errors}
								type="password"
								fluid
								withTogglePassword
							/>
						</div>
						<div className="col-12">
							<div className={styles.labelInp}>
								{t("placeholder.repeatPassword")}{" "}
								<span style={{ color: "red" }}>*</span>
							</div>
							<InputAuth
								id="repeatPassword"
								name="repeatPassword"
								errorPos="right"
								register={register}
								rules={{
									required: {
										value: true,
										message: t("messageError.repeatPassword"),
									},
									// minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
								}}
								errors={errors}
								type="password"
								fluid
								withTogglePassword
							/>
						</div>
						<div className="col-12">
							<Input.Group className={styles.checkboxNew}>
								<Input
									name="agree"
									type="checkbox"
									checked={agree}
									value={agree}
									onChange={(e) => setAgree(e.checked)}
									checkboxView="secondary"
									register={register}
									rules={{
										required: {
											value: true,
											message: t("messageError.dataProcessing"),
										},
										// minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
									}}
									errors={errors}
									errorPos="checkBoxleft"
								/>
								<Text
									size="xs"
									color="white"
									decoration="underline"
									style={{ width: "100%", marginLeft: 5 }}
								>
									{t("agree")}
									<span
										style={{
											color: "red",
											textAlign: "right",
											fontWeight: 400,
										}}
									>
										{" "}
										*
									</span>
								</Text>
							</Input.Group>
						</div>
						<div className="col-12">
							<Input.Group className={styles.checkboxNew}>
								<Input
									name="termsOfUse"
									type="checkbox"
									checked={terms}
									value={terms}
									onChange={(e) => setTerms(e.checked)}
									checkboxView="secondary"
									register={register}
									rules={{
										required: {
											value: true,
											message: t("messageError.termsOfUse"),
										},
										// minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
									}}
									errors={errors}
									errorPos="checkBoxleft2"
								/>
								<Text
									size="xs"
									color="white"
									decoration="underline"
									style={{ width: "100%", marginLeft: 5 }}
								>
									{t("termsOfUse")}
									<span style={{ color: "red", fontWeight: 400 }}> *</span>
								</Text>
							</Input.Group>
						</div>
						<div
							className="col-12"
							style={{ display: "flex", justifyContent: "center" }}
						>
							<button
								type="submit"
								style={{ marginTop: 5, height: "42px" }}
								className={styles.btnNewAuth}
								// disabled={!agree || !terms }
								view="tertiary"
							>
								{t("button.registerReg")}
							</button>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Link to="/">
								<Text size="xs" color="white">
									{t("dontHaveAnAccount")}{" "}
									<span style={{ borderBottom: "1px solid white" }}>
										{t("login")}
									</span>
								</Text>
							</Link>
						</div>
					</div>
				</form>
			</div>
		</LayoutLogin>
	);
};

UserRegister.propTypes = {
	setRegisterInfo: PropTypes.func.isRequired,
	setRegStep: PropTypes.func.isRequired,
};

export default UserRegister;
