import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Input, Switch, Text, Disclosure } from "components/ui";
import { splitString } from "helpers";
import styles from "./catalog.module.sass";

const ProductMobile = ({
	product,
	handlerPopular,
	handlerRecommend,
	handlerVisible,
	editProduct,
	deleteProduct,
	checkedProduct,
	onChangeCheckedProduct,
	someCheckedProducts,
}) => {
	const { t } = useTranslation(["catalog", "common"]);

	let textHidden;
	if (product.visible) {
		textHidden =
			someCheckedProducts > 1
				? t("buttonDesc.hidden", {
						value: t("buttonDesc.products"),
						ns: "common",
				  })
				: t("buttonDesc.hidden", {
						value: t("buttonDesc.product"),
						ns: "common",
				  });
	}
	if (!product.visible) {
		textHidden =
			someCheckedProducts > 1
				? t("buttonDesc.show", {
						value: t("buttonDesc.products"),
						ns: "common",
				  })
				: t("buttonDesc.show", {
						value: t("buttonDesc.product"),
						ns: "common",
				  });
	}

	let textDelete =
		someCheckedProducts > 1
			? t("buttonDesc.delete", {
					value: t("buttonDesc.products"),
					ns: "common",
			  })
			: t("buttonDesc.delete", {
					value: t("buttonDesc.product"),
					ns: "common",
			  });

	return (
		<div className={cn(styles["product-mobile"])}>
			<div className={styles["product-mobile-top"]}>
				<Input
					type="checkbox"
					name={product._id}
					checked={checkedProduct}
					onChange={(e) =>
						onChangeCheckedProduct(e.target.checked, product._id)
					}
				/>
				<div className={styles["product-mobile__info"]}>
					<Text size="sm">{product.name}</Text>
					<div className="offset-top-10">
						<Text style={{ fontSize: 18 }}>{splitString(product.price)}₽</Text>
						{product.discount && (
							<Text
								size="sm"
								color="red"
								decoration="line-through"
								style={{ marginLeft: 10 }}
							>
								{splitString(product.discount)}₽
							</Text>
						)}
					</div>
				</div>
			</div>

			<div className={styles["product-mobile-bottom"]}>
				<div className={styles["product-mobile__img"]}>
					<img src={product.imageUrls[0]} alt="product-img" />
				</div>
				<div className={styles["product-mobile__switches"]}>
					<div className={styles["product-mobile__switch"]}>
						<Text size="sm" color="gray" overflow="ellipsis">
							{t("popular")}
						</Text>
						<Switch
							id={product._id}
							checked={product.popular}
							onChange={(checked) => handlerPopular(checked, product._id)}
							square
						/>
					</div>
					<div className={styles["product-mobile__switch"]}>
						<Text size="sm" color="gray">
							{t("recommend")}
						</Text>
						<Switch
							id={"0" + product._id}
							checked={product.recommend}
							onChange={(checked) => handlerRecommend(checked, product._id)}
							square
						/>
					</div>
				</div>
			</div>

			<div className={styles["product-mobile__controls"]}>
				<Disclosure>
					<Text
						as="p"
						onClick={() => handlerVisible(product._id, !product.visible)}
					>
						{textHidden}
					</Text>
					<Text as="p" onClick={() => editProduct(product._id)}>
						{t("buttonDesc.edit", {
							value: t("buttonDesc.product"),
							ns: "common",
						})}
					</Text>
					<Text as="p" onClick={() => deleteProduct(product._id)}>
						{textDelete}
					</Text>
				</Disclosure>
			</div>
		</div>
	);
};

ProductMobile.propTypes = {
	product: PropTypes.shape({}).isRequired,
	handlerPopular: PropTypes.func.isRequired,
	handlerRecommend: PropTypes.func.isRequired,
	handlerVisible: PropTypes.func.isRequired,
	editProduct: PropTypes.func.isRequired,
	deleteProduct: PropTypes.func.isRequired,
	checkedProduct: PropTypes.bool.isRequired,
	onChangeCheckedProduct: PropTypes.func.isRequired,
};

export default ProductMobile;
