import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Icon, Text, Input, Textarea, Button, Screen } from "components/ui";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import defaultLogoImg from "assets/upload-logo.png";
import styles from "./settings.module.sass";
import { ControlMenu } from "components/ui";
import DeleteShop from "./controls/DeleteShop";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import auth from "api/service/auth";
import { updateSidebarAction } from "../../redux/reducers/global";
import { getShopData } from "redux/asyncAction/settings";

const MainStore = ({ data, onSaveChangedData }) => {
	const { t } = useTranslation(["settings", "common"]);
	const {
		setValue,
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const dispatch = useDispatch();
	const { updateSidebar } = useSelector((state) => state.updateSideBarReducer);

	const [uploadLogo, setUploadLogo] = useState({});
	const { deviceWidth } = useWindowSize();

	const [userLogo, setUserLogo] = useState("");
	const [logoUrl, setLogoUrl] = useState("");

	const { shopData, shopDataLoading, isMainEditLoading } = useSelector(
		(state) => state.settingsReducer
	);

	const [isOpenMenu, setIsOpenMenu] = useState(false);

	let logoStore = localStorage.getItem("logoStore");

	const closeControlMenu = () => {
		setIsOpenMenu(false);
	};

	const openControlMenu = () => {
		setIsOpenMenu(true);
	};

	const submitImage = async (logo) => {
		const responce = await auth.getImgStr(logo);
		localStorage.setItem("logoStore", responce.data.url);
		const responseUrl = responce.data.url;
		setLogoUrl(responseUrl);
		return responce;
	};

	const handleImgChange = async (e) => {
		setUserLogo(URL.createObjectURL(e.target.files[0]));
		await submitImage(e.target.files[0]).then((str) =>
			setValue("uploadLogo", str)
		);
	};
	console.log(logoUrl, "lofofofof");
	useEffect(() => {
		setLogoUrl(shopData?.mainInformation?.logoUrl || "");
	}, [shopData]);
	//change after connect to db
	const submitForm = (data) => {
		onSaveChangedData({ ...data, logoUrl: logoUrl });
		dispatch(updateSidebarAction(!updateSidebar));
	};
	if (shopDataLoading) {
		return (
			<div className="container">
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<>
				<div className={cn(styles["setting-store"], styles["main"])}>
					<div className={styles["setting-store__header"]}>
						<div className={styles["title"]}>
							<Icon size="md" name="settings" color="blue" />
							<Text size="md" color="blue">
								{t("filters.mainInfo")}
							</Text>
						</div>
						<Screen size="xl">
							<Icon name="question-border" size="md" />
						</Screen>
					</div>
					<div className={styles["setting-store__content"]}>
						<form onSubmit={handleSubmit(submitForm)}>
							<div className="row row-10">
								<div className="col-xl-6">
									<div className={styles["setting-item"]}>
										<Text size={deviceWidth === "large" ? "md" : "sm"}>
											{t("main.uploadLogo")}
										</Text>
										<div className={cn("offset-top-10", styles.logo)}>
											<label htmlFor="upload-logo">
												<img src={logoUrl} alt="" />
											</label>
											<input
												id="upload-logo"
												name="upload-logo"
												type="file"
												accept="image/png, image/jpeg, image/jpg, image/svg"
												// onChange={e => onLogoChange(e)}
												onChange={handleImgChange}
												style={{ display: "none" }}
											/>
										</div>
									</div>
									<div className={styles["setting-item"]}>
										<Input
											id="shop"
											name="shop"
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
											}}
											label={t("main.shop")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={shopData?.mainInformation?.title}
											errors={errors}
											suffix="lock"
											readOnly
											fluid
										/>
									</div>
									<div className={styles["setting-item"]}>
										<Input
											id="country"
											name="country"
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
											}}
											label={t("main.country")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={shopData?.mainInformation?.countryId?.title}
											errors={errors}
											errorPos="requsitesError"
											suffix="lock"
											readOnly
											fluid
										/>
									</div>
									<div className={styles["setting-item"]}>
										<Input
											id="city"
											name="city"
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
											}}
											label={t("main.city")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={shopData?.mainInformation?.cityId?.title}
											errors={errors}
											errorPos="requsitesError"
											suffix="lock"
											readOnly
											fluid
										/>
									</div>
									<div className={styles["setting-item"]}>
										<Input
											id="address"
											name="address"
											register={register}
											rules={{
												minLength: {
													value: 10,
													message: t("validations.minLength", {
														value: 10,
														ns: "common",
													}),
												},
											}}
											label={t("main.address")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={shopData?.mainInformation?.address}
											errors={errors}
											placeholder={t("main.placeholder.address")}
											fluid
										/>
									</div>
								</div>
								<div className="col-xl-6">
									<div className={styles["setting-item"]}>
										<Controller
											name="number"
											control={control}
											defaultValue={shopData?.mainInformation?.phoneNumber}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
												pattern: {
													value:
														/\+\d{1}[\- ]?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/,
													message: t("validations.number", { ns: "common" }),
												},
											}}
											render={({ field: { onChange, value } }) => (
												<InputMask
													mask="+7 (999) 999-99-99"
													value={value}
													maskChar={null}
													onChange={onChange}
												>
													{(inputProps) => (
														<Input
															id="number"
															name="number"
															errors={errors}
															errorPos="mainStorePhoneError"
															label={t("main.number")}
															labelSize={deviceWidth === "large" ? "lg" : "sm"}
															fluid
															{...inputProps}
														/>
													)}
												</InputMask>
											)}
										/>
									</div>
									<div className={styles["setting-item"]}>
										<Input
											id="category"
											name="category"
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
											}}
											label={t("main.category")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={
												shopData?.mainInformation?.rootCategory.title
											}
											errors={errors}
											fluid
											suffix="lock"
											readOnly
										/>
									</div>
									<div className={styles["setting-item"]}>
										<Text size={deviceWidth === "large" ? "md" : "sm"}>
											{t("main.workHours")}
										</Text>
										<div className="offset-top-10">
											<div className={styles["work-hours"]}>
												<Text size="xs">{t("main.weekdays")}</Text>
												<Controller
													name="weekdaysFrom"
													control={control}
													defaultValue={
														shopData?.mainInformation?.schedule?.mondayToFriday
															?.openTime
													}
													rules={{
														required: {
															value: true,
															message: t("validations.required", {
																ns: "common",
															}),
														},
														pattern: {
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="99:99"
															value={value}
															maskChar={null}
															onChange={onChange}
														>
															{(inputProps) => (
																<Input
																	name="weekdaysFrom"
																	errors={errors}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
												<Controller
													name="weekdaysTo"
													control={control}
													defaultValue={
														shopData?.mainInformation?.schedule?.mondayToFriday
															?.closeTime
													}
													rules={{
														required: {
															value: true,
															message: t("validations.required", {
																ns: "common",
															}),
														},
														pattern: {
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="99:99"
															value={value}
															maskChar={null}
															onChange={onChange}
														>
															{(inputProps) => (
																<Input
																	name="weekdaysTo"
																	errors={errors}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
											</div>
											<div className={styles["work-hours"]}>
												<Text size="xs">{t("main.weekend")}</Text>
												<Controller
													name="weekendFrom"
													control={control}
													defaultValue={
														shopData?.mainInformation?.schedule
															?.saturdayToSunday?.openTime
													}
													rules={{
														required: {
															value: true,
															message: t("validations.required", {
																ns: "common",
															}),
														},
														pattern: {
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="99:99"
															value={value}
															maskChar={null}
															onChange={onChange}
														>
															{(inputProps) => (
																<Input
																	name="weekendFrom"
																	errors={errors}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
												<Controller
													name="weekendTo"
													control={control}
													defaultValue={
														shopData?.mainInformation?.schedule
															?.saturdayToSunday?.closeTime
													}
													rules={{
														required: {
															value: true,
															message: t("validations.required", {
																ns: "common",
															}),
														},
														pattern: {
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="99:99"
															value={value}
															maskChar={null}
															onChange={onChange}
														>
															{(inputProps) => (
																<Input
																	name="weekendTo"
																	errors={errors}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
											</div>
										</div>
									</div>
									<div className={styles["setting-item"]}>
										<Textarea
											id="description"
											name="description"
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", { ns: "common" }),
												},
												minLength: {
													value: 10,
													message: t("validations.minLength", {
														value: 10,
														ns: "common",
													}),
												},
											}}
											label={t("main.description")}
											labelSize={deviceWidth === "large" ? "md" : "sm"}
											defaultValue={shopData?.mainInformation?.shortDescription}
											errors={errors}
											errorPos="mainStoreErrorTextarea"
											heightSize="xl"
											fluid={true}
											placeholder={t("main.placeholder.description")}
										/>
									</div>
								</div>
							</div>
							<div className={styles.btnCont}>
								<Button type="submit" fluid={deviceWidth === "small"}>
									{t("buttons.save", { ns: "common" })}
								</Button>
								<Button
									onClick={openControlMenu}
									fluid={deviceWidth === "small"}
								>
									Удалить магазин
								</Button>
							</div>
						</form>
					</div>
				</div>
				<ControlMenu
					isOpen={isOpenMenu}
					onClose={closeControlMenu}
					title={"Задать вопрос"}
				>
					<DeleteShop />
				</ControlMenu>
			</>
		);
	}
};

MainStore.propTypes = {
	data: PropTypes.shape({}).isRequired,
	onSaveChangedData: PropTypes.func.isRequired,
};

export default MainStore;
