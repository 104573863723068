import Header from "components/layout/header/Header";
import Button from "components/ui/button/Button";
import React, { useEffect, useState } from "react";
import { PageDescription, Screen, SearchForm } from "components/ui";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Library from "pages/banners/Library";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Select } from "components/ui";
import { Input } from "components/ui";
import big1 from "../../assets/big1.svg";
import big2 from "../../assets/big2.svg";
import big3 from "../../assets/big3.svg";
import big4 from "../../assets/big4.svg";
import PageDescriptionSection from "components/ui/pageDescription/PageDescriptionSection";
import { Icon } from "components/ui";
import { Text } from "components/ui";
import clsx from "clsx";

import styles from "./layout.banners.module.scss";
// import { useGetDefaultBannersQuery } from "redux/reducers/banners";

const variants = [
	{
		id: 1,
		label: "Вариант 1",
		value: 0,
		img: big1,
	},
	{
		id: 2,
		label: "Вариант 2",
		value: 1,
		img: big2,
	},
	{
		id: 3,
		label: "Вариант 3",
		value: 2,
		img: big3,
	},
	// {
	// 	id: 4,
	// 	title: "Вариант 4",
	// 	value: "position4",
	// },
];

const LayoutBanners = () => {
	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		defaultValues: {
			variant: variants[0],
		},
	});

	const variant = useWatch({
		name: "variant",
		control,
	});

	const img = variant?.img;

	const location = useLocation();

	//const [img, setImg] = useState();

	// const handleChangeBanner = (e) => {
	// 	if (e === "position1") {
	// 		setImg(big1);
	// 	} else if (e === "position2") {
	// 		setImg(big2);
	// 	} else if (e === "position3") {
	// 		setImg(big3);
	// 	} else if (e === "position4") {
	// 		setImg(big4);
	// 	}
	// };

	return (
		<>
			<Header></Header>
			<main
				className={clsx("nav", styles.main)}
				style={{ position: "relative" }}
			>
				<PageDescription.Section>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={"Баннеры"} />

						<Icon
							name="question-border"
							size="md"
							title={
								"В данном разделе вы можете установить баннеры для вашего магазина"
							}
							placementCustomTitle="right"
							customTitle
							style={{ marginTop: "-10px", marginLeft: "8px" }}
						/>
					</div>
				</PageDescription.Section>
				<section className={clsx("section", styles.section)}>
					<div className="container">
						<ul style={{ listStyle: "none", display: "flex" }}>
							<Link to="/banners/library">
								<li
									className={styles.navli}
									style={{
										borderBottom:
											location.pathname === "/banners/library"
												? "2px solid red"
												: "2px solid #006296",
									}}
								>
									<Text
										color={
											location.pathname === "/banners/library" ? "red" : "blue"
										}
									>
										Библиотека
									</Text>
								</li>
							</Link>
							<Link to="/banners/yourbanners">
								<li
									className={styles.navli}
									style={{
										borderBottom:
											location.pathname === "/banners/yourbanners"
												? "2px solid red"
												: "2px solid #006296",
									}}
								>
									<Text
										color={
											location.pathname === "/banners/yourbanners"
												? "red"
												: "blue"
										}
									>
										Ваши баннеры
									</Text>
								</li>
							</Link>
						</ul>

						{/* <ul style={{ display: 'flex', width: '95vw', overflow: 'auto', listStyle: 'none', gap: '8px' }}>
							<li>
								<Button as="link" to="library" view="header-nav" size="lg">
									Библиотека
								</Button>
							</li>
							<li>
								<Button as="link" to="yourbanners" view="header-nav" size="lg">
									Ваши Баннеры
								</Button>
							</li>
						</ul> */}
						<div className="col-xl-3" style={{ marginTop: "20px" }}>
							<Controller
								name="variant"
								control={control}
								render={({ field: { value, name } }) => (
									<Select
										label="Расположение баннера:"
										fluid
										onChange={(_value, option) => {
											setValue(name, option);
										}}
										defaultValue={variants[0]}
										value={value}
										//@ts-ignore
										options={variants}
									/>
								)}
							/>
						</div>
						<div className="col-xl-3" style={{ marginTop: "10px" }}>
							<Input
								suffix="lock"
								readOnly
								label="Выберите категорию баннера:"
								fluid
								value="222"
							/>
						</div>
						<Outlet context={{ variant: variant.value }} />
					</div>
					<Screen size="xl">
						<div style={{ position: "absolute", top: "20px", right: "10%" }}>
							<img src={img} width={430} />
						</div>
					</Screen>
				</section>
			</main>
		</>
	);
};

export default LayoutBanners;
