import settings from "api/service/settings";
import { editContactsAction } from "redux/reducers/settings";
import { deleteQaAction } from "redux/reducers/settings";
import { editQaAction } from "redux/reducers/settings";
import { hideQaAction } from "redux/reducers/settings";
import { addQaAction } from "redux/reducers/settings";
import { editMainAction } from "redux/reducers/settings";
import { editReqAction } from "redux/reducers/settings";
import { getShopDataAction } from "redux/reducers/settings";

export const getShopData = () => {
  return async function(dispatch){
    const fetchedShopData = await settings.getShopData();
		dispatch(getShopDataAction(fetchedShopData.data.shopData))
  }
}

export const editMain = (data) => {
  return async function(dispatch){
    const res =  await settings.editMain(data)
    dispatch(editMainAction(res.data.data))
  }
}

export const editReq = (data) => {
  return async function(dispatch){
    const res = await settings.editReq(data)
    dispatch(editReqAction(res.data.data))
  }
}

export const editContacts = (data) => {
  return async function(dispatch){
    const res = await settings.editContacts(data)
    dispatch(editContactsAction(res.data.data))
  }
}

export const addQa = (data) => {
  return async function(dispatch){
    const res = await settings.addQa(data)
    dispatch(addQaAction(res.data.data))
  }
}

export const deleteQa = (id) => {
  return async function(dispatch){
    const res = await settings.deleteQa(id)
    dispatch(deleteQaAction(res.data.data))
  }
}

export const hideQa = (id, value) => {
  return async function(dispatch){
    const res = await settings.hideQa(id, value)
    dispatch(hideQaAction(res.data.data))
  }
}

export const editQa = (data) => {
  return async function(dispatch){
    const res = await settings.editQa(data)
    dispatch(editQaAction(res.data.data))
  }
}

export const editSeo = (data) => {
	return async function(dispatch){
		const res = await settings.editSeo(data)
		dispatch(editSeoAction(res.data))
	}
}

export const updateDomain = (data) => {
	return async function (dispatch) {
		const res = await settings.updateDomain(data)
		dispatch(updateDomainAction(res.data))
	}
}
