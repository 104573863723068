import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { Text } from "components/ui";
import styles from "./settings.module.sass";

const Navigation = ({ section, setSection }) => {
	const { t } = useTranslation(["settings"], { keyPrefix: "filters" });
	const { size } = useWindowSize();

	return (
		<div className={styles["navigation"]}>
			<ul>
				<li
					className={styles["navigation__item"]}
					onClick={() => setSection("main")}
					style={{
						borderBottom:
							section === "main" ? "2px solid red" : "2px solid #006296",
					}}
				>
					<Text
						size={size === "xxl" ? "md" : "sm"}
						color={section === "main" ? "red" : "blue"}
					>
						{t("mainInfo")}
					</Text>
				</li>
				<li
					className={styles["navigation__item"]}
					onClick={() => setSection("contacts")}
					style={{
						borderBottom:
							section === "contacts" ? "2px solid red" : "2px solid #006296",
					}}
				>
					<Text
						size={size === "xxl" ? "md" : "sm"}
						color={section === "contacts" ? "red" : "blue"}
					>
						{t("contacts")}
					</Text>
				</li>
				<li
					className={styles["navigation__item"]}
					onClick={() => setSection("requisites")}
					style={{
						borderBottom:
							section === "requisites" ? "2px solid red" : "2px solid #006296",
					}}
				>
					<Text
						size={size === "xxl" ? "md" : "sm"}
						color={section === "requisites" ? "red" : "blue"}
					>
						{t("requisites")}
					</Text>
				</li>
				<li
					className={styles["navigation__item"]}
					onClick={() => setSection("faq")}
					style={{
						borderBottom:
							section === "faq" ? "2px solid red" : "2px solid #006296",
					}}
				>
					<Text
						size={size === "xxl" ? "md" : "sm"}
						color={section === "faq" ? "red" : "blue"}
					>
						{t("faq")}
					</Text>
				</li>
				<li
					className={styles["navigation__item"]}
					onClick={() => setSection("payments")}
					style={{
						borderBottom:
							section === "payments" ? "2px solid red" : "2px solid #006296",
					}}
				>
					<Text
						size={size === "xxl" ? "md" : "sm"}
						color={section === "payments" ? "red" : "blue"}
					>
						{t("payments")}
					</Text>
				</li>
			</ul>
		</div>
	);
};

Navigation.propTypes = {
	section: PropTypes.string.isRequired,
	setSection: PropTypes.func.isRequired,
};

export default React.memo(Navigation);
