import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import {
	Text,
	Button,
	SearchForm,
	Input,
	Icon,
	Select,
	Screen,
	Pagination,
} from "components/ui";
import ProductCreator from "./ProductCreator";
import HandlerLanguage from "./HandlerLanguage";
import FoundProduct from "./FoundProduct";
import FoundProductMobile from "./FoundProductMobile";
import styles from "../catalog.module.sass";
//delete
import categoryPhoto from "assets/delete/category-photo.png";
import { useDispatch, useSelector } from "react-redux";
import {
	createProductFromTemplate,
	getTemplateProducts,
} from "../../../redux/asyncAction/product";
import {
	categoryReducer,
	useGetCategoriesQuery,
	useGetSubcategoriesQuery,
} from "../../../redux/reducers/category";
import {
	//getAllCategories,
	getAllCategoriesUnNested,
} from "../../../redux/asyncAction/category";
import debounce from "lodash.debounce";
import {
	useGetTemplateProductsQuery,
	useCreateProductsFromTemplateMutation,
} from "../../../redux/reducers/product";
import ControlProductAddForm from "./ControlProductAddForm";

const ControlProductAdd = ({
	lang,
	setLang,
	onSaveAddedProducts,
	closeControlMenu,
	categoryId,
}) => {
	const { t } = useTranslation(["catalog", "common"]);

	const [checkedProducts, setCheckedProducts] = useState([]);
	//const [categorySearchText, setCategorySearchText] = useState("");
	const [isProductsConfig, setIsProductsConfig] = useState(false);
	const [mode, setMode] = useState("add");
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const { deviceWidth } = useWindowSize();
	/*const { productsUpdated, templateProducts } = useSelector(
		(state) => state.productsReducer
	);*/
	/*const { allCategories } = useSelector((state) => state.categoryReducer);
	const dispatch = useDispatch();*/

	const pageSize = 20;

	const getTemplateProductsQuery = useGetTemplateProductsQuery({
		searchText,
		limit: pageSize,
		page,
	});

	const templateProducts = getTemplateProductsQuery.data?.products || [];
	const total = getTemplateProductsQuery.data?.total || 0;

	/*useEffect(() => {
		dispatch(getTemplateProducts());
		dispatch(getAllCategoriesUnNested());
	}, []);*/

	/*const debouncedFunction = useMemo(() => {
		//  todo
		return debounce((categorySearchText) => {
			if (categorySearchText.length >= 3) {
				dispatch(getAllCategoriesUnNested(categorySearchText));
			}
		}, 300);
	}, []);*/

	const handlerCheckedProducts = (checked, product) => {
		if (checked) {
			setCheckedProducts([...checkedProducts, product]);
		} else {
			setCheckedProducts(checkedProducts.filter((v) => v._id !== product._id));
		}
	};

	const deleteCheckedProduct = (product) => {
		setCheckedProducts(checkedProducts.filter((v) => v._id !== product._id));
	};

	const handleEnter = (searchValue) => {
		setSearchText(searchValue);
	};

	const handleSearch = (searchValue) => {
		setSearchText(searchValue);
	};

	const resetInput = () => {
		setSearchText("");
	};

	/*const handleChange = (name, value) => {
		setValue(name, value);
	};

	const handleCategoryChange = (value, option, name) => {
		setValue(name, option.key);
	};

	const handleCategorySearch = (categoryTitle) => {
		setCategorySearchText(categoryTitle);
	};*/

	const handlePageChange = (page) => {
		setPage(page);
	};

	/*const getSubcategoriesQuery = useGetSubcategoriesQuery();
	const subCategories = getSubcategoriesQuery.data?.categories || [];*/

	if (mode === "create") {
		return (
			<ProductCreator
				lang={lang}
				setLang={setLang}
				closeControlMenu={closeControlMenu}
				categoryId={categoryId}
			/>
		);
	}

	return (
		<div className={styles["control-product-add"]}>
			{/* <HandlerLanguage lang={lang} setLang={setLang} /> */}
			{!isProductsConfig ? (
				<>
					<Text
						as="p"
						size={deviceWidth === "large" ? "lg" : "sm"}
						className="offset-top-10"
					>
						{t("drawer.addProduct.productHas")}
					</Text>
					<Text as="p" size="sm" color="gray" className="offset-top-10">
						{t("drawer.addProduct.productHasDesc")}
					</Text>
					<SearchForm
						resetInput={resetInput}
						onSearch={handleSearch}
						handleEnter={handleEnter}
						className="offset-top-10"
						placeholder={t("drawer.addProduct.enterName")}
						inputName="product"
						overlay={false}
					/>
					{Boolean(templateProducts.length) && (
						<div className="offset-top-10">
							<Text as="p" size={deviceWidth === "large" ? "lg" : "sm"}>
								{t("drawer.addProduct.selectedProduct")}{" "}
								{checkedProducts.length}
							</Text>
							<div className="offset-top-10">
								{templateProducts.map((product) => {
									return deviceWidth === "large" ? (
										<FoundProduct
											key={product._id}
											product={product}
											checkedProducts={checkedProducts}
											handlerCheckedProducts={handlerCheckedProducts}
										/>
									) : (
										<FoundProductMobile
											key={product._id}
											product={product}
											checkedProducts={checkedProducts}
											handlerCheckedProducts={handlerCheckedProducts}
										/>
									);
								})}
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									flexDirection: deviceWidth === "large" ? "row" : "column",
									marginTop: "10px",
								}}
							>
								<Button
									disabled={!checkedProducts.length}
									onClick={() => setIsProductsConfig(true)}
									fluid={deviceWidth === "small"}
								>
									{t("buttons.next", { ns: "common" })}
								</Button>
								<Pagination
									pageSize={pageSize}
									onChange={handlePageChange}
									current={page}
									total={total}
									mobile={deviceWidth !== "large"}
								/>
							</div>
						</div>
					)}
					<hr className="margin-10 color-blue" />
					<Text as="p" size={deviceWidth === "large" ? "lg" : "sm"}>
						{t("drawer.addProduct.createNewProduct")}
					</Text>
					<Text as="p" size="sm" color="gray" className="offset-top-10">
						{t("drawer.addProduct.createNewProductDesc")}
					</Text>
					<Button
						className={
							deviceWidth === "large" ? "offset-top-10" : "offset-top-20"
						}
						style={{ padding: "0 8px" }}
						onClick={() => setMode("create")}
						fluid={deviceWidth === "small"}
					>
						{t("drawer.addProduct.createNewProductButton")}
					</Button>
				</>
			) : (
				<ControlProductAddForm
					products={checkedProducts}
					deleteCheckedProduct={deleteCheckedProduct}
					onSaveAddedProducts={onSaveAddedProducts}
					goBack={() => setIsProductsConfig(false)}
				/>
			)}
		</div>
	);
};

ControlProductAdd.propTypes = {
	lang: PropTypes.string.isRequired,
	setLang: PropTypes.func.isRequired,
	onSaveAddedProducts: PropTypes.func.isRequired,
};

export default ControlProductAdd;
