import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'components/layout/header'
import { PageDescription } from 'components/ui'

export default function Payment() {
	const { t } = useTranslation(['payment'])

	return (
		<>
			<Header />
			<main>
				<PageDescription.Section>
					<PageDescription
						title={t('title')}
						description={t('description')}
					/>
				</PageDescription.Section>

				<div className='container'>
				</div>
			</main>
		</>
	)
}
