import clients from "api/service/clients";
import { getClientsAction } from "redux/reducers/client";
import { getClientAction } from "redux/reducers/client";
import { getClientsStatistisAction } from "redux/reducers/client";

export const getClients = (page, limit, filterClient, searchRegion) => {
  return async function(dispatch){
    const fetchedClients = await clients.getClients(page, limit, filterClient, searchRegion);
		dispatch(getClientsAction(fetchedClients.data))
  }
}

export const getClient = (id) => {
  return async function(dispatch){
    const fetchedClient = await clients.getClient(id);
    console.log(fetchedClient, 'fetchedClient')
		dispatch(getClientAction(fetchedClient.data))
  }
}


export const getSumOfAllClients = () => {
  return async function(dispatch){
    const fetchedClientsStatistics = await clients.getSumOfAllClients();
		dispatch(getClientsStatistisAction(fetchedClientsStatistics.data))
  }
}

