import axios from "axios";
import { createApi } from "@reduxjs/toolkit/query/react";

export const $api = axios.create({
	baseURL: process.env.REACT_APP_API,
});

export const axiosBaseQuery =
	() =>
	async ({ url, method, data, params }) => {
		try {
			const result = await $api({ url, method, data, params });
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};

export const baseApi = createApi({
	reducerPath: "api",
	baseQuery: axiosBaseQuery(),
	endpoints: () => ({}),
});

export const $mediaApi = axios.create({
	baseURL: "https://back-media.zumzak.ru",
});

$api.interceptors.request.use((config) => {
	const token = localStorage.getItem("zztoken");
	if (token) {
		config.headers["Authorization"] = token;
		config.headers["Cache-Control"] = "no-cache";
	}
	return config;
});
