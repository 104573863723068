const defaultState = {
  countries: [],
  cities: [],
  countriesLoading: true,
  citiesLoading: true
};

const GET_COUNTRIES = "GET_COUNTRIES";
const GET_CITIES = "GET_CITIES";
const GET_CITIES_AVAILABLE_FOR_SEARCH = 'GET_CITIES_AVAILABLE_FOR_SEARCH';
export const regionReducer = (state = defaultState, action) => {
  switch (action.type) {

    case GET_COUNTRIES:
      return { ...state, countries: action.payload, countriesLoading: false };
    
    case GET_CITIES:
      return { ...state, cities: action.payload, citiesLoading: false };

      case GET_CITIES_AVAILABLE_FOR_SEARCH:
        return { ...state, cities: action.payload, citiesLoading: false };

    default:
      return state;
  }
};

export const getCountriesAction = (payload) => ({ type: GET_COUNTRIES, payload });
export const getCitiesAction = (payload) => ({ type: GET_CITIES, payload });
export const getCitiesAvailableForSearchAction = (payload) => ({ type: GET_CITIES_AVAILABLE_FOR_SEARCH, payload });