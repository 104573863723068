import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Icon, Text } from "components/ui";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import styles from "./catalog.module.sass";
import { Img } from "../ui";

const Breadcrumbs = ({
	children,
	className,
	icon,
	imageUrl,
	title,
	color,
	goBack,
}) => {
	const { deviceWidth } = useWindowSize();

	const classList = cn(styles.breadcrumbs, className);

	// const goBack = () => navigate(-1);

	return (
		<div className={classList}>
			{children && (
				<div className={styles.breadcrumbs__children}>{children}</div>
			)}
			<Icon
				name="arrow-back"
				size="md"
				color={color}
				onClick={goBack}
				cursorPointer
			/>

			<Img src={imageUrl} height="20px" width="20px" />
			<Text
				size={deviceWidth === "large" ? "sm" : "xs"}
				color={color}
				style={{ marginLeft: 5 }}
				overflow="ellipsis"
			>
				{title}
			</Text>
		</div>
	);
};

Breadcrumbs.defaultProps = {
	color: "red",
};

Breadcrumbs.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string.isRequired,
	color: PropTypes.oneOf(["primary", "peach", "gray", "red"]),
};

export default Breadcrumbs;
