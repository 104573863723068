import {
	Button,
	ButtonGroup,
	Icon,
	Input,
	Modal,
	Select,
	Text,
	Textarea,
} from "components/ui";
import "./EditBanner.scss";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { FabricJSEditor } from "fabricjs-react";
import { Controller, useForm } from "react-hook-form";
import useMessage from "hooks/useMessage";
import { BannerEditor } from "../BannerEditor";
import {
	useRemoveBannerMutation,
	useUpdateBannerMutation,
} from "redux/reducers/banners";
import { getBannerTextObjects } from "../../../utils/getBannerTextobjects";
import { $mediaApi } from "../../../api";
import { useDispatch } from "react-redux";

const EditBanner = ({ closeMenu, editingBanner }) => {
	const { t } = useTranslation(["catalog", "banners", "common"]);
	const { showMessage } = useMessage();

	const [modal, setModal] = useState(false);

	/** @type React.MutableRefObject<FabricJSEditor>  **/
	const editorRef = useRef(null);

	const {
		handleSubmit,
		formState: { errors },
		control,
	} = useForm({
		defaultValues: {
			link: editingBanner.link,
		},
	});

	const dispatch = useDispatch();

	const [updateBanner] = useUpdateBannerMutation();

	const [removeBanner] = useRemoveBannerMutation();

	const bannerId = editingBanner._id;

	const handleDeleteBanner = async () => {
		await removeBanner({ bannerId }).then(({ error, data }) => {
			if (!error) {
				dispatch({ type: "UPDATE_BANNERS", payload: data.data.banners });

				showMessage({ value: t("message.bannerDeleted", { ns: "banners" }) });

				closeMenu();
			}
		});
	};

	const onDeleteBanner = () => {
		setModal(
			<Modal
				overlayClassName={"editBanner__modalOverlay"}
				isOpen={true}
				onRequestClose={() => setModal(null)}
				size="sm"
			>
				<div className="modal-body">
					<div className="modal-body__text">
						<Text as="p" size="xl" align="center">
							{t("confirm.deleteBanner", { ns: "banners" })}
						</Text>
					</div>
					<div className="modal-body__confirm-buttons">
						<ButtonGroup>
							<Button
								fluid={true}
								onClick={() =>
									handleDeleteBanner(bannerId).finally(() => setModal(null))
								}
							>
								{t("buttons.confirm", { ns: "common" })}
							</Button>
							<Button
								view={"cancel"}
								fluid={true}
								onClick={() => setModal(null)}
							>
								{t("buttons.cancel", { ns: "common" })}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</Modal>
		);
	};

	const onSubmit = async (inputs) => {
		const textObjects = getBannerTextObjects(editorRef.current.canvas);

		const blob = await (
			await fetch(editorRef.current.canvas.toDataURL({ format: "png" }))
		).blob();

		const formData = new FormData();
		formData.append(`image`, blob);

		const response = await $mediaApi.post("/uploadMedia", formData);
		const url = response.data.url;

		updateBanner({
			bannerId: editingBanner._id,
			url,
			link: inputs.link,
			textObjects,
		}).then(({ error, data }) => {
			if (!error) {
				dispatch({ type: "UPDATE_BANNERS", payload: data.data.banners });
				showMessage({ value: t("messages.dataSaved", { ns: "common" }) });
				closeMenu();
			}
		});
	};

	return (
		<>
			<form className="edit-banner" onSubmit={handleSubmit(onSubmit)}>
				<div className="banner-edit-group">
					<BannerEditor
						key={editingBanner._id}
						editorRef={editorRef}
						banner={editingBanner}
					/>
				</div>
				{/*<div className="banner-edit-group">
				<div className="banner-edit-title">Заголовок:</div>

				<Controller
					name="title"
					control={control}
					rules={{
						required: {
							value: true,
							message: t("validations.required", { ns: "common" }),
						},
					}}
					render={({ field: { onChange, value } }) => (
						<Input
							id="title"
							name="title"
							onChange={onChange}
							errors={errors}

							type="text"
							className="banner-edit-input"
							placeholder="Введите заголовок"
						/>
					)}
				/>
			</div>
			<div className="banner-edit-group">
				<div className="banner-edit-title">Описание баннера:</div>
				<Controller
					name="text"
					control={control}
					rules={{
						required: {
							value: true,
							message: t("validations.required", { ns: "common" }),
						},
					}}
					render={({ field: { onChange, value } }) => (
						<Textarea
							id="text"
							name="text"
							onChange={onChange}

							errors={errors}
							className="banner-edit-textarea"
							placeholder="Введите описание баннера"
						/>
					)}
				/>
			</div>*/}
				<div className="banner-edit-group">
					<div className="banner-edit-title">Ссылка:</div>
					<Controller
						name="link"
						control={control}
						rules={{
							required: {
								value: true,
								message: t("validations.required", { ns: "common" }),
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Input
								name="link"
								id="link"
								onChange={onChange}
								errors={errors}
								value={value}
								className="banner-edit-input"
								placeholder="Вставьте ссылку для перехода"
							/>
						)}
					/>
				</div>

				<div className="banner-edit-btns">
					<Button className="banner-btn" type="submit">
						Сохранить
					</Button>
					<Button onClick={onDeleteBanner} className="banner-btn">
						Удалить
					</Button>
				</div>
			</form>
			{modal}
		</>
	);
};

export default EditBanner;
