import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import cn from "classnames";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import {
	Breadcrumbs,
	Category,
	ControlCategoryEdit,
	ControlCategoryAdd,
	ControlProductAdd,
} from "components/catalog";
import {
	Text,
	Button,
	ControlMenu,
	ButtonGroup,
	Screen,
	Modal,
} from "components/ui";
import { useDispatch, useSelector } from "react-redux";
/*import {
	getAllCategories,
	changeCategoriesVisibility,
	changeCategoriesPopularity,
	getShopGlobalCategories,
} from "../../redux/asyncAction/category";*/
import {
	useGetCategoriesQuery,
	useChangeCategoryVisibilityMutation,
	useChangeCategoryPopularityMutation,
	useDeleteCategoryMutation,
} from "../../redux/reducers/category";
import { useGetProductsQuery } from "../../redux/reducers/product";
import { deleteCategory } from "../../redux/asyncAction/category";
//import { refreshCategoriesAction } from "../../redux/reducers/category";

export function Categories({
	currentCategories,
	parentCategoryId,
	parentCategory,
	header,
}) {
	const { t } = useTranslation(["catalog"]);
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [openedMenu, setOpenedMenu] = useState({});
	//const [creatingCategory, setCreatingCategory] = useState({});
	const [editingCategory, setEditingCategory] = useState(null);
	const [lang, setLang] = useState("ru");
	/*const [breadcrumbs, setBreadcrumbs] = useState({
		title: "",
		iconImageUrl: "",
	});*/
	const { showMessage } = useMessage();
	//const { subcategoryId } = useParams();
	const { deviceWidth } = useWindowSize();
	const isCreateCategoryMode = openedMenu.type === "createCategory";
	const isEditCategoryMode = openedMenu.type === "editCategory";
	const isAddProductMode = openedMenu.type === "addProduct";
	//const dispatch = useDispatch();

	const [_, setSearchParams] = useSearchParams();

	const [changeCategoryVisibility] = useChangeCategoryVisibilityMutation();
	const [changeCategoryPopularity] = useChangeCategoryPopularityMutation();
	const [deleteCategory] = useDeleteCategoryMutation();

	const [modal, setModal] = useState(null);

	/*const findCategoryById = (id, allCategories) => {
		for (let i = 0; i < allCategories.length; i++) {
			const current = allCategories[i];
			if (current._id === id) {
				return current;
			} else if (!current.subCategories) {
				const oldIds = categoryIds;
				oldIds.splice(categoryIds.length - 1, 1);
				setCategoryIds(oldIds);
			} else if (current.subCategories.length > 0) {
				const foundChild = findCategoryById(id, current.subCategories);
				if (foundChild) {
					return foundChild;
				}
			}
		}
	};*/

	//const [categoryIds, setCategoryIds] = React.useState([]);

	/*const { loading, /!*categories,*!/ refreshCategories } = useSelector(
		(state) => state.categoryReducer
	);*/

	//console.log("categories", categories);

	//const [currentCategories, setCurrentCategories] = React.useState([]);

	/*React.useEffect(() => {
		dispatch(getShopGlobalCategories());
	}, []);*/

	/*React.useEffect(() => {
		if (categories) {
			setCurrentCategories(categories?.data);
		}
	}, [categories]);*/

	const menuTypes = [
		{
			type: "createCategory",
			value: parentCategoryId
				? t("drawer.addCategory.titleSub")
				: t("drawer.addCategory.title"),
		},
		{
			type: "editCategory",
			value: parentCategoryId
				? t("drawer.editCategory.titleSubcategory")
				: t("drawer.editCategory.title"),
		},
		{ type: "addProduct", value: t("drawer.addProduct.title") },
	];

	/*useEffect(() => {
		dispatch(getAllCategories());
	}, [refreshCategories]);*/

	/*useEffect(() => {
		if (categoryIds.length > 0) {
			const currentCategoryId = categoryIds[categoryIds.length - 1];

			const category = currentCategories.find(
				(category) => category._id === currentCategoryId
			);

			if (category) {
				setCurrentCategories(category.subCategories);
				setBreadcrumbs({
					title: category.title,
					iconImageUrl: category.iconImageUrl,
				});
			}
		}
	}, [categoryIds.length]);*/

	const closeMenu = () => {
		setIsOpenMenu(false);
		setOpenedMenu({});
		//complete after connect to db
	};

	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenu({});

		if (isActive) {
			setIsOpenMenu(true);
			setOpenedMenu(menuTypes.find((v) => v.type === type));
		} else {
			closeMenu();
		}
	};

	const handlerPopular = async (categoryId, value) => {
		const response = await changeCategoryPopularity({ categoryId, value });

		if (!response.error) {
			if (value) showMessage({ value: t("message.showPopular") });
			else showMessage({ value: t("message.hiddenPopular") });
		}
		/*const currentCategory = findCategoryById(id, currentCategories);

		updateCurrentCategory(
			currentCategories,
			id,
			"popular",
			setCurrentCategories
		);

		dispatch(
			changeCategoriesPopularity({
				categoryId: currentCategory._id,
				value: currentCategory?.popular,
			})
		);*/

		/*currentCategory.popular
			? showMessage({ value: t("message.showPopular") })
			: showMessage({ value: t("message.hiddenPopular") });*/
	};

	const handlerVisible = async (categoryId, value) => {
		const response = await changeCategoryVisibility({ categoryId, value });

		if (!response.error) {
			if (value) showMessage({ value: t("message.showCategory") });
			else showMessage({ value: t("message.hiddenCategory") });
		}
		//const currentCategory = findCategoryById(id, currentCategories);
		/*updateCurrentCategory(
			currentCategories,
			id,
			"visible",
			setCurrentCategories
		);

		dispatch(
			changeCategoriesVisibility({
				categoryId: currentCategory._id,
				value: currentCategory?.visible,
			})
		);*/
		/*currentCategory.visible
			? showMessage({ value: t("message.showCategory") })
			: showMessage({ value: t("message.hiddenCategory") });*/
	};

	const editCategory = (category) => {
		/*const currentCategory = findCategoryById(id, categories.data); */
		setEditingCategory(category);
		toggleOpenMenu(true, "editCategory");
	};

	const handleDeleteCategory = async (categoryId) => {
		const response = await deleteCategory({ categoryId });
		//dispatch(deleteCategory({ categoryId: id }));
		if (!response.error) showMessage({ value: t("message.deleteCategory") });
		//dispatch(refreshCategoriesAction(!refreshCategories));
	};

	const onDeleteCategory = (categoryId) => {
		setModal(
			<Modal isOpen={true} onRequestClose={() => setModal(null)} size="sm">
				<div className="modal-body">
					<div className="modal-body__text">
						<Text as="p" size="xl" align="center">
							{t("confirm.deleteCategory", { ns: "catalog" })}
						</Text>
					</div>
					<div className="modal-body__confirm-buttons">
						<ButtonGroup>
							<Button
								fluid={true}
								onClick={() =>
									handleDeleteCategory(categoryId).finally(() => setModal(null))
								}
							>
								{t("buttons.confirm", { ns: "common" })}
							</Button>
							<Button
								view={"cancel"}
								fluid={true}
								onClick={() => setModal(null)}
							>
								{t("buttons.cancel", { ns: "common" })}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</Modal>
		);
	};

	const createCategory = () => {
		toggleOpenMenu(true, "createCategory");
	};

	const addProduct = () => {
		toggleOpenMenu(true, "addProduct");
	};

	const onSaveAddedProducts = (data) => {
		console.log(data);
		//change after connect to db
		showMessage({ value: t("message.productsAdd") });
		closeMenu();
	};

	const navigateOutwards = () => {
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);

			if (parentCategory.parentCategoryId)
				nextParams.set("parentCategoryId", parentCategory.parentCategoryId);
			else nextParams.delete("parentCategoryId");

			return nextParams;
		});
	};

	/*const updateCurrentCategory = (
		categoriesArr,
		id,
		property,
		setCurrentState
	) => {
		const updatedCategories = [...categoriesArr];

		for (let i = 0; i < updatedCategories.length; i++) {
			const current = updatedCategories[i];
			if (current._id === id) {
				current[property] = !current[property];
			} else if (current?.subCategories?.length > 0) {
				updateCurrentCategory(current?.subCategories, id, property);
			}
		}

		setCurrentState(updatedCategories);
	};*/

	const handleCategoryClick = useCallback(
		(event) => {
			setSearchParams((params) => ({
				...Object.fromEntries(params.entries()),
				parentCategoryId: event.currentTarget.dataset.id,
			}));
		},
		[setSearchParams]
	);

	return (
		<>
			{modal}
			<section className="section">
				<div className="container">
					<Screen size="xl" down>
						<ButtonGroup column={deviceWidth === "small"}>
							<Button onClick={createCategory} fluid={true}>
								{!parentCategoryId
									? t("button.addCategory")
									: t("button.addSubCategory")}
							</Button>
							{!!currentCategories?.length && (
								<Button onClick={addProduct} fluid={true}>
									{t("button.addGoods")}
								</Button>
							)}
						</ButtonGroup>
					</Screen>
					{header}
					{Boolean(currentCategories?.length) && (
						<div className="offset-top-10">
							{/*edit after connect to db*/}
							{currentCategories.map((category) => (
								<Category
									//categoryIds={categoryIds}
									//setCategoryIds={setCategoryIds}
									id={category._id}
									key={category._id}
									category={category}
									onClick={handleCategoryClick}
									handlerPopular={handlerPopular}
									handlerVisible={handlerVisible}
									editCategory={editCategory}
									deleteCategory={onDeleteCategory}
								/>
							))}
						</div>
					)}
					<Screen size="xl">
						<ButtonGroup
							className={
								currentCategories?.length ? "offset-top-20" : "offset-top-10"
							}
						>
							<Button onClick={createCategory}>
								{!parentCategoryId
									? t("button.addCategory")
									: t("button.addSubCategory")}
							</Button>
							{Boolean(parentCategoryId) && (
								<Button
									disabled={
										Boolean(currentCategories.length) ||
										!parentCategory?.parentCategoryId
									}
									onClick={addProduct}
								>
									{t("button.addGoods")}
								</Button>
							)}
						</ButtonGroup>
					</Screen>
				</div>
			</section>

			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeMenu}
				title={openedMenu?.value}
				// hasChanges={hasFaqChanges}
				// onSaveData={onAlertSaveData}
				// isCanClose={canCloseMenu}
			>
				{isCreateCategoryMode && (
					<ControlCategoryAdd
						setIsOpenMenu={setIsOpenMenu}
						lang={lang}
						setLang={setLang}
						parentCategory={parentCategory}
						parentTemplateCategoryId={parentCategory?.templateCategoryId}
					/>
				)}
				{isEditCategoryMode && (
					<ControlCategoryEdit
						setIsOpenMenu={setIsOpenMenu}
						lang={lang}
						setLang={setLang}
						category={editingCategory}
					/>
				)}

				{isAddProductMode && (
					<ControlProductAdd
						categoryId={parentCategory?._id}
						lang={lang}
						setLang={setLang}
						onSaveAddedProducts={onSaveAddedProducts}
					/>
				)}
			</ControlMenu>
		</>
	);
}
