import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMessage from 'hooks/useMessage'
import { Header } from 'components/layout/header'
import { RatesList, MemoryChart } from 'components/rate'
import { PageDescription } from 'components/ui'
import { Icon } from 'components/ui'
import { Screen } from 'components/ui'

export default function Rate() {
	const { t } = useTranslation(['rate'])
	const { showMessage } = useMessage()

	//delete after connect to db
	const [activeRate, setActiveRate] = useState('standard')
	const rates = [
		{ title: 'free', cost: '0' },
		{ title: 'standard', cost: '1495' },
		{ title: 'premium', cost: '2985' },
		{ title: 'gold', cost: '5985' }
	]
	const data = [
		{ name: 'taken', value: 350 },
		{ name: 'free', value: 1150 }
	]

	const setRate = rate => {
		setActiveRate(rate)
		showMessage({ value: t('messages.changesSaved', { ns: 'common' }) })
	}

	return (
		<>
			<Header />
			<main>

				<PageDescription.Section>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<PageDescription
							title={t('title')}

						/>
						<Screen size='xl'>
							<Icon
								name='question-border'
								size='md'
								title={t('description')}
								placementCustomTitle='right'
								customTitle
								style={{ marginTop: '-10px', marginLeft: '8px' }}
							/>
						</Screen>
					</div>

				</PageDescription.Section>
				<MemoryChart data={data} />
				<RatesList
					activeRate={activeRate}
					setRate={setRate}
					rates={rates}
				/>
			</main>
		</>
	)
}
