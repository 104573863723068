import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'components/layout/header'
import { Domain, Favicon, SeoSettings } from 'components/seo'
import { PageDescription } from 'components/ui/pageDescription'
import { Button, Icon, Screen } from 'components/ui'
import { $mediaApi } from "../api";
import { useDispatch } from "react-redux";
import useMessage from "../hooks/useMessage";
import { editSeo, updateDomain } from "../redux/asyncAction/settings";
import settings from "../api/service/settings";

export default function Seo() {
	const [faviconInfo, setFaviconInfo] = useState({});
	const [domainInfo, setDomainInfo] = useState({});
	const [seoSettings, setSeoSettings] = useState({});
	const { t } = useTranslation(['seo'])

	const getDomainInfo = async () => {
		let info = await settings.getShopData();
		const { domain, subDomain, favicon } = info.data.shopData
		setDomainInfo({ domain, subDomain, favicon });
	};

	useEffect(async () => {
		await getDomainInfo()
	}, []);

	const onChangeFavicon = async (favicon) => {
		const response = await getFavicon(favicon);
		const url = response.data.url;
		setFaviconInfo({ favicon: url })

		return response;
	}

	const getFavicon = async (favicon) => {
		let formData = new FormData();
		formData.append("image", favicon);

		return await $mediaApi.post("/uploadMedia", formData);
	}

	const { showMessage } = useMessage()
	let dispatch = useDispatch()
	const saveInfo = async () => {
		const seo = {
			chapter: seoSettings
		}

		dispatch(editSeo(seo))
		dispatch(updateDomain({
			favicon: faviconInfo.favicon,
		}))
		showMessage({ value: t('messages.changesSaved', { ns: 'common' }) })
	}

	return (
		<>
			<Header/>
			<main>
				<PageDescription.Section>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<PageDescription
							title={t('title')}
							description={''}/>
						<Screen size='xl'>
							<Icon
								name='question-border'
								size='md'
								title={t('description')}
								placementCustomTitle='right'
								customTitle
								style={{ marginTop: '-10px', marginLeft: '8px' }}
							/>
						</Screen>
					</div>
				</PageDescription.Section>

				<section className='section'>
					<div className='container'>
						<div className='row row-20'>
							<div className='col-12'>
								<SeoSettings seo={seoSettings} setSeo={setSeoSettings}/>
							</div>
							<div className='col-xl-6'>
								<Favicon
									favicon={domainInfo.favicon}
									onChangeFavicon={onChangeFavicon}
								/>
							</div>
							<div className='col-xl-6'>
								<Domain
									domain={domainInfo.domain ? domainInfo.domain : domainInfo.subDomain}
								/>
							</div>
						</div>
						<Button view='primary' onClick={saveInfo}>Сохранить</Button>
					</div>

				</section>
			</main>
		</>
	)
}
