import React from "react";
import { useTranslation } from "react-i18next";
import { splitString } from "helpers";
import { Text } from "components/ui";
import useWindowSize from "hooks/useWindowSize";
import walletImg from "assets/dashboard/wallet.svg";
import globeImg from "assets/dashboard/globe.svg";
import documentImg from "assets/dashboard/document.svg";
import cartImg from "assets/dashboard/cart.svg";
import styles from "./dashboard.module.sass";
import usersImg from "assets/dashboard/users.svg";

export default function Statistics({ statistics }) {
	const { t } = useTranslation(["dashboard"], { keyPrefix: "statistics" });
	const { size } = useWindowSize();
	// console.log(statistics, "statistics");
	return (
		<div className={styles["new-stat-block"]}>
			<div className={styles["new-stat-row"]}>
				<div className={styles.statistics}>
					<div className={styles.statistics__text}>
						<Text as="p" size="sm" color="gray" style={{ marginBottom: 5 }}>
							{t("salesToday")}
						</Text>
						<Text size="sm">
							{statistics?.todayOrdersStatistics
								? splitString(
										statistics?.todayOrdersStatistics?.todayTotalAmount,
										"."
								  )
								: "0.00"}{" "}
							₽
						</Text>
						<Text
							size="sm"
							color={
								statistics?.todayOrdersStatistics?.todayTotalAmountPercent < 0
									? "red"
									: "green"
							}
							style={{ marginLeft: 3 }}
						>
							{statistics?.todayOrdersStatistics
								? statistics?.todayOrdersStatistics?.todayTotalAmountPercent +
								  "%"
								: "0%"}
						</Text>
					</div>
					<div className={styles.statistics__icon}>
						<img src={walletImg} width={36} height={36} alt="" />
					</div>
				</div>
			</div>
			<div className={styles["new-stat-row"]}>
				<div className={styles.statistics}>
					<div className={styles.statistics__text}>
						<Text
							as="p"
							size="sm"
							color="gray"
							style={{ marginBottom: 5, lineHeight: size === "xl" && "14px" }}
						>
							{t("visitors")}
						</Text>
						<Text size="sm">
							{statistics?.todayOrdersStatistics
								? splitString(
										statistics?.todayOrdersStatistics?.todayCount,
										"."
								  ) + " "
								: 0}
						</Text>
						<Text
							size="sm"
							color={
								statistics?.todayOrdersStatistics?.todayCountPercent < 0
									? "red"
									: "green"
							}
							style={{ marginLeft: 3 }}
						>
							{statistics?.todayOrdersStatistics
								? statistics?.todayOrdersStatistics?.todayCountPercent + "%"
								: "0%"}
						</Text>
					</div>
					<div className={styles.statistics__icon}>
						<img src={globeImg} width={36} height={36} alt="" />
					</div>
				</div>
			</div>
			<div className={styles["new-stat-row"]}>
				<div className={styles.statistics}>
					<div className={styles.statistics__text}>
						<Text as="p" size="sm" color="gray" style={{ marginBottom: 5 }}>
							{t("newClients")}
						</Text>
						<Text size="sm">
							{statistics?.newClientsCount
								? splitString(statistics?.newClientsCount, ".")
								: 0}
						</Text>
						<Text
							size="sm"
							color={statistics?.newClientsCountPercent < 0 ? "red" : "green"}
							style={{ marginLeft: 3 }}
						>
							{statistics?.newClientsCountPercent
								? splitString(statistics?.newClientsCountPercent, ".") + "%"
								: "0%"}
						</Text>
					</div>
					<div className={styles.statistics__icon}>
						<img src={documentImg} width={36} height={36} alt="" />
					</div>
				</div>
			</div>
			<div className={styles["new-stat-row"]}>
				<div className={styles.statistics}>
					<div className={styles.statistics__text}>
						<Text as="p" size="sm" color="gray" style={{ marginBottom: 5 }}>
							{t("salesMonth")}
						</Text>
						<Text size="sm">
							{statistics?.monthSalesTotalAmount
								? splitString(statistics?.monthSalesTotalAmount, ".")
								: "0.00"}{" "}
							₽
						</Text>
						<Text
							size="sm"
							color={statistics?.monthSalesTotalAmount < 0 ? "red" : "green"}
							style={{ marginLeft: 3 }}
						>
							{statistics?.monthSalesTotalAmountPercent
								? statistics?.monthSalesTotalAmountPercent + "%"
								: "0%"}
						</Text>
					</div>
					<div className={styles.statistics__icon}>
						<img src={cartImg} width={36} height={36} alt="" />
					</div>
				</div>
			</div>
			<div className={styles["new-stat-row"]}>
				<div className={styles["new-clients"]}>
					<div className={styles["new-clients__list"]}>
						<Text as="p" size="sm">
							{t("title")}
						</Text>
						<Text
							style={{ whiteSpace: "nowrap" }}
							as="p"
							size="sm"
							color="green"
							weight="medium"
						>
							(
							{statistics?.newClients?.lastMonth
								? statistics?.newClients?.lastMonth
								: 0}
							)
							<Text
								color="gray"
								style={{ marginLeft: 5, whiteSpace: "nowrap" }}
							>
								{t("lastMonth")}
							</Text>
						</Text>
						<Text
							style={{ whiteSpace: "nowrap" }}
							as="p"
							size="sm"
							color="green"
							weight="medium"
						>
							(
							{statistics?.newClients?.thisMonth
								? statistics?.newClients?.thisMonth
								: 0}
							)
							<Text
								color="gray"
								style={{ marginLeft: 5, whiteSpace: "nowrap" }}
							>
								{t("thisMonth")}
							</Text>
						</Text>
					</div>
					<div className={styles["new-clients__users"]}>
						<div className={styles.logo}>
							<img src={usersImg} width={36} height={36} alt="" />
						</div>
						<div className={styles.counter}>
							<Text as="p" size="xs">
								{t("total")}
							</Text>
							<Text as="p" size="md">
								{statistics?.newClients?.total
									? statistics?.newClients?.total
									: 0}
							</Text>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
