const defaultState = {
	productsArr: [],
	loading: true,
	popSwitched: false,
	recSwitched: false,
	deleted: false,
	productsTotal: 0,
};

const GET_PRODUCTS = "GET_PRODUCTS";
const SWITCH_POPULAR = "SWITCH_POPULAR";
const SWITCH_REC = "SWITCH_REC";
const DELETE_PRODUCT = "DELETE_PRODUCT";
const SET_PRODUCTS_TOTAL = "SET_PRODUCTS_TOTAL";

export const catalogReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_PRODUCTS:
			return { ...state, productsArr: action.payload, loading: false };

		case SWITCH_POPULAR:
			return { ...state, popSwitched: !state.popSwitched };

		case SWITCH_REC:
			return { ...state, recSwitched: !state.recSwitched };

		case DELETE_PRODUCT:
			return { ...state, deleted: !state.deleted };

		case SET_PRODUCTS_TOTAL:
			return { ...state, productsTotal: action.payload };

		default:
			return state;
	}
};

export const getProductsAction = (payload) => ({ type: GET_PRODUCTS, payload });
export const switchPopularAction = (payload) => ({
	type: SWITCH_POPULAR,
	payload,
});
export const switchRecAction = (payload) => ({ type: SWITCH_REC, payload });
export const deleteProductAction = (payload) => ({
	type: DELETE_PRODUCT,
	payload,
});
export const setProductsTotalAction = (payload) => ({
	type: SET_PRODUCTS_TOTAL,
	payload,
});
