import { Textarea } from 'components/ui'
import { Text } from 'components/ui'
import { Button } from 'components/ui'
import styles from '../settings.module.sass'

export default function DeleteShop() {
  return (
    <div className={styles.deleteShopContainer}>
     <form>
        <div className='col-12'>
          <Text>Причина удаления магазина</Text>
          <Textarea heightSize='lg' placeholder="Опишите вашу проблему..."></Textarea>
        </div>
        <div className={styles.deleteBtn}>
          <Button>Отправить</Button>
        </div>
      </form>
    </div>
  )
}