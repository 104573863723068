import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import cn from "classnames";
import { Text, Icon, Img } from "components/ui";
import styles from "../../catalog.module.sass";
import { useDispatch } from "react-redux";
import { useGetTemplateCategoriesQuery } from "../../../../redux/reducers/category";

const CategorySection = ({
	onChange,
	depth,
	hoverDepth,
	setHoverDepth,
	parentCategoryId,
	searchText,
}) => {
	const [selectedCategory, setSelectedCategory] = useState(null);
	//const [subCategory, setSubCategory] = useState([]);
	//const [showNextLevel, setShowNextLevel] = useState(false);
	const categoryWrapperRef = useRef(null);
	const dispatch = useDispatch();
	const [subCategoriesKey, setSubCategoriesKey] = useState(nanoid());

	const categoriesQuery = useGetTemplateCategoriesQuery(
		{
			parentCategoryId,
			searchText,
		},
		{ skip: depth !== 0 && !parentCategoryId }
	);

	const categories = categoriesQuery.data?.templateCategories || [];

	console.log("templateCategories", categories);

	const onCategoryItem = (category) => {
		//const subCategories = category?.subCategory;
		setHoverDepth(Number(categoryWrapperRef.current.dataset.depth));
		//setShowNextLevel(true);

		/*if (subCategories) {
			setSubCategory(subCategories);
		} else {
			setShowNextLevel(false);
		}*/
	};

	const onCategoryWrapper = (e) => {
		const element = e.target;
		const depth = Number(element.dataset.depth);

		if (element === categoryWrapperRef.current) {
			setHoverDepth(depth);
			//setShowNextLevel(false);
		}
	};

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		onChange(category);
		setSubCategoriesKey(nanoid());
		//dispatch(setSelectedCategoryAction(category));
	};

	return (
		<>
			<div className="col-6 col-xl-3">
				<div
					ref={categoryWrapperRef}
					data-depth={depth}
					onMouseMove={onCategoryWrapper}
					className={styles["category-section-wrapper"]}
				>
					<div className={styles["category-section"]}>
						<div className={styles["category-section2"]}>
							{categories.map((category, i) => (
								<div
									key={category._id}
									title={category.title}
									className={cn(styles["category-section__item"], {
										[styles.active]: category === selectedCategory,
									})}
									onMouseEnter={() => onCategoryItem(category)}
									onClick={() => handleCategoryClick(category)}
								>
									<Img
										src={category.iconImageUrl}
										//title={category.title}
										height="20px"
										width="20px"
									/>
									<Text
										as="p"
										className={styles["category-section__item-text"]}
										size="sm"
										overflow="ellipsis"
										weight="medium"
									>
										{category.title}
									</Text>
									<Icon
										name="arrow-right"
										size="sm"
										wrapperClassName={styles["next-level-icon"]}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			{depth < 3 && (
				<CategorySection
					key={subCategoriesKey}
					searchText={searchText}
					parentCategoryId={selectedCategory?._id}
					onChange={onChange}
					depth={depth + 1}
					hoverDepth={hoverDepth}
					setHoverDepth={setHoverDepth}
				/>
			)}
		</>
	);
};

/*CategorySection.propTypes = {
	categories: PropTypes.array.isRequired,
	selectCategory: PropTypes.string.isRequired,
	setSelectCategory: PropTypes.func.isRequired,
	depth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	hoverDepth: PropTypes.number,
	setHoverDepth: PropTypes.func.isRequired,
};*/

export default CategorySection;
