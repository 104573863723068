import { createAction, createReducer } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";

const apiWithCategoryTags = baseApi.enhanceEndpoints({
	tagTypes: ["Category", "TemplateCategory", "GlobalCategory"],
});

export const categoryApi = apiWithCategoryTags.injectEndpoints({
	endpoints: (builder) => ({
		getCategories: builder.query({
			query: (params) => ({ url: `/searchCategories`, params }),
			providesTags: (result, error, arg) => {
				const tags = [{ type: "Category", id: "LIST" }];

				if (!result) return tags;

				if (result.parentCategory)
					tags.push({ type: "Category", id: result.parentCategory._id });

				return tags.concat(
					result.categories.map(({ _id }) => ({ type: "Category", id: _id }))
				);
			},
		}),
		getCategoriesTree: builder.query({
			query: (params) => ({ url: `/getCategoriesAsTree`, params }),
			transformResponse: (baseQueryReturnValue, meta, arg) => {
				const getAllCategories = (categories) => {
					const result = [];

					for (const category of categories) {
						result.push(category);

						if (category.subCategories)
							result.push(...getAllCategories(category.subCategories));
					}

					return result;
				};

				for (const category of getAllCategories(
					baseQueryReturnValue.categoriesTree
				)) {
					category.value = category._id;
					delete category._id;

					if (category.subCategories) {
						category.children = category.subCategories;
						delete category.subCategories;
					}

					category.disabled = Boolean(category.children);
				}

				for (const baseCategory of baseQueryReturnValue.categoriesTree) {
					baseCategory.disabled = true;
				}

				return baseQueryReturnValue;
			},
			providesTags: (result, error, arg) => {
				const tags = [{ type: "Category", id: "LIST" }];

				if (!result) return tags;

				const getCategoriesAsList = (categories) => {
					const categoriesList = [];

					for (const category of categories) {
						tags.push(category);
						if (category.subCategories)
							categoriesList.push(
								...getCategoriesAsList(category.subCategories)
							);
					}

					return categoriesList;
				};

				return tags.concat(
					getCategoriesAsList(result.categoriesTree).map(({ value }) => ({
						type: "Category",
						id: value,
					}))
				);
			},
		}),
		getGlobalCategories: builder.query({
			query: (params) => ({ url: `/getGlobalCategories`, params }),
			providesTags: (result, error, arg) => {
				const tags = [{ type: "Category", id: "LIST" }];

				if (!result) return tags;

				if (result.parentCategory)
					tags.push({ type: "Category", id: result.parentCategory._id });

				return tags.concat(
					result.categories.map(({ _id }) => ({
						type: "GlobalCategory",
						id: _id,
					}))
				);
			},
		}),
		getTemplateCategories: builder.query({
			query: (params) => ({ url: `/getTemplateCategories`, params }),
			providesTags: (result, error, arg) => {
				const tags = [{ type: "TemplateCategory", id: "LIST" }];

				if (!result) return tags;

				return tags.concat(
					result.templateCategories.map(({ _id }) => ({
						type: "TemplateCategory",
						id: _id,
					}))
				);
			},
		}),
		changeCategoryVisibility: builder.mutation({
			query: (params) => ({
				url: "/changeCategoryVisibility",
				method: "POST",
				data: params,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted(
				{ categoryId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(categoryApi.util.invalidateTags([{ type: "Category", id }]));
				} catch {}
			},
		}),
		changeCategoryPopularity: builder.mutation({
			query: (data) => ({
				url: "/changeCategoryPopularity",
				method: "POST",
				data,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted(
				{ categoryId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(categoryApi.util.invalidateTags([{ type: "Category", id }]));
				} catch {}
			},
		}),
		editCategory: builder.mutation({
			query: (data) => ({
				url: "/editCategory",
				method: "POST",
				data,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted(
				{ _id: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(categoryApi.util.invalidateTags([{ type: "Category", id }]));
				} catch {}
			},
		}),
		deleteCategory: builder.mutation({
			query: (data) => ({
				url: "/deleteCategory",
				method: "POST",
				data,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted(
				{ categoryId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(categoryApi.util.invalidateTags([{ type: "Category", id }]));
				} catch {}
			},
		}),
		createCategoryFromTemplate: builder.mutation({
			query: (data) => ({
				url: "/createCategoryFromTemplateV2",
				method: "POST",
				data,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(
						categoryApi.util.invalidateTags([{ type: "Category", id: "LIST" }])
					);
				} catch {}
			},
		}),
		createCategory: builder.mutation({
			query: (data) => ({
				url: "/createCategory",
				method: "POST",
				data,
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(
						categoryApi.util.invalidateTags([{ type: "Category", id: "LIST" }])
					);
				} catch {}
			},
		}),
	}),
});

export const {
	useGetCategoriesQuery,
	useGetGlobalCategoriesQuery,
	useGetCategoriesTreeQuery,
	useChangeCategoryVisibilityMutation,
	useChangeCategoryPopularityMutation,
	useEditCategoryMutation,
	useDeleteCategoryMutation,
	useGetTemplateCategoriesQuery,
	useCreateCategoryFromTemplateMutation,
	useCreateCategoryMutation,
} = categoryApi;

const defaultState = {
	globalCategories: [],
	categories: [],
	loading: true,
	categoryVisibility: { categoryId: "", value: false },
	searchText: "", //TODO: use when client site filtering is required
	newCategory: [],
	refreshCategories: true,
	selectedCategory: {},
	allCategories: [],
};

const GET_GLOBAL_CATEGORIES = "GET_GLOBAL_CATEGORIES";
const GET_SHOP_GLOBAL_CATEGORIES = "GET_SHOP_GLOBAL_CATEGORIES";
const SEARCH_CATEGORIES = "SEARCH_CATEGORIES";
const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
const CHANGE_CATEGORY_VISIBILITY = "CHANGE_CATEGORY_VISIBILITY";
const CHANGE_CATEGORY_POPULARITY = "CHANGE_CATEGORY_POPULARITY";
const CREATE_CATEGORY = "CREATE_CATEGORY";
const DELETE_CATEGORY = "CREATE_CATEGORY";
const REFRESH_CATEGORIES = "REFRESH_CATEGORIES";
const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";

export const getGlobalCategoriesAction = createAction(GET_GLOBAL_CATEGORIES);
export const getShopGlobalCategoriesAction = createAction(
	GET_SHOP_GLOBAL_CATEGORIES
);

export const searchCategoriesAction = createAction(SEARCH_CATEGORIES);

export const getAllCategoriesAction = createAction(GET_ALL_CATEGORIES);

export const changeCategoryVisibilityAction = createAction(
	CHANGE_CATEGORY_VISIBILITY
);

export const createNewCategory = createAction(CREATE_CATEGORY);

export const refreshCategoriesAction = createAction(REFRESH_CATEGORIES);

export const setSelectedCategoryAction = createAction(SET_SELECTED_CATEGORY);

export const categoryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_GLOBAL_CATEGORIES:
			return { ...state, globalCategories: action.payload, loading: false };

		case SEARCH_CATEGORIES:
			return { ...state, categories: action.payload, loading: false };

		case GET_ALL_CATEGORIES:
			return { ...state, allCategories: action.payload, loading: false };

		case SET_SEARCH_TEXT:
			return { ...state, allCategories: action.payload, loading: false };

		case CHANGE_CATEGORY_VISIBILITY:
			return { ...state, categoryVisibility: action.payload, loading: false };

		case CREATE_CATEGORY:
			return { ...state, newCategory: action.payload, loading: false };

		case REFRESH_CATEGORIES:
			return { ...state, refreshCategories: action.payload, loading: false };

		case GET_SHOP_GLOBAL_CATEGORIES:
			return { ...state, shopGlobalCategories: action.payload, loading: false };

		case SET_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload, loading: false };

		default:
			return state;
	}
};
