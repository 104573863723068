import React from "react";
import cn from "classnames";
import { Text } from "../ui";
import useWindowSize from "../../hooks/useWindowSize";

export const CatalogTitle = ({ children }) => {
	const { deviceWidth } = useWindowSize();
	return (
		<Text
			as="p"
			size="lg"
			className={cn(deviceWidth !== "large" && "offset-top-10")}
		>
			{children}
		</Text>
	);
};
