import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './form.module.sass'
import FormErrorAuth from './FormErrorAuth'

const InputGroup = ({ children, className, align , name, errors, rules, onChange, required, register}) => {
	const classNames = cn(
		styles['input-group'],
		`align-items-${align}`,
		className
	)
	const validations = register
	? { ...register(name, { required, ...rules, onChange: e => onChange ? onChange(e.target.value) : null }) }
	: null

	return (
		<div className={classNames}>{children}<FormErrorAuth {...validations} message={errors?.[name]?.message} /></div>
	)
}

InputGroup.defaultProps = {
	align: 'center'
}

InputGroup.propTypes = {
	className: PropTypes.string,
	align: PropTypes.oneOf(['center', 'start', 'end'])
}

export default InputGroup
