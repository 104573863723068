import { useTranslation } from "react-i18next";
import { Text, Icon } from "components/ui";
import styles from "./dashboard.module.sass";
import { Filter } from "components/ui";
import { Select } from "components/ui";
import { Screen } from "components/ui";

export default function GeneralInformation({
	withChanges,
	info,
	period,
	onChangePeriod,
}) {
	const { t } = useTranslation(["dashboard"], {
		keyPrefix: "generalInformation",
	});

	//delete after connect to db
	//const withChanges = true;
	const up = true;

	const handlePeriodChange = () => {};

	return (
		<div className={"offset-top-20"}>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<Text as="h2">{t("title")}</Text>
				<Screen size="xl">
					<div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
						<Text as="h3" color="blue">
							{t("sorted")}:
						</Text>
						<Select value={period || null} onChange={onChangePeriod}>
							<Select.Option value={null}>За все время</Select.Option>
							<Select.Option value={"FOR_DAY"}>За сегодня</Select.Option>
							<Select.Option value={"FOR_WEEK"}>За неделю</Select.Option>
							<Select.Option value={"FOR_MONTH"}>За месяц</Select.Option>
							<Select.Option value={"FOR_YEAR"}>За год</Select.Option>
						</Select>
					</div>
				</Screen>
			</div>

			<div className="row row-10 row-sm offset-top-10">
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.totalBuyers || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("clientsCounter")}
							</Text>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.totalOrdersCount || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("ordersCounter")}
							</Text>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.totalOrdersProfit || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("income")}
							</Text>
							{withChanges && (
								<div className={styles["general-info-item__changes"]}>
									<Icon
										name={
											info?.profitAmountPercent >= 0
												? "arrow-increase"
												: "arrow-decrease"
										}
										size="xs"
										color={info?.profitAmountPercent >= 0 ? "green" : "red"}
									/>
									<Text
										size="xs"
										color={info?.profitAmountPercent >= 0 ? "green" : "red"}
										style={{ marginLeft: 2 }}
									>
										{info?.profitAmountPercent
											? `${info?.profitAmountPercent}%`
											: "0%"}
									</Text>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.totalOrdersAmount || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("amountOrders")}
							</Text>
							{withChanges && (
								<div className={styles["general-info-item__changes"]}>
									<Icon
										name={
											info?.totalOrdersAmountPercent >= 0
												? "arrow-increase"
												: "arrow-decrease"
										}
										size="xs"
										color={
											info?.totalOrdersAmountPercent >= 0 ? "green" : "red"
										}
									/>
									<Text
										size="xs"
										color={
											info?.totalOrdersAmountPercent >= 0 ? "green" : "red"
										}
										style={{ marginLeft: 2 }}
									>
										{info?.totalOrdersAmountPercent
											? `${info?.totalOrdersAmountPercent}%`
											: "0%"}
									</Text>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.productsCount || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("numberOfGoods")}
							</Text>
							{withChanges && (
								<div className={styles["general-info-item__changes"]}>
									<Icon
										name={
											info?.productsCountPercent >= 0
												? "arrow-increase"
												: "arrow-decrease"
										}
										size="xs"
										color={info?.productsCountPercent >= 0 ? "green" : "red"}
									/>
									<Text
										size="xs"
										color={info?.productsCountPercent >= 0 ? "green" : "red"}
										style={{ marginLeft: 2 }}
									>
										{info?.productsCountPercent
											? `${info?.productsCountPercent}%`
											: "0%"}
									</Text>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-6 col-xl-4">
					<div className={styles["general-info-item"]}>
						<Text as="p" weight="medium" align="center">
							{info?.totalCanceledOrdersCount || 0}
						</Text>
						<div className={styles["general-info-item__desc"]}>
							<Text size="md" color="gray" align="center">
								{t("canceledOrders")}
							</Text>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
