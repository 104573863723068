import React, { useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cn from "classnames";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import {
	ControlProductAdd,
	ControlProductEdit,
	Product,
	ProductMobile,
} from "components/catalog";
import {
	Text,
	Input,
	Button,
	ButtonGroup,
	ControlMenu,
	Screen,
	Pagination,
	Modal,
} from "components/ui";
import styles from "./catalog.module.sass";
import { Select } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, switchVisibility } from "redux/asyncAction/catalog";
import { switchRec } from "redux/asyncAction/catalog";
import { switchPopular } from "redux/asyncAction/catalog";
import { deleteProduct } from "redux/asyncAction/catalog";
import { SearchForm } from "components/ui";
import { getTemplateProducts } from "../../redux/asyncAction/product";
import {
	useGetProductsQuery,
	useChangeProductPromotionPropertyMutation,
	useChangeProductVisibilityMutation,
	useDeleteProductMutation,
} from "../../redux/reducers/product";
import { useSearchParams } from "react-router-dom";
import { produce } from "immer";

const Products = ({
	products,
	productsTotal,
	filterBy,
	page,
	withFilter,
	header,
}) => {
	const { t } = useTranslation(["catalog"]);
	//const [products, setProducts] = useState([]);
	//const [isAllProductsChecked, setIsAllProductsChecked] = useState(false);
	const [includesProducts, setIncludesProducts] = useState(new Set());
	//const [excludesProducts, setExcludesProducts] = useState(new Set());
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [openedMenuType, setOpenedMenuType] = useState({});
	const [editingProduct, setEditingProduct] = useState({});
	const [lang, setLang] = useState("ru");
	//const [current, setCurrent] = useState(1);
	//const [popular, setPopular] = useState(false);
	const [modal, setModal] = useState(null);
	const { showMessage } = useMessage();
	const { deviceWidth } = useWindowSize();
	const menuTypes = [
		{ type: "add", value: t("drawer.addProduct.title") },
		{ type: "edit", value: t("drawer.editProduct.title") },
	];

	//const [filterBy, setFilterBy] = useState(null);

	const [_, setSearchParams] = useSearchParams();

	const setFilterBy = (value) => {
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);
			nextParams.set("filterBy", value);
			if (value === null) nextParams.delete("filterBy");

			nextParams.delete("page");

			return nextParams;
		});
	};

	/*const {
		productsArr,
		loading,
		popSwitched,
		recSwitched,
		deleted,
		//productsTotal,
	} = useSelector((state) => state.catalogReducer);

	const { productsUpdated } = useSelector((state) => state.productsReducer);*/

	//let dispatch = useDispatch();

	/*useEffect(() => {
		dispatch(getProducts(1, "", 10));
	}, [popSwitched, recSwitched, deleted, productsTotal, productsUpdated]);*/

	/*useEffect(() => {
		setProducts(productsArr);
	}, [productsArr, current, productsUpdated]);*/

	const closeControlMenu = () => {
		setIsOpenMenu(false);
		//complete after connect to db

		// if (isInfoMode) {
		// 	setInfoOrder({})
		// }
	};

	const toggleOpenMenu = (isActive, type, title) => {
		setOpenedMenuType({});

		if (isActive) {
			const openedMenu = menuTypes.find((v) => v.type === type) || menuTypes[0];

			setIsOpenMenu(true);
			setOpenedMenuType(
				title
					? { ...openedMenu, value: `${openedMenu.value} - ${title}` }
					: openedMenu
			);
		} else {
			closeControlMenu();
		}
	};

	const addProduct = () => {
		toggleOpenMenu(true, "add");
	};

	const onSaveAddedProducts = (data) => {
		console.log(data);
		//change after connect to db
		showMessage({ value: t("message.productsAdd") });
		closeControlMenu();
	};

	const onSaveEditingProducts = (data) => {
		console.log(data);
		//change after connect to db
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
		closeControlMenu();
	};

	const handleCheckedProduct = (checked, id) => {
		/*setIncludesProducts((state) =>
			produce(state, (draft) => {
				if (checked) {
					if (isAllProductsChecked) draft.delete(id);
					else draft.add(id);
				} else {
					if (isAllProductsChecked) draft.add(id);
					else draft.delete(id);
				}
			})
		);*/
		setIncludesProducts((state) =>
			produce(
				state,
				(draft) => void (checked ? draft.add(id) : draft.delete(id))
			)
		);
	};

	/*const setAllChecked = (checked) => {
		if (checked) {
			const notCheckedOrders = products.filter(
				(v) => !includesProducts.includes(v._id)
			);
			setIncludesProducts([
				...includesProducts,
				...notCheckedOrders.map((v) => v._id),
			]);
		} else {
			setIncludesProducts([]);
		}
	};*/

	//change after connect to db
	const handlerPopular = async (productId, value) => {
		const response = await changeProductPromotionProperty({
			productId,
			value,
			property: "popular",
		});

		if (!response.error)
			showMessage({
				value: value ? t("message.inPopular") : t("message.notPopular"),
			});
		/*await dispatch(switchPopular(id, checked));
		setProducts(
			products.map((v) =>
				v._id === id
					? { ...v, promotion: { ...v.promotion, popular: checked } }
					: v
			)
		);
		showMessage({
			value: checked ? t("message.inPopular") : t("message.notPopular"),
		});*/
	};

	const [changeProductPromotionProperty] =
		useChangeProductPromotionPropertyMutation();

	const [changeProductVisibility] = useChangeProductVisibilityMutation();

	const [deleteProduct] = useDeleteProductMutation();

	//change after connect to db
	const handlerRecommend = async (productId, value) => {
		const response = await changeProductPromotionProperty({
			productId,
			value,
			property: "recommendations",
		});

		if (!response.error)
			showMessage({
				value: value ? t("message.inRecommend") : t("message.notRecommend"),
			});
		/*await dispatch(switchRec(id, checked));
		setProducts(
			products.map((v) =>
				v._id === id
					? { ...v, promotion: { ...v.promotion, recommendations: checked } }
					: v
			)
		);*/
	};

	//change after connect to db
	const handlerVisible = async (productId, value) => {
		const response = await changeProductVisibility({
			productId,
			value,
		});

		if (!response.error)
			showMessage({
				value: value
					? t("messages.itemVisible", {
							item: t("message.product"),
							ns: "common",
					  })
					: t("messages.itemHidden", {
							item: t("message.product"),
							ns: "common",
					  }),
			});
		/*if (checkedProducts.length > 1) {
			setProducts(
				products.map((v) =>
					checkedProducts.includes(v._id) ? { ...v, visible } : v
				)
			);
			await dispatch(switchVisibility(id, visible));
			showMessage({
				value: visible
					? t("messages.itemsVisible", {
							item: t("message.products"),
							ns: "common",
					  })
					: t("messages.itemsHidden", {
							item: t("message.products"),
							ns: "common",
					  }),
			});
		} else {
			setProducts(products.map((v) => (v._id === id ? { ...v, visible } : v)));
			await dispatch(switchVisibility(id, visible));
			showMessage({
				value: visible
					? t("messages.itemVisible", {
							item: t("message.product"),
							ns: "common",
					  })
					: t("messages.itemHidden", {
							item: t("message.product"),
							ns: "common",
					  }),
			});
		}*/
	};

	const editProduct = (product) => {
		setEditingProduct(product);
		toggleOpenMenu(true, "edit");
	};

	const handleDeleteProduct = async (productId) => {
		const response = await deleteProduct({ productId });
		/*dispatch(deleteProduct(id));
		dispatch(refreshProductsAction(!productsUpdated));*/
		if (!response.error)
			showMessage({
				value: t("messages.itemDelete", {
					item: t("message.product"),
					ns: "common",
				}),
			});
	};

	const onDeleteProduct = (productId) => {
		setModal(
			<Modal isOpen={true} onRequestClose={() => setModal(null)} size="sm">
				<div className="modal-body">
					<div className="modal-body__text">
						<Text as="p" size="xl" align="center">
							{t("confirm.deleteProduct", { ns: "catalog" })}
						</Text>
					</div>
					<div className="modal-body__confirm-buttons">
						<ButtonGroup>
							<Button
								fluid={true}
								onClick={() =>
									handleDeleteProduct(productId).finally(() => setModal(null))
								}
							>
								{t("buttons.confirm", { ns: "common" })}
							</Button>
							<Button
								view={"cancel"}
								fluid={true}
								onClick={() => setModal(null)}
							>
								{t("buttons.cancel", { ns: "common" })}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</Modal>
		);
	};

	const handlePageChange = (page) => {
		console.log("page", page);
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);
			nextParams.set("page", page);

			return nextParams;
		});
		//setCurrent(page);
		//dispatch(getProducts(page, "", 10));
	};

	const handleAllChecked = (e) => {
		const checked = e.currentTarget.checked;

		/*setIsAllProductsChecked(checked);
		setIncludesProducts(new Set());*/

		setIncludesProducts((state) =>
			produce(state, (draft) =>
				products.forEach((product) =>
					checked ? draft.add(product._id) : draft.delete(product._id)
				)
			)
		);
	};

	const isAllProductsChecked = useMemo(
		() =>
			includesProducts.size &&
			products.every((product) => includesProducts.has(product._id)),
		[includesProducts, products]
	);

	return (
		<>
			{modal}
			<section className="section">
				<div className="container">
					<Screen size="xl" down>
						<SearchForm.Section sectionFirst>
							<SearchForm placeholder={t("Поиск по названию товара")} />
						</SearchForm.Section>
					</Screen>
					<Screen size="xl" down>
						<Screen size="md" down>
							<Button onClick={addProduct} fluid={true}>
								{t("button.addGoods")}
							</Button>
						</Screen>
						<ButtonGroup
							className={cn(deviceWidth === "small" && "offset-top-10")}
						>
							<Screen size="md">
								<Button onClick={addProduct} fluid={true}>
									{t("button.addGoods")}
								</Button>
							</Screen>
							<Button fluid={true}>{t("button.export")}</Button>
							<Button fluid={true}>{t("button.import")}</Button>
						</ButtonGroup>
					</Screen>
					{withFilter && (
						<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
							<Input
								type="checkbox"
								name="all"
								onChange={handleAllChecked}
								checked={isAllProductsChecked}
							/>

							<Select value={filterBy} onChange={setFilterBy}>
								<Select.Option value={null}>Все товары</Select.Option>
								<Select.Option value={"popular"}>Популярное</Select.Option>
								<Select.Option value={"recommendations"}>
									Рекоммендуемое
								</Select.Option>
								<Select.Option value={"novelty"}>Новинки</Select.Option>
								<Select.Option value={"discountPrice"}>
									Со скидкой
								</Select.Option>
							</Select>
						</div>
					)}
					{header}
					{/* {breadcrumbs?.title ? (
						<>
							<Text
								as='p'
								size='lg'
								className={cn(deviceWidth !== 'large' && 'offset-top-10')}
							>
								{title}
							</Text>
							<Breadcrumbs
								title={breadcrumbs.title}
								className='offset-top-10'
								color={deviceWidth === 'large' ? 'red' : 'primary'}
							>
								<Input
									type='checkbox'
									name='all'
									onChange={e => setAllChecked(e.target.checked)}
									checked={checkedProducts.length && (products.length === checkedProducts.length)}
								/>
							</Breadcrumbs>
						</>
					) : (
						<div className={cn(
							styles['checkbox-title'],
							{ ['offset-top-10']: deviceWidth !== 'large' }
						)}>
							<Input
								type='checkbox'
								name='all'
								onChange={e => setAllChecked(e.target.checked)}
								checked={checkedProducts.length && (products.length === checkedProducts.length)}
							/>
							<Text as='p' size='md'>{title}</Text>
						</div>
					)} */}

					<div className="offset-top-10">
						{products?.map((product, i) => {
							return deviceWidth === "large" ? (
								<Product
									key={product._id}
									product={product}
									handlerPopular={handlerPopular}
									handlerRecommend={handlerRecommend}
									handlerVisible={handlerVisible}
									editProduct={editProduct}
									deleteProduct={onDeleteProduct}
									checkedProduct={includesProducts.has(product._id)}
									onChangeCheckedProduct={handleCheckedProduct}
								/>
							) : (
								<ProductMobile
									key={product._id}
									product={product}
									handlerPopular={handlerPopular}
									handlerRecommend={handlerRecommend}
									handlerVisible={handlerVisible}
									editProduct={editProduct}
									deleteProduct={onDeleteProduct}
									/*checkedProduct={
										isAllProductsChecked
											? !includesProducts.has(product._id)
											: includesProducts.has(product._id)
									}*/
									checkedProduct={includesProducts.has(product._id)}
									onChangeCheckedProduct={handleCheckedProduct}
									someCheckedProducts={Boolean(includesProducts.size)}
								/>
							);
						})}
					</div>

					<Screen size="xl">
						<ButtonGroup className="offset-top-20" justifyContent="center">
							<Button onClick={addProduct}>{t("button.addGoods")}</Button>
							<Button>{t("button.export")}</Button>
							<Button>{t("button.import")}</Button>
						</ButtonGroup>
					</Screen>

					<Pagination
						onChange={handlePageChange}
						current={page}
						total={productsTotal}
						mobile={deviceWidth !== "large"}
					/>
				</div>
			</section>

			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeControlMenu}
				title={openedMenuType?.value}
				// hasChanges={hasFaqChanges}
				// onSaveData={onAlertSaveData}
				// isCanClose={canCloseMenu}
			>
				{openedMenuType.type === "add" && (
					<ControlProductAdd
						closeControlMenu={closeControlMenu}
						lang={lang}
						setLang={setLang}
						onSaveAddedProducts={onSaveAddedProducts}
					/>
				)}
				{openedMenuType.type === "edit" && (
					<ControlProductEdit
						closeControlMenu={closeControlMenu}
						lang={lang}
						setLang={setLang}
						product={editingProduct}
						onSubmit={onSaveEditingProducts}
					/>
				)}
			</ControlMenu>
		</>
	);
};

/*Products.propTypes = {
	title: PropTypes.string.isRequired,
	breadcrumbs: PropTypes.shape({
		title: PropTypes.string.isRequired,
	}),
};*/

export default Products;
