import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Filter} from 'components/ui'
import styles from '../clients/clients.module.sass'
import selectIcon from '../../assets/selectArrNew.svg'


const FilterReviews = ({filters, onChange, clear, mobile}) => {


	const {time, region, status} = filters

	// console.log(filters)

	const {t} = useTranslation(['reviews'])

	const filterStatuses = ['all', 'public', 'moder', 'fail', 'waiting']

	const [open, setOpen] = useState(false)

	const onOpenClick = () => {
		setOpen((prev) => !prev)
	}


	return (
		<>
			{/*<Filter*/}
			{/*	title={t('filters.sort')}*/}
			{/*	clear={clear}*/}
			{/*	mobile={mobile}*/}
			{/*	onChange={onChange}*/}
			{/*	translationPage='reviews'*/}
			{/*>*/}
			{/*	<Filter.Item name='time' value={time}/>*/}
			{/*	<Filter.Item name='region' value={region}/>*/}
			{/*	<Filter.Item name='status' value={status} options={filterStatuses}/>*/}
			{/*</Filter>*/}
			<>
				<div className={styles.clOpen} onClick={onOpenClick}>
					<div className={styles.clOpenTitle}>
						{t('filters.sort')}
					</div>

					<img style={{transform: open ? 'rotate(180deg)' : ''}} src={selectIcon} alt=""/>
				</div>

				{open &&
					<div className={styles.clSel}>
						<Filter
							title={t('filters.sort')}
							clear={clear}
							mobile={mobile}
							onChange={onChange}
							translationPage='reviews'
						>
							<Filter.Item name='time' value={time}/>
							<Filter.Item name='region' value={region}/>
							<Filter.Item name='status' value={status} options={filterStatuses}/>
						</Filter>
					</div>}
			</>
		</>
	)
}

FilterReviews.propTypes = {
	filters: PropTypes.shape({
		time: PropTypes.string,
		region: PropTypes.string,
		status: PropTypes.string
	}),
	onChange: PropTypes.func.isRequired,
	clear: PropTypes.func.isRequired,
	mobile: PropTypes.bool
}

export default FilterReviews
