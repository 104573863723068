import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { Icon, Text, Input, Textarea, Button, Screen } from "components/ui";
import styles from "./settings.module.sass";
import { useSelector } from "react-redux";

const ContactsStore = ({ data, onSaveChangedData }) => {
	const { t } = useTranslation(["settings", "common"]);

	const { shopData, shopDataLoading } = useSelector(
		(state) => state.settingsReducer
	);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const {
		register: register2,
		control,
		handleSubmit: handleSubmit2,
		reset: reset2,
		formState: { errors: errors2 },
	} = useForm();
	const {
		register: register3,
		handleSubmit: handleSubmit3,
		formState: { errors: errors3 },
	} = useForm();
	const [addedEmail, setAddedEmail] = useState(
		shopData?.contacts?.emailAddresses || []
	);
	const [addedNumber, setAddedNumber] = useState(
		shopData?.contacts?.phoneNumbers || []
	);
	const { deviceWidth } = useWindowSize();

	const addEmail = (email) => {
		setAddedEmail([...addedEmail, email]);
		reset();
	};

	const deleteEmail = (email) => {
		setAddedEmail(addedEmail.filter((v) => v.email !== email));
	};

	const addNumber = (number) => {
		setAddedNumber([...addedNumber, number]);
		reset2({ number: "", description: "" });
	};

	const deleteNumber = (number) => {
		setAddedNumber(addedNumber.filter((v) => v.number !== number));
	};

	const saveData = (data) => {
		const updatedData = {
			email: addedEmail,
			number: addedNumber,
			sectionInfo: data.sectionInfo,
		};
		onSaveChangedData(updatedData);
	};

	const AddedData = ({ field, description = "", deleteItemFunc }) => {
		return (
			<div className={styles["added-data-item"]}>
				<div className={styles.field}>
					<Text size={deviceWidth === "large" ? "sm" : "xs"}>{field}</Text>
					<div className={styles.field__icon}>
						<Icon
							name="delete"
							color="red"
							size="md"
							onClick={() => deleteItemFunc(field)}
							cursorPointer
						/>
					</div>
				</div>
				{description && (
					<div className={cn(styles.description, "offset-top-10")}>
						<Text size={deviceWidth === "large" ? "sm" : "xs"}>
							{description}
						</Text>
					</div>
				)}
			</div>
		);
	};

	const SectionInfoForm = () => (
		<form onSubmit={handleSubmit3(saveData)}>
			<div className={cn(styles["setting-item"], "offset-top-10")}>
				<Textarea
					id="sectionInfo"
					name="sectionInfo"
					register={register3}
					rules={{
						minLength: {
							value: 2,
							message: t("validations.minLength", { value: 2, ns: "common" }),
						},
						maxLength: {
							value: 1000,
							message: t("validations.maxLength", {
								value: 1000,
								ns: "common",
							}),
						},
					}}
					defaultValue={shopData?.contacts.sectionInformation}
					label={t("contacts.sectionInfo")}
					labelSize={deviceWidth === "large" ? "md" : "sm"}
					errors={errors3}
					errorPos="contactErrorTextareaInfo"
					fluid={true}
					placeholder={t("contacts.placeholder.enterSectionInfo")}
					heightSize="xl"
				/>
			</div>
			<Button type="submit" fluid={deviceWidth === "small"}>
				{t("buttons.save", { ns: "common" })}
			</Button>
		</form>
	);

	if (shopDataLoading) {
		return (
			<div className="container">
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div className={cn(styles["setting-store"], styles["contacts"])}>
				<div className={styles["setting-store__header"]}>
					<div className={styles["title"]}>
						<Icon size="md" name="contacts" color="blue" />
						<Text size="md" color="blue">
							{t("filters.contacts")}
						</Text>
					</div>
					<Screen size="xl">
						<Icon name="question-border" size="md" />
					</Screen>
				</div>
				<div className={styles["setting-store__content"]}>
					<div className="row row-10">
						<div className="col-xl-6">
							<Text size={deviceWidth === "large" ? "md" : "sm"}>Email:</Text>
							{/* {shopData?.contacts?.emailAddresses.map((v, i) => (
								<AddedData
									key={i}
									field={v.email}
									description={v.description}
									deleteItemFunc={deleteEmail}
							/>
							))} */}
							{addedEmail?.map((v, i) => (
								<AddedData
									key={i}
									field={v.email}
									description={v.description}
									deleteItemFunc={deleteEmail}
								/>
							))}

							<form onSubmit={handleSubmit(addEmail)}>
								<div className={cn(styles["setting-item"], "offset-top-10")}>
									<Input
										id="email"
										name="email"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 10,
												message: t("validations.minLength", {
													value: 10,
													ns: "common",
												}),
											},
										}}
										errors={errors}
										errorPos="contactError"
										fluid={true}
										placeholder={t("contacts.placeholder.enterEmail")}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Textarea
										id="description"
										name="description"
										register={register}
										rules={{
											minLength: {
												value: 2,
												message: t("validations.minLength", {
													value: 2,
													ns: "common",
												}),
											},
											maxLength: {
												value: 50,
												message: t("validations.maxLength", {
													value: 50,
													ns: "common",
												}),
											},
										}}
										labelSize="md"
										errors={errors}
										errorPos="contactErrorTextarea"
										fluid={true}
										placeholder={t(
											"contacts.placeholder.enterEmailDescription"
										)}
										heightSize="md"
									/>
								</div>
								<Button type="submit" fluid={deviceWidth === "small"}>
									{t("contacts.buttons.addEmail")}
								</Button>
							</form>

							<Screen size="xl">
								<SectionInfoForm />
							</Screen>
						</div>

						<div className="col-xl-6">
							<Text size={deviceWidth === "large" ? "md" : "sm"}>
								{t("contacts.number")}
							</Text>
							{addedNumber?.map((v, i) => (
								<AddedData
									key={i}
									field={v.number}
									description={v.description}
									deleteItemFunc={deleteNumber}
								/>
							))}

							<form onSubmit={handleSubmit2(addNumber)}>
								<div className={cn(styles["setting-item"], "offset-top-10")}>
									<Controller
										name="number"
										control={control}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											pattern: {
												value: /\+\d{1}[\- ]?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/,
												message: t("validations.number", { ns: "common" }),
											},
										}}
										render={({ field: { onChange, value } }) => (
											<InputMask
												mask="+7 (999) 999-99-99"
												value={value}
												maskChar={null}
												onChange={onChange}
											>
												{(inputProps) => (
													<Input
														id="number"
														name="number"
														errorPos="contactError"
														placeholder={t("contacts.placeholder.enterNumber")}
														errors={errors2}
														// errorPos={contactError}
														fluid
														{...inputProps}
													/>
												)}
											</InputMask>
										)}
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Textarea
										id="description"
										name="description"
										register={register2}
										rules={{
											minLength: {
												value: 2,
												message: t("validations.minLength", {
													value: 2,
													ns: "common",
												}),
											},
											maxLength: {
												value: 50,
												message: t("validations.maxLength", {
													value: 50,
													ns: "common",
												}),
											},
										}}
										labelSize="md"
										errors={errors2}
										errorPos="contactErrorTextarea"
										fluid={true}
										placeholder={t(
											"contacts.placeholder.enterNumberDescription"
										)}
										heightSize="md"
									/>
								</div>
								<Button type="submit" fluid={deviceWidth === "small"}>
									{t("contacts.buttons.addNumber")}
								</Button>
							</form>
							<Screen size="xl" down>
								<SectionInfoForm />
							</Screen>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

ContactsStore.propTypes = {
	data: PropTypes.shape({}).isRequired,
	onSaveChangedData: PropTypes.func.isRequired,
};

export default ContactsStore;
