import styled from "styled-components";

export const Input = styled.input`
	position: relative;

	background: #F2F3F5;
	border: 1px solid #E5E6EB;
	line-height: 15px;
	appearance: none;
	outline: none;
	padding: 10px;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	resize: none;
	height: 33px;
	font-size: 14px;


	&::placeholder {
		font-size: 14px;
		color: #C4C4C4;
	}

	${({isFile}) => !!isFile && `
		border: none;
		background: none;
		padding: 0;
  `}
`;

export const InputTextArea = styled(Input)`
	height: 132px;
`;
