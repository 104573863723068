import { ToastContainer } from 'react-toastify'
import { AuthProvider } from './auth/AuthContext'

export default function AppProvider({ children }) {
	return (
		<AuthProvider>
			{children}
			<ToastContainer />
		</AuthProvider>
	)
}
