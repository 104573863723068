import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui";
import styles from "./filter.module.sass";
import Text from "../text/Text";
import { $api } from "api";

const FilterItem = ({
	name,
	value,
	options,
	onChange,
	fluid,
	translationPage,
	mobile,
}) => {
	const { t } = useTranslation([translationPage]);
	const defaultOptions = !options;
	const time = ["all", "month", "week", "today"];
	//delete after connect to db
	// const regions = ["all", "moscow", "minsk", "kiev"];

	// const [regions, setRegions] = useState(["all", "moscow", "minsk", "kiev"]);
	const [regions, setRegions] = useState([
		{
			_id: "all",
			countryId: "all",
			title: "Все регионы",
		},
	]);

	// const getCityId = async () => {
	// 	let city = await $api.get("/getCitiesAvailableForSearchOrders");
	// 	setRegions([...regions, ...city.data]);
	// 	// city.data.map((item) => setRegions([...regions, item.title]));
	// };

	// useEffect(() => {
	// 	getCityId();
	// }, []);

	if (!options) {
		switch (name) {
			case "time":
				options = time;
				break;
			case "region":
				options = [];
				break;
			default:
				options = [];
		}
	}

	return (
		<div className={styles["filter-item"]}>
			{mobile && <Text size="sm">{t("filters.mobile." + name)}</Text>}
			<Select
				value={value}
				onChange={(value) => onChange(value, name)}
				style={{ fontSize: mobile ? 12 : 16 }}
				fluid={fluid}
				// showSearch={name === "region" ? true : ""}
				filterOption={(input, option) =>
					(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
				}
			>
				{options?.map((option) => {
					return (
						<Select.Option
							key={option._id ? option._id : option}
							value={option.title ? option._id : option}
						>
							{/*repeat it after connect to db*/}
							{/* {t(`filters.${name}` === "filters.region")
							? option
							: t(`filters.${name}.${option}`)} */}
							{name === "region"
								? `${option?.title}`
								: t(`filters.${name}.${option}`)}
							{/*{defaultOptions ? t(`filters.${name}.${option}`) : option}*/}
						</Select.Option>
					);
				})}
			</Select>
		</div>
	);
};

FilterItem.defaultProps = {
	fluid: true,
	mobile: false,
};

FilterItem.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	fluid: PropTypes.bool,
	translationPage: PropTypes.string,
	mobile: PropTypes.bool,
};

export default React.memo(FilterItem);
