import { toast, Slide } from 'react-toastify'
import toastIcon from '../assets/toast.svg'

const useMessage = () => {
	const defaultParams = {
		position: 'bottom-right',
		transition: Slide
	}

	const showMessage = ({ value, type = 'success', params = {} }) => {
		let currentParams = defaultParams

		if (Object.keys(params).length) {
			const copyParams = JSON.parse(JSON.stringify(defaultParams))
			currentParams = ({ ...copyParams, ...params })
		}

		return toast[type](value, {
			...currentParams,
			icon: ({theme, type}) =>  <img src={toastIcon}/>
		})
	}

	return { showMessage }
}

export default useMessage
