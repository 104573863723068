import React, { useState } from "react";
import CategoryCreator from "./CategoryCreator";
import ControlCategoryAddFromTemplate from "./ControlCategoryAddFromTemplate";

const ControlCategoryAdd = ({ ...props }) => {
	const [mode, setMode] = useState("add");

	switch (mode) {
		case "add":
			return <ControlCategoryAddFromTemplate setMode={setMode} {...props} />;
		case "create":
			return <CategoryCreator {...props} />;
		default:
			return null;
	}
};

export default ControlCategoryAdd;
