const defaultState = {
  isLogin: false,
  loading: true,
  token: '',
  reset: false,
  userDetails: [],
  shopDetails: [],
  reqDetails: [],
  exist: null,
  isReg: false,
  isRegLoading: true,
  regToken: '',
  searchedInn: [],
  searchedInnLoading: true,
  searchedBik: [],
  searchedBikLoading: true
};

const LOGIN = "LOGIN";
const REGISTER = "REGISTER"
const RESET_PASSWORD = "RESET_PASSWORD"
const CHECK_PARTNER = "CHECK_PARTNER"
const ADD_USER = "ADD_USER"
const ADD_SHOP = "ADD_SHOP"
const REG_CLIENT = "REG_CLIENT"
const SEARCH_INN = "SEARCH_INN"
const SEARCH_BIK = "SEARCH_BIK"


export const authReducer = (state = defaultState, action) => {
  switch (action.type) {

    case LOGIN:
      return { ...state, isLogin: true , loading: false, token: action.payload};

    case REGISTER: 
      return { ...state, isRegister: true, loading: false, token: action.payload}
    
    case ADD_USER: 
      return { ...state, userDetails: action.payload}

    case ADD_SHOP: 
      return { ...state, shopDetails: action.payload}

    case CHECK_PARTNER: 
      return { ...state, exist: action.payload}
    
    case RESET_PASSWORD: 
      return { ...state, reset: true,}

    case REG_CLIENT:
      return { ...state, isReg: true , isRegLoading: false, regToken: action.payload};
  
    case SEARCH_INN:
        return { ...state, searchedInn: action.payload , searchedInnLoading: false};

    case SEARCH_BIK:
        return { ...state, searchedBik: action.payload , searchedBikLoading: false};
  
    default:
      return state;
  }
};

export const loginAction = (payload) => ({
	type: LOGIN,
	payload,
});

export const registerAction = (payload) => ({
	type: REGISTER,
	payload,
});

export const resetPasswordAction = (payload) => ({
	type: RESET_PASSWORD,
	payload,
});

export const checkPartnerAction = (payload) => ({
  type: CHECK_PARTNER,
  payload
})

export const addUserAction = (payload) => ({
	type: ADD_USER,
	payload,
});

export const addShopAction = (payload) => ({
	type: ADD_SHOP,
	payload,
});

export const regClientAction = (payload) => ({
	type: REG_CLIENT,
	payload,
});

export const searchInnAction = (payload) => ({
	type: SEARCH_INN,
	payload,
});

export const searchBikAction = (payload) => ({
	type: SEARCH_BIK,
	payload,
});