import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { Header } from "components/layout/header";
import { Icon, Loading, PageDescription } from "components/ui";
import styles from "./preview.module.sass";
import { useSelector } from "react-redux";

export default function Preview() {
	const { t } = useTranslation(["preview"]);
	const [isReady, setIsReady] = useState(false);
	const [view, setView] = useState("large");
	const iframeRef = useRef(null);
	const { deviceWidth } = useWindowSize();
	const navigate = useNavigate();
	const subDomain = useSelector(
		(state) => state.settingsReducer?.shopData?.subDomain
	);
	const windowSizes = {
		large: { width: "100%" },
		medium: { width: "calc(768px + 17px)" },
		mediumReverse: { width: "calc(1024px + 17px)" },
		small: { width: "calc(375px + 17px)" },
	};

	useEffect(() => {
		if (isReady && deviceWidth !== "large") {
			navigate("../dashboard");
		}
	}, [isReady, deviceWidth]);

	const styleIframe = {
		border: 0,
		height: "calc(100vh - 70px)",
		...windowSizes[view],
	};

	const handleLoad = () => {
		setIsReady(true);
		//cross error
		// setHeight(iframeRef.current.contentWindow.document.body.offsetHeight)
	};

	return (
		<>
			<Header>
				<ul>
					<li
						className={cn(styles["link-wrapper"], {
							[styles.active]: view === "large",
						})}
						onClick={() => setView("large")}
					>
						<Icon name="laptop" size="lg" color="white" />
					</li>
					<li
						className={cn(styles["link-wrapper"], {
							[styles.active]: view === "medium",
						})}
						onClick={() => setView("medium")}
					>
						<Icon name="tablet" size="lg" color="white" />
					</li>
					<li
						className={cn(styles["link-wrapper"], {
							[styles.active]: view === "mediumReverse",
						})}
						onClick={() => setView("mediumReverse")}
					>
						<Icon name="tablet-revert" size="lg" color="white" />
					</li>
					<li
						className={cn(styles["link-wrapper"], {
							[styles.active]: view === "small",
						})}
						onClick={() => setView("small")}
					>
						<Icon name="phone" size="lg" color="white" />
					</li>
				</ul>
			</Header>

			<main>
				<PageDescription.Section>
					<PageDescription title={t("title")} description={t("description")} />
				</PageDescription.Section>

				<section className="section">
					<div className="container" style={{ textAlign: "center" }}>
						{!isReady && <Loading />}
						<iframe
							ref={iframeRef}
							src={`https://${subDomain}.zumzak.ru/`}
							style={styleIframe}
							onLoad={handleLoad}
						/>
					</div>
				</section>
			</main>
		</>
	);
}
