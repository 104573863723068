import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './local-switcher.module.sass'

export default function LocaleSwitcher() {
	const { i18n } = useTranslation()

	const changeLanguage = locale => {
		i18n.changeLanguage(locale)
		localStorage.setItem('locale', locale)
	}

	const [checked, setChecked] = useState(false)

	const handlerLanguage = (check) => {
		setChecked(check)
		!checked
			? changeLanguage('en')
			: changeLanguage('ru')
	}

	return (
		<div onClick={() => handlerLanguage(!checked)} className={styles['locale-switcher']}>
			<div>EN</div>
			<div>RU</div>
			<div  className={styles['locale-switcher__button']} style={{left: checked && '35px'}} />
		</div>
	)
}
