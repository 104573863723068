import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Icon, Input, Switch, Text } from "components/ui";
import { splitString } from "helpers";

import defaultPh from "../../assets/catalog/photo-default.png";
import styles from "./catalog.module.sass";
import { useSelector } from "react-redux";

const Product = ({
	product,
	handlerPopular,
	handlerRecommend,
	handlerVisible,
	editProduct,
	deleteProduct,
	checkedProduct,
	onChangeCheckedProduct,
}) => {
	const { t } = useTranslation(["catalog"]);

	return (
		<div className={styles.product}>
			<Input
				type="checkbox"
				name={product._id}
				checked={checkedProduct}
				onChange={(e) => onChangeCheckedProduct(e.target.checked, product._id)}
			/>
			<div className={styles["product__img"]}>
				<img src={product.imageUrls[0] || defaultPh} alt="product-img" />
			</div>
			<div className={styles["product__title"]}>
				<Text size="sm">{product.title}</Text>
				<div className={styles["product__switches"]}>
					<div className={styles["product__switch-item"]}>
						<Switch
							id={product._id}
							checked={Boolean(product?.promotion?.popular)}
							onChange={(checked) => handlerPopular(product._id, checked)}
						/>
						<Text size="sm" color="gray">
							{t("showInPopular")}
						</Text>
					</div>
					<div className={styles["product__switch-item"]}>
						<Switch
							id={"0" + product._id}
							checked={Boolean(product?.promotion?.recommendations)}
							onChange={(checked) => handlerRecommend(product._id, checked)}
						/>
						<Text size="sm" color="gray">
							{t("showInRecommend")}
						</Text>
					</div>
				</div>
			</div>
			<div className={styles["product__controls"]}>
				<div className={styles["product-price"]}>
					<Text style={{ fontSize: 18 }}>{splitString(product.price)}₽</Text>
					{product.discountPrice && (
						<Text size="sm" color="red" decoration="line-through">
							{splitString(product.discountPrice)}₽
						</Text>
					)}
				</div>
				<div className={styles["controls-group"]}>
					<Icon
						name="eye"
						size="md"
						turnOff={!product.visible}
						onClick={() => handlerVisible(product._id, !product.visible)}
						title={t("buttonDesc.hidden", { value: "", ns: "common" })}
						cursorPointer
					/>
					<Icon
						name="edit"
						size="md"
						onClick={() => editProduct(product)}
						title={t("buttonDesc.edit", { value: "", ns: "common" })}
						cursorPointer
					/>
					<Icon
						name="delete"
						size="md"
						color="red"
						onClick={() => deleteProduct(product._id)}
						title={t("buttonDesc.delete", { value: "", ns: "common" })}
						cursorPointer
					/>
				</div>
			</div>
		</div>
	);
};

Product.propTypes = {
	product: PropTypes.shape({}).isRequired,
	handlerPopular: PropTypes.func.isRequired,
	handlerRecommend: PropTypes.func.isRequired,
	handlerVisible: PropTypes.func.isRequired,
	editProduct: PropTypes.func.isRequired,
	deleteProduct: PropTypes.func.isRequired,
	checkedProduct: PropTypes.bool.isRequired,
	onChangeCheckedProduct: PropTypes.func.isRequired,
};

export default Product;
