import {useTranslation} from "react-i18next";
import {Text} from "components/ui";
import defaultRecentlySold from "assets/dashboard/default-recently-sold.png";
import styles from "./dashboard.module.sass";

export default function RecentlySold({items}) {
	const {t} = useTranslation(["dashboard"]);
	// console.log("styles", styles);

	// const items = [{id: 1}, {id: 2}]
	// console.log(items, 'dashboardRecently')
	return (<div className={styles["recently-sold"]}>
		<Text as="p" size="md" className="offset-xl-top-10">
			{t("recentlySold")}
		</Text>

		<div className="row row-10 row-md-20 row-sm offset-top-10 offset-xl-top-20">
			{items?.map((item, index) => (
				<div key={`${item._id}-${item.product._id}`} className="col-6 col-md-4">
					<div className={styles["recently-sold__item"]}>
						<div className={styles["recently-sold__itemImg"]}>
							<img src={item.productData.imageUrls?.[0]} alt=""/>
						</div>
						<Text as="p" size="sm" weight="medium" align="center" className={styles.title}>
							{item?.productData.title }
						</Text>
						<Text as="p" size="sm" style={{color: "#003AD2", marginTop: 5}}>
							{item?.product.price} ₽
						</Text>
						<hr/>
					</div>
				</div>
			))}
		</div>
	</div>);
}
