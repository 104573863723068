import { $api } from "api";

class Clients {
  async getClients(page, limit, filterClient, searchRegion) {
    const res = await $api.get(`/searchBuyers?page=${page}&limit=${limit}&searchText=${filterClient?filterClient:''}&cityId=${searchRegion || ''}`);
    console.log(searchRegion, 'searchRegion')
    return res
  }

  async getClient(id) {
    const res = await $api.get(`/getOrder?orderId=${id}`);
    console.log(res, 'getClient')
    return res
  }

  async getSumOfAllClients() {
    const res = await $api.get('/getBuyersStatistics');
    return res
  }

// async get

//   async switchPopular(id, value) {
//     console.log('popid', id, value)
//     return await $api.post(`/changeProductPromotionProperty`, {
//       productId: id,
//       property: 'popular',
//       value: value
//     });
//   }

//   async switchRec(id, value) {
//     console.log('recid', id, value)
//     return await $api.post(`/changeProductPromotionProperty`, {
//       productId: id,
//       property: 'recommendations',
//       value: value
//     });
//   }

//   async deleteProduct(id) {
//     console.log('api del', id)
//     return await $api.post(`/deleteProduct`, {
//       productId: id,
//     });
//   }
}

export default new Clients()