import React from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { Header } from "components/layout/header";
import { Button, PageDescription, Screen, SearchForm } from "components/ui";
import { Icon } from "components/ui";
//import { useDispatch } from "react-redux";
/*import { getAllCategories } from "../../redux/asyncAction/category";
import { getAllPromoCodes } from "../../redux/asyncAction/promocode";
import { getTemplateProducts } from "../../redux/asyncAction/product";*/

export default function LayoutCatalog() {
	const { t } = useTranslation(["catalog"]);
	const { deviceWidth } = useWindowSize();
	//const dispatch = useDispatch();
	const location = useLocation();

	//after connect to db change on search
	const [count, setCount] = React.useState("05454");

	/*const handleCategorySearch = (searchValue) => {
		if (location.pathname.split("/").includes("category")) {
			dispatch(getAllCategories(searchValue));
		}

		if (location.pathname.split("/").includes("promocodes")) {
			dispatch(getAllPromoCodes(searchValue));
		}

		if (location.pathname.split("/").includes("goods")) {
			dispatch(getTemplateProducts(searchValue));
		}
	};*/

	const resetInput = () => {
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);

			nextParams.delete("searchText");
			nextParams.delete("page");

			return nextParams;
		});
		/*if (location.pathname.split("/").includes("category")) {
			dispatch(getAllCategories());
		}

		if (location.pathname.split("/").includes("promocodes")) {
			dispatch(getAllPromoCodes());
		}

		if (location.pathname.split("/").includes("goods")) {
			dispatch(getTemplateProducts());
		}*/
	};

	const [searchParams, setSearchParams] = useSearchParams();
	const { searchText } = Object.fromEntries(searchParams.entries());

	const handleSearch = (searchText) => {
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);

			if (searchText) nextParams.set("searchText", searchText);
			else nextParams.delete("searchText");
			nextParams.delete("page");

			return nextParams;
		});
		/*if (location.pathname.split("/").includes("category")) {
			dispatch(getAllCategories(searchValue));
		}

		if (location.pathname.split("/").includes("promocodes")) {
			dispatch(getAllPromoCodes(searchValue));
		}

		if (location.pathname.split("/").includes("goods")) {
			dispatch(getTemplateProducts(searchValue));
		}*/
	};

	return (
		<>
			<Header>
				{/* <ul>
					<li>
						<Button as='link' to='category' view='header-nav' size='md'>
							{t('navigation.category')}
						</Button>
					</li>
					<li>
						<Button as='link' to='goods' view='header-nav' size='md'>
							{t('navigation.goods')}
						</Button>
					</li>
					<li>
						<Button as='link' to='new' view='header-nav' size='md'>
							{t('navigation.new')}
						</Button>
					</li>
					<li>
						<Button as='link' to='discount' view='header-nav' size='md'>
							{t('navigation.discount')}
						</Button>
					</li>
					<li>
						<Button as='link' to='recommend' view='header-nav' size='md'>
							{t('navigation.recommend')}
						</Button>
					</li>
					<li>
						<Button as='link' to='popular' view='header-nav' size='md'>
							{t('navigation.popular')}
						</Button>
					</li>
				</ul> */}
			</Header>

			<main className="nav">
				<PageDescription.Section sectionFirst={deviceWidth === "large"}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={t("title")} />
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={t ("description")}
								placementCustomTitle="right"
								customTitle
								style={{ marginTop: "-10px", marginLeft: "8px" }}
							/>
						</Screen>
					</div>
				</PageDescription.Section>

				<Screen size="xl">
					<div
						className="container h-100"
						style={{ marginTop: "12px", marginBottom: "8px" }}
					>
						<ul style={{ display: "flex", gap: "16px", listStyle: "none" }}>
							<li>
								<Button
									style={{ background: "transparent" }}
									as="link"
									to="category"
									view="header-nav"
									size="md"
								>
									{t("navigation.category")}
								</Button>
							</li>
							<li>
								<Button
									style={{ background: "transparent" }}
									as="link"
									to="goods"
									view="header-nav"
									size="md"
								>
									{t("navigation.goods")}
								</Button>
							</li>

							<li>
								<Button
									style={{ background: "transparent" }}
									as="link"
									to="promocodes"
									view="header-nav"
									size="md"
								>
									{t("navigation.promocodes")}
								</Button>
							</li>
						</ul>
					</div>
					<SearchForm.Section>
						<SearchForm
							key={location.pathname}
							defaultValue={searchText}
							handleEnter={handleSearch}
							resetInput={resetInput}
							onSearch={handleSearch}
							placeholder={t("searchPlaceholder")}
						/>
					</SearchForm.Section>
				</Screen>
				<div></div>

				<Outlet context={[count, setCount]} />
			</main>
		</>
	);
}
