import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Filter } from "components/ui";
import styles from "../clients/clients.module.sass";

import selectIcon from "../../assets/selectArrNew.svg";
import { Select } from "components/ui";

const FilterOrders = ({
	filters: { time, region, status },
	onChange,
	clear,
	mobile,
	cities,
	handlePeriod,
}) => {
	const { t } = useTranslation(["orders"]);

	const filterStatuses = ["all", "DELIVERED", "IN_PROGRESS", "CANCELED"];
	const filterStatusesTime = ["all", "month", "week", "today"];
	console.log(time);
	const [open, setOpen] = useState(false);
	handlePeriod(time);

	// console.log(filterCity, "filterStatuses");

	const onOpenClick = () => {
		setOpen((prev) => !prev);
	};
	// const city = cities.push(region)
	// console.log(cities.concat(region), 'concat')
	return (
		<>
			<div className={styles.clOpen} onClick={onOpenClick}>
				<div className={styles.clOpenTitle}>{t("filters.sort")}</div>

				<img
					style={{ transform: open ? "rotate(180deg)" : "" }}
					src={selectIcon}
					alt=""
				/>
			</div>

			{open && (
				<div className={styles.clSel}>
					{console.log("cities", cities)}
					<Filter
						title={t("filters.sort")}
						clear={clear}
						mobile={mobile}
						onChange={onChange}
						translationPage="orders"
					>
						<Filter.Item
							name="time"
							value={time}
							options={filterStatusesTime}
						/>
						<Filter.Item
							name="region"
							value={region}
							options={[{ title: "Все города", _id: null }, ...cities]}
						/>
						<Filter.Item
							name="status"
							value={status}
							options={filterStatuses}
						/>
					</Filter>
				</div>
			)}
		</>
	);

	// )
};

FilterOrders.propTypes = {
	filters: PropTypes.shape({}).isRequired,
	onChange: PropTypes.func.isRequired,
	clear: PropTypes.func.isRequired,
	mobile: PropTypes.bool,
};

export default FilterOrders;
