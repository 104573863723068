import React, { useState, useMemo, useEffect } from "react";
import { SearchForm, ResultItem } from "components/ui";
import { useGetProductsInfiniteQuery } from "../../../redux/reducers/product";
import debounce from "lodash/debounce";

export const ProductSearch = ({ onSelect, ...props }) => {
	const limit = 5;

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);

	const { data } = useGetProductsInfiniteQuery(
		{ limit, searchText, page },
		{ skip: !searchText }
	);

	const products = data?.products || [];

	useEffect(() => {
		setPage(Math.ceil(products.length / limit) || 1);
	}, [products.length, setPage]);

	const next = () => setPage((state) => state + 1);

	const handleChangeSearch = useMemo(() => {
		const setQueryValueDebounced = debounce(setSearchText, 500, {
			leading: false,
			trailing: true,
		});

		return (value) => {
			setQueryValueDebounced(value);
		};
	}, [setSearchText]);

	const handleSelect = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		if (onSelect) onSelect(products[index]);
	};

	return (
		<SearchForm {...props} onChange={handleChangeSearch} next={next}>
			{Boolean(searchText) &&
				products.map((product, index) => (
					<ResultItem
						onClick={handleSelect}
						data-index={index}
						key={product._id}
					>
						{product.title}
					</ResultItem>
				))}
		</SearchForm>
	);
};
