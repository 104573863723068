import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Screen, Text } from "components/ui";
import styles from "./auth.module.sass";
import { LocaleSwitcher } from "components/ui";
import logoSrc from "assets/logo.svg";
import logoSrcEn from "assets/logoEn.svg";
import { Link } from "react-router-dom";

const LayoutRegister = ({ children, regStep, setRegStep }) => {
	const { t } = useTranslation(["auth"]);

	console.log("layout");

	function backButton() {
		regStep === "store" ? setRegStep("user") : setRegStep("store");
	}

	return (
		<div className={styles["auth-wrapper"]}>
			<div
				style={{
					display: "flex",
					width: "100%",
					padding: "0px 50px",
					justifyContent: "space-between",
					position: "absolute",
					top: "50px",
				}}
			>
				<button onClick={backButton} className={styles.btnBack}>
					{t("button.back")}
				</button>
			</div>
			<Link to="https://partners.zumzak.ru/">
				<img
					className={styles.logoStep1}
					style={{ with: "140px", height: "41px" }}
					src={t("logo") === "assets/logoEn.svg" ? logoSrcEn : logoSrc}
					alt=""
				/>
			</Link>
			<Text
				style={{ textAlign: "center", lineHeight: "52px" }}
				size="super2"
				color="white"
			>
				{t("registrationNewPartners")}
			</Text>
			<div className={cn("container", styles.container)}>
				<div
					className="row align-items-center"
					style={{ display: "flex", flexDirection: "column" }}
				>
					<div className="col-xl-7">{children}</div>
					<div
						style={{ marginBottom: "20px" }}
						className={styles["register-steps"]}
					>
						<Text as="p" className="offset-top-20">
							<p className={styles.step}>
								{regStep === "store" ? t("step1") : t("step2")}
							</p>
						</Text>
						<div
							className={cn(
								styles["register-steps__progress"],
								regStep === "store" ? styles["step-1"] : styles["step-2"]
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LayoutRegister;
