import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

const AuthContext = createContext()

export const useAuthContext = () => {
	return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useState(null)
	const [userId, setUsedId] = useState(null)
	const [isReady, setIsReady] = useState(false)
	// const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(true)
	const storageName = 'userData'

	const login = useCallback((jwtToken, id) => {
		setToken(jwtToken)
		setUsedId(id)

		localStorage.setItem(storageName, JSON.stringify({
			token: jwtToken,
			userId: id
		}))
	}, [])

	const logout = useCallback(() => {
		setToken(null)
		setUsedId(null)

		localStorage.removeItem(storageName)
	}, [])

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem(storageName))

		if (userData && userData.token) {
			login(userData.token, userData.userId)
		}

		setIsReady(true)
	}, [login])

	useEffect(() => {
		if (token) {
			setIsAuthenticated(true)
		}

		return () => setIsAuthenticated(false)
	}, [token])

	const memoedValue = useMemo(
		() => ({
			token,
			userId,
			login,
			logout,
			isAuthenticated,
			isReady,
			//delete after connect to db
			setIsAuthenticated
		}),
		[token, userId, isAuthenticated, isReady]
	)

	return (
		<AuthContext.Provider value={memoedValue}>
			{children}
		</AuthContext.Provider>
	)
}
