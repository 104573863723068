import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMessage from "hooks/useMessage";
import { Header } from "components/layout/header";
import {
	ContactsStore,
	MainStore,
	RequisitesStore,
	FaqStore,
	Navigation,
	NavigationMobile,
	Payments,
} from "components/settings";
import { PageDescription, Screen } from "components/ui";
import { Button } from "components/ui";
import { Icon } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { getShopData } from "redux/asyncAction/settings";
import { editReq } from "redux/asyncAction/settings";
import { editMain } from "redux/asyncAction/settings";
import { editContacts } from "redux/asyncAction/settings";

export default function Settings() {
	const { t } = useTranslation(["settings", "common"]);
	const { showMessage } = useMessage();
	const [section, setSection] = useState("main");

	const [data, setData] = useState({
		main: {
			logo: "",
			shop: "Лента",
			country: "Россия",
			city: "Москва",
			address: "",
			number: "+7 (495) 100 00 00",
			category: "Продукты",
			weekdaysFrom: "12:00",
			weekdaysTo: "24:00",
			weekendFrom: "10:00",
			weekendTo: "24:00",
			description:
				"Мультибрендовый магазин продуктовых товаров, а так же товаров бытового характера",
		},
		contacts: {
			email: [],
			number: [],
			sectionInfo: "",
		},
		requisites: {
			contract: "ZZ-2021/01/01/015 000",
			organization: "Лента",
			nameHead: "Иванов Иван Иванович",
			inn: "134565468746516",
			kpp: "134565468746516",
			ogrn: "134565468746516",
			legalAddress: "г. Одинцова 1А",
			taxation: "simplified",
			bik: "134565468746516",
			bankName: "Сбербанк",
			checkingAccount: "516549854165849851",
		},
	});

	let dispatch = useDispatch();
	useEffect(() => {
		dispatch(getShopData());
	}, []);

	const onChangeMainData = (mainData) => {
		dispatch(editMain(mainData));
		dispatch(getShopData());
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
	};

	const onChangeContactsData = (contactsData) => {
		dispatch(editContacts(contactsData));
		dispatch(getShopData());
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
	};

	const onChangeRequisitesData = (requisitesData) => {
		dispatch(editReq(requisitesData));
		dispatch(getShopData());
		showMessage({ value: t("messages.changesSaved", { ns: "common" }) });
	};

	const { shopDataLoading, shopData } = useSelector(
		(state) => state.settingsReducer
	);

	return (
		<>
			<Header>
				<Screen size="xl" down>
					<NavigationMobile section={section} setSection={setSection} />
				</Screen>
			</Header>
			<main className="nav">
				<PageDescription.Section>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={t("title")} />
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={
									"В данном разделе указывается ваша контактная информация с помощью которой покупатель узнает больше о вашем магазине и может связаться с вами в случае необходимости. "
								}
								placementCustomTitle="right"
								customTitle
								style={{ marginTop: "-10px", marginLeft: "8px" }}
							/>
						</Screen>
					</div>
					<Screen size="xl">
						<Navigation section={section} setSection={setSection} />
					</Screen>
				</PageDescription.Section>

				<section className="section">
					<div className="container">
						<div className="row row-20">
							<div className="col-xl-9 col-xxl-9">
								{section === "main" && (
									<MainStore
										data={data.main}
										onSaveChangedData={onChangeMainData}
									/>
								)}
								{section === "contacts" && (
									<ContactsStore
										data={data.contacts}
										onSaveChangedData={onChangeContactsData}
									/>
								)}
								{section === "requisites" && (
									<RequisitesStore
										data={data.requisites}
										onSaveChangedData={onChangeRequisitesData}
									/>
								)}
								{section === "faq" && <FaqStore />}
								{section === "payments" && <Payments />}
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}
