import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import { ControlMenu, Icon, Text } from "components/ui";
import styles from "./header.module.sass";

const BurgerMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const logo = null;

	const HeaderBurgerMenu = () => {
		return (
			<div className={styles["header-burger-menu"]}>
				<div className={styles["header-burger-menu__logo"]}>
					{logo ? (
						<img src={logo} alt="logo" />
					) : (
						<div className={styles["default-logo"]}>
							<Text size="xs" color="white">
								logo
							</Text>
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			<Icon
				name="menu"
				size="md"
				hoverColor="gray"
				onClick={() => setIsOpen(true)}
			/>

			<ControlMenu
				fullHeight={true}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				observerChanges={false}
				placement="left"
				closePlacement="left"
				customHeader={<HeaderBurgerMenu />}
			>
				<MobileMenu />
			</ControlMenu>
		</>
	);
};

export default BurgerMenu;
