import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import OrdersItemsTable from "./OrdersItemsTable";
import { ControlMenu, Text } from "components/ui";
import { getDate } from "utils/getDate";
import { useGetOrderQuery } from "redux/reducers/orders";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import ControlOrdersItemsInfoTable from "./ControlOrdersItemsInfoTable";
import moment from "moment";


const ControlOrdersInfo = ({ order, isOpen, onClose, orderId }) => {
	const { t } = useTranslation(["orders"]);
	const { deviceWidth } = useWindowSize();
	const {
		data: orderData,
		isLoadung,
		isFetching,
	} = useGetOrderQuery({ orderId });

	// const { control, register } = useForm();
	// const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
	//     control, // control props comes from useForm (optional: if you are using FormContext)
	//     name: "products", // unique name for your Field Array
	//   });

	console.log(orderData, "orderDataorderData");

	if (!order) return null;

	return (
		<ControlMenu
			isOpen={isOpen}
			onClose={onClose}
			title={t("drawer.infoOrder")}
			observerChanges={false}
		>
			<div className="row row-10">
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.number")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.orderNumber}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.lastName")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.lastName}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.name")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.firstName}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.email")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.email}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.phone")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.phone}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.created")}
						</Text>
						<Text size="sm" weight="medium">
							{getDate(orderData?.order?.createdAt)}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.region")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.shippingAddress?.city?.[0]?.title}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.address")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.shippingAddress?.street || "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.building")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.shippingAddress?.building || "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.apartment")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.shippingAddress?.apartment || "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.floor")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.shippingAddress?.floor || "..."}
						</Text>
					</div>
				</div>
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.paymentType")}
						</Text>
						<Text size="sm" weight="medium">
							{order?.amount
								? t("paymentTypes." + orderData?.order?.paymentMethod)
								: "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.deliveryType")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order?.amount
								? "Доставка"
								: "..."
								? t("deliveryTypes." + orderData?.order?.amount)
								: "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.status")}
						</Text>
						<Text size="sm" weight="medium">
							{orderData?.order.status ? t("statuses." + order.status) : "..."}
							{/* {order?.status} */}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.orderInfo.deliveryData")}
						</Text>
						<Text size="sm" weight="medium">
							{moment(order?.deliviredAt).format("DD.MM.YYYY") || "..."}
							{/* {getDate(order.s)} */}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.paid")}
						</Text>
						<Text size="sm" weight="medium">
							{/* {order.paid || "..."} */}
							{order?.paidAt ? "Да" : "Нет"}
						</Text>
					</div>
				</div>
				<div className="col-12">
					<Text
						size={deviceWidth === "large" ? "md" : "sm"}
						style={{ marginRight: 5 }}
					>
						{t("drawer.orderInfo.comment")}
					</Text>
					<Text size="sm" weight="medium">
						{orderData?.order?.comment || "..."}
					</Text>
				</div>
			</div>
			<Text
				size={deviceWidth === "large" ? "md" : "sm"}
				style={{ marginRight: 5 }}
			>
				{t("drawer.orderInfo.orderItems")}
			</Text>
			<ControlOrdersItemsInfoTable
				products={orderData?.order?.products}
				// orderQuantity={order?.productQuantities}
			/>
			<Text as="p" className="offset-top-10" size="lg" style={{ fontSize: 18 }}>
				{t("drawer.total")}
				<Text color="blue" style={{ marginLeft: 7 }}>
					{orderData?.order?.amount} ₽
				</Text>
			</Text>
		</ControlMenu>
	);
};

ControlOrdersInfo.propTypes = {
	order: PropTypes.shape({}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ControlOrdersInfo;
