import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuthContext } from 'context/auth/AuthContext'
import { LayoutLogin } from 'components/auth'
import { Text, Button, Input } from 'components/ui'
import logoSrc from 'assets/logo.svg'
import styles from 'components/auth/auth.module.sass'
import s from './authnew.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { login } from 'redux/asyncAction/auth'
import { toast } from 'materialize-css'
import InputAuth from 'components/ui/form/InputAuth'
import LocaleSwitcher from 'components/ui/localeSwitcher/LocaleSwitcher'
import { Icon } from '@mui/material'
import { padding } from '@mui/system'
import logoSrcEn from  'assets/logoEn.svg'


const Login = () => {
	const { t } = useTranslation(['auth'])
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [agree, setAgree] = useState(true)
	const [terms, setTerms] = useState(true)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	let zztoken = localStorage.getItem('zztoken')

	const [loginErr, setLoginErr] = useState(false)

	const submitForm = async ({ email, password }) => {
		try {
			await dispatch(login(email, password))
			navigate('/dashboard')

		} catch (error) {
			setLoginErr(true)
		}

	}

	return (
		<LayoutLogin>
							<Link to="https://partners.zumzak.ru/">
						<img style={{with: '140px', height: '41px'}} src={t('logo') === "assets/logoEn.svg" ? logoSrcEn : logoSrc} alt=''/>
							</Link>
			{/* <img style={{width: '140px', height: '41px'}} src={t('logo') === "assets/logo.svg" ? logoSrc : logoSrcEn} alt=""/> */}
				<Text size="super" color='white'>{t('LoginToTheOnlineStore')}</Text>
			<div className={styles['login']}>
		
				<form onSubmit={handleSubmit(submitForm)} className='offset-top-10'>
					<div className='row row-10'>

						<div className='col-12' onClick={() => setLoginErr(false)}>

							{/* <Input
								type='email'
								name='email'
								register={register}
								rules={{
									required: { value: true, message: t('validations.required', { ns: 'common' }) },
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/,
										message: t('validations.email', { ns: 'common' })
									}
								}}
								errors={errors}
								placeholder='Email'
								fluid
							/> */}
							<div className={s.labelInp}>Email <span style={{color: 'red'}}>*</span></div>
							<InputAuth
								type='email'
								name='email'
								register={register}
								rules={{
									required: { value: true, message: t("messageError.email") },
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/,
										message: t('validations.email', { ns: 'common' })
									}
								}}
								errors={errors}
								// placeholder='Email'
								fluid
								errorPos="right"
							/>
						</div>
						<div className='col-12' onClick={() => setLoginErr(false)}>
							<div className={s.labelInp}>{t('placeholder.password')} <span style={{color: 'red'}}>*</span></div>
							<InputAuth
								id='password'
								name='password'
								register={register}
								rules={{
									required: { value: true, message: t("messageError.passwordLogin") }
									// minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
								}}
								errors={errors}
								type='password'
								// placeholder={t('placeholder.password')}
								fluid
								withTogglePassword
								errorPos="right"
							/>
						</div>
						{/* <div className='col-12'>
							<Input.Group>
								<Input
									name='agree'
									type='checkbox'
									checked={agree}
									onChange={e => setAgree(e.target.checked)}
									checkboxView='secondary'
								/>
								<Text
									size='xs'
									color='white'
									decoration='underline'
									style={{ width: '100%', marginLeft: 5 }}
								>
									{t('agree')}
								</Text>
							</Input.Group>
						</div>
						<div className='col-12'>
							<Input.Group>
								<Input
									name='termsOfUse'
									type='checkbox'
									checked={terms}
									onChange={e => setTerms(e.target.checked)}
									checkboxView='secondary'
								/>
								<Text
									size='xs'
									color='white'
									decoration='underline'
									style={{ width: '100%', marginLeft: 5 }}
								>
									{t('termsOfUse')}
								</Text>
							</Input.Group>
						</div> */}
						{/* <div className='col-12'>
							<Button
								as='link'
								to='register'
								view='secondary'
								style={{ marginTop: 5 }}
							>
								{t('button.register')}
							</Button>
						</div> */}
						<div style={{position: 'absolute', top: '204px', left: '50px'}}>{loginErr && <span style={{fontSize: '11px', fontWeight: 400, color: 'red'}}>пользователь с введенными данными не найден</span>}</div>
						<div className='col-12' style={{display: 'flex', justifyContent: 'center', paddingTop: '20px', paddingBottom: '10px'}}>
							{/* <Button
								type='submit'
								disabled={!agree || !terms}
								view='tertiary'
							>
								{t('button.login')}
							</Button> */}
							<button
							style={{height: '43px'}}
								type='submit'
								className={s.btnNewAuth}
							>
								{t('button.login')}
							</button>
						</div>
					</div>
				</form>
				<Link to='register'>
					<Text size='xs' color='white'>{t('dontHaveAnAccount')} <span style={{borderBottom: '1px solid white'}}>{t('register')}</span></Text>
				</Link>
				<Link to='resetpassword'>
					<Text size='xs' color='white'>{t('forgotPassword')} <span style={{borderBottom: '1px solid white'}}>{t('clickHere')}</span></Text>
				</Link>
			</div>
		</LayoutLogin>
	)
}

export default Login
