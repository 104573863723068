import React from 'react';
import termsOfUsePdf from '../../assets/TermsOfUse.pdf'
import styled from "styled-components";


const Root = styled.div`
	width: 100vw;
	height: 100vh;

	iframe {
		width: 100vw;
		height: 100vh;
	}
`

const TermsOfUse = () => {

	return (
		<Root>
			<iframe title="TermsOfUse" src={termsOfUsePdf}/>
		</Root>
	);
};

export default TermsOfUse;
