import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { ControlMenu, Text } from "components/ui";
import { useGetReviewQuery } from "redux/reducers/reviews";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const ControlReviewsInfo = ({ reviewId, isOpen, onClose }) => {
	const { t } = useTranslation(["reviews"]);
	const { deviceWidth } = useWindowSize();
	const [dataReview, setDataReview] = useState({});
	const [haveChanges, setHaveChanges] = useState(false);
	const shopData = useSelector((state) => state.settingsReducer?.shopData);

	const getReviewQuery = useGetReviewQuery({ reviewId });
	const review = getReviewQuery.data;

	useEffect(() => {
		setDataReview(review?.data);
	}, [isOpen, dataReview, review]);
	useEffect(() => {
		if (!review) return;
		if (Object.keys(review).length) {
			//change checking objects when will be connect to db
			if (JSON.stringify(review) !== JSON.stringify(review)) {
				setHaveChanges(true);
			}
		}

		return () => setHaveChanges(false);
	}, [review]);

	if (!review) return null;

	const link = `https://${shopData?.subDomain}.zumzak.ru/product/${review.data?.product?._id}`;

	return (
		<ControlMenu
			isOpen={isOpen}
			onClose={onClose}
			title={t("drawer.infoReview")}
			observerChanges={false}
		>
			<div className="row row-10">
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.client")}
						</Text>
						<Text size="sm" weight="medium">
							{`${dataReview?.buyer?.firstName} ${dataReview?.buyer?.lastName}`}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.phone")}
						</Text>
						<Text size="sm" weight="medium">
							{dataReview?.buyer?.phone}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.reviewInfo.feedback")}
						</Text>
						<Text size="sm" weight="medium">
							{dataReview?.text}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.reviewInfo.vendorCode")}
						</Text>
						<Text size="sm" weight="medium">
							{review.data?.product?.vendorCode}
						</Text>
					</div>
				</div>
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.region")}
						</Text>
						<Text size="sm" weight="medium">
							{dataReview?.buyer?.city.title}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("table.created")}
						</Text>
						<Text size="sm" weight="medium">
							{dataReview?.createdAt
								?.slice(0, 10)
								.split("-")
								.reverse()
								.join(".")}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							color="blue"
							style={{ marginRight: 5 }}
						>
							{t("table.status")}
						</Text>
						<Text size="sm" weight="medium">
							{t("statuses." + dataReview?.status)}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							//color="blue"
							style={{ marginRight: 5 }}
						>
							{t("drawer.reviewInfo.link")}
						</Text>
						<Text size="sm" weight="medium">
							<a href={link} target={"_blank"}>
								{link}
							</a>
						</Text>
					</div>
				</div>
				<div className="col-md-12">
					<Text
						size={deviceWidth === "large" ? "md" : "sm"}
						style={{ marginRight: 5 }}
					>
						{t("drawer.reviewInfo.commentManager")}
					</Text>
					<Text size="sm" weight="medium">
						{dataReview?.reply || "..."}
					</Text>
				</div>
			</div>
		</ControlMenu>
	);
};

ControlReviewsInfo.propTypes = {
	review: PropTypes.shape({}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ControlReviewsInfo;
