import styles from "./img.module.scss";
import cn from "classnames";

const classNames = cn(styles["img-wrapper"]);
const imgClassNames = cn(styles["img-wrapper__img"]);

const Img = ({ src, title, height, width }) => {
	return (
		<div className={classNames}>
			<img
				className={imgClassNames}
				src={src}
				alt={title}
				height={height}
				width={width}
			/>
		</div>
	);
};

export default Img;
