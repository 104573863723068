import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { splitString } from "helpers";
import {
	Collapse,
	ControlMenu,
	Pagination,
	Table,
	Td,
	Text,
	Tr,
} from "components/ui";
import styles from "../clients.module.sass";
//import { useDispatch, useSelector } from "react-redux";
import { useGetBuyerOrdersQuery } from "redux/reducers/orders";
//import { $api } from "api";

const ControlClientsInfo = ({ client, isOpen, onClose }) => {
	const { t } = useTranslation(["clients"]);
	const { deviceWidth } = useWindowSize();

	const limit = 20;
	const [page, setPage] = useState(1);

	const ordersQuery = useGetBuyerOrdersQuery({
		buyerId: client._id,
		limit,
		page,
	});
	const orders = ordersQuery.data?.orders || [];
	const total = ordersQuery.data?.total || 0;

	//const dispatch = useDispatch();
	//const [orders, setOrders] = useState([]);
	//const { clientInfo } = useSelector((state) => state.clientReducer);
	// const getOrders = async () => {
	// 	try {
	// 		const response = await $api.get(`/buyerOrders/${client._id}`);
	// 		setOrders(response.data.orders);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };
	// console.log("orders", orders);
	// useEffect(() => {
	// 	if (client) {
	// 		dispatch(getClient(client?.orders?.[0]?._id));
	// 	}
	// 	getOrders();
	// }, [client]);
	//
	// console.log("client", client);

	if (!client) return null;

	return (
		<ControlMenu
			isOpen={isOpen}
			onClose={onClose}
			title={t("drawer.infoClient.title")}
			observerChanges={false}
		>
			<div className="row row-10">
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.name")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.firstName}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.lastName")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.lastName}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.dateOfRegistration")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.createdAt?.slice(0, 10).split("-").reverse().join(".")}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.phone")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.phone}
						</Text>
					</div>
				</div>
				<div className="col-md-6">
					<div>
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							Email:
						</Text>
						<Text size="sm" weight="medium">
							{client?.email}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.country")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.countryData?.title || "Россия"}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.city")}
						</Text>
						<Text size="sm" weight="medium">
							{client?.city?.title || "..."}
						</Text>
					</div>
					<div className="offset-top-10">
						<Text
							size={deviceWidth === "large" ? "md" : "sm"}
							style={{ marginRight: 5 }}
						>
							{t("drawer.infoClient.address")}
						</Text>
						<Text
							size="sm"
							weight="medium"
						>{`Ул. ${client?.street}, дом ${client?.building}, кв ${client?.apartment}`}</Text>
					</div>
				</div>
				<div className="col-12">
					<Text
						size={deviceWidth === "large" ? "md" : "sm"}
						style={{ marginRight: 5 }}
					>
						{t("drawer.infoClient.clientOrders")}
					</Text>
					{Boolean(orders.length) && (
						<>
							<div className="offset-top-10">
								<Collapse>
									{orders.map((order, i) => (
										<Collapse.Panel
											key={i}
											index={i}
											isArrowMoving={false}
											icon="info-border"
											label={
												<>
													{console.log(order)}
													<Text
														// size={deviceWidth === 'large' ? 'md' : 'sm'}
														size="sm"
														style={{ marginRight: 5 }}
													>
														{t("drawer.infoClient.orderNumber")}
													</Text>
													<Text size="sm" weight="medium">
														{order?.orderNumber}
													</Text>
												</>
											}
										>
											<>
												<div className="row row-10">
													<div className="col-md-6">
														<div>
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.orderNumber")}
															</Text>
															<Text size="sm" weight="medium">
																{order?.orderNumber}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.phone")}
															</Text>
															<Text
																size="sm"
																weight="medium"
															>{`+7 ${order?.phone}`}</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.city")}
															</Text>
															<Text size="sm" weight="medium">
																{order?.shippingAddress?.city[0]?.title}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.created")}
															</Text>
															<Text size="sm" weight="medium">
																{order?.createdAt
																	.slice(0, 10)
																	.split("-")
																	.reverse()
																	.join(".")}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.paid")}
															</Text>
															<Text size="sm" weight="medium">
																{order?.paid ? "Оплачено" : "Не оплачено"}
															</Text>
														</div>
													</div>
													<div className="col-md-6">
														<div>
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.delivered")}
															</Text>
															<Text size="sm" weight="medium">
																{order?.shippingStatus ? "Да" : "Нет"}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.paymentType")}
															</Text>
															<Text size="sm" weight="medium">
																{/* {t(
																`paymentTypes.${order?.paymentMethod}` ||
																	"Безналичный"
															)} */}
																{order?.paymentMethod === "ONLINE"
																	? "Онлайн"
																	: "Наличными"}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.deliveryType")}
															</Text>
															<Text size="sm" weight="medium">
																{/* {t(
																`deliveryTypes.${order?.shippingType}` ||
																	"Самовывоз"
															)} */}
																{order?.shippingType === "COURIER"
																	? "Курьер"
																	: "Самовывоз"}
															</Text>
														</div>
														<div className="offset-top-10">
															<Text
																size={deviceWidth === "large" ? "md" : "sm"}
																weight="bold"
																style={{ marginRight: 5 }}
															>
																{t("drawer.infoClient.orderStatus")}
															</Text>
															<Text size="sm" weight="medium">
																{t("orderStatuses." + order?.status)}
															</Text>
														</div>
													</div>
													<div className="col-12">
														<Text
															size={deviceWidth === "large" ? "md" : "sm"}
															weight="bold"
														>
															{t("drawer.infoClient.orderItems")}
														</Text>
														<Table className="offset-top-10">
															<Table.Head>
																<Td width={35}>№</Td>
																<Td width={deviceWidth === "large" ? 500 : 300}>
																	{t("drawer.infoClient.itemName")}
																</Td>
																<Td width={100}>
																	{t("drawer.infoClient.article")}
																</Td>
																<Td width={100}>
																	{t("drawer.infoClient.quantity")}
																</Td>
																<Td width={100}>
																	{t("drawer.infoClient.cost")}
																</Td>
															</Table.Head>
															<Table.Body>
																{order.products.map((product, i) => (
																	<Tr key={i}>
																		<Td width={35}>{i + 1}</Td>
																		<Td
																			width={
																				deviceWidth === "large" ? 500 : 300
																			}
																			overflow
																		>
																			{product?.data.title}
																		</Td>
																		<Td width={100}>
																			{product?.data.vendorCode}
																		</Td>
																		<Td width={100}>
																			{product?.quantity || "111"}
																		</Td>
																		<Td width={100}>{`${product?.price} ₽`}</Td>
																	</Tr>
																))}
															</Table.Body>
														</Table>
													</div>
												</div>
												<Text
													as="p"
													size="lg"
													weight="bold"
													className={styles.total}
												>
													{t("drawer.infoClient.total")}
													<Text color="blue">
														{`${splitString(order?.amount)} ₽`}{" "}
													</Text>
												</Text>
											</>
										</Collapse.Panel>
									))}
								</Collapse>
							</div>
							<Pagination
								//key={1}
								current={page}
								pageSize={limit}
								total={total}
								onChange={(page) => {
									setPage(page);
								}}
								mobile={deviceWidth !== "large"}
							/>
						</>
					)}
					<Text
						as="p"
						size="lg"
						weight="bold"
						className={cn(styles.total, styles.orders)}
					>
						{t("drawer.infoClient.sum")}
						<Text color="blue">{`${splitString(
							client?.statistics?.buyerOrdersAmount
						)} ₽`}</Text>
					</Text>
				</div>
			</div>
		</ControlMenu>
	);
};

ControlClientsInfo.propTypes = {
	client: PropTypes.shape({}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ControlClientsInfo;
