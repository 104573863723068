import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Products } from "components/catalog";
//import { defaultProducts } from "fakeData/products";
import { useSearchParams } from "react-router-dom";
import { useGetProductsQuery } from "../../redux/reducers/product";

export default function Goods() {
	const { t } = useTranslation(["catalog"]);

	const [searchParams] = useSearchParams();
	const params = Object.fromEntries(searchParams.entries());
	const searchText = params.searchText;
	const page = Number(params.page) || 1;
	const filterBy = params.filterBy || null;

	const productsQuery = useGetProductsQuery({
		page,
		limit: 10,
		searchText,
		filterBy,
	});

	const products = productsQuery.data?.products || [];
	const productsTotal = productsQuery.data?.total || 0;
	/*const [products, setProducts] = useState([]);

	console.log("ggods");

	// change after connect to db
	useEffect(() => {
		if (defaultProducts) {
			setProducts(defaultProducts);
		}
	}, [defaultProducts]);

	if (!products.length) {
		return <p>loading...</p>;
	}*/

	return (
		<Products
			title={t("navigation.goods")}
			products={products}
			productsTotal={productsTotal}
			withFilter={true}
			filterBy={filterBy}
			page={page}
		/>
	);
}
