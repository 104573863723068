import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout/header";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { splitString } from "helpers";
import {
	FilterClients,
	ClientsInfo,
	ControlClientsInfo,
} from "components/clients";
import {
	Icon,
	SearchForm,
	Screen,
	PageDescription,
	Table,
	Td,
	Tr,
	Text,
	Pagination,
	ControlMenu,
} from "components/ui";
import { defaultClients } from "fakeData/clients";
import { useDispatch, useSelector } from "react-redux";
import { getClients, getSumOfAllClients } from "redux/asyncAction/client";
import { getCities } from "redux/asyncAction/region";
import { getCitiesAvailableForSearch } from "redux/asyncAction/region";

export default function Clients() {
	const { t } = useTranslation(["clients"]);
	const [clients, setClients] = useState([]);
	const [openedMenu, setOpenedMenu] = useState("");
	const [infoClient, setInfoClient] = useState({});
	const [isOpenFilterMobile, setIsOpenFilterMobile] = useState(false);
	const [filters, setFilters] = useState({ region: "" });
	const { deviceWidth } = useWindowSize();
	const isInfoMode = openedMenu === "info";
	const dispatch = useDispatch();
	const { clientsArr } = useSelector((state) => state.clientReducer);
	const { clientsStatistics } = useSelector((state) => state.clientReducer);
	const [productId, setProductId] = useState({});
	const [searchName, setSearchName] = useState("");
	const { cities } = useSelector((state) => state.regionReducer);

	// console.log("searchName", searchName);

	// const [data, setData] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	//   const indexOfLastItem = currentPage * itemsPerPage;
	//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	//   const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	// setData(clientsStatistics?.buyersCount)

	// let data = clientsStatistics?.buyersCount || 0

	const startIndex = (currentPage - 1) * itemsPerPage;
	// const endIndex = startIndex + itemsPerPage;
	useEffect(() => {
		dispatch(getCitiesAvailableForSearch());
		dispatch(getSumOfAllClients());
		dispatch(getClients(currentPage, itemsPerPage, searchName, filters.region));
		// setClients(defaultClients)
		// console.log("22");
	}, [currentPage, filters, searchName]);

	// console.log(startIndex, endIndex, 'endIndexstartIndex');

	// console.log(data, '1111')

	const closeMenu = () => {
		setOpenedMenu("");
		isInfoMode && setInfoClient({});
	};

	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenu("");
		isActive ? setOpenedMenu(type) : closeMenu();
	};

	const setInfoMode = (id) => {
		setInfoClient(clientsArr.buyers.find((v) => v._id === id));
		toggleOpenMenu(true, "info");
	};

	const onChangeFilter = (value, filter) => {
		setFilters({
			...filters,
			[filter]: value,
		});
		dispatch(getCities());
		dispatch(getSumOfAllClients());
	};

	useEffect(() => {
		dispatch(getCities());
	}, []);

	const clearFilters = () => {
		setFilters(defaultFilters);
	};

	const MobileFilter = () => (
		<Screen size="xl" down>
			<Icon
				name="filters"
				size="md"
				hoverColor="gray"
				onClick={() => setIsOpenFilterMobile(true)}
			/>
		</Screen>
	);

	return (
		<>
			<Header options={<MobileFilter />} />
			<main>
				<Screen size="xl" down>
					<SearchForm.Section sectionFirst>
						<SearchForm
							placeholder={"test"}
							setSearchName={setSearchName}
							onSearch={setSearchName}
						/>
					</SearchForm.Section>
				</Screen>
				<PageDescription.Section>
					<div style={{ display: "flex", alignItems: "center" }}>
						<PageDescription title={t("title")} />
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={
									"В данном разделе указывается основная информация о ваших клиентах."
								}
								placementCustomTitle="right"
								customTitle
								style={{ marginTop: "-10px", marginLeft: "8px" }}
							/>
						</Screen>
					</div>
				</PageDescription.Section>

				<Screen size="xl">
					<SearchForm.Section>
						<SearchForm onSearch={setSearchName} />
					</SearchForm.Section>
				</Screen>

				<section className="section">
					<div className="container">
						{clientsArr?.buyers ? (
							<div className="row row-20">
								<div className="col-xl-3 col-xxxl-2">
									<Screen size="xl">
										<FilterClients
											cities={cities}
											filters={filters}
											onChange={onChangeFilter}
											clear={clearFilters}
										/>
									</Screen>
									<ClientsInfo clientsArr={clientsArr} />
								</div>

								<div className="col-xl-9 col-xxxl-10">
									<Screen size="xl" down>
										<Text size="md" color="blue">
											{t("clients")}
										</Text>
									</Screen>
									<Table
										className={cn(deviceWidth !== "large" && "offset-top-10")}
									>
										<Table.Head>
											<Td width={40}>№</Td>
											<Td width={deviceWidth === "large" ? 210 : 150}>
												{t("table.client")}
											</Td>
											<Td width={deviceWidth === "large" ? 210 : 150}>
												{t("table.email")}
											</Td>
											<Td width={deviceWidth === "large" ? 200 : 150}>
												{t("table.number")}
											</Td>
											<Td width={deviceWidth === "large" ? 210 : 150}>
												{t("table.region")}
											</Td>
											<Td width={deviceWidth === "large" ? 210 : 160}>
												{t("table.registration")}
											</Td>
											<Td width={deviceWidth === "large" ? 210 : 150}>
												{t("table.ordersSum")}
											</Td>
											<Td width={40} />
										</Table.Head>
										<Table.Body>
											{clientsArr?.buyers?.map((client, i) => (
												<Tr key={client._id}>
													<Td width={40}>{startIndex + i + 1}</Td>
													<Td
														width={deviceWidth === "large" ? 210 : 150}
														color="blue"
														overflow
													>
														{client.fullName}
													</Td>
													<Td width={deviceWidth === "large" ? 210 : 150}>
														{client?.email}
													</Td>
													<Td width={deviceWidth === "large" ? 200 : 150}>
														{client?.phone}
													</Td>
													<Td width={deviceWidth === "large" ? 210 : 150}>
														{client?.city.title}
													</Td>
													<Td width={deviceWidth === "large" ? 210 : 160}>
														{client?.createdAt
															.slice(0, 10)
															.split("-")
															.reverse()
															.join(".")}
													</Td>
													<Td width={deviceWidth === "large" ? 210 : 150}>
														{splitString(client?.statistics.buyerOrdersAmount)}{" "}
														₽
													</Td>
													<Td
														width={40}
														buttons={deviceWidth === "large"}
														ellipsis={deviceWidth !== "large"}
													>
														<Icon
															name="info-border"
															size="md"
															onClick={() => setInfoMode(client?._id)}
															title={t("buttonDesc.view", {
																value: "",
																ns: "common",
															})}
															cursorPointer
														/>
													</Td>
												</Tr>
											))}
										</Table.Body>
									</Table>
									{clientsArr?.buyers.length >= 1 ? (
										<Pagination
											key={currentPage}
											current={currentPage}
											pageSize={itemsPerPage}
											total={Math.ceil(clientsArr.pagination?.total)}
											onChange={handlePageChange}
											mobile={deviceWidth !== "large"}
										/>
									) : (
										<p style={{ marginLeft: "300px" }}>
											у вас пока нет клиентов
										</p>
									)}
								</div>
							</div>
						) : (
							<Text size="md">{t("haventClients")}</Text>
						)}
					</div>
				</section>
			</main>

			{isInfoMode && (
				<ControlClientsInfo
					client={infoClient}
					isOpen={isInfoMode}
					onClose={closeMenu}
				/>
			)}

			<ControlMenu
				isOpen={isOpenFilterMobile}
				onClose={() => setIsOpenFilterMobile(false)}
				observerChanges={false}
				title={t("filters.mobileTitle")}
			>
				<Screen size="xl" down>
					<FilterClients
						filters={filters}
						onChange={onChangeFilter}
						clear={clearFilters}
						mobile
					/>
				</Screen>
			</ControlMenu>
		</>
	);
}
