import React from 'react'
import { useAuthContext } from 'context/auth/AuthContext'
import ActiveLink from 'components/utils/ActiveLink'
import { Icon } from 'components/ui'
import styles from './sidebar.module.sass'
import { Navigate, useNavigate } from 'react-router-dom'
import bannerNew from '../../../assets/bannewicon.svg'

export default function Menu() {
	let navigate = useNavigate()
	const logout = () => {
		localStorage.removeItem('zztoken')
		window.location.reload(false);
	}

	return (
		<ul className={styles.menu}>
			<li className={styles.menu__item}>
				<ActiveLink to='/dashboard' className={styles.link} activeClassName={styles.active}>
					<Icon name='dashboard' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/orders' className={styles.link} activeClassName={styles.active}>
					<Icon name='orders' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/clients' className={styles.link} activeClassName={styles.active}>
					<Icon name='clients' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/catalog/category' className={styles.link} activeClassName={styles.active} nav='catalog'>
					<Icon name='catalog' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/reviews' className={styles.link} activeClassName={styles.active}>
					<Icon name='reviews' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/hints' className={styles.link} activeClassName={styles.active}>
					<Icon name='hints' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink
					to="/banners/library"
					className={styles.link}
					activeClassName={styles.active}
					nav="banners"
				>
					<img src={bannerNew} alt="banners"/>
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/settings' className={styles.link} activeClassName={styles.active}>
					<Icon name='settings' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/seo' className={styles.link} activeClassName={styles.active}>
					<Icon name='seo' size='lg' color='white' />
				</ActiveLink>
			</li>
			{/* <li className={styles.menu__item}>
				<ActiveLink to='/payment' className={styles.link} activeClassName={styles.active}>
					<Icon name='payment' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/delivery' className={styles.link} activeClassName={styles.active}>
					<Icon name='delivery' size='lg' color='white' />
				</ActiveLink>
			</li> */}
			<li className={styles.menu__item}>
				<ActiveLink to='/rate' className={styles.link} activeClassName={styles.active}>
					<Icon name='rate' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<ActiveLink to='/preview' className={styles.link} activeClassName={styles.active}>
					<Icon name='preview' size='lg' color='white' />
				</ActiveLink>
			</li>
			<li className={styles.menu__item}>
				<div className={styles.link} onClick={logout}>
					<Icon name='exit' size='lg' color='white' cursorPointer={true} />
				</div>
			</li>
		</ul>
	)
}
