import React from "react";
import { Button, Input, Text } from "../ui";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useWindowSize from "../../hooks/useWindowSize";
import styles from "./youmoneyModal.module.scss";
import youmomeyLogo from "../../assets/payments/youmoney.png";

export const YoumoneyModal = ({ data, onSubmit }) => {
	const {
		handleSubmit,
		formState: { errors },
		register,
	} = useForm({
		defaultValues: {
			apiKey: data?.apiKey,
			shopId: data?.shopId,
		},
	});

	const { t } = useTranslation(["settings", "common", "payment"]);

	const { deviceWidth } = useWindowSize();

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<div className={styles.formLogoContainer}>
				<img className={styles.formLogoContainer__logo} src={youmomeyLogo} />
			</div>
			<Text size="md" color="blue" className="offset-top-30">
				{t("youmoney.description", { ns: "payment" })}
			</Text>
			<Button
				as={"link"}
				to={"https://yookassa.ru/"}
				target={"_blank"}
				className="offset-top-20"
				fluid={deviceWidth === "small"}
			>
				{t("buttons.create", { ns: "common" })}
			</Button>
			<div className={styles.formSeparatorContainer}>
				<div className={styles.formSeparatorContainer__or}>
					{t("or", { ns: "payment" })}
				</div>
			</div>
			<Text size="md" color="blue">
				{t("youmoney.hint", { ns: "payment" })}
			</Text>
			<Input
				className={"offset-top-15"}
				//onChange={(value) => handleChange(value, "seo-title")}
				register={register}
				name="apiKey"
				rules={{
					required: {
						value: true,
						message: t("validations.required", { ns: "common" }),
					},
				}}
				//value={category?.seo?.title || category?.seo?.title}
				//label="Title"
				errors={errors}
				fluid={true}
				placeholder={t("youmoney.apiKey", { ns: "payment" })}
				labelClassName="offset-top-10"
				labelSize={deviceWidth === "large" ? "lg" : "sm"}
			/>
			<Input
				className={"offset-top-20"}
				//onChange={(value) => handleChange(value, "seo-title")}
				register={register}
				name="shopId"
				rules={{
					required: {
						value: true,
						message: t("validations.required", { ns: "common" }),
					},
				}}
				//value={category?.seo?.title || category?.seo?.title}
				//label="Title"
				errors={errors}
				fluid={true}
				placeholder={t("youmoney.shopId", { ns: "payment" })}
				labelClassName="offset-top-10"
				labelSize={deviceWidth === "large" ? "lg" : "sm"}
			/>
			<Button
				type="submit"
				className="offset-top-20"
				fluid={deviceWidth === "small"}
			>
				{t("buttons.save", { ns: "common" })}
			</Button>
		</form>
	);
};
