import bannerImg2 from "assets/banner2.svg";
import bannerImg3 from "assets/banner3.svg";

import Icon from "components/ui/icon/Icon";
import styles from "./banners.module.sass";
import React, { useState } from "react";
import useMessage from "hooks/useMessage";
import { useTranslation } from "react-i18next";
import EditBanner from "components/banners/controls/EditBanner";
import { Button, ButtonGroup, ControlMenu, Modal, Text } from "components/ui";
import bannerimg from "../../assets/bannervar.svg";
import { useDispatch, useSelector } from "react-redux";
import { useRemoveBannerMutation } from "redux/reducers/banners";

const YourBanners = () => {
	const [error, setError] = useState(null);
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [mode, setMode] = useState("edit");
	const [editingBanner, setEditingBanner] = useState(null);
	const { showMessage } = useMessage();
	const { t } = useTranslation(["banners", "common"]);

	const [modal, setModal] = useState(null);

	const { shopData, shopDataLoading } = useSelector(
		(state) => state.settingsReducer
	);

	const dispatch = useDispatch();

	const [removeBanner] = useRemoveBannerMutation();

	const banners = shopData?.banners || [];

	const handleDeleteBanner = async (bannerId) => {
		await removeBanner({ bannerId }).then(({ error, data }) => {
			if (error) {
				return setError(error?.message);
			}

			dispatch({ type: "UPDATE_BANNERS", payload: data.data.banners });

			showMessage({ value: t("message.bannerDeleted", { ns: "banners" }) });
		});
	};

	const toggleOpenMenu = (isActive) => {
		if (isActive) {
			setIsOpenMenu(true);
		} else {
			closeMenu();
		}
	};

	const closeMenu = () => {
		setIsOpenMenu(false);
	};

	const editBanner = (banner) => {
		toggleOpenMenu(true);
		setEditingBanner(banner);
	};

	const onDeleteBanner = (bannerId) => {
		setModal(
			<Modal isOpen={true} onRequestClose={() => setModal(null)} size="sm">
				<div className="modal-body">
					<div className="modal-body__text">
						<Text as="p" size="xl" align="center">
							{t("confirm.deleteBanner", { ns: "banners" })}
						</Text>
					</div>
					<div className="modal-body__confirm-buttons">
						<ButtonGroup>
							<Button
								fluid={true}
								onClick={() =>
									handleDeleteBanner(bannerId).finally(() => setModal(null))
								}
							>
								{t("buttons.confirm", { ns: "common" })}
							</Button>
							<Button
								view={"cancel"}
								fluid={true}
								onClick={() => setModal(null)}
							>
								{t("buttons.cancel", { ns: "common" })}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</Modal>
		);
	};

	return (
		<>
			{modal}
			<p
				style={{
					fontFamily: "Montserrat",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "16px",
					lineHeight: "20px",
					marginLeft: "10px",
					marginTop: "20px",
				}}
			>
				Баннеры:
			</p>
			<div className="col-xl-9" style={{}}>
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{banners?.map((banner) => {
						return (
							<div className={styles["imgContainer"]} key={banner._id}>
								<div
									style={{
										position: "absolute",
										color: "white",
										padding: "20px",
									}}
								>
									<span>{banner.text}</span>
								</div>
								<img
									style={{
										marginTop: "5px",
										borderRadius: "5px",
										width: "350px",
										marginRight: "20px",
									}}
									className={styles["banner"]}
									// src={
									// 	// process.env.REACT_APP_STATIC_URL + item.banner.filename
									// }

									src={banner.url}
								/>
								<div className={styles["deleteEdit4"]}>
									<Icon
										name="edit"
										size="sm"
										cursorPointer
										onClick={() => editBanner(banner)}
									/>
									<Icon
										name="delete"
										size="sm"
										color="red"
										cursorPointer
										onClick={() => onDeleteBanner(banner._id)}
									/>
								</div>
							</div>
						);
					})}
				</div>

				{/* <p
							style={{
								fontFamily: "Montserrat",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "14px",
								lineHeight: "17px",
								marginTop: "10px",
							}}
						>
							Расположение №2
						</p> */}
				{/*<div style={{ display: "flex", flexWrap: "wrap" }}>
					{[].map((banner) => {
						return (
							<div className={styles["imgContainer"]}>
								<img
									style={{ marginTop: "5px", borderRadius: "5px" }}
									className={styles["banner"]}
									key={banner}
									src={bannerImg2}
								/>
								<div className={styles["deleteEdit2"]}>
									<Icon name="edit" size="sm" cursorPointer />
									<Icon name="delete" size="sm" color="red" cursorPointer />
								</div>
							</div>
						);
					})}
				</div>*/}

				{/* <p
							style={{
								fontFamily: "Montserrat",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "14px",
								lineHeight: "17px",
								marginTop: "10px",
							}}
						>
							Расположение №3
						</p> */}
				{/*<div style={{ display: "flex", flexWrap: "wrap" }}>
					{[].map((banner) => {
						return (
							<div className={styles["imgContainer"]}>
								<img
									style={{
										marginTop: "5px",
										marginRight: "7px",
										borderRadius: "5px",
									}}
									className={styles["banner"]}
									key={banner}
									src={bannerImg3}
								/>
								<div className={styles["deleteEdit3"]}>
									<Icon name="edit" size="sm" cursorPointer />
									<Icon name="delete" size="sm" color="red" cursorPointer />
								</div>
							</div>
						);
					})}
				</div>*/}

				{/* <p
							style={{
								fontFamily: "Montserrat",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "14px",
								lineHeight: "17px",
								marginTop: "10px",
							}}
						>
							Расположение №4
						</p> */}
				<div className={styles["imgContainer"]}>
					{/* <img
								style={{
									marginTop: "5px",
									marginRight: "7px",
									borderRadius: "5px",
								}}
								className={styles["banner"]}
								src={bannerImg4}
							/> */}
					{/* <div className={styles["deleteEdit"]}>
								<Icon name="edit" size="sm" cursorPointer />
								<Icon name="delete" size="sm" color="red" cursorPointer />
							</div> */}
				</div>
			</div>

			{isOpenMenu && (
				<ControlMenu
					isOpen={isOpenMenu}
					onClose={closeMenu}
					title={"Редактировать баннер"}
				>
					<EditBanner closeMenu={closeMenu} editingBanner={editingBanner} />
				</ControlMenu>
			)}
		</>
	);
};

export default YourBanners;
