import { $api } from "api";

class Catalog {
	async getProducts(page, searchText, limit) {
		const res = await $api.get(
			`/searchProducts?page=${page}&limit=${limit}&searchText=${searchText}`
		);
		return res;
	}

	async switchPopular(id, value) {
		console.log("popid", id, value);
		return await $api.post(`/changeProductPromotionProperty`, {
			productId: id,
			property: "popular",
			value: value,
		});
	}

	async switchRec(id, value) {
		console.log("recid", id, value);
		return await $api.post(`/changeProductPromotionProperty`, {
			productId: id,
			property: "recommendations",
			value: value,
		});
	}

	async switchVisibility(id, value) {
		return await $api.post(`/changeProductVisibility`, {
			productId: id,
			value: value,
		});
	}

	async deleteProduct(id) {
		console.log("api del", id);
		return await $api.post(`/deleteProduct`, {
			productId: id,
		});
	}
}

export default new Catalog();
