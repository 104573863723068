import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { Text, Button, SearchForm } from "components/ui";
import CategoryTree from "./caregoryTree/CategoryTree";
import { useCreateCategoryFromTemplateMutation } from "../../../redux/reducers/category";
import useMessage from "../../../hooks/useMessage";

const ControlCategoryAddFromTemplate = ({
	setIsOpenMenu,
	setMode,
	parentTemplateCategoryId,
	...props
}) => {
	const { t } = useTranslation(["catalog", "common"]);
	const { deviceWidth } = useWindowSize();
	//const dispatch = useDispatch();
	const { showMessage } = useMessage();

	const [searchText, setSearchText] = useState("");

	const [createCategoryFromTemplate] = useCreateCategoryFromTemplateMutation();

	//console.log("add from template");

	/*const { shopGlobalCategories, selectedCategory, refreshCategories } =
		useSelector((state) => state.categoryReducer);*/

	const [selectedCategory, setSelectedCategory] = useState(null);

	const handleCategoriesSearch = (searchedCategories) => {
		/*const filteredCategories = shopGlobalCategories.filter((category) =>
			category.title.includes(searchedCategories)
		);
		setCurrentCategories(filteredCategories);*/
	};

	const handleEnter = (searchedCategories) => {
		handleCategoriesSearch(searchedCategories);
	};

	const handleAddCategory = async () => {
		/*delete selectedCategory["_id"];
		delete selectedCategory["parentCategoryId"];

		console.log("category", selectedCategory);

		dispatch(createNewCategory({ category: omit(selectedCategory, "_id") }));*/

		const response = await createCategoryFromTemplate({
			templateCategoryId: selectedCategory._id,
		});

		if (!response.error) showMessage({ value: t("message.addCategory") });
		/*dispatch(refreshCategoriesAction(!refreshCategories));
		dispatch(setSelectedCategoryAction({}));*/
	};

	return (
		<>
			<Text as="p" size={deviceWidth === "large" ? "lg" : "sm"}>
				{t("drawer.addCategory.categoryHas")}
			</Text>
			<Text as="p" size="sm" color="gray" className="offset-top-10">
				{t("drawer.addCategory.categoryHasDesc")}
			</Text>
			<SearchForm
				key={location.pathname}
				handleEnter={setSearchText}
				resetInput={() => setSearchText("")}
				onSearch={setSearchText}
				placeholder={t("searchTemplateCategoryPlaceholder")}
			/>
			<CategoryTree
				key={searchText}
				className="offset-top-10"
				parentCategoryId={parentTemplateCategoryId}
				onChange={setSelectedCategory}
				searchText={searchText}
			/>
			<Button
				disabled={!selectedCategory}
				onClick={handleAddCategory}
				className={deviceWidth === "large" ? "offset-top-10" : "offset-top-20"}
				fluid={deviceWidth !== "large"}
			>
				{t("buttons.add", { ns: "common" })}
			</Button>
			<hr className="margin-10 color-blue" />
			<Text as="p" size={deviceWidth === "large" ? "lg" : "sm"}>
				{t("drawer.addCategory.createNewCategory")}
			</Text>
			<Text as="p" size="sm" color="gray" className="offset-top-10">
				{t("drawer.addCategory.createNewCategoryDesc")}
			</Text>
			<Button
				className={deviceWidth === "large" ? "offset-top-10" : "offset-top-20"}
				style={{ padding: "0 8px" }}
				onClick={() => setMode("create")}
				fluid={deviceWidth !== "large"}
			>
				{t("drawer.addCategory.createNewCategoryButton")}
			</Button>
		</>
	);
};

/*ControlCategoryAdd.propTypes = {
	categories: PropTypes.array.isRequired,
};*/

export default ControlCategoryAddFromTemplate;
