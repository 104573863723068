import { Text } from "components/ui";
import { Select } from "components/ui";
import { Button, Input } from "components/ui";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./AddPromocode.module.scss";
// import { editPromoCode } from "../../../../redux/asyncAction/promocode";
import { useDispatch } from "react-redux";
import useMessage from "../../../../hooks/useMessage";
import { useEditPromocodeMutation } from "redux/reducers/promocode";
import moment from "moment";
import { format } from "prettier";

const EditPromocode = ({
	closeMenu,
	setRefreshPromoCodes,
	refreshPromoCodes,
	selectedPromoCode,
}) => {
	const dispatch = useDispatch();
	const { showMessage } = useMessage();
	const { t } = useTranslation(["catalog", "common"]);

	const [editPromocode] = useEditPromocodeMutation();

	console.log("selectedPromoCode", selectedPromoCode);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
		setValue,
		setError,
		getValues,
		clearErrors,
	} = useForm({ mode: "onTouched", reValidateMode: "onChange" });

	const [startDateCal, setStartDate] = useState(
		new Date(selectedPromoCode.startDate)
	);
	const [endDateCal, setEndDate] = useState(
		new Date(selectedPromoCode.endDate)
	);

	useEffect(() => {
		let dateStart = new Date(selectedPromoCode.startDate);
		let dateEnd = new Date(selectedPromoCode.endDate);
		let day = dateStart.getUTCDate();
		let month = dateStart.getUTCMonth() + 1; // Месяцы начинаются с 0, поэтому добавляем 1
		let year = dateStart.getUTCFullYear();

		let dayEnd = dateEnd.getUTCDate();
		let monthEnd = dateEnd.getUTCMonth() + 1; // Месяцы начинаются с 0, поэтому добавляем 1
		let yearEnd = dateEnd.getUTCFullYear();

		// Формируем строку в формате "дд.мм.гг"
		let formattedDateEnd =
			yearEnd +
			"-" +
			(monthEnd < 10 ? "0" : "") +
			monthEnd +
			"-" +
			(dayEnd < 10 ? "0" : "") +
			dayEnd;

		let formattedDate =
			year +
			"-" +
			(month < 10 ? "0" : "") +
			month +
			"-" +
			(day < 10 ? "0" : "") +
			day;

		setStartDate(formattedDate);
		setEndDate(formattedDateEnd);
	}, []);

	const handleEditPromoCode = async (data) => {
		console.log(data);
		const reqData = {
			code: data.code,
			startDate: `${startDateCal}T23:59:00.000Z`,
			endDate: `${endDateCal}T23:08:26.000Z`,
			discountPercent: data.discountPercent,
			title: data.title,
			id: selectedPromoCode._id,
		};
		editPromocode(reqData);
		// dispatch(editPromoCode({ ...values, _id: selectedPromoCode._id }));
		showMessage({ value: t("message.editPromoCode") });
		closeMenu();
		setRefreshPromoCodes(!refreshPromoCodes);
	};

	useEffect(() => {
		setValue("title", selectedPromoCode.title);
		setValue("code", selectedPromoCode.code);
		setValue(
			"startDate",
			moment(selectedPromoCode.startDate).format("DD.MM.YYYY")
		);
		setValue("endDate", moment(selectedPromoCode.endDate).format("DD.MM.YYYY"));
		setValue("discountPercent", selectedPromoCode.discountPercent);
	}, []);

	const handleChange = (value, name) => {
		const { startDate, endDate } = getValues();
		console.log(startDate, endDate);
		if (startDate && endDate) {
			const startDateTime = new Date(startDate).getTime();
			const endDateTime = new Date(endDate).getTime();
			console.log(startDateTime, endDateTime);
			if (startDateTime >= endDateTime) {
				setError(
					"date",
					{ message: "дата начала не может быть больше даты окончания" },
					{ shouldFocus: true }
				);

				setError(
					"startDate",
					{ message: "дата начала не может быть больше даты окончания" },
					{ shouldFocus: true }
				);
			} else {
				clearErrors("date");
			}
		}
	};

	console.log("endDateCal", endDateCal);

	return (
		<form
			onSubmit={handleSubmit(handleEditPromoCode)}
			className={styles.addPromoForm}
		>
			<Text as="h3" color="blue">
				Основная информация:
			</Text>
			<div className={styles.addInputGroup}>
				<div>
					<Text color="black">Название акции:</Text>
					<Input
						size="md"
						required
						id="title"
						name="title"
						register={register}
						errors={errors}
					/>
				</div>
				<div>
					<Text color="black">Код промоакции:</Text>
					<Input
						id="code"
						name="code"
						size="md"
						register={register}
						errors={errors}
					/>
				</div>
				<div>
					<Text color="black">Дата проведения:</Text>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Input
							onChange={(value) => {
								setStartDate(value);
								handleChange(value, "startDate");
							}}
							type="date"
							size="sm"
							style={{ borderRightStyle: "none" }}
							id="startDate"
							name="startDate"
							register={register}
							errors={errors}
							value={startDateCal}
						/>
						<div
							style={{
								marginTop: "9px",
								height: "33px",
								background: "#F2F3F5",
								alignSelf: "center",
								borderTop: "1px solid #E5E6EB",
								borderBottom: "1px solid #E5E6EB",
								boxSizing: "border-box",
								paddingTop: "6px",
							}}
						>
							&minus;
						</div>
						<Input
							onChange={(value) => {
								setEndDate(value);
								handleChange(value, "endDate");
							}}
							id="endDate"
							register={register}
							errors={errors}
							name="endDate"
							type="date"
							size="sm"
							style={{ borderLeftStyle: "none" }}
							value={endDateCal}
						/>
					</div>
				</div>
				<div>
					<Text color="black">Процент скидки:</Text>
					<Input
						register={register}
						name="discountPercent"
						id="discountPercent"
						errors={errors}
						size="md"
					/>
				</div>
			</div>
			<Button type="submit">Редактировать</Button>
		</form>
	);
};

export default EditPromocode;
