import { Text } from "components/ui";
import { Button, Input } from "components/ui";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./InfoPromocode.module.scss";
import moment from "moment";


const InfoPromocode = ({ closeMenu, selectedPromoCode }) => {
	return (
		<div className={styles.infoPromoBlock}>
			<Text as="h3" color="blue">
				Основная информация:
			</Text>
			<div className={styles.infoPromoGroup}>
				<div>
					<span style={{ fontWeight: 500 }}>
						<strong>Имя:</strong> {selectedPromoCode.title}
					</span>
				</div>
				<div>
					<span style={{ fontWeight: 500 }}>
						<strong>Код промоакции:</strong> {selectedPromoCode.code}
					</span>
				</div>
				<div>
					<span style={{ fontWeight: 500 }}>
						<strong>Дата проведения: </strong>
						{moment(selectedPromoCode.startDate).format("DD.MM.YYYY")} -{" "}
						{moment(selectedPromoCode.endDate).format("DD.MM.YYYY")}
					</span>
				</div>
				<div>
					<span style={{ fontWeight: 500 }}>
						<strong>Процент скидки:</strong> {selectedPromoCode.discountPercent} %
					</span>
				</div>
			</div>
		</div>
	);
};

export default InfoPromocode;
