import PropTypes from "prop-types";
import { Text } from "components/ui";
import styles from "./form.module.sass";
import { useEffect, useState } from "react";

const FormError = ({ message, errorPos }) => {
	const [errorClass, setErrorClass] = useState(styles.errorMsg);

	useEffect(() => {
		switch (errorPos) {
			case "organizationLeft":
				return setErrorClass(styles.errorOrganizationLeft);

			case "left":
				return setErrorClass(styles.errorMsgLeft);

			case "store":
				return setErrorClass(styles.errorMsgStore);

			case "leftSmall":
				return setErrorClass(styles.errorMsgStoreLeftSmall);

			case "right":
				return setErrorClass(styles.errorMsgRight);

			case "checkBoxleft":
				return setErrorClass(styles.errorsCheckBoxLeft);
			case "checkBoxleft2":
				return setErrorClass(styles.errorsCheckBoxLeft2);

			case "storeRightTextArea":
				return setErrorClass(styles.errorsStoreTextAreaRight);

			case "timeLeft":
				return setErrorClass(styles.errorTimeLeft);

			case "contactError":
				return setErrorClass(styles.contactError);

			case "contactErrorTextarea":
				return setErrorClass(styles.contactErrorTextarea);

			case "contactErrorTextareaInfo":
				return setErrorClass(styles.contactErrorTextareaInfo);

			case "mainStorePhoneError":
				return setErrorClass(styles.mainStorePhoneError);

			case "mainStoreErrorTextarea":
				return setErrorClass(styles.mainStoreErrorTextarea);

			case "requsitesError":
				return setErrorClass(styles.requsitesError);

			default:
				setErrorClass(styles.errorMsg);
				break;
		}
	}, [errorClass]);
	return (
		<>
			{message && (
				<Text
					as="p"
					color="danger"
					className={`${styles.errorMsg} ${errorClass}`}
					style={{ lineHeight: "14px" }}
				>
					{message}
				</Text>
			)}
		</>
	);
};

FormError.propTypes = {
	message: PropTypes.string,
};

export default FormError;
