import React from "react";
import OrdersCreator from "./OrdersCreator";
import { useGetOrderQuery } from "redux/reducers/orders";

const ControlOrdersEdit = (props) => {
	const {
		data: orderData,
		isLoadung,
		isFetching,
	} = useGetOrderQuery({ orderId: props.orderId });

	console.log("ControlOrdersEdit", orderData);

	if (!orderData) {
		return null;
	}

	return <OrdersCreator {...props} orderData={orderData} />;
};

export default ControlOrdersEdit;
