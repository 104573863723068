export const getDate = (iso) => {
	let a =
		new Date(iso).getDate() < 10
			? `0${new Date(iso).getDate()}`
			: new Date(iso).getDate();
	let b =
		new Date(iso).getMonth() + 1 < 10
			? `0${new Date(iso).getMonth() + 1}`
			: new Date(iso).getMonth() + 1;
	let c = new Date(iso).getFullYear();
	return `${a}.${b}.${c}`;
};
