import styles from "./search-form.module.sass";
import { Text } from "../text";

export const ResultItem = ({ children, ...props }) => {
	return (
		<div className={styles["search-result__item"]} {...props}>
			<Text>{children}</Text>
		</div>
	);
};
