import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import {Header} from "components/layout/header";
import {ControlReviewsEdit, ControlReviewsInfo, FilterReviews,} from "components/reviews";
import {
	ControlMenu,
	Disclosure,
	Icon,
	Input,
	PageDescription,
	Pagination,
	Screen,
	SearchForm,
	Table,
	Td,
	Text,
	Tr,
} from "components/ui";
import {useDeleteReviewsMutation, useGetReviewsQuery} from "redux/reducers/reviews";

export default function Reviews() {
	const {t} = useTranslation(["reviews", "common"]);
	const [openedMenu, setOpenedMenu] = useState("");
	const [infoReview, setInfoReview] = useState({});
	const [editingReview, setEditingReview] = useState({});
	const [checkedReviews, setCheckedReviews] = useState([]);
	const [isOpenFilterMobile, setIsOpenFilterMobile] = useState(false);
	const defaultFilters = {time: "all", region: "Москва", status: "all"};
	const [filters, setFilters] = useState(defaultFilters);
	const {showMessage} = useMessage();
	const {deviceWidth} = useWindowSize();
	const isEditMode = openedMenu === "edit";
	const isInfoMode = openedMenu === "info";

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);


	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	const {data} = useGetReviewsQuery({
		limit: 15,
		page: currentPage,
		// status: "MODERATED"
		// period: "FOR WEEK" - "FOR MONTH"
		// cityId: string;
		// search: string;
		sortBy: "CREATED_ASC",
	});
	const [deleteReviews] = useDeleteReviewsMutation();

	const total = data?.total || 0;
	const reviews = data?.reviews || [];


	const closeMenu = () => {
		setOpenedMenu("");
		isEditMode && setEditingReview({});
		isInfoMode && setInfoReview({});
	};
	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenu("");
		isActive ? setOpenedMenu(type) : closeMenu();
	};
	const deleteReviewsItem = (id) => {
		// if (checkedReviews.length > 1) {
		deleteReviews({
			ids: id,
		});
		// }
	};
	const deleteAllReviewsItem = () => {
		if (checkedReviews.length > 1) {
			deleteReviews({
				ids: checkedReviews,
			});

			setCheckedReviews([]);
			showMessage({
				value: t("messages.itemsDelete", {
					item: t("messages.reviews"),
					ns: "common",
				}),
			});
		} else {
			// setReviews(reviews.filter((v) => v.id !== id));
			setCheckedReviews(checkedReviews.filter((v) => v !== id));
			showMessage({
				value: t("messages.itemDelete", {
					item: t("messages.review"),
					ns: "common",
				}),
			});
		}
	};
	const setInfoMode = (id) => {
		setInfoReview(reviews.find((v) => v._id === id));
		toggleOpenMenu(true, "info");
	};
	const setEditMode = (id) => {
		setEditingReview(reviews.find((v) => v._id === id));
		toggleOpenMenu(true, "edit");
	};
	const setChecked = (checked, id) => {
		if (checked && !checkedReviews.includes(id)) {
			setCheckedReviews([...checkedReviews, id]);
		} else {
			setCheckedReviews(checkedReviews.filter((v) => v !== id));
		}
	};
	const setAllChecked = (checked) => {
		if (checked) {
			setCheckedReviews(reviews.map((rev) => rev._id));
			// const notCheckedReviews = reviews.filter(
			// 	(v) => !checkedReviews.includes(v._id)
			// );
			// setCheckedReviews([
			// 	...checkedReviews,
			// 	...notCheckedReviews.map((v) => v._id),
			// ]);
		} else {
			setCheckedReviews([]);
		}
	};
	const onChangeFilter = (value, filter) => {
		setFilters({...filters, [filter]: value,});
	};
	const clearFilters = () => {
		setFilters(defaultFilters);
	};
	const MobileFilter = () => (
		<Screen size="xl" down>
			<Icon
				name="filters"
				size="md"
				hoverColor="gray"
				onClick={() => setIsOpenFilterMobile(true)}
			/>
		</Screen>
	);
	const afterSuccessEditing = async (data) => {
		//change after connect to db
		// setReviews(
		// 	reviews.map((review) =>
		// 		review.id === data.id ? Object.assign({}, data) : review
		// 	)
		// );
		showMessage({value: t("messages.dataUpdated", {ns: "common"})});
	};


	const reviewsPublished = reviews.filter((review) => review.status === "PUBLISHED")
	// const reviewsAll = reviews.filter((review) => review.status === "all")
	const reviewsModer = reviews.filter((review) => review.status === "MODERATED")


	return (
		<>
			<Header options={<MobileFilter/>}/>
			<main>
				<Screen size="xl" down>
					<SearchForm.Section sectionFirst>
						<SearchForm placeholder={t("searchPlaceholder")}/>
					</SearchForm.Section>
				</Screen>

				<PageDescription.Section>
					<div style={{display: "flex", alignItems: "center"}}>
						<PageDescription title={t("title")}/>
						<Screen size="xl">
							<Icon
								name="question-border"
								size="md"
								title={
									"Данный раздел предназначен для работы с отзывами покупателей вашего интернет-магазина. Мнение клиентов поможет вам улучшить сервис и сделать клиентоориентированный ассортимент. Перед публикацией отзывы нуждаются в вашей модерации: те, которые содержат нецензурные выражения и не соответствуют действительности, к публикации не допускаются."
								}
								placementCustomTitle="right"
								customTitle
								style={{marginTop: "-10px", marginLeft: "8px"}}
							/>
						</Screen>
					</div>
				</PageDescription.Section>

				<Screen size="xl">
					<SearchForm.Section>
						<SearchForm placeholder={t("searchPlaceholder")}/>
					</SearchForm.Section>
				</Screen>

				<section className="section">

					<div className="container">

						<div className="row">
							<Screen size="xl">
								<div className="col-xl-3 col-xxxl-2">
									<FilterReviews
										filters={filters}
										onChange={onChangeFilter}
										clear={clearFilters}
									/>
								</div>
							</Screen>
							{filters.region === "Москва" && filters.status === "all" && reviews?.length && (
								<div className="col-xl-9 col-xxxl-10">
									<Table>
										<Table.Head>
											<Td width={35}>
												<Input
													type="checkbox"
													name="all"
													onChange={(e) => setAllChecked(e.target.checked)}
													checked={reviews.length === checkedReviews.length}
												/>
											</Td>
											<Td width={50}>№</Td>
											<Td width={300}>{t("table.client")}</Td>
											<Td width={210}>{t("table.region")}</Td>
											<Td width={210}>{t("table.created")}</Td>
											<Td width={210}>{t("table.phone")}</Td>
											<Td width={210}>{t("table.status")}</Td>
											<Td width={deviceWidth === "large" ? 37 : 44}/>
											<Td width={deviceWidth === "large" ? 0 : 44}/>
										</Table.Head>
										<Table.Body>
											{reviews?.map((review, i) => {
												const colors = {
													public: "#B3DE75",
													moder: "#78C2DD",
													fail: "#EB2316",
													waiting: "#969696",
												};
												const bgs = {
													public: "rgba(179, 222, 117, 0.2)",
													moder: "rgba(120, 194, 221, 0.2)",
													fail: "rgba(235, 35, 22, 0.2)",
													waiting: "white",
												};

												return (
													<Tr key={i}>
														<Td width={35}>
															<Input
																type="checkbox"
																name={review?.reviewNumber.toString()}
																onChange={(e) =>
																	setChecked(e.target.checked, review._id)
																}
																checked={checkedReviews.includes(review._id)}
															/>
														</Td>
														<Td width={50}>{review?.reviewNumber}</Td>
														<Td width={300} overflow>
															{`${review?.buyer?.firstName} ${review?.buyer?.lastName}`}
														</Td>
														<Td width={210} overflow>
															{review?.buyer?.city?.title}
														</Td>
														<Td width={210}>
															{review?.createdAt
																.slice(0, 10)
																.split("-")
																.reverse()
																.join(".")}
														</Td>
														<Td width={210}>{review?.buyer?.phone}</Td>
														<Td
															width={130}
															style={{
																border: `1px solid ${colors[review?.status]}`,
																marginLeft: "38px",
																marginRight: "8px",
																borderRadius: "4px",
																background: `${bgs[review?.status]}`,
															}}
														>
															{review.status
																? t("statuses." + review?.status)
																: ""}
														</Td>

														<Td
															width={deviceWidth === "large" ? 105 : 44}
															buttons={deviceWidth === "large"}
															ellipsis={deviceWidth !== "large"}
														>
															<Screen size="xl" down>
																<Disclosure>
																	<Text
																		as="p"
																		onClick={() => setInfoMode(review?._id)}
																	>
																		{t("buttonDesc.info2", {
																			value: t("review2"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setEditMode(review?._id)}
																	>
																		{t("buttonDesc.edit", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() =>
																			deleteReviewsItem(review?._id)
																		}
																	>
																		{t("buttonDesc.delete", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																</Disclosure>
															</Screen>
															<Screen size="xl">
																<Icon
																	name="info-border"
																	size="md"
																	onClick={() => setInfoMode(review?._id)}
																	title={t("buttonDesc.view", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
																<Icon
																	name="edit"
																	size="md"
																	turnOff={editingReview?._id !== review?._id}
																	onClick={() => setEditMode(review?._id)}
																	title={t("buttonDesc.edit", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
															</Screen>
														</Td>
													</Tr>
												);
											})}
										</Table.Body>
									</Table>
									<Pagination
										//key={currentPage}
										current={currentPage}
										pageSize={itemsPerPage}
										total={total}
										onChange={handlePageChange}
										mobile={deviceWidth !== "large"}
									/>
								</div>
							)}

							{filters.region === "Москва" && !filters.status && reviews?.length && (
								<div className="col-xl-9 col-xxxl-10">
									<Table>
										<Table.Head>
											<Td width={35}>
												<Input
													type="checkbox"
													name="all"
													onChange={(e) => setAllChecked(e.target.checked)}
													checked={reviews.length === checkedReviews.length}
												/>
											</Td>
											<Td width={50}>№</Td>
											<Td width={300}>{t("table.client")}</Td>
											<Td width={210}>{t("table.region")}</Td>
											<Td width={210}>{t("table.created")}</Td>
											<Td width={210}>{t("table.phone")}</Td>
											<Td width={210}>{t("table.status")}</Td>
											<Td width={deviceWidth === "large" ? 37 : 44}/>
											<Td width={deviceWidth === "large" ? 0 : 44}/>
										</Table.Head>
										<Table.Body>
											{reviews?.map((review, i) => {
												const colors = {
													public: "#B3DE75",
													moder: "#78C2DD",
													fail: "#EB2316",
													waiting: "#969696",
												};
												const bgs = {
													public: "rgba(179, 222, 117, 0.2)",
													moder: "rgba(120, 194, 221, 0.2)",
													fail: "rgba(235, 35, 22, 0.2)",
													waiting: "white",
												};

												return (
													<Tr key={i}>
														<Td width={35}>
															<Input
																type="checkbox"
																name={review?.reviewNumber.toString()}
																onChange={(e) =>
																	setChecked(e.target.checked, review._id)
																}
																checked={checkedReviews.includes(review._id)}
															/>
														</Td>
														<Td width={50}>{review?.reviewNumber}</Td>
														<Td width={300} overflow>
															{`${review?.buyer?.firstName} ${review?.buyer?.lastName}`}
														</Td>
														<Td width={210} overflow>
															{review?.buyer?.city?.title}
														</Td>
														<Td width={210}>
															{review?.createdAt
																.slice(0, 10)
																.split("-")
																.reverse()
																.join(".")}
														</Td>
														<Td width={210}>{review?.buyer?.phone}</Td>
														<Td
															width={130}
															style={{
																border: `1px solid ${colors[review?.status]}`,
																marginLeft: "38px",
																marginRight: "8px",
																borderRadius: "4px",
																background: `${bgs[review?.status]}`,
															}}
														>
															{review.status
																? t("statuses." + review?.status)
																: ""}
														</Td>

														<Td
															width={deviceWidth === "large" ? 105 : 44}
															buttons={deviceWidth === "large"}
															ellipsis={deviceWidth !== "large"}
														>
															<Screen size="xl" down>
																<Disclosure>
																	<Text
																		as="p"
																		onClick={() => setInfoMode(review?._id)}
																	>
																		{t("buttonDesc.info2", {
																			value: t("review2"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setEditMode(review?._id)}
																	>
																		{t("buttonDesc.edit", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() =>
																			deleteReviewsItem(review?._id)
																		}
																	>
																		{t("buttonDesc.delete", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																</Disclosure>
															</Screen>
															<Screen size="xl">
																<Icon
																	name="info-border"
																	size="md"
																	onClick={() => setInfoMode(review?._id)}
																	title={t("buttonDesc.view", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
																<Icon
																	name="edit"
																	size="md"
																	turnOff={editingReview?._id !== review?._id}
																	onClick={() => setEditMode(review?._id)}
																	title={t("buttonDesc.edit", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
															</Screen>
														</Td>
													</Tr>
												);
											})}
										</Table.Body>
									</Table>
									<Pagination
										//key={currentPage}
										current={currentPage}
										pageSize={itemsPerPage}
										total={total}
										onChange={handlePageChange}
										mobile={deviceWidth !== "large"}
									/>
								</div>
							)}


							{filters.status === "moder" && reviewsModer.length && (
								<div className="col-xl-9 col-xxxl-10">
									<Table>
										<Table.Head>
											<Td width={35}>
												<Input
													type="checkbox"
													name="all"
													onChange={(e) => setAllChecked(e.target.checked)}
													checked={reviewsModer.length === checkedReviews.length}
												/>
											</Td>
											<Td width={50}>№</Td>
											<Td width={300}>{t("table.client")}</Td>
											<Td width={210}>{t("table.region")}</Td>
											<Td width={210}>{t("table.created")}</Td>
											<Td width={210}>{t("table.phone")}</Td>
											<Td width={210}>{t("table.status")}</Td>
											<Td width={deviceWidth === "large" ? 37 : 44}/>
											<Td width={deviceWidth === "large" ? 0 : 44}/>
										</Table.Head>
										<Table.Body>
											{reviewsModer?.map((item, i) => {
												const colors = {
													public: "#B3DE75",
													moder: "#78C2DD",
													fail: "#EB2316",
													waiting: "#969696",
												};
												const bgs = {
													public: "rgba(179, 222, 117, 0.2)",
													moder: "rgba(120, 194, 221, 0.2)",
													fail: "rgba(235, 35, 22, 0.2)",
													waiting: "white",
												};

												return (
													<Tr key={i}>
														<Td width={35}>
															<Input
																type="checkbox"
																name={item?.reviewNumber.toString()}
																onChange={(e) =>
																	setChecked(e.target.checked, item._id)
																}
																checked={checkedReviews.includes(item._id)}
															/>
														</Td>
														<Td width={50}>{item?.reviewNumber}</Td>
														<Td width={300} overflow>
															{`${item?.buyer?.firstName} ${item?.buyer?.lastName}`}
														</Td>
														<Td width={210} overflow>
															{item?.buyer?.city?.title}
														</Td>
														<Td width={210}>
															{item?.createdAt
																.slice(0, 10)
																.split("-")
																.reverse()
																.join(".")}
														</Td>
														<Td width={210}>{item?.buyer?.phone}</Td>
														<Td
															width={130}
															style={{
																border: `1px solid ${colors[item?.status]}`,
																marginLeft: "38px",
																marginRight: "8px",
																borderRadius: "4px",
																background: `${bgs[item?.status]}`,
															}}
														>
															{item.status
																? t("statuses." + item?.status)
																: ""}
														</Td>

														<Td
															width={deviceWidth === "large" ? 105 : 44}
															buttons={deviceWidth === "large"}
															ellipsis={deviceWidth !== "large"}
														>
															<Screen size="xl" down>
																<Disclosure>
																	<Text
																		as="p"
																		onClick={() => setInfoMode(item?._id)}
																	>
																		{t("buttonDesc.info2", {
																			value: t("review2"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setEditMode(item?._id)}
																	>
																		{t("buttonDesc.edit", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() =>
																			deleteReviewsItem(item?._id)
																		}
																	>
																		{t("buttonDesc.delete", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																</Disclosure>
															</Screen>
															<Screen size="xl">
																<Icon
																	name="info-border"
																	size="md"
																	onClick={() => setInfoMode(item?._id)}
																	title={t("buttonDesc.view", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
																<Icon
																	name="edit"
																	size="md"
																	turnOff={editingReview?._id !== item?._id}
																	onClick={() => setEditMode(item?._id)}
																	title={t("buttonDesc.edit", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
															</Screen>
														</Td>
													</Tr>
												);
											})}
										</Table.Body>
									</Table>
									<Pagination
										//key={currentPage}
										current={currentPage}
										pageSize={itemsPerPage}
										total={total}
										onChange={handlePageChange}
										mobile={deviceWidth !== "large"}
									/>
								</div>
							)}

							{filters.status === "public" && reviewsPublished.length && (
								<div className="col-xl-9 col-xxxl-10">
									<Table>
										<Table.Head>
											<Td width={35}>
												<Input
													type="checkbox"
													name="all"
													onChange={(e) => setAllChecked(e.target.checked)}
													checked={reviewsPublished.length === checkedReviews.length}
												/>
											</Td>
											<Td width={50}>№</Td>
											<Td width={300}>{t("table.client")}</Td>
											<Td width={210}>{t("table.region")}</Td>
											<Td width={210}>{t("table.created")}</Td>
											<Td width={210}>{t("table.phone")}</Td>
											<Td width={210}>{t("table.status")}</Td>
											<Td width={deviceWidth === "large" ? 37 : 44}/>
											<Td width={deviceWidth === "large" ? 0 : 44}/>
										</Table.Head>
										<Table.Body>
											{reviewsPublished?.map((item, i) => {
												const colors = {
													public: "#B3DE75",
													moder: "#78C2DD",
													fail: "#EB2316",
													waiting: "#969696",
												};
												const bgs = {
													public: "rgba(179, 222, 117, 0.2)",
													moder: "rgba(120, 194, 221, 0.2)",
													fail: "rgba(235, 35, 22, 0.2)",
													waiting: "white",
												};

												return (
													<Tr key={i}>
														<Td width={35}>
															<Input
																type="checkbox"
																name={item?.reviewNumber.toString()}
																onChange={(e) =>
																	setChecked(e.target.checked, item._id)
																}
																checked={checkedReviews.includes(item._id)}
															/>
														</Td>
														<Td width={50}>{item?.reviewNumber}</Td>
														<Td width={300} overflow>
															{`${item?.buyer?.firstName} ${item?.buyer?.lastName}`}
														</Td>
														<Td width={210} overflow>
															{item?.buyer?.city?.title}
														</Td>
														<Td width={210}>
															{item?.createdAt
																.slice(0, 10)
																.split("-")
																.reverse()
																.join(".")}
														</Td>
														<Td width={210}>{item?.buyer?.phone}</Td>
														<Td
															width={130}
															style={{
																border: `1px solid ${colors[item?.status]}`,
																marginLeft: "38px",
																marginRight: "8px",
																borderRadius: "4px",
																background: `${bgs[item?.status]}`,
															}}
														>
															{item.status
																? t("statuses." + item?.status)
																: ""}
														</Td>

														<Td
															width={deviceWidth === "large" ? 105 : 44}
															buttons={deviceWidth === "large"}
															ellipsis={deviceWidth !== "large"}
														>
															<Screen size="xl" down>
																<Disclosure>
																	<Text
																		as="p"
																		onClick={() => setInfoMode(item?._id)}
																	>
																		{t("buttonDesc.info2", {
																			value: t("review2"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() => setEditMode(item?._id)}
																	>
																		{t("buttonDesc.edit", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																	<Text
																		as="p"
																		onClick={() =>
																			deleteReviewsItem(item?._id)
																		}
																	>
																		{t("buttonDesc.delete", {
																			value: t("review"),
																			ns: "common",
																		})}
																	</Text>
																</Disclosure>
															</Screen>
															<Screen size="xl">
																<Icon
																	name="info-border"
																	size="md"
																	onClick={() => setInfoMode(item?._id)}
																	title={t("buttonDesc.view", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
																<Icon
																	name="edit"
																	size="md"
																	turnOff={editingReview?._id !== item?._id}
																	onClick={() => setEditMode(item?._id)}
																	title={t("buttonDesc.edit", {
																		value: "",
																		ns: "common",
																	})}
																	style={{marginRight: 10}}
																	cursorPointer
																/>
															</Screen>
														</Td>
													</Tr>
												);
											})}
										</Table.Body>
									</Table>
									<Pagination
										//key={currentPage}
										current={currentPage}
										pageSize={itemsPerPage}
										total={total}
										onChange={handlePageChange}
										mobile={deviceWidth !== "large"}
									/>
								</div>
							)}
						</div>




					</div>
				</section>
			</main>

			{isInfoMode && (
				<ControlReviewsInfo
					reviewId={infoReview._id}
					isOpen={isInfoMode}
					onClose={closeMenu}
				/>
			)}
			{isEditMode && (
				<ControlReviewsEdit
					reviewId={editingReview._id}
					isOpen={isEditMode}
					onClose={closeMenu}
					afterSuccessChanges={afterSuccessEditing}
				/>
			)}

			<ControlMenu
				isOpen={isOpenFilterMobile}
				onClose={() => setIsOpenFilterMobile(false)}
				observerChanges={false}
				title={t("filters.mobile.title")}
			>
				<Screen size="xl" down>
					{/* <FilterReviews
						filters={filters}
						// onChange={onChangeFilter}
						// clear={clearFilters}
						mobile={true}
					/> */}
				</Screen>
			</ControlMenu>
		</>
	);
}
