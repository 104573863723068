import { $api } from "api";

class Product {
	async getAllTemplateProducts(searchText) {
		const url = searchText
			? `/searchTemplateProducts?searchText=${searchText}`
			: "/searchTemplateProducts";
		const res = await $api.get(url);
		return res;
	}

	async getAllProducts(searchText) {
		const url = searchText
			? `/searchProducts?searchText=${searchText}`
			: "/searchProducts";
		const res = await $api.get(url);
		return res;
	}

	async createProductFromTemplate(payload) {
		const url = "/createProductFromTemplate";

		const res = await $api.post(url, payload);
		return res;
	}

	async createNewProduct(payload) {
		const url = "/createProduct";

		const res = await $api.post(url, payload);
		return res;
	}

	async editProduct(payload) {
		const url = "/editProduct";

		const res = await $api.post(url, payload);
		return res;
	}
}

export default new Product();
