import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { Disclosure, Icon, Screen, Text, Img } from "components/ui";
import styles from "./catalog.module.sass";

const Category = ({
	category,
	category: { _id, title, iconImageUrl, popular, visible },
	handlerPopular,
	handlerVisible,
	editCategory,
	deleteCategory,
	className,
	onClick,
	categoryIds,
	setCategoryIds,
}) => {
	const { t } = useTranslation(["catalog"]);
	const { deviceWidth } = useWindowSize();

	//const navigate = useNavigate();
	const classNames = cn(styles.category, className);

	/*const navigateCategory = (id) => {
		setCategoryIds([...categoryIds, id]);
	};*/

	return (
		<div className={classNames}>
			{iconImageUrl && (
				<Img src={iconImageUrl} title={title} height="24px" width="24px" />
			)}
			<Text
				data-id={_id}
				size={deviceWidth === "large" ? "sm" : "xs"}
				overflow="ellipsis"
				onClick={onClick}
				className={styles["category__title"]}
			>
				{title}
			</Text>
			<div className={styles["category__controls"]}>
				<Screen size="xl" down>
					<Disclosure>
						<Text as="p" onClick={() => editCategory(category)}>
							{t("buttonDesc.editCategory")}
						</Text>
						<Text as="p" onClick={() => handlerPopular(_id, !popular)}>
							{popular
								? t("buttonDesc.deletePopular")
								: t("buttonDesc.addPopular")}
						</Text>
						<Text as="p" onClick={() => handlerVisible(_id, !visible)}>
							{visible
								? t("buttonDesc.visibleCategory")
								: t("buttonDesc.showCategory")}
						</Text>
						<Text as="p" onClick={() => deleteCategory(_id)}>
							{t("buttonDesc.deleteCategory")}
						</Text>
					</Disclosure>
				</Screen>
				<Screen size="xl">
					<Icon data-id={_id} name="arrow-right" size="md" onClick={onClick} />
					<Icon
						name="star"
						size="md"
						color={popular ? "blue" : "gray-light"}
						onClick={() => handlerPopular(_id, !popular)}
					/>
					<Icon
						name="eye"
						size="md"
						turnOff={!visible}
						onClick={() => handlerVisible(_id, !visible)}
					/>
					<Icon name="edit" size="md" onClick={() => editCategory(category)} />
					<Icon
						name="delete"
						size="md"
						color="red"
						onClick={() => deleteCategory(_id)}
					/>
				</Screen>
			</div>
		</div>
	);
};

/*Category.propTypes = {
	category: PropTypes.shape({}).isRequired,
	handlerPopular: PropTypes.func.isRequired,
	handlerVisible: PropTypes.func.isRequired,
	editCategory: PropTypes.func.isRequired,
	deleteCategory: PropTypes.func.isRequired,
	className: PropTypes.string,
};*/

export default Category;
