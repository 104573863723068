import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import CategorySection from "./CategorySection";
import styles from "../../catalog.module.sass";

const CategoryTree = ({
	className,
	searchText,
	onChange,
	parentCategoryId,
}) => {
	const [hoverDepth, setHoverDepth] = useState(0);
	const { deviceWidth } = useWindowSize();

	return (
		<div className={className}>
			<div
				className={cn("row", "row-sm", {
					[styles["category-tree-mobile"]]: deviceWidth !== "large",
				})}
			>
				<CategorySection
					parentCategoryId={parentCategoryId}
					searchText={searchText}
					onChange={onChange}
					depth={0}
					hoverDepth={hoverDepth}
					setHoverDepth={setHoverDepth}
				/>
			</div>
		</div>
	);
};

CategoryTree.propTypes = {
	className: PropTypes.string,
	categories: PropTypes.array.isRequired,
};

export default CategoryTree;
