export const splitString = (string, separator = ' ', spaceOn = 3) => {
	if (typeof string === 'number') {
		string = string.toString()
	}
	if (typeof string !== 'number' && typeof string !== 'string') return null
	if (!string.length) return null

	const length = string.toString().length
	const splitString = string.toString().split('')
	const stringWithSeparator = splitString.reduceRight((acc, char, i) => {
		const separatorOrNothing = ((((length - i) % spaceOn) === 0) ? separator : '')
		return separatorOrNothing + char + acc
	}, '')

	return (stringWithSeparator[0] === separator) ? stringWithSeparator.slice(1) : stringWithSeparator
}
