import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

const ActiveLink = ({
	children,
	to,
	className,
	activeClassName,
	nav,
	...props
}) => {
	const location = useLocation();

	const setActive = ({ isActive }) =>
		isActive || location.pathname.split("/")[1] === nav
			? `${className} ${activeClassName}`
			: `${className}`;

	return (
		<NavLink to={to} className={setActive} {...props}>
			{children}
		</NavLink>
	);
};

ActiveLink.propTypes = {
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	activeClassName: PropTypes.string,
	nav: PropTypes.string,
	target: PropTypes.string,
};

export default ActiveLink;
