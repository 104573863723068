import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import cn from "classnames";
import useWindowSize from "hooks/useWindowSize";
import {
	Text,
	Button,
	Input,
	Icon,
	Select,
	Screen,
	TreeSelect,
} from "components/ui";
import styles from "../catalog.module.sass";
import { useGetCategoriesTreeQuery } from "../../../redux/reducers/category";
import get from "lodash/get";
import { useCreateProductsFromTemplateMutation } from "../../../redux/reducers/product";

const ControlProductAddForm = ({
	onSaveAddedProducts,
	products,
	goBack,
	deleteCheckedProduct,
}) => {
	const { t } = useTranslation(["catalog", "common"]);

	const defaultValues = useMemo(
		() => ({
			products: products.map((product) => ({
				product,
				patch: {
					price: product.price,
					NDS: product.NDS,
					category: null,
				},
			})),
		}),
		[products]
	);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, dirtyFields },
		setValue,
		getValues,
		reset,
	} = useForm({
		defaultValues,
		mode: "onSubmit",
		reValidateMode: "onChange",
	});

	const addingProducts = useFieldArray({
		control,
		name: "products",
	});

	const { deviceWidth } = useWindowSize();
	const allowedVat = ["taxed", "notTaxed"];

	const [createProductsFromTemplate] = useCreateProductsFromTemplateMutation();

	const onSubmit = async (data) => {
		//return console.log("submitData", submitData);
		const productTemplates = data.products.map((addingProduct, index) => {
			const result = {
				_id: addingProduct.product._id,
				patch: {},
			};

			if (get(dirtyFields, `products[${index}].patch.price`))
				result.patch.price = addingProduct.patch.price;

			if (get(dirtyFields, `products[${index}].patch.NDS`))
				result.patch.NDS = addingProduct.patch.NDS;

			if (get(dirtyFields, `products[${index}].patch.category`))
				result.patch.category = addingProduct.patch.category;

			return result;
		});

		const response = await createProductsFromTemplate({
			productTemplates,
		});
		//await dispatch(createProductFromTemplate({ productTemplates: submitData }));
		//dispatch(refreshProductsAction(!productsUpdated));
		if (!response.error) onSaveAddedProducts(data);
	};

	const handleDeleteCheckedProduct = (event) => {
		const index = Number(event.currentTarget.dataset.index);
		const product = addingProducts.fields[index].product;

		addingProducts.remove(index);

		deleteCheckedProduct(product);
	};

	const getCategoriesTreeQuery = useGetCategoriesTreeQuery({ title: 1 });
	const categoriesData = getCategoriesTreeQuery.data?.categoriesTree || [];

	return (
		<div
			className={cn(
				styles["product-config"],
				deviceWidth === "large" ? "offset-top-20" : "offset-top-10"
			)}
		>
			<div className={styles["product-config__back"]} onClick={goBack}>
				<Icon name="arrow-back" size="md" color="gray" />
				<Text size="sm" color="gray" style={{ marginLeft: 5 }}>
					{t("drawer.addProduct.back")}
				</Text>
			</div>
			<Text
				as="p"
				size={deviceWidth === "large" ? "lg" : "sm"}
				className={deviceWidth === "large" ? "offset-top-20" : "offset-top-10"}
			>
				{t("drawer.addProduct.selectedProduct")} {addingProducts.fields.length}
			</Text>
			<div className="offset-top-10">
				<form onSubmit={handleSubmit(onSubmit)}>
					{addingProducts.fields.map((addingProduct, index) => (
						<div
							key={addingProduct.id}
							className={styles["product-config__product"]}
						>
							<div className={styles["found-product"]}>
								<Screen size="xl">
									<div className={styles["found-product__photo"]}>
										<img src={addingProduct.product.imageUrls[0]} alt="" />
									</div>
									<div
										style={{ alignSelf: "center" }}
										className={styles["found-product__info"]}
									>
										<Text as="p" size="sm">
											{addingProduct.product.title}
										</Text>
									</div>
								</Screen>
								<Screen size="xl" down>
									<div className={styles["found-product__info"]}>
										<Text as="p" size="sm">
											{addingProduct.product.title}
										</Text>
										<div className={styles["product-config__info-mobile"]}>
											<div className={styles["found-product__photo"]}>
												<img src={addingProduct.product.imageUrls[0]} alt="" />
											</div>
										</div>
									</div>
								</Screen>
								<div className={styles["found-product__check"]}>
									<Icon
										data-index={index}
										name="delete"
										color="red"
										size="md"
										onClick={handleDeleteCheckedProduct}
										cursorPointer
									/>
								</div>
							</div>
							<hr />
							<div className="offset-top-10">
								<div className="row row-10">
									<div className="col-md-4">
										<Input
											//defaultValue={addingProduct.price}
											name={`products.[${index}].patch.price`}
											register={register}
											rules={{
												required: {
													value: true,
													message: t("validations.required", {
														ns: "common",
													}),
												},
												valueAsNumber: true,
											}}
											label={t("drawer.addProduct.price")}
											placeholder={t("drawer.addProduct.enterPrice")}
											errors={errors}
											fluid={true}
											type="number"
											labelSize={deviceWidth === "large" ? "lg" : "sm"}
										/>
									</div>
									<div className="col-md-4">
										<Controller
											control={control}
											name={`products.[${index}].patch.NDS`}
											render={({ field: { value, name } }) => (
												<Select
													name={name}
													value={value ? allowedVat[0] : allowedVat[1]}
													onChange={(value, option) => {
														setValue(name, value === allowedVat[0], {
															shouldDirty: true,
															shouldValidate: true,
														});
													}}
													label={t("drawer.addProduct.vat")}
													labelSize={deviceWidth === "large" ? "lg" : "sm"}
													errors={errors}
													fluid
												>
													{allowedVat.map((vat) => (
														<Select.Option key={vat} value={vat}>
															{t("drawer.addProduct.allowedVat." + vat)}
														</Select.Option>
													))}
												</Select>
											)}
										/>
									</div>
									<div className="col-md-4">
										<Controller
											control={control}
											name={`products.[${index}].patch.category`}
											rules={{
												required: {
													value: true,
													message: t("validations.required", {
														ns: "common",
													}),
												},
											}}
											render={({ field: { value, name } }) => (
												<TreeSelect
													//key={JSON.stringify(categoriesData)}
													//onSearch={debouncedFunction}
													//showSearch={true}
													treeData={categoriesData}
													/*treeData={[
														{
															title: "test1",
															value: "test1",
															children: [
																{ title: "test1.1", value: "test1.2" },
															],
															disabled: true,
														},
													]}*/
													placeholder="Выберите категории"
													//showSearch={false}
													treeDefaultExpandAll={true}
													value={value}
													name={name}
													label={t("drawer.addProduct.category")}
													labelSize={deviceWidth === "large" ? "lg" : "sm"}
													errors={errors}
													fluid
													notFoundContent={null}
													onChange={(value) => {
														setValue(name, value, {
															shouldDirty: true,
															shouldValidate: true,
														});
													}}
													/*
													//value={value}
													//notFoundContent={null}
													//filterOption={false}
													//fluid
													onChange={(value) =>
														setValue(name, value, {
															shouldDirty: true,
															shouldValidate: true,
														})
													}*/
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
					{!!addingProducts.fields.length && (
						<Button type="submit">{t("buttons.save", { ns: "common" })}</Button>
					)}
				</form>
			</div>
		</div>
	);
};

export default ControlProductAddForm;
