import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
	.use(initReactI18next)
	.init({
		fallbackLng: ['ru', 'en'],
		lng: localStorage.getItem('locale') || 'ru',
		resources: {
			ru: {
				common: require('locales/ru/common.json'),
				auth: require('locales/ru/auth.json'),
				dashboard: require('locales/ru/dashboard.json'),
				orders: require('locales/ru/orders.json'),
				clients: require('locales/ru/clients.json'),
				catalog: require('locales/ru/catalog.json'),
				reviews: require('locales/ru/reviews.json'),
				banners: require('locales/ru/banners.json'),
				hints: require('locales/ru/hints.json'),
				settings: require('locales/ru/settings.json'),
				seo: require('locales/ru/seo.json'),
				payment: require('locales/ru/payment.json'),
				delivery: require('locales/ru/delivery.json'),
				rate: require('locales/ru/rate.json'),
				preview: require('locales/ru/preview.json')
			},
			en: {
				common: require('locales/en/common.json'),
				auth: require('locales/en/auth.json'),
				dashboard: require('locales/en/dashboard.json'),
				orders: require('locales/en/orders.json'),
				clients: require('locales/en/clients.json'),
				catalog: require('locales/en/catalog.json'),
				reviews: require('locales/en/reviews.json'),
				banners: require('locales/en/banners.json'),
				hints: require('locales/en/hints.json'),
				settings: require('locales/en/settings.json'),
				seo: require('locales/en/seo.json'),
				payment: require('locales/en/payment.json'),
				delivery: require('locales/en/delivery.json'),
				rate: require('locales/en/rate.json'),
				preview: require('locales/en/preview.json')
			}
		}
	})

export default i18n
