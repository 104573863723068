import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, LocaleSwitcher } from 'components/ui'
import logo from 'assets/logo.svg'
import logoRoboweb from 'assets/main-logo.svg'
import styles from './footer.module.sass'
import { useLocation } from 'react-router-dom'

export default function Footer() {
	const { t } = useTranslation(['common'])
	const location = useLocation()
	console.log('location', location.pathname)
	return (
		<footer className={styles.footer} style={{marginTop: location.pathname === '/banners/yourbanners' && '100px'}}>
			<div className='container'>
				<div className={styles['footer-wrapper']}>
					<div className={styles.left}>
						<img src={logo} alt='logo-zumzak' style={{width: '70px'}}/>
						<Text size='xs' align='start'>{t('footer.text1')}</Text>
					</div>
					<div className={styles.center}>
						<a
							href="/privacy-policy"
							target="_blank">
							{t('footer.text2')}
						</a>
						<a
							href="/terms-of-use"
							target="_blank">
							{t('footer.text3')}
						</a>
						<a
							href="/notice-disclaimere"
							target="_blank">
							{t('footer.text4')}
						</a>
						{/*<Text size='xs'>{t('footer.text3')}</Text>*/}
						{/*<Text size='xs'>{t('footer.text4')}</Text>*/}
					</div>
					<div className={styles.right}>
						<div className={styles['created-by']}>
							<Text size='xs'>{t('footer.text5')}</Text>
							<img src={logoRoboweb} alt='logo-roboweb' />

						</div>
						<div className={styles['locale-switcher']}>
							<LocaleSwitcher />
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
