// import {Input} from "components/ui";
// import {Textarea} from "components/ui";
import {Button} from "components/ui";
import {Text} from "components/ui";
// import Select from "rc-select";
import {useForm} from "react-hook-form";
import styles from '../hints.module.sass'
// import {useEffect} from "react";
import {$api} from "../../../api";
import {Input, InputTextArea} from './Elements'


export default function AskQuestion({hints, setHints, onClose})  {

	const {
		register,
		handleSubmit,
		reset,
	} = useForm()

	const onSubmit = async (formData) => {
		// console.log(formData);
		try {
			const {question, textArea} = formData
			const res = await $api.post("/hints", {
				question: question, textArea: textArea
			})
			onClose();
			reset();
			return res;

		} catch (error) {
			console.error('Ошибка:', error);
			onClose();
			reset();
		}

	}

	return (<div className={styles['ask-question-container']}>
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className='col-xl-6'>
				<Text>Напишите Вопрос </Text>

				<Input placeholder={'Напишите ваш вопрос'}
							 required={true}

							 {...register('question')}
				/>
			</div>
			<div className='col-12'>

				<Text>Описание проблемы</Text>
				<InputTextArea placeholder="Опишите вашу проблему"
											 required={true}
											 {...register('textArea')}
				>
				</InputTextArea>
			</div>
			<div className='col-xl-6'>

				<Text>Файлы</Text>

				<Input isFile={true} type={'file'}
							 {...register('fileInput')}
							 placeholder="Загрузите файлы"
				/>

			</div>
			<div className={styles.askBtn}>
				<Button type={'submit'}>Отправить</Button>
			</div>
		</form>
	</div>)
}
