import { $api } from "api";
import axios from "axios";

class Region {
	async getCountries() {
		const res = await $api.get("/getCountries");
		return res;
	}

	async getCities() {
		const res = await $api.get("/searchCity?searchText");
		return res;
	}

	async getCitiesAvailableForSearch() {
		const res = await $api.get("/getCitiesAvailableForSearch");
		return res;
	}

	async getCitiesAvailableForSearchOrders() {
		const res = await $api.get("/getCitiesAvailableForSearchOrders");
		return res;
	}
}

export default new Region();
