import { baseApi } from "api";

const apiWithPaymentTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Payment"],
});

export const paymentsApi = apiWithPaymentTags.injectEndpoints({
	endpoints: (builder) => ({
		getPayments: builder.query({
			query: (params) => ({
				url: "/getPayments",
				params,
			}),
			providesTags: ["Payment"],
		}),
		editPayments: builder.mutation({
			query: (data) => ({
				url: "/editPayments",
				data,
				method: "POST",
			}),
			invalidatesTags: ["Payment"],
		}),
	}),
});

export const { useGetPaymentsQuery, useEditPaymentsMutation } = paymentsApi;
