import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Products } from 'components/catalog'
import { defaultProducts } from 'fakeData/products'

export default function Discount() {
	const { t } = useTranslation(['catalog'])
	const [products, setProducts] = useState([])

	// change after connect to db
	useEffect(() => {
		if (defaultProducts) {
			setProducts(defaultProducts)
		}
	}, [defaultProducts])

	if (!products.length) {
		return <p>loading...</p>
	}

	return (
		<Products
			defaultProducts={products}
			title={t('navigation.discount')}
		/>
	)
}
