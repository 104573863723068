import React, { useEffect, useState } from "react";
import cn from "classnames";
import Menu from "./Menu";
import { Icon, Text } from "components/ui";
import styles from "./sidebar.module.sass";
import axios from "axios";
import { $api } from "api";
import { useDispatch, useSelector } from "react-redux";
import { updateSideBarReducer } from "../../../redux/reducers/global";
import { getShopData } from "redux/asyncAction/settings";
export default function Sidebar() {
	const [isActive, setIsActive] = useState(true);
	//change after connect to db
	// const [logo, setLogo] = useState('')
	// const logo = localStorage.getItem('logoStore')
	// 	useEffect(() => {
	// 		$api('/getShopLogo').then((response) => {
	// 			localStorage.setItem('zztoken')
	//              console.log(response, 'responseresponse')
	// 			setData(response)
	// 		})

	// 	},[])
	// console.log(data, 'datadadada')

	// useEffect(() => {
	// 	localStorage.getItem('logoStore')
	// },[logo])
	const [imageUrl, setImageUrl] = useState("");

	const { updateSidebar } = useSelector((state) => state.updateSideBarReducer);
	const state = useSelector((state) => state.settingsReducer);
	const dispatch = useDispatch();

	console.log(state);

	useEffect(() => {
		dispatch(getShopData());
		const logo = localStorage.getItem("logoStore");
		if (logo) {
			setImageUrl(logo);
		}
	}, [updateSidebar]);

	return (
		<nav className={cn(styles.sidebar, { [styles.active]: isActive })}>
			<div className={styles.sidebar__logo}>
				{state?.shopData ? (
					<img src={state.shopData?.mainInformation?.logoUrl} alt="logo" />
				) : (
					<div className={styles["default-logo"]}>
						<Text size="xs" color="white">
							logo
						</Text>
					</div>
				)}
			</div>
			<div className={styles["sidebar__menu-wrapper"]}>
				<Menu />
			</div>
			<Icon
				name="menu"
				size="md"
				hoverColor="gray"
				className={styles.sidebar__toggle}
				onClick={() => setIsActive(!isActive)}
			/>
		</nav>
	);
}
