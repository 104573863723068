import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppProvider from 'context'
import Router from 'Router'

export default function App() {
	return (
		<AppProvider>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</AppProvider>
	)
}
