import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";
import omit from "lodash/omit";

const apiWithProductTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Product", "TemplateProduct", "ProductSpecification"],
});

export const productApi = apiWithProductTags.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query({
			query: (params) => ({
				url: "/searchProducts",
				params,
			}),
			transformResponse: ({ data }) => ({ ...data }),
			providesTags: (result, error, arg) => {
				const tags = [{ type: "Product", id: "LIST" }];

				if (!result) return tags;

				return tags.concat(
					result.products.map(({ _id }) => ({ type: "Product", id: _id }))
				);
			},
		}),
		getProductsInfinite: builder.query({
			query: (params) => ({ url: "/searchProducts", params }),
			transformResponse: ({ data }) => ({ ...data }),
			/*providesTags: (result, error, arg, meta) => {
				const tags = [{ type: "Product", id: "LIST" }];

				if (!result) return tags;

				return tags.concat(
					result.products.map(({ _id }) => ({ type: "Product", id: _id }))
				);
			},*/
			serializeQueryArgs: ({ queryArgs }) => {
				return omit(queryArgs, ["page"]);
			},
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems, { arg }) => {
				const newProducts = [...currentCache.products];
				newProducts.splice(
					(arg.page - 1) * args.limit,
					newProducts.length,
					...newItems.products
				);

				newItems.products = newProducts;

				return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
		}),
		getProductSpecifications: builder.query({
			query: (params) => ({ url: "/getProductSpecifications", params }),
			//transformResponse: ({ data }, meta, arg) => ({ ...data }),
			providesTags: (result, error, arg) => {
				return [{ type: "ProductSpecification", id: "LIST" }];
			},
		}),
		changeProductPromotionProperty: builder.mutation({
			query: (data) => ({
				url: "/changeProductPromotionProperty",
				method: "POST",
				data,
			}),
			async onQueryStarted(
				{ productId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(productApi.util.invalidateTags([{ type: "Product", id }]));
				} catch {}
			},
		}),
		changeProductVisibility: builder.mutation({
			query: (data) => ({
				url: "/changeProductVisibility",
				method: "POST",
				data,
			}),
			async onQueryStarted(
				{ productId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(productApi.util.invalidateTags([{ type: "Product", id }]));
				} catch {}
			},
		}),
		deleteProduct: builder.mutation({
			query: (data) => ({
				url: "/deleteProduct",
				method: "POST",
				data,
			}),
			async onQueryStarted(
				{ productId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(productApi.util.invalidateTags([{ type: "Product", id }]));
				} catch {}
			},
		}),
		editProduct: builder.mutation({
			query: (data) => ({
				url: "/editProduct",
				method: "POST",
				data,
			}),
			async onQueryStarted(
				{ productId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(productApi.util.invalidateTags([{ type: "Product", id }]));
				} catch {}
			},
		}),
		createProduct: builder.mutation({
			query: (data) => ({
				url: "/createProduct",
				method: "POST",
				data,
			}),
			async onQueryStarted(
				{ productId: id, ...patch },
				{ dispatch, queryFulfilled }
			) {
				try {
					await queryFulfilled;
					dispatch(
						productApi.util.invalidateTags([{ type: "Product", id: "LIST" }])
					);
				} catch {}
			},
		}),
		getTemplateProducts: builder.query({
			query: (params) => ({ url: "/searchTemplateProducts", params }),
			transformResponse: ({ data }, meta, arg) => ({ ...data }),
			providesTags: (result, error, arg) => {
				const tags = [{ type: "TemplateProduct", id: "LIST" }];

				if (!result) return tags;

				return tags.concat(
					result.products.map(({ _id }) => ({
						type: "TemplateProduct",
						id: _id,
					}))
				);
			},
		}),
		createProductsFromTemplate: builder.mutation({
			query: (data) => ({
				url: "/createProductFromTemplate",
				method: "POST",
				data,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(
						productApi.util.invalidateTags([{ type: "Product", id: "LIST" }])
					);
				} catch {}
			},
		}),
	}),
});

export const {
	useGetProductsQuery,
	useGetProductsInfiniteQuery,
	useChangeProductPromotionPropertyMutation,
	useChangeProductVisibilityMutation,
	useDeleteProductMutation,
	useEditProductMutation,
	useCreateProductMutation,
	useGetTemplateProductsQuery,
	useCreateProductsFromTemplateMutation,
	useGetProductSpecificationsQuery,
} = productApi;

const initialState = {
	templateProducts: [],
	products: [],
	loading: false,
	productsUpdated: false,
};

const GET_ALL_TEMPLATE_PRODUCTS = "GET_ALL_TEMPLATE_PRODUCTS";
const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
const PRODUCTS_UPDATED = "PRODUCTS_UPDATED";

export const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_TEMPLATE_PRODUCTS:
			return { ...state, templateProducts: action.payload, loading: false };
		case GET_ALL_PRODUCTS:
			return { ...state, products: action.payload, loading: false };

		case PRODUCTS_UPDATED:
			return {
				...state,
				productsUpdated: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};

export const getTemplateProductsAction = (payload) => ({
	type: GET_ALL_TEMPLATE_PRODUCTS,
	payload,
});

export const getProductsAction = (payload) => ({
	type: GET_ALL_TEMPLATE_PRODUCTS,
	payload,
});

export const refreshProductsAction = (payload) => ({
	type: PRODUCTS_UPDATED,
	payload,
});
