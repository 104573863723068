import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, baseApi } from "api";
import omit from "lodash/omit";

const apiWithOrdersTags = baseApi.enhanceEndpoints({
	addTagTypes: ["Orders"],
});

export const ordersApi = apiWithOrdersTags.injectEndpoints({
	endpoints: (builder) => ({
		getOrders: builder.query({
			query: (data) => ({
				url: "/searchOrders",
				data,
				method: "POST",
			}),
			providesTags: ["Orders"],
		}),
		getBuyerOrders: builder.query({
			query: ({ buyerId, ...params }) => ({
				url: `/buyerOrders/${buyerId}`,
				params,
				method: "GET",
			}),
			providesTags: ["Orders"],
		}),
		getOrder: builder.query({
			query: (params) => ({
				url: "/getOrder",
				params,
			}),
			providesTags: ["Orders"],
		}),
		deleteOrders: builder.mutation({
			query: (data) => ({
				url: "/deleteOrders",
				method: "POST",
				data,
			}),
			invalidatesTags: ["Orders"],
		}),
		editOrder: builder.mutation({
			query: (data) => ({
				url: "/editOrder",
				method: "POST",
				data,
			}),
			invalidatesTags: ["Orders"],
		}),
		createOrder: builder.mutation({
			query: (data) => ({
				url: "/createOrder",
				method: "POST",
				data,
			}),
			invalidatesTags: ["Orders"],
		}),
	}),
});

export const {
	useGetOrdersQuery,
	useDeleteOrdersMutation,
	// useEditReviewsMutation,
	useGetOrderQuery,
	useEditOrderMutation,
	useCreateOrderMutation,
	useGetBuyerOrdersQuery,
	// useGetProductsInfiniteQuery,
	// useChangeProductPromotionPropertyMutation,
	// useChangeProductVisibilityMutation,
	// useCreateProductMutation,
	// useGetTemplateProductsQuery,
	// useCreateProductsFromTemplateMutation,
	// useGetProductSpecificationsQuery,
} = ordersApi;
