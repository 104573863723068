import { Select } from "components/ui";
import { Text } from "components/ui";
import { Button, Input } from "components/ui";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./AddPromocode.module.scss";
import { useDispatch } from "react-redux";
// import { createNewPromoCode } from "../../../../redux/asyncAction/promocode";
import useMessage from "../../../../hooks/useMessage";
import { useCreatePromocodeMutation } from "redux/reducers/promocode";

const AddPromocode = ({
	closeMenu,
	setRefreshPromoCodes,
	refreshPromoCodes,
}) => {
	const dispatch = useDispatch();
	const { showMessage } = useMessage();
	const { t } = useTranslation(["catalog", "common"]);

	const [createPromocode] = useCreatePromocodeMutation()

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
		setValue,
		setError,
		getValues,
		clearErrors,
	} = useForm({ mode: "onTouched", reValidateMode: "onChange" });

	const addPromoCode = async (data) => {
		const newData = {
         code: data.code,
		 discountPercent: data.discountPercent,
		 title: data.title,
		 startDate: data.startDate,
		 endDate: data.endDate,
		}
		createPromocode(newData)
		// dispatch(createNewPromoCode(values));
		showMessage({ value: t("message.addPromoCode") });
		closeMenu();
		setRefreshPromoCodes(!refreshPromoCodes);
	};

	const handleChange = (value, name) => {
		const { startDate, endDate } = getValues();
		if (startDate && endDate) {
			const startDateTime = new Date(startDate).getTime();
			const endDateTime = new Date(endDate).getTime();

			if (startDateTime >= endDateTime) {
				setError(
					"date",
					{ message: "дата начала не может быть больше даты окончания" },
					{ shouldFocus: true }
				);

				setError(
					"startDate",
					{ message: "дата начала не может быть больше даты окончания" },
					{ shouldFocus: true }
				);
			} else {
				clearErrors("date");
			}
		}
	};

	console.log("addpromocode")

	return (
		<form onSubmit={handleSubmit(addPromoCode)} className={styles.addPromoForm}>
			<Text as="h3" color="blue">
				Основная информация:
			</Text>
			<div className={styles.addInputGroup}>
				<div>
					<Text color="black">Название акции:</Text>
					<Input
						required
						id="title"
						name="title"
						register={register}
						errors={errors}
						size="md"
					/>
				</div>
				<div>
					<Text color="black">Код промоакции:</Text>
					<Input
						required
						id="code"
						name="code"
						register={register}
						errors={errors}
						size="md"
					/>
				</div>
				<div>
					<Text color="black">Дата проведения:</Text>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Input
							onChange={(value) => handleChange(value, "startDate")}
							required
							id="startDate"
							name="startDate"
							register={register}
							errors={errors}
							type="date"
							size="sm"
							style={{ borderRightStyle: "none" }}
						/>
						<div
							style={{
								marginTop: "9px",
								height: "33px",
								background: "#F2F3F5",
								alignSelf: "center",
								borderTop: "1px solid #E5E6EB",
								borderBottom: "1px solid #E5E6EB",
								boxSizing: "border-box",
								paddingTop: "6px",
							}}
						>
							&minus;
						</div>
						<Input
							onChange={(value) => handleChange(value, "endDate")}
							required
							id="endDate"
							name="endDate"
							register={register}
							errors={errors}
							type="date"
							size="sm"
							style={{ borderLeftStyle: "none" }}
						/>
					</div>
				</div>
				<div>
					<Text color="black">Процент скидки:</Text>
					<Input
						required
						id="discountPercent"
						name="discountPercent"
						register={register}
						errors={errors}
						size="md"
					/>
				</div>
			</div>
			<Button type="submit">Создать</Button>
		</form>
	);
};

export default AddPromocode;
