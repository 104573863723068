import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui'
import styles from './settings.module.sass'

const NavigationMobile = ({ section, setSection }) => {
	const { t } = useTranslation(['settings'], { keyPrefix: 'filters' })

	return (
		<ul className={styles['navigation-mobile']}>
			<li className={cn(section === 'main' && styles.active)} onClick={() => setSection('main')}>
				<Button view='header-nav' size='md'>
					{t('mainInfo')}
				</Button>
			</li>
			<li className={cn(section === 'contacts' && styles.active)} onClick={() => setSection('contacts')}>
				<Button view='header-nav' size='md'>
					{t('contacts')}
				</Button>
			</li>
			<li className={cn(section === 'requisites' && styles.active)} onClick={() => setSection('requisites')}>
				<Button view='header-nav' size='md'>
					{t('requisites')}
				</Button>
			</li>
			<li className={cn(section === 'faq' && styles.active)} onClick={() => setSection('faq')}>
				<Button view='header-nav' size='md'>
					{t('faq')}
				</Button>
			</li>
		</ul>
	)
}

NavigationMobile.propTypes = {
	section: PropTypes.string.isRequired,
	setSection: PropTypes.func.isRequired
}

export default React.memo(NavigationMobile)
