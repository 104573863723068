import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { useTranslation } from "react-i18next";
import { ResponsiveAreaChart, Text } from "components/ui";
import styles from "./dashboard.module.sass";
//import { useSelector } from "react-redux";

export default function SoldItemsGraphic({ todaySales = 0, result = [] }) {
	const { t, i18n } = useTranslation(["dashboard", "common"]);
	//console.log("language", i18n.language);
	const now = new Date();
	const currentMonth = moment(now).locale(i18n.language).format("MMMM");
	//const currentDate = moment().format("DD.MM.YYYY");
	//const currentMonth = moment().month();
	//const daysInMonth = moment(now).daysInMonth();
	//const arrayOfDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
	//const { dashboardCurrent } = useSelector((state) => state.dashboardReducer);

	// const initGraphic = arrayOfDays.map((i) => {
	// 	const splitDate = currentDate.split(".");
	// 	const currentDay = i < 10 ? "0" + i : i;
	// 	return {
	// 		day: currentDay,
	// 		data: `${currentDay}.${splitDate[1]}.${splitDate[2]}`,
	// 		//delete after connect to db
	// 		soldItems: 0,
	// 		// soldItems: Math.floor(Math.random() * 950)
	// 	};
	// });

	// current.forEach((day) => {
	// 	const currentDay = initGraphic.findIndex((el) => {
	// 		return el.data === day._id.replaceAll("-", ".").split('.').reverse().join('.')
	// 	});
	// 	initGraphic[currentDay].soldItems = day.count
	// });

	// const data = [
	// 	{ day: "1", soldItems: 10 },
	// 	{ day: "2", soldItems: 230 },
	// 	{ day: "3", soldItems: 110 },
	// 	{ day: "4", soldItems: 430 },
	// 	{ day: "5", soldItems: 234 },
	// 	{ day: "6", soldItems: 234 },
	// 	{ day: "7", soldItems: 123 },
	// 	{ day: "8", soldItems: 342 },
	// 	{ day: "9", soldItems: 345 },
	// 	{ day: "10", soldItems: 934 },
	// 	{ day: "11", soldItems: 703 },
	// 	{ day: "12", soldItems: 234 },
	// 	{ day: "13", soldItems: 23 },
	// 	{ day: "14", soldItems: 230 },
	// 	{ day: "15", soldItems: 12 },
	// 	{ day: "16", soldItems: 134 },
	// 	{ day: "17", soldItems: 450 },
	// 	{ day: "18", soldItems: 540 },
	// 	{ day: "19", soldItems: 560 },
	// 	{ day: "20", soldItems: 500 },
	// 	{ day: "21", soldItems: 356 },
	// 	{ day: "22", soldItems: 480 },
	// 	{ day: "23", soldItems: 230 },
	// 	{ day: "24", soldItems: 212 },
	// 	{ day: "25", soldItems: 222 },
	// 	{ day: "26", soldItems: 190 },
	// 	{ day: "27", soldItems: 256 },
	// 	{ day: "28", soldItems: 280 },
	// 	{ day: "29", soldItems: 300 },
	// 	{ day: "30", soldItems: 342 },
	// 	{ day: "31", soldItems: 700 },
	// ];
	// Object.keys(points).map((el)=>({day:el,soldItems:points[el]})) //Преобразовать

	return (
		<div className={styles["sold-items-graphic"]}>
			<Text size="md">{t("soldItemsGraphic.title")}</Text>
			<Text size="md" transform="lowercase" style={{ marginLeft: 5 }}>
				{currentMonth}:
			</Text>
			<div className={styles["sold-items-graphic__today"]}>
				<Text size="sm" color="blue">
					(+{todaySales}){" "}
					{t("units", { ns: "common", keyPrefix: "statisticsDashboard" })}
				</Text>
				<Text size="sm" color="gray" style={{ marginLeft: 5 }}>
					{t("for today", { ns: "common", keyPrefix: "statisticsDashboard" })}
				</Text>
			</div>
			<div className={styles["sold-items-graphic__chart"]}>
				<ResponsiveAreaChart dashboardCurrent={result} />
			</div>
		</div>
	);
}
