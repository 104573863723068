import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import useWindowSize from 'hooks/useWindowSize'
import BurgerMenu from './BurgerMenu'
import { Screen, Icon, Text } from 'components/ui'
import styles from './header.module.sass'

const Header = ({ children, options }) => {
	const location = useLocation()
	const mainRouterPage = location.pathname.split('/')[1]
	const { t } = useTranslation([mainRouterPage])
	const { deviceWidth } = useWindowSize()

	return (
		<header className={styles.header}>
			<div className='container h-100'>
				<div className={styles['header-wrapper']}>
					<div className={styles['header-top']}>
						<Screen size='xl' down>
							<div className={styles['header__burger-menu']}>
								<BurgerMenu />
							</div>
						</Screen>
						<div className={styles['header__logo']}>
							<Icon
								name={mainRouterPage}
								size={deviceWidth === 'large' ? 'xl' : 'sm'}
								color='white'
							/>
						</div>
						<div className={styles['header__title']}>
							<Text as='h2'>{t('headerTitle')}</Text>
						</div>
					</div>
					<div className={styles['header__nav']}>
						{/* {children} */}
					</div>
					{options && (
						<div className={styles['header__options']}>
							{options}
						</div>
					)}
				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	options: PropTypes.shape({})
}

export default React.memo(Header)
