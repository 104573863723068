import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Icon, Text, Input, Select, Button, Screen } from "components/ui";
import PropTypes from "prop-types";
import useWindowSize from "hooks/useWindowSize";
import styles from "./settings.module.sass";
import { useSelector } from "react-redux";

const RequisitesStore = ({ data, onSaveChangedData }) => {
	const { t } = useTranslation(["settings", "common"]);
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const { deviceWidth } = useWindowSize();
	const savedNumContract = localStorage.getItem("numContract");
	console.log(savedNumContract, "savedNumContract");

	const { shopData, shopDataLoading } = useSelector(
		(state) => state.settingsReducer
	);
	console.log(shopData, "shopData");
	const allowedTaxation = ["simplified", "general"];
	if (shopDataLoading) {
		return (
			<div className="container">
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div className={styles["setting-store"]}>
				<div className={styles["setting-store__header"]}>
					<div className={styles["title"]}>
						<Icon size="md" name="requisites" color="blue" />
						<Text size="md" color="blue">
							{t("filters.requisites")}
						</Text>
					</div>
					<Screen size="xl">
						<Icon name="question-border" size="md" />
					</Screen>
				</div>
				<div className={styles["setting-store__content"]}>
					<form onSubmit={handleSubmit(onSaveChangedData)}>
						<div className="row row-10">
							<div className="col-xl-6">
								<div className={styles["setting-item"]}>
									<Input
										id="contract"
										name="contract"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
										}}
										label={t("requisites.contract")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={savedNumContract}
										errors={errors}
										fluid={true}
										suffix="lock"
										required
										readOnly
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="organization"
										name="organization"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 2,
												message: t("validations.minLength", {
													value: 2,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.organization")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.organizationName}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="nameHead"
										name="nameHead"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.nameHead")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.directorFullName}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="inn"
										name="inn"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.inn")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.INN}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="kpp"
										name="kpp"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.kpp")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.KPP}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="ogrn"
										name="ogrn"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.ogrn")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.OGRN}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
							</div>
							<div className="col-xl-6">
								<div className={styles["setting-item"]}>
									<Input
										id="legalAddress"
										name="legalAddress"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.legalAddress")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.officialAddress}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Controller
										name="taxation"
										control={control}
										defaultValue={shopData?.bankDetails?.taxType}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										render={({ field: { onChange, value } }) => (
											<Select
												name="taxation"
												value={value}
												onChange={onChange}
												label={t("requisites.taxation")}
												labelSize={deviceWidth === "large" ? "md" : "sm"}
												errors={errors}
												fluid
											>
												{allowedTaxation.map((taxation) => (
													<Select.Option key={taxation} value={taxation}>
														{t("requisites.allowedTaxation." + taxation)}
													</Select.Option>
												))}
											</Select>
										)}
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="bik"
										name="bik"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.bik")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.BIK}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="bankName"
										name="bankName"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.bankName")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.bankName}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
								<div className={styles["setting-item"]}>
									<Input
										id="checkingAccount"
										name="checkingAccount"
										register={register}
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
											minLength: {
												value: 6,
												message: t("validations.minLength", {
													value: 6,
													ns: "common",
												}),
											},
										}}
										label={t("requisites.checkingAccount")}
										labelSize={deviceWidth === "large" ? "md" : "sm"}
										defaultValue={shopData?.bankDetails?.checkingAccount}
										errors={errors}
										errorPos="requsitesError"
										fluid={true}
										required
									/>
								</div>
							</div>
						</div>
						<Button type="submit" fluid={deviceWidth === "small"}>
							{t("buttons.save", { ns: "common" })}
						</Button>
					</form>
				</div>
			</div>
		);
	}
};

RequisitesStore.propTypes = {
	data: PropTypes.shape({}).isRequired,
	onSaveChangedData: PropTypes.func.isRequired,
};

export default RequisitesStore;
