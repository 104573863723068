import React from 'react';
import privacyPdf from '../../assets/PrivacyPolicy.pdf'
import styled from "styled-components";


const Root = styled.div`
	width: 100vw;
	height: 100vh;

	iframe {
		width: 100vw;
		height: 100vh;
	}
`

const PrivacyPolicy = () => {

	return (
		<Root>
			<iframe title="Политика конфиденциальности" src={privacyPdf}/>
		</Root>
	);
};

export default PrivacyPolicy;
