const defaultState = {
	updateSidebar: false,
	loading: false,
};

const UPDATE_SIDE_BAR = "UPDATE_SIDE_BAR";

export const updateSideBarReducer = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_SIDE_BAR:
			return { ...state, updateSidebar: action.payload, loading: false };

		default:
			return state;
	}
};

export const updateSidebarAction = (payload) => ({
	type: UPDATE_SIDE_BAR,
	payload,
});
