import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import useWindowSize from 'hooks/useWindowSize'
import { Button, Input, Screen, Text } from 'components/ui'
import styles from '../seo.module.sass'
import settings from "../../../api/service/settings";

const ControlDomainEdit = ({onSubmit, domainInfo, setDomainInfo }) => {
	const { t } = useTranslation(['seo', 'common'])
	const { deviceWidth } = useWindowSize()
	const getDomainInfo = async () => {
		let info = await settings.getShopData();
		const { domain, subDomain } = info.data.shopData
		setDomainInfo({ domain, subDomain });
	};

	useEffect(async () => {
		await getDomainInfo();
	}, []);

	const onChangeDomainInfo = (value, key) => {
		domainInfo[key] = value
		setDomainInfo({ ...domainInfo })
	}

	return (
		<div className={styles['control-domain-edit']}>
			<Screen size='xl' down>
				<Text size='sm'>{t('domain.drawer.ourSubdomain')}:</Text>
				<Text size='sm' color='blue' style={{ marginLeft: 10 }}>.zumzak.com</Text>
				<Input
					name='subdomain'
					fluid={true}
					placeholder='shop'
					className='offset-top-10'
				/>
			</Screen>
			<Screen size='xl'>
				<Input.Group className='offset-top-20' align='end'>
					<Input
						name='subDomain'
						fluid={true}
						label={t('domain.drawer.ourSubdomain')}
						value={domainInfo.subDomain}
						onChange={e => onChangeDomainInfo(e.target.value, 'subDomain')}
						labelSize='md'
						labelColor='blue'
						placeholder='shop'
					/>
					<Input.GroupItem>
						<div className={styles['default-domain']}>.zumzak.com</div>
					</Input.GroupItem>
				</Input.Group>
			</Screen>
			<Text
				as='p'
				size='sm'
				color='gray'
				className='offset-top-10'
				style={deviceWidth !== 'large' ? { fontSize: 12 } : {}}
			>
				{t('domain.drawer.ourSubdomainDesc')}
				<Text
					size='sm'
					color='blue'
					style={deviceWidth !== 'large'
						? { marginLeft: 5, fontSize: 12 }
						: { marginLeft: 5 }}
				>
					shop.zumzak.com
				</Text>
			</Text>
			<div className={styles.or}>
				<div className={styles.or__circle}>
					<Text size='xs' color='white'>{t('domain.drawer.or')}</Text>
				</div>
			</div>
			<Input
				name='domain'
				fluid={true}
				label={t('domain.drawer.yourDomain')}
				value={domainInfo.domain}
				onChange={e => onChangeDomainInfo(e.target.value, 'domain')}
				labelSize={deviceWidth === 'large' ? 'md' : 'sm'}
				labelColor={deviceWidth === 'large' ? 'blue' : 'primary'}
				placeholder='example.com'
			/>
			<div className={styles.instruction}>
				<Text as='p' size='sm' color='gray' style={deviceWidth !== 'large' ? { fontSize: 12 } : {}}>
					{t('domain.drawer.instruction.' + 0)}
				</Text>
				<Text as='p' size='sm' color='gray' style={deviceWidth !== 'large' ? { fontSize: 12 } : {}}>
					{t('domain.drawer.instruction.' + 1)}
				</Text>
				<Text as='p' size='sm' color='gray' style={deviceWidth !== 'large' ? { fontSize: 12 } : {}}>
					{t('domain.drawer.instruction.' + 2)}
				</Text>
				<Text as='p' size='sm' color='gray' style={deviceWidth !== 'large' ? { fontSize: 12 } : {}}>
					{t('domain.drawer.instruction.' + 3)}
				</Text>
				<Button
					className={cn(deviceWidth !== 'large' && 'offset-top-10')}
					fluid={deviceWidth === 'small'}
					onClick={onSubmit}
				>
					{t('buttons.save', { ns: 'common' })}
				</Button>
			</div>
		</div>
	)
}

export default ControlDomainEdit
