import { $api } from "api";

class Category {
	async getGlobalCategories() {
		const res = await $api.get("/getGlobalCategories");
		return res;
	}

	async getShopGlobalCategories() {
		const res = await $api.get("/getShopsGlobalCategories");
		return res;
	}

	async searchCategories(searchText) {
		const searchUrl = searchText
			? `/searchCategories/?searchText=${searchText}`
			: "/searchCategories";
		const res = await $api.get(searchUrl, {
			headers: {
				"Cache-Control": "no-cache",
				Pragma: "no-cache",
				Expires: "0",
			},
		});

		return res.data;
	}

	async getUnNestedCategories(searchText) {
		const searchUrl =
			searchText && searchText.length > 0
				? `getAllCategories?searchText=${searchText}`
				: "/getAllCategories";
		const res = await $api.get(searchUrl, {
			headers: {
				"Cache-Control": "no-cache",
				Pragma: "no-cache",
				Expires: "0",
			},
		});

		return res;
	}

	async changeCategoryVisibility(payload) {
		const url = "/changeCategoryVisibility";

		const res = await $api.post(url, payload);
		return res;
	}

	async changeCategoryPopularity(payload) {
		const url = "/changeCategoryPopularity";

		const res = await $api.post(url, payload);
		return res;
	}

	async createNewCategory(payload) {
		const url = "/createCategory";

		const res = await $api.post(url, payload);
		return res;
	}

	async editCategory(payload) {
		const url = "/editCategory";

		const res = await $api.post(url, payload);
		return res;
	}

	async deleteCategory(payload) {
		const url = "/deleteCategory";

		const res = await $api.post(url, payload);
		return res;
	}
}

export default new Category();
