import { fabric } from "fabric";

export const getBannerTextObjects = (canvas) => {
	return canvas
		.getObjects()
		.filter((obj) => obj instanceof fabric.Textbox)
		.map((obj) => ({
			text: obj.text,
			top: obj.top,
			left: obj.left,
			width: obj.width,
			fill: obj.fill,
			fontFamily: obj.fontFamily,
			fontStyle: obj.fontStyle,
			fontSize: obj.fontSize,
			fontWeight: obj.fontWeight,
			shadow: obj.shadow,
			textAlign: obj.textAlign,
		}));
};
