import "./Promocode.scss";
import { Icon } from "components/ui";
import { useTranslation } from "react-i18next";

const Promocode = ({
	title,
	id,
	deletePromocode,
	editPromocode,
	infoPromocode,
}) => {
	const { t } = useTranslation(["catalog"]);

	return (
		<>
			<div className="promocode-title">{title}</div>
			<div className="controls-group">
				<Icon
					name="info-border"
					size="md"
					title={t("buttonDesc.hidden", { value: "", ns: "common" })}
					onClick={infoPromocode}
					cursorPointer
				/>
				<Icon
					name="edit"
					size="md"
					title={t("buttonDesc.edit", { value: "", ns: "common" })}
					cursorPointer
					onClick={editPromocode}
				/>
				<Icon
					name="delete"
					size="md"
					color="red"
					title={t("buttonDesc.delete", { value: "", ns: "common" })}
					cursorPointer
					onClick={deletePromocode}
				/>
			</div>
		</>
	);
};

export default Promocode;
