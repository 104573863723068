import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './footer'
import { Sidebar } from './sidebar'
import { Screen } from 'components/ui'

export default function Layout() {
	return (
		<>
			<Screen size='xl'>
				<Sidebar />
			</Screen>
			<Outlet />
			<Footer />
		</>
	)
}
