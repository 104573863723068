import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Text, Icon, Screen } from 'components/ui'
import PropTypes from 'prop-types'
import faviconImg from 'assets/seo/title.png'
import styles from './seo.module.sass'

const Favicon = ({ favicon, onChangeFavicon }) => {
	const { t } = useTranslation(['seo'], { keyPrefix: 'favicon' })

	return (
		<div className={styles['favicon-settings']}>
			<Text as='p' size='md' color='blue'>Favicon</Text>
			<Screen size='xl' down>
				<Text as='p' size='sm' className='offset-top-20'>{t('uploadFavicon')}</Text>
			</Screen>
			<div className={cn(styles['favicon-settings-wrapper'], 'offset-top-10')}>
				<div className={styles['favicon-settings__upload']}>
					<label htmlFor='upload-favicon'>
						{favicon ? (
							<img src={favicon} alt='' />
						) : (
							<Icon
								name='plus'
								size='xl'
								color='blue'
								cursorPointer
							/>
						)}
					</label>
					<input
						id='upload-favicon'
						name='upload-logo'
						type='file'
						accept='image/png, image/jpeg, image/jpg, image/svg'
						onChange={e => onChangeFavicon(e.target.files[0])}
						style={{ display: 'none' }}
					/>
					<div className={styles['example-img']}>
						<div className={styles['img-wrapper']}>
							<img src={faviconImg} alt='' />
						</div>
					</div>
				</div>
				<Text
					as='p'
					size='md'
					color='gray'
					className='offset-top-10'
					style={{ fontSize: 12 }}
				>
					{t('requirementFavicon')}
				</Text>
				<Screen size='xl'>
					<div className={styles['favicon-settings__faq']}>
						<Icon
							name='question-border'
							size='md'
							title={t('description')}
							customTitle
						/>
					</div>
				</Screen>
			</div>
		</div>
	)
}

Favicon.propTypes = {
	favicon: PropTypes.string.isRequired,
	onChangeFavicon: PropTypes.func.isRequired
}

export default Favicon
