import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Text, Button, Input, Textarea, LocaleSwitcher } from "components/ui";
// import logoSrc from 'assets/logo.svg'
import uploadLogoImg from "assets/upload-logo.png";
import styles from "./auth.module.sass";
import auth from "api/service/auth";
import { useDispatch, useSelector } from "react-redux";
//import { getGlobalCategories } from "redux/asyncAction/category";
import { getCountries } from "redux/asyncAction/region";
import { getCities } from "redux/asyncAction/region";
import { addShop } from "redux/asyncAction/auth";
import InputAuth from "components/ui/form/InputAuth";
import TextareaAuth from "components/ui/form/TextareaAuth";
import { SelectAuth } from "components/ui/select";
import { Select } from "antd";
import { useGetGlobalCategoriesQuery } from "redux/reducers/category";

import logoSrc from "assets/logo.svg";
import logoSrcEn from "assets/logoEn.svg";

import selectIcon from "../../assets/dropdownauth.svg";
import FormErrorAuth from "components/ui/form/FormErrorAuth";

const SelectIcon = () => {
	return <img src={selectIcon} alt="" />;
};

const StoreRegister = ({ setRegisterInfo, setRegStep }) => {
	const { t } = useTranslation(["auth"]);
	const {
		setValue,
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const [userLogo, setUserLogo] = useState("");
	const [logoUrl, setLogoUrl] = useState("");

	const getGlobalCategoriesQuery = useGetGlobalCategoriesQuery();

	const globalCategories = getGlobalCategoriesQuery.data?.categories || [];

	const { countries, cities } = useSelector((state) => state.regionReducer);
	let dispatch = useDispatch();

	const submitImage = async (logo) => {
		const responce = await auth.getImgStr(logo);
		console.log(responce, "logoStore");
		const shopLogo = responce.data.url;
		setLogoUrl(shopLogo);
		localStorage.setItem("logoStore", responce.data.url);

		return responce;
	};

	useEffect(() => {
		//dispatch(getGlobalCategories());
		dispatch(getCountries());
		dispatch(getCities());
	}, []);

	const handleImgChange = async (e) => {
		setUserLogo(URL.createObjectURL(e.target.files[0]));
		await submitImage(e.target.files[0]).then((str) =>
			setValue("uploadLogo", str)
		);
	};

	const submitForm = async (data) => {
		setRegStep("requisites");
		dispatch(addShop({ ...data, logoUrl }));
	};

	// let mask = '12:34';
	let formatChars = {
		1: "[0-2]",
		2: "[0-9]",
		3: "[0-5]",
		4: "[0-9]",
	};

	const handleKeyPress = (event) => {
		const charCode = event.which || event.keyCode;
		const charTyped = String.fromCharCode(charCode);
		const pattern = /^[a-zA-Z0-9]*$/;

		if (!pattern.test(charTyped)) {
			event.preventDefault();
		}
	};

	let beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState;

		// Conditional mask for the 2nd digit base on the first digit
		if (value.startsWith("2"))
			formatChars["2"] = "[0-3]"; // To block 24, 25, etc.
		else formatChars["2"] = "[0-9]"; // To allow 05, 12, etc.
		return { value, selection: newState.selection };
	};

	if (globalCategories.length > 0) {
		return (
			<form onSubmit={handleSubmit(submitForm)}>
				<div className={cn(styles["register"], styles["store"])}>
					<div className="offset-top-20">
						<div className="row row-10">
							<div className="col-xl-6">
								<div
									style={{ position: "relative" }}
									className={styles["store__upload-logo"]}
								>
									<Text as="p" color="white" size="labelinp">
										{t("store.logo")} <span style={{ color: "red" }}>*</span>
									</Text>
									<label htmlFor="uploadLogo">
										<img
											src={userLogo ? userLogo : uploadLogoImg}
											alt=""
											style={{ borderRadius: "8px" }}
										/>
									</label>
									<input
										id="uploadLogo"
										name="uploadLogo"
										type="file"
										accept="image/png, image/jpeg, image/jpg, image/svg"
										onChange={handleImgChange}
										style={{ display: "none" }}
										// register={register}
										errors={errors}
										errorPos="left"
										rules={{
											required: {
												value: true,
												message: t("validations.required", { ns: "common" }),
											},
										}}
									/>
								</div>
								{errors?.uploadLogo?.message && (
									<div className={styles.errorMessage1}>
										<FormErrorAuth
											message={errors?.uploadLogo?.message}
											errorPos="left"
										/>
									</div>
								)}
								<div
									className={cn(styles["store__subdomain"], "offset-top-10")}
								>
									<Text size="labelinp" color="white">
										{t("store.subdomain")}{" "}
										<span style={{ color: "red" }}>*</span>
									</Text>
									<div className={cn(styles.input, "offset-top-10")}>
										<InputAuth
											id="subdomain"
											name="subdomain"
											register={register}
											onKeyPress={handleKeyPress}
											rules={{
												required: {
													value: true,
													message: t("messageError.SpecifyTheDesiredSubdomain"),
												},
											}}
											errors={errors}
											fluid
											errorPos="domainLeft"
										/>
										<div
											style={{
												fontWeight: 400,
												fontSize: "18px",
												height: "48px",
												marginLeft: "20px",
												background: "white",
												borderRadius: "10px",
												padding: "0 10px",
												display: "flex",
												alignItems: "center",
											}}
										>
											.zumzаk.com
										</div>
									</div>
								</div>
								<div className="offset-top-10">
									<Text size="labelinp" color="white">
										{t("store.name")} <span style={{ color: "red" }}>*</span>
									</Text>
									<InputAuth
										id="name"
										name="name"
										register={register}
										// maxLength='3'
										rules={{
											required: {
												value: true,
												message: t("messageError.nameOfyourStore"),
											},
											// maxLength: { value: 3, message: 'vfr'}
										}}
										errors={errors}
										fluid
										errorPos="storeNameLeft"
									/>
								</div>
								<div style={{ position: "relative" }} className="offset-top-10">
									<Text size="labelinp" color="white">
										{t("store.category")}{" "}
										<span style={{ color: "red" }}>*</span>
									</Text>
									<Controller
										name="category"
										control={control}
										register={register}
										rules={{
											required: {
												value: true,
												message: t("messageError.specifyTheMainCategory"),
											},
										}}
										errorPos="categotyStoreLeft"
										errors={errors}
										render={({ field: { onChange, value } }) => (
											<Select
												name="category"
												value={value || t("store.placeholder.category")}
												onChange={onChange}
												className={styles.selectAuth}
												// register={register}
												// rules={{
												// 	required: { value: true, message: t('validations.required', { ns: 'common' }) }
												// }}

												fluid
												errorPos="categotyStoreLeft"
												suffixIcon={<SelectIcon />}
											>
												{globalCategories?.map((category) => (
													<Select.Option
														key={category?._id}
														value={category?._id}
													>
														{category?.title}
													</Select.Option>
												))}
											</Select>
										)}
									/>
									{errors?.category?.message && (
										<div className={styles.errorMessage1}>
											<FormErrorAuth
												message={errors?.category?.message}
												errorPos="categotyStoreLeft"
											/>
										</div>
									)}
								</div>

								<div style={{ position: "relative" }} className="offset-top-10">
									{/* { <InputAuth style={{position: 'absolute', width: '228px', bottom: '-59px',  zIndex: '1001'}}/>} */}
									<Text size="labelinp" color="white">
										{t("store.country")} <span style={{ color: "red" }}>*</span>
									</Text>
									<Controller
										name="country"
										control={control}
										register={register}
										rules={{
											required: {
												value: true,
												message: t("messageError.countryYourStoreIsLocated"),
											},
										}}
										errorPos="categotyStoreLeft"
										errors={errors}
										render={({ field: { onChange, value } }) => (
											<Select
												name="country"
												className={styles.selectAuth}
												value={value || t("store.placeholder.country")}
												onChange={onChange}
												errors={errors}
												suffixIcon={<SelectIcon />}
												fluid
												errorPos="categotyStoreLeft"
											>
												{countries?.map((country) => (
													<Select.Option
														style={{ zIndex: "1000" }}
														key={country?._id}
														value={country?._id}
													>
														{country?.title}
													</Select.Option>
												))}
											</Select>
										)}
									/>
									{errors?.country?.message && (
										<div className={styles.errorMessage1}>
											<FormErrorAuth
												message={errors?.country?.message}
												errorPos="categotyStoreLeft"
											/>
										</div>
									)}
								</div>
								<div style={{ position: "relative" }} className="offset-top-10">
									<Text size="labelinp" color="white">
										{t("store.city")} <span style={{ color: "red" }}>*</span>
									</Text>
									<Controller
										name="city"
										control={control}
										errorPos="categotyStoreLeft"
										rules={{
											required: {
												value: true,
												message: t("messageError.cityYourStoreIsLocated"),
											},
										}}
										render={({ field: { onChange, value } }) => (
											<Select
												className={styles.selectAuth}
												name="city"
												showSearch
												errorPos="categotyStoreLeft"
												value={value || t("store.placeholder.city")}
												onChange={onChange}
												errors={errors}
												fluid
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												options={cities?.map((el) => {
													return {
														id: el._id,
														value: el._id,
														label: el.title,
													};
												})}
												suffixIcon={<SelectIcon />}
											></Select>
										)}
									/>
									{errors?.city?.message && (
										<div className={styles.errorMessage1}>
											<FormErrorAuth
												message={errors?.city?.message}
												errorPos="left"
											/>
										</div>
									)}
								</div>
							</div>

							<div className="col-xl-6">
								<div className={cn(styles["store__work-hours"])}>
									<Text size="labelinp" color="white">
										{t("store.working")} <span style={{ color: "red" }}>*</span>
									</Text>
									<div className="offset-top-10">
										<div className={styles["work-hour-item"]}>
											<Text size="labelinp">{t("store.weekdays")}</Text>
											<div className={styles.inputs}>
												<Controller
													name="weekdaysFrom"
													control={control}
													rules={{
														required: t("validations.required", {
															ns: "common",
														}),
														// required: { value: true, message: t('validations.required', { ns: 'common' }), },
														pattern: {
															value: [
																/^([0-2])/,
																/([0-9])/,
																":",
																/[0-5]/,
																/[0-9]/,
															],
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask={"12:34"}
															// type="time"
															// value={value}
															maskChar={"0"}
															// alwaysShowMask
															placeholder="00:00"
															formatChars={formatChars}
															beforeMaskedValueChange={beforeMaskedValueChange}
															onChange={onChange}
															// {...register("99:99", { required: true,})}
														>
															{(inputProps) => (
																<InputAuth
																	content="centered"
																	// register={register}
																	name="weekdaysFrom"
																	errors={errors}
																	size="sm"
																	placeholder="00:00"
																	fluid
																	errorPos="timeLeft"
																	// rules={{
																	// 	required: { value: true, message: t('validations.required', { ns: 'common' }), }
																	// }}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
												<Controller
													name="weekdaysTo"
													control={control}
													rules={{
														// required: true,
														required: t("validations.required", {
															ns: "common",
														}),
														pattern: {
															required: {
																value: true,
																message: t("validations.required", {
																	ns: "common",
																}),
															},
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="12:34"
															// type='time'
															value={value}
															maskChar={"0"}
															//alwaysShowMask
															// register={register}
															formatChars={formatChars}
															beforeMaskedValueChange={beforeMaskedValueChange}
															onChange={onChange}
														>
															{(inputProps) => (
																<InputAuth
																	content="centered"
																	name="weekdaysTo"
																	errors={errors}
																	// type='time'
																	size="sm"
																	placeholder="00:00"
																	style={{
																		marginLeft: 10,
																		textAlign: "center",
																		paddingLeft: 0,
																	}}
																	fluid
																	errorPos="timeRight"
																	// rules={{
																	// 	required: { value: true, message: t('validations.required', { ns: 'common' }) }
																	// }}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
											</div>
										</div>
										<div className={styles["work-hour-item"]}>
											<Text size="labelinp">{t("store.weekend")}</Text>
											<div className={styles.inputs}>
												<Controller
													name="weekendFrom"
													control={control}
													rules={{
														// required: true,
														required: t("validations.required", {
															ns: "common",
														}),
														pattern: {
															required: {
																value: true,
																message: t("validations.required", {
																	ns: "common",
																}),
															},
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="12:34"
															// type='time'
															value={value}
															maskChar={"0"}
															// alwaysShowMask
															onChange={onChange}
															formatChars={formatChars}
															beforeMaskedValueChange={beforeMaskedValueChange}
															// register={register}
														>
															{(inputProps) => (
																<InputAuth
																	content="centered"
																	name="weekendFrom"
																	errors={errors}
																	size="sm"
																	placeholder="00:00"
																	fluid
																	errorPos="timeLeft"
																	// rules={{
																	// 	required: { value: true, message: t('validations.required', { ns: 'common' }) }
																	// }}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
												<Controller
													name="weekendTo"
													control={control}
													rules={{
														required: t("validations.required", {
															ns: "common",
														}),
														pattern: {
															// required: true,
															required: {
																value: true,
																message: t("validations.required", {
																	ns: "common",
																}),
															},
															value: /^\d{2}[:\\\/]\d{2}$/,
														},
													}}
													render={({ field: { onChange, value } }) => (
														<InputMask
															mask="12:34"
															// type='time'
															value={value}
															maskChar={"0"}
															// alwaysShowMask
															onChange={onChange}
															// register={register}
															formatChars={formatChars}
															beforeMaskedValueChange={beforeMaskedValueChange}
														>
															{(inputProps) => (
																<InputAuth
																	content="centered"
																	name="weekendTo"
																	errorPos="timeRight"
																	errors={errors}
																	size="sm"
																	placeholder="00:00"
																	style={{
																		marginLeft: 10,
																		textAlign: "center",
																		paddingLeft: 0,
																	}}
																	fluid
																	rules={{
																		required: {
																			value: true,
																			message: t("validations.required", {
																				ns: "common",
																			}),
																		},
																	}}
																	{...inputProps}
																/>
															)}
														</InputMask>
													)}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="offset-top-10">
									<Text size="labelinp">
										{t("store.advantages")}{" "}
										<span style={{ color: "red" }}>*</span>
									</Text>
									<TextareaAuth
										id="advantages"
										name="advantages"
										register={register}
										errors={errors}
										style={{ height: 145 }}
										fluid
										errorPos="storeRightTextArea"
										rules={{
											required: {
												value: true,
												message: t(
													"messageError.specifyTheAdvantagesOfYourStore"
												),
											},
										}}
									/>
								</div>
								<div className="offset-top-10">
									<Text size="labelinp">
										{t("store.description")}{" "}
										<span style={{ color: "red" }}>*</span>
									</Text>
									<TextareaAuth
										id="description"
										name="description"
										register={register}
										errors={errors}
										fluid
										style={{ height: 145 }}
										errorPos="storeRightTextArea"
										rules={{
											required: {
												value: true,
												message: t(
													"messageError.specifyMultipleDescriptionOfYourStore"
												),
											},
										}}
									/>
								</div>
								<div className="offset-top-10">
									<Text size="labelinp">
										{t("store.number")} <span style={{ color: "red" }}>*</span>
									</Text>
									<Controller
										name="number"
										control={control}
										rules={{
											required: {
												value: true,
												message: t("messageError.provideContactPhoneNumber"),
											},
											pattern: {
												value: /\+\d{1}[\- ]?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/,
												message: t("messageError.provideContactPhoneNumber"),
											},
										}}
										render={({ field: { onChange, value } }) => (
											<div sttyle={{ position: "relative" }}>
												<InputMask
													mask="+7 (999) 999-99-99"
													value={value}
													maskChar={null}
													onChange={onChange}
												>
													{(inputProps) => (
														<InputAuth
															errorPos="phoneRight"
															name="number"
															errors={errors}
															fluid
															{...inputProps}
														/>
													)}
												</InputMask>
											</div>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<button
						type="submit"
						view="secondary"
						className={styles.btnNewAuthNext}
					>
						{t("button.next")}
					</button>
				</div>
			</form>
		);
	} else {
		return <h1></h1>;
	}
};

StoreRegister.propTypes = {
	setRegisterInfo: PropTypes.func.isRequired,
	setRegStep: PropTypes.func.isRequired,
};

export default StoreRegister;
