import React from 'react'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import { ResponsivePieChart, Text } from 'components/ui'
import styles from './rate.module.sass'

const MemoryChart = ({ data }) => {
	const { t } = useTranslation(['rate'])
	const { deviceWidth } = useWindowSize()

	return (
		<section className='section'>
			<div className='container'>
				<div className='row row-10 align-items-center'>
					<div className='col-md-6 col-xl-3'>
						<ResponsivePieChart
							data={data}
							innerRadius={deviceWidth === 'large' ? 55 : 80}
							outerRadius={deviceWidth === 'large' ? 100 : '100%'}
							cx={deviceWidth === 'large' && 100 }
							height={deviceWidth === 'large' ? 200 : 300}
						/>
					</div>
					<div className='col-md-6 col-xl-9'>
						<div className={styles['memory-chart']}>
							<div className={styles['memory-chart__taken']}>
								<div />
								<Text size='xs'>{t('publish')}</Text>
								<Text size='xs'>{data.find(v => v.name === 'taken').value}</Text>
								<Text size='xs'>{t('goods')}</Text>
							</div>
							<div className={styles['memory-chart__free']}>
								<div />
								<Text size='xs'>{t('free')}</Text>
								<Text size='xs'>{data.find(v => v.name === 'free').value}</Text>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default MemoryChart
