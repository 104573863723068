import React, { useState } from 'react'
import { UserRegister, StoreRegister, RequisitesRegister, LayoutRegister } from 'components/auth'

export default function Register() {
	const [registerInfo, setRegisterInfo] = useState({})
	const [regStep, setRegStep] = useState('user')

	const setStoreInfo = data => {
		setRegisterInfo({
			...registerInfo,
			store: data
		})
	}

	const setRequisitesInfo = data => {
		setRegisterInfo({
			...registerInfo,
			requisites: data
		})
	}

	switch (regStep) {
		case 'user':
			return (
				<UserRegister
					setRegStep={setRegStep}
					setRegisterInfo={setRegisterInfo}
				/>
			)
		case 'store':
			return (
				<LayoutRegister setRegStep={setRegStep} regStep={regStep}>
					<StoreRegister
						setRegStep={setRegStep}
						setRegisterInfo={setStoreInfo}
					/>
				</LayoutRegister>
			)
		case 'requisites':
			return (
				<LayoutRegister setRegStep={setRegStep} regStep={regStep}>
					<RequisitesRegister
						setRegisterInfo={setRequisitesInfo}
					/>
				</LayoutRegister>
			)
		default:
			return (<UserRegister
					setRegStep={setRegStep}
					setRegisterInfo={setRegisterInfo}
				/>
			)
	}
}
