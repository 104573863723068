import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Screen, Text } from 'components/ui'
import backgroundImg from 'assets/auth/background.png'
import styles from './auth.module.sass'

export default function LayoutLogin({ children }) {
	const { t } = useTranslation(['auth'])

	return (
		<div className={styles['auth-wrapper']}>
			<div className={cn('container', styles.container)} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems:'center', boxSizing: 'border-box',}}>
				{children}
			</div>
		</div>
	)
}
