const defaultState = {
	clientsArr: [],
	loading: true,
	clientsStatistics: [],
  clientInfo: [],
};

const GET_CLIENTS = "GET_CLIENTS";
const GET_CLIENT = "GET_CLIENT";
const GET_CLIENTS_STATISTICS = "GET_CLIENTS_STATISTICS";

export const clientReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_CLIENTS:
			return { ...state, clientsArr: action.payload, loading: false };

		case GET_CLIENT:
			return { ...state, clientInfo: action.payload, loading: false };

		case GET_CLIENTS_STATISTICS:
			return { ...state, clientsStatistics: action.payload, loading: false };
		default:
			return state;
	}
};

export const getClientsAction = (payload) => ({ type: GET_CLIENTS, payload });
export const getClientAction = (payload) => ({ type: GET_CLIENT, payload });
export const getClientsStatistisAction = (payload) => ({
	type: GET_CLIENTS_STATISTICS,
	payload,
});
//   export const switchPopularAction = (payload) => ({type: SWITCH_POPULAR, payload})
//   export const switchRecAction = (payload) => ({type: SWITCH_REC, payload})
//   export const deleteProductAction = (payload) => ({type: DELETE_PRODUCT, payload})
