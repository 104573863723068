import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import cn from "classnames";
import useMessage from "hooks/useMessage";
import useWindowSize from "hooks/useWindowSize";
import {
	Breadcrumbs,
	CatalogTitle,
	Category,
	ControlCategoryEdit,
	ControlCategoryAdd,
	ControlProductAdd,
	Categories,
	Products,
} from "components/catalog";
import {
	Text,
	Button,
	ControlMenu,
	ButtonGroup,
	Screen,
	Modal,
} from "components/ui";
import { useDispatch, useSelector } from "react-redux";
/*import {
	getAllCategories,
	changeCategoriesVisibility,
	changeCategoriesPopularity,
	getShopGlobalCategories,
} from "../../redux/asyncAction/category";*/
import {
	useGetCategoriesQuery,
	useChangeCategoryVisibilityMutation,
	useChangeCategoryPopularityMutation,
	useDeleteCategoryMutation,
} from "../../redux/reducers/category";
import { useGetProductsQuery } from "../../redux/reducers/product";
import { deleteCategory } from "../../redux/asyncAction/category";
//import { refreshCategoriesAction } from "../../redux/reducers/category";

export default function CategoriesPage() {
	const { t } = useTranslation(["catalog"]);

	const { deviceWidth } = useWindowSize();

	const [searchParams, setSearchParams] = useSearchParams();
	const { parentCategoryId, searchText, ...params } = Object.fromEntries(
		searchParams.entries()
	);

	const categoriesQuery = useGetCategoriesQuery({
		parentCategoryId,
		searchText,
	});

	const currentCategories = categoriesQuery.data?.categories || [];

	const page = Number(params.page) || 1;

	const skipProductsQuery = !parentCategoryId;

	const productsQuery = useGetProductsQuery(
		{
			categoryId: parentCategoryId,
			searchText,
			limit: 10,
			page,
		},
		{ skip: skipProductsQuery }
	);

	const products = productsQuery.data?.products || [];
	const productsTotal = productsQuery.data?.total || 0;

	const parentCategory = categoriesQuery.data?.parentCategory;

	const navigateOutwards = () => {
		setSearchParams((params) => {
			const nextParams = new URLSearchParams(params);

			if (parentCategory.parentCategoryId)
				nextParams.set("parentCategoryId", parentCategory.parentCategoryId);
			else nextParams.delete("parentCategoryId");

			nextParams.delete("page");

			return nextParams;
		});
	};

	if (!categoriesQuery.data) {
		return null;
	}

	return productsTotal && !skipProductsQuery ? (
		<Products
			title={t("navigation.goods")}
			products={products}
			productsTotal={productsTotal}
			withFilter={false}
			page={page}
			header={
				<>
					<CatalogTitle>{t("products")}</CatalogTitle>
					{parentCategory && (
						<Breadcrumbs
							goBack={navigateOutwards}
							className="offset-top-10"
							title={parentCategory.title}
							imageUrl={parentCategory.iconImageUrl}
							color={deviceWidth === "large" ? "red" : "primary"}
						/>
					)}
				</>
			}
		/>
	) : (
		<Categories
			currentCategories={currentCategories}
			parentCategory={parentCategory}
			parentCategoryId={parentCategoryId}
			header={
				<>
					<Text
						as="p"
						size="lg"
						className={cn(deviceWidth !== "large" && "offset-top-10")}
					>
						{parentCategoryId ? t("subCategories") : t("mainCategories")}
					</Text>
					{parentCategory && (
						<Breadcrumbs
							goBack={navigateOutwards}
							className="offset-top-10"
							title={parentCategory.title}
							imageUrl={parentCategory.iconImageUrl}
							color={deviceWidth === "large" ? "red" : "primary"}
						/>
					)}
				</>
			}
		/>
	);

	/*return (
		<>
			{modal}
			<section className="section">
				<div className="container">
					<Screen size="xl" down>
						<ButtonGroup column={deviceWidth === "small"}>
							<Button onClick={createCategory} fluid={true}>
								{!parentCategoryId
									? t("button.addCategory")
									: t("button.addSubCategory")}
							</Button>
							{!!currentCategories?.length && (
								<Button onClick={addProduct} fluid={true}>
									{t("button.addGoods")}
								</Button>
							)}
						</ButtonGroup>
					</Screen>
					<Text
						as="p"
						size="lg"
						className={cn(deviceWidth !== "large" && "offset-top-10")}
					>
						{parentCategoryId ? t("subCategories") : t("mainCategories")}
					</Text>
					{parentCategory && (
						<Breadcrumbs
							goBack={navigateOutwards}
							className="offset-top-10"
							title={parentCategory.title}
							imageUrl={parentCategory.iconImageUrl}
							color={deviceWidth === "large" ? "red" : "primary"}
						/>
					)}
					{Boolean(currentCategories?.length) && (
						<div className="offset-top-10">
							{/!*edit after connect to db*!/}
							{currentCategories.map((category) => (
								<Category
									//categoryIds={categoryIds}
									//setCategoryIds={setCategoryIds}
									id={category._id}
									key={category._id}
									category={category}
									onClick={handleCategoryClick}
									handlerPopular={handlerPopular}
									handlerVisible={handlerVisible}
									editCategory={editCategory}
									deleteCategory={onDeleteCategory}
								/>
							))}
						</div>
					)}
					<Screen size="xl">
						<ButtonGroup
							className={
								currentCategories?.length ? "offset-top-20" : "offset-top-10"
							}
						>
							<Button onClick={createCategory}>
								{!parentCategoryId
									? t("button.addCategory")
									: t("button.addSubCategory")}
							</Button>
							{Boolean(parentCategoryId) && (
								<Button
									disabled={Boolean(currentCategories.length)}
									onClick={addProduct}
								>
									{t("button.addGoods")}
								</Button>
							)}
						</ButtonGroup>
					</Screen>
				</div>
			</section>

			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeMenu}
				title={openedMenu?.value}
				// hasChanges={hasFaqChanges}
				// onSaveData={onAlertSaveData}
				// isCanClose={canCloseMenu}
			>
				{isCreateCategoryMode && (
					<ControlCategoryAdd
						setIsOpenMenu={setIsOpenMenu}
						lang={lang}
						setLang={setLang}
						parentCategory={parentCategory}
						parentTemplateCategoryId={parentCategory?.templateCategoryId}
					/>
				)}
				{isEditCategoryMode && (
					<ControlCategoryEdit
						setIsOpenMenu={setIsOpenMenu}
						lang={lang}
						setLang={setLang}
						category={editingCategory}
					/>
				)}

				{isAddProductMode && (
					<ControlProductAdd
						categoryId={parentCategory?._id}
						lang={lang}
						setLang={setLang}
						onSaveAddedProducts={onSaveAddedProducts}
					/>
				)}
			</ControlMenu>
		</>
	);*/
}
