import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import cn from 'classnames'
import useMessage from 'hooks/useMessage'
import useWindowSize from 'hooks/useWindowSize'
import RichText from 'components/RichText'
import { ControlMenu, Button, Collapse, Icon, Textarea, FormLabel, FormError, Text, Screen } from 'components/ui'
import styles from './settings.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { addQa } from 'redux/asyncAction/settings'
import { deleteQa } from 'redux/asyncAction/settings'
import { hideQa } from 'redux/asyncAction/settings'
import { editQa } from 'redux/asyncAction/settings'

export default function FaqStore() {
	const { t } = useTranslation(['settings', 'common'])
	const { register, handleSubmit, control, reset, getValues, trigger, formState: { errors } } = useForm()
	const [isOpenMenu, setIsOpenMenu] = useState(false)
	const [openedMenuType, setOpenedMenuType] = useState({})
	const [editingFaq, setEditingFaq] = useState({})
	const [addingFaq, setAddingFaq] = useState({})
	const [hasFaqChanges, setHasFaqChanges] = useState(false)
	const menuTypes = [
		{ type: 'add', value: t('faq.drawer.addFaq') },
		{ type: 'edit', value: t('faq.drawer.editFaq') }
	]
	const { showMessage } = useMessage()
	const { deviceWidth } = useWindowSize()
	const isEditMode = Object.keys(editingFaq).length > 0 && openedMenuType.type === 'edit'
	const isAddMode = openedMenuType.type === 'add'
	const tFaq = t('faq.messages.faq')

	const [editControl, setEditControl] = useState(false)

	//delete after connect to db
	const [userFaqs, setUserFaqs] = useState([])

	const { shopData, shopDataLoading, } = useSelector(state => state.settingsReducer)

	useEffect(() => {
		if (isAddMode) {
			const addingKeys = Object.keys(addingFaq)
			if (addingKeys.length) {
				setHasFaqChanges(addingKeys.some(key => addingFaq[key].length))
			}
		}

		return () => setHasFaqChanges(false)
	}, [isAddMode, addingFaq])

	useEffect(() => {
		if (isEditMode) {
			const matchedFaq = userFaqs.find(v => v.id === editingFaq.id)
			//change checking objects when will be connect to db
			if (JSON.stringify(matchedFaq) !== JSON.stringify(editingFaq)) {
				setHasFaqChanges(true)
			}
		}

		return () => setHasFaqChanges(false)
	}, [isEditMode, editingFaq, userFaqs])

	const resetForm = () => {
		reset({
				...getValues(),
				question: undefined,
				answer: undefined
			}, {
				keepErrors: false,
				keepDirty: false,
				keepIsSubmitted: false,
				keepTouched: false,
				keepIsValid: true,
				keepSubmitCount: false
			}
		)
	}

	const closeControlMenu = () => {
		setIsOpenMenu(false)
		resetForm()
		isEditMode && setEditingFaq({})
		isAddMode && setAddingFaq({})
	}

	const toggleOpenMenu = (isActive, type) => {
		setOpenedMenuType({})

		if (isActive) {
			const openedMenu = menuTypes.find(v => v.type === type) || menuTypes[0]

			setIsOpenMenu(true)
			setOpenedMenuType(openedMenu)
		} else {
			closeControlMenu()
		}
	}

	const toggleVisible = faqId => {
		const copyUserFaq = [...userFaqs]
		const indexChangingFaq = userFaqs.findIndex(v => v.id === faqId)
		const isFaqHidden = copyUserFaq[indexChangingFaq].hidden

		copyUserFaq[indexChangingFaq].hidden = !isFaqHidden
		setUserFaqs(copyUserFaq)

		if (isFaqHidden) {
			showMessage({ value: t('messages.itemVisible', { item: tFaq, ns: 'common' }) })
		} else {
			showMessage({ value: t('messages.itemHidden', { item: tFaq, ns: 'common' }) })
		}
	}

	const setEditMode = (itemId) => {
		setEditingFaq(userFaqs.find(v => v.id === itemId))
		toggleOpenMenu(true, 'edit')
	}

	const deleteFaqItem = itemId => {
		setUserFaqs(userFaqs.filter(v => v.id !== itemId))
		showMessage({ value: t('messages.itemDelete', { item: tFaq, ns: 'common' }) })
	}

	const onAddingFaq = (value, name) => {
		setAddingFaq({
			...addingFaq,
			[name]: value
		})
	}

	let dispatch = useDispatch()

	const addNewFaq = data => {
		data.id = Math.floor(Math.random() * 1000)
		data.question = data.question.replace(/\n/g, '')
		data.hidden = false
		setUserFaqs([...userFaqs, data])

		toggleOpenMenu(false)
		dispatch(addQa(data))
		resetForm()
		showMessage({ value: t('messages.itemAdd', { item: tFaq, ns: 'common' }) })
	}

	const onChangingFaq = (value, name) => {
		setEditingFaq({
			...editingFaq,
			[name]: value
		})
	}

	const saveEditingFaq = () => {
		const copyUserFaq = [...userFaqs]
		const indexChangingFaq = userFaqs.findIndex(v => v.id === editingFaq.id)

		copyUserFaq[indexChangingFaq].answer = editingFaq.answer
		copyUserFaq[indexChangingFaq].question = editingFaq.question
		setUserFaqs(copyUserFaq)
		toggleOpenMenu(false)
		showMessage({ value: t('messages.changesSaved', { ns: 'common' }) })
	}

	const canCloseMenu = async () => {
		return await trigger(['question', 'answer'])
	}

	const onAlertSaveData = () => {
		if (isAddMode) {
			const values = getValues()
			addNewFaq(values)
		}

		if (isEditMode) {
			saveEditingFaq()
		}
	}

	const editFaq = (data, id) => {
		console.log('faq editing',data)
		console.log('faq editing',editingFaq._id)
		const idEdit = editingFaq._id
		dispatch(editQa({...data, idEdit }))
		toggleOpenMenu(false)
		showMessage({ value: t('messages.changesSaved', { ns: 'common' }) })
	}

	return (
		<>
			<div className={cn(styles['setting-store'], styles['faq'])}>
				<div className={styles['setting-store__header']}>
					<div className={styles['title']}>
						<Icon size='md' name='faq' color='blue' />
						<Text size='md' color='blue'>{t('filters.faq')}</Text>
					</div>
					<Screen size='xl'>
						<Icon name='question-border' size='md' />
					</Screen>
				</div>

				<div className={styles['setting-store__content']}>
					<Button
						onClick={() => toggleOpenMenu(true, 'add')}
						fluid={deviceWidth === 'small'}
					>
						{t('buttons.add', { ns: 'common' })}
					</Button>

					{shopData?.QA?.length > 0 && (
						<div className='offset-top-20'>
							<h2>{t('faq.yourQuestions')}</h2>

							<Collapse className='offset-top-20'>
								{shopData?.QA?.map((userFaqItem, i) => (
									<Collapse.Panel
										key={userFaqItem._id}
										label={userFaqItem.question}
										index={i}
										isArrowMoving={false}
										options={
											<>
												<Icon
													name='eye'
													size='md'
													turnOff={!userFaqItem?.visible}
													onClick={() => {
														dispatch(hideQa(userFaqItem._id, !userFaqItem.visible))
													}}
													title={t('buttonDesc.hidden', { value: '', ns: 'common' })}
												/>
												<Icon
													name='edit'
													size='md'
													turnOff={editingFaq.id !== userFaqItem.id}
													onClick={() => {
														setEditControl(true)
														setEditingFaq({
														_id: userFaqItem._id,
														question: userFaqItem.question,
														answer: userFaqItem.answer,
														visible: userFaqItem.visible
														})
														setIsOpenMenu(true)
														console.log('edit yopta', editControl)
													}}
													title={t('buttonDesc.edit', { value: '', ns: 'common' })}
												/>
												<Icon
													name='delete'
													size='md'
													color='red'
													onClick={() => dispatch(deleteQa(userFaqItem._id))}
													title={t('buttonDesc.delete', { value: '', ns: 'common' })}
												/>
											</>
										}
									>
										<div
											dangerouslySetInnerHTML={{ __html: userFaqItem.answer }}
											className='result-tinymce'
										/>
									</Collapse.Panel>
								))}
							</Collapse>
						</div>
					)}
				</div>
			</div>

			{isAddMode &&

			<ControlMenu
				isOpen={isOpenMenu}
				onClose={closeControlMenu}
				title={openedMenuType?.value}
				hasChanges={hasFaqChanges}
				onSaveData={onAlertSaveData}
				isCanClose={canCloseMenu}
			>
				<form onSubmit={handleSubmit(addNewFaq)}>
					<Textarea
						id='question'
						name='question'
						register={register}
						rules={{
							required: { value: true, message: t('validations.required', { ns: 'common' }) },
							minLength: { value: 2, message: t('validations.minLength', { value: 2, ns: 'common' }) },
							maxLength: { value: 200, message: t('validations.maxLength', { value: 200, ns: 'common' }) }
						}}
						label={t('faq.drawer.question')}
						placeholder={t('faq.drawer.placeholderQuestion')}
						onChange={value => onAddingFaq(value, 'question')}
						errors={errors}
						fluid={true}
						heightSize='md'
						required
						labelSize={deviceWidth === 'large' ? 'lg' : 'sm'}
						style={{marginBottom: '12px'}}
					/>
					<Textarea
						id='answer'
						name='answer'
						register={register}
						rules={{
							required: { value: true, message: t('validations.required', { ns: 'common' }) },
							minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
						}}
						label={t('faq.drawer.answer')}
						placeholder={t('faq.drawer.placeholderAnswer')}
						onChange={value => onAddingFaq(value, 'question')}
						errors={errors}
						fluid={true}
						heightSize='md'
						required
						labelSize={deviceWidth === 'large' ? 'lg' : 'sm'}
					/>
					
					<Button
						type='submit'
						className='offset-top-10'
						fluid={deviceWidth === 'small'}
					>
						{t('buttons.add', { ns: 'common' })}
					</Button>
				</form>
			</ControlMenu>}

			{editControl &&
			<ControlMenu
			isOpen={isOpenMenu}
			onClose={closeControlMenu}
			title={'Редактировать'}
			hasChanges={hasFaqChanges}
			onSaveData={onAlertSaveData}
			isCanClose={canCloseMenu}
		>
			<form onSubmit={handleSubmit(editFaq)}>
				<Textarea
					id='question'
					name='question'
					register={register}
					rules={{
						required: { value: true, message: t('validations.required', { ns: 'common' }) },
						minLength: { value: 2, message: t('validations.minLength', { value: 2, ns: 'common' }) },
						maxLength: { value: 200, message: t('validations.maxLength', { value: 200, ns: 'common' }) }
					}}
					label={t('faq.drawer.question')}
					placeholder={t('faq.drawer.placeholderQuestion')}
					defaultValue={editingFaq?.question}
					onChange={value => isAddMode
						? onAddingFaq(value, 'question')
						: onChangingFaq(value, 'question')
					}
					errors={errors}
					fluid={true}
					heightSize='md'
					required
					labelSize={deviceWidth === 'large' ? 'lg' : 'sm'}
					style={{marginBottom: '12px'}}
				/>

<Textarea
					id='answer'
					name='answer'
					register={register}
					rules={{
						required: { value: true, message: t('validations.required', { ns: 'common' }) },
						minLength: { value: 10, message: t('validations.minLength', { value: 10, ns: 'common' }) }
					}}
					label={t('faq.drawer.answer')}
					placeholder={t('faq.drawer.placeholderQuestion')}
					defaultValue={editingFaq?.answer}
					onChange={value => isAddMode
						? onAddingFaq(value, 'question')
						: onChangingFaq(value, 'question')
					}
					errors={errors}
					fluid={true}
					heightSize='md'
					required
					labelSize={deviceWidth === 'large' ? 'lg' : 'sm'}
				/>
			
				<Button
					type='submit'
					className='offset-top-10'
					fluid={deviceWidth === 'small'}
				>
					{t('Сохранить', { ns: 'common' })}
				</Button>
			</form>
			</ControlMenu>}
			
		</>
	)
}
