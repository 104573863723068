import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { splitString } from "helpers";
import { Text } from "components/ui";
import styles from "./clients.module.sass";

const ClientsInfo = ({ clientsArr }) => {
	const { t } = useTranslation(["clients"]);

	return (
		// <div className={cn(styles['clients-info'], 'offset-xl-top-20')}>
		// 	{/* <ul>
		// 		<li className={styles['clients-info__title']}>
		// 			<Text size='md'>{t('info.info')}</Text>
		// 		</li>
		// 		<li>
		// 			<div className={styles['clients-info__item']}>
		// 				<Text size='sm'>{t('info.clients')}</Text>
		// 				<Text size='sm' color='blue'>2519</Text>
		// 			</div>
		// 		</li>
		// 		<li>
		// 			<div className={styles['clients-info__item']}>
		// 				<Text size='sm'>{t('info.sum')}</Text>
		// 				<Text size='sm' color='blue'>{splitString(5000000)} ₽</Text>
		// 			</div>
		// 		</li>
		// 	</ul> */}
		// </div>
		<div>
			<div className={styles.clInfo}>
				<div className={styles.clInfoNum}>
					{clientsArr.statistics.countOfBuyers || 0}
				</div>
				<div className={styles.clInfoTitle}>Кол-во клиентов</div>
			</div>
			<div className={styles.clInfo}>
				<div className={styles.clInfoNum}>
					{/* {`${clientsStatistics.buyerOrdersAmount}`
						.split("")
						.reverse()
						.map((el, index) => (index % 3 !== 2 ? el : ` ${el}`))
						.reverse()
						.join("")}{" "} */}
					{clientsArr.statistics.totalOrdersAmount} ₽
				</div>
				<div className={styles.clInfoTitle}>Сумма заказов</div>
			</div>
		</div>
	);
};

export default React.memo(ClientsInfo);
