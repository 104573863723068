import categoryPhoto from 'assets/delete/category-photo.png'

export const defaultProducts = [
	{
		id: '0',
		name: '1 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '20500',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '1',
		name: '2 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '2',
		name: '3 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '20500',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '3',
		name: '4 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '4',
		name: '5 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '20500',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '5',
		name: '6 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '6',
		name: '7 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '20500',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '7',
		name: '8 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '8',
		name: '9 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '20500',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	},
	{
		id: '9',
		name: '10 Смартфон Apple iPhone SE 64GB / MHGP3 (черный)',
		img: categoryPhoto,
		price: '26596',
		measurement: 'За шт',
		availability: '500',
		amount: '1',
		category: 'Смартфоны и гаджеты',
		discount: '',
		popular: true,
		recommend: true,
		visible: true,
		characteristics: [
			{ id: '0', name: 'Диагональ экрана', value: '4.7' },
			{ id: '1', name: 'Диагональ экрана', value: '4.7' },
			{ id: '2', name: 'Диагональ экрана', value: '4.7' }
		],
		modifications: [
			{ id: '0', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' },
			{ id: '1', modList: [{ value: 'Серый' }, { value: 'Серый' }], price: '1000' }
		],
		recommendList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		buyWithList: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		oftenSearch: [
			{ id: '0', value: 'Защитное стекло АМП / 3 мл' },
			{ id: '1', value: 'Защитное стекло АМП / 3 мл' }
		],
		productsPhotos: [categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto, categoryPhoto],
		purchasePrice: '28900',
		productDescription: 'iPhone SE — воплощение красоты и интеллекта. Инновационные технологии подсветки позволили нам создать дисплей закруглённый по углам и занимающий всю переднюю панель.'
	}
]
