import { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cn from "classnames";
import useOverlay from "hooks/useOverlay";
import useOverflow from "hooks/useOverflow";
import SearchFormSection from "./SearchFormSection";
import SearchFormGroup from "./SearchFormGroup";
import SearchFormGroupItem from "./SearchFormGroupItem";
import { Button, Input, Screen, Text } from "components/ui";
import styles from "./search-form.module.sass";
import InfiniteScroll from "react-infinite-scroll-component";
import { nanoid } from "nanoid";

const SearchForm = forwardRef((props, ref) => {
	const {
		placeholder,
		onSearch,
		className,
		inputName,
		overlay,
		searchButton,
		searchPrefix,
		resetInput,
		handleEnter,
		resetSearchInput,
		defaultValue,
		onChange,
		next,
	} = props;
	const { t } = useTranslation(["common"]);
	const { handlerOverlay } = overlay && useOverlay();
	const { handlerOverflow } = useOverflow();
	const searchFormWrapRef = useRef(ref || null);
	const searchResultRef = useRef(null);
	const mobileInputRef = useRef(null);

	const [scrollableId] = useState(`id-${nanoid()}`);

	const children = Array.isArray(props.children)
		? props.children
		: props.children
		? [props.children]
		: [];

	const classNames = cn(styles["search-form-wrapper"], className);

	const [searchValue, setSearchValue] = useState(defaultValue || "");

	// onSearch = () => {
	// 	dispatch(getClients(1,10,searchValue))
	// }

	const onClickOutsideResult = (e) => {
		const element = e.target;

		if (
			searchFormWrapRef.current &&
			!searchFormWrapRef.current.contains(element)
		) {
			e.preventDefault();
			e.stopPropagation();
			searchFormWrapRef.current.classList.remove(styles.active);
			overlay && handlerOverlay(false);
			document.body.removeEventListener("click", onClickOutsideResult);
		}
	};

	// const handleSearchClose = () => {
	// 	searchFormWrapRef.current.classList.remove(styles.active)

	// 	if (mobileInputRef.current) {
	// 		handlerOverflow(false)
	// 	}
	// }

	const handleSearchFocus = () => {
		document.body.addEventListener("click", onClickOutsideResult);
		searchFormWrapRef.current.classList.add(styles.active);
		overlay && handlerOverlay(true);

		if (mobileInputRef.current) {
			mobileInputRef.current.focus();
			handlerOverflow(true);
		}
	};

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			//add methods after connect to db
		}
	};

	const handleChange = (event) => {
		let value = event.target.value;
		setSearchValue(value);

		if (!value && resetInput) {
			resetInput();
		}

		if (onChange) onChange(value);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter" && handleEnter) {
			handleEnter(event.target.value);
			if (resetSearchInput) {
				resetSearchInput(setSearchValue);
			}
		}
	};

	return (
		<div
			className={classNames}
			style={{ border: "1px solid #E5E6EB", borderRadius: "5px" }}
			ref={searchFormWrapRef}
		>
			<div className={styles["search-form"]}>
				<Input.Group>
					<Input
						value={searchValue}
						onKeyPress={handleKeyPress}
						id={inputName ? inputName : "search"}
						name={inputName ? inputName : "search"}
						placeholder={placeholder}
						type="search"
						prefix={searchPrefix ? "search" : ""}
						heightSize="sm"
						onChange={handleChange}
						onFocus={handleSearchFocus}
						fluid={true}
						onBlur={() => onSearch(searchValue)}
					/>
					{searchButton && (
						<Screen size="xl">
							<Input.GroupItem>
								<Button onClick={() => onSearch(searchValue)} size="sm">
									{t("buttons.search")}
								</Button>
							</Input.GroupItem>
						</Screen>
					)}
				</Input.Group>
			</div>
			<div className={styles["search-result-wrapper"]}>
				{/*complete after do responsible layout*/}
				{/*<Screen size='sm' down>*/}
				{/*	<div className={styles.searchResultHeader}>*/}
				{/*		<Input*/}
				{/*			fluid*/}
				{/*			id='searchFormMobile'*/}
				{/*			suffix='search'*/}
				{/*			name='search'*/}
				{/*			placeholder='Поиск товаров по каталогу, наименованию и артикулу...'*/}
				{/*			type='search'*/}
				{/*			ref={mobileInputRef}*/}
				{/*			tabIndex={-1}*/}
				{/*		/>*/}
				{/*		<Button*/}
				{/*			style={{ marginLeft: 12 }}*/}
				{/*			view='plain'*/}
				{/*			onClick={handleSearchClose}*/}
				{/*			icon='close'*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*</Screen>*/}
				{children && children?.length > 0 && (
					<div
						id={scrollableId}
						className={styles["search-result"]}
						ref={searchResultRef}
					>
						<InfiniteScroll
							next={next}
							hasMore={true}
							loader={null}
							dataLength={children?.length}
							scrollableTarget={scrollableId}
						>
							{children}
						</InfiniteScroll>
					</div>
				)}
			</div>
		</div>
	);
});

SearchForm.Section = SearchFormSection;
SearchForm.Group = SearchFormGroup;
SearchForm.GroupItem = SearchFormGroupItem;

SearchForm.defaultProps = {
	placeholder: "Поиск...",
	overlay: true,
	searchButton: true,
	searchPrefix: true,
};

SearchForm.propsTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	overlay: PropTypes.bool,
	searchButton: PropTypes.bool,
	searchPrefix: PropTypes.bool,
};

export default SearchForm;
