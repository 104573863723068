import { baseApi } from "api";

const apiWithDashboardTags = baseApi.enhanceEndpoints({
	addTagTypes: [],
});

export const dashboardApi = apiWithDashboardTags.injectEndpoints({
	endpoints: (builder) => ({
		getAnalyticalData: builder.query({
			query: (params) => ({
				url: "/getAnalyticalData",
				params,
			}),
		}),
		getSalesChartData: builder.query({
			query: (params) => ({
				url: "/getSalesChartData",
				params,
			}),
		}),
		getGlobalInformation: builder.query({
			query: (params) => ({
				url: "/getGlobalInformation",
				params,
			}),
		}),
		getRecentlySoldItems: builder.query({
			query: (params) => ({
				url: "/getRecentlySoldItems",
				params,
			}),
		}),
	}),
});

export const {
	useGetAnalyticalDataQuery,
	useGetSalesChartDataQuery,
	useGetGlobalInformationQuery,
	useGetRecentlySoldItemsQuery,
} = dashboardApi;
