import { $api } from "api";

class Settings {
	async getShopData() {
		const res = await $api.get("/getShopData", {
			headers: {
				"Cache-Control": "no-cache",
				Pragma: "no-cache",
				Expires: "0",
			},
		});
		return res;
	}

	async editMain(data) {
		return await $api.post("/editShopMainInformation", {
			mainInformation: {
				address: data.address,
				logoUrl: data.logoUrl,
				schedule: {
					mondayToFriday: {
						openTime: data.weekdaysFrom,
						closeTime: data.weekdaysTo,
					},
					saturdayToSunday: {
						openTime: data.weekendFrom,
						closeTime: data.weekendTo,
					},
				},
				shortDescription: data.description,
				phoneNumber: data.number,
			},
		});
	}

	async editReq(data) {
		return await $api.post("/editShopBankDetails", {
			bankDetails: {
				organizationName: data.organization,
				OKVED: "OOO",
				INN: data.inn,
				KPP: data.kpp,
				OGRN: data.ogrn,
				BIK: data.bik,
				taxType: data.taxation,
				bankName: data.bankName,
				checkingAccount: data.checkingAccount,
				officialAddress: data.legalAddress,
				directorFullName: data.nameHead,
			},
		});
	}

	async editContacts(data) {
		return await $api.post("/editShopContacts", {
			contacts: {
				emailAddresses: data.email,
				phoneNumbers: data.number,
				sectionInformation: data.sectionInfo,
			},
		});
	}

	async addQa(data) {
		return await $api.post("/addQa", {
			QA: {
				question: data.question,
				answer: data.answer,
			},
		});
	}

	async deleteQa(id) {
		return await $api.post("/deleteQa", {
			_id: id,
		});
	}

	async editQa(data) {
		console.log("apiii", data);
		return await $api.post("/editQa", {
			_id: data.idEdit,
			update: {
				question: data.question,
				answer: data.answer,
			},
		});
	}

	async hideQa(id, value) {
		return await $api.post("/changeQaVisibility", {
			_id: id,
			value: value,
		});
	}

	async editSeo(data) {
		return await $api.post("/editSeo", {
			seo: data
		});
	}
	async updateDomain(data) {
		return await $api.post("/updateShopDomain", {
			domain: data.domain,
			subDomain: data.subDomain,
			favicon: data.favicon
		});
	}
}

export default new Settings();
